import PropTypes from "prop-types";

function getUserFilePath({ file, id }) {
  const exception = file.name.split(".").pop();
  const blobName = `users/${id}/photo.${exception}`;
  return blobName;
}

getUserFilePath.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  id: PropTypes.number.isRequired,
};

export default getUserFilePath;
