import React from "react";

import PropTypes from "prop-types";

import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";
import { useSnackbar } from "notistack";
import { COLORS } from "../../constants/base";

import axiosDeleteUser from "../../utils/users/axiosDeleteUser";

function DeleteUser({ id, setDataUsers, handleCloseModal }) {
  const { enqueueSnackbar } = useSnackbar();

  const StyledIconButton = styled(IconButton)`
    margin: 0;
    padding: 2px;
    color: ${COLORS.gray_icon};
  `;

  const handleDelete = async () => {
    try {
      await axiosDeleteUser({ id, setDataUsers, enqueueSnackbar });
    } catch (error) {
      console.warn("Delete User error", error);
    }
    handleCloseModal();
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <StyledIconButton
        aria-label="delete user"
        component="label"
        onClick={handleDelete}
      >
        <DeleteIcon />
      </StyledIconButton>
    </div>
  );
}

DeleteUser.propTypes = {
  id: PropTypes.number.isRequired,
  setDataUsers: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default DeleteUser;
