import PropTypes from "prop-types";
import { baseAxios } from "../axios";

// TDOO: remane to eventUpdate
export const axiosUpdate = async ({
  eventId,
  userId,
  status,
  foodId,
  trimmings,
}) => {
  const url = `api/events/${eventId}`;
  const data = { user: userId, status };

  if (trimmings !== null) data.trimmings = trimmings;
  if (foodId) data.food = foodId;

  const response = await baseAxios.patch(url, data);
  return response;
};

axiosUpdate.propTypes = {
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  foodId: PropTypes.string,
  trimmings: PropTypes.bool,
};

export default axiosUpdate;
