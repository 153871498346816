import React, {useState} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import {COLORS} from "../../../../constants/base";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {TRIMMINGS_COLOR_MAP} from "../../../../constants/explore";
import GlobalStyles from "@mui/material/GlobalStyles";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <text x={sx + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}%`}</text>
      {/*<text x={sx + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">*/}
      {/*  {`${(payload?.value).toFixed(2)}kg`}*/}
      {/*</text>*/}
    </g>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
   cx,
   cy,
   midAngle,
   innerRadius,
   outerRadius,
   percent,
   index,
 }) => {
  const radius = outerRadius + 10; // adjust the radius to move the label outside the pie
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx? 'tart' : 'end'}
      dominantBaseline="central"
      fontSize={12} // adjust the font size to your liking
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const Chart = ({ data }) => {
  const [active, setActive] = useState(1)
  return (
    <Grid container sx={{ height: '100%' }}>
      <GlobalStyles styles={{
        '.recharts-wrapper svg': {
          overflow: 'visible',
        }
      }} />
      <Grid item xs={12} md={8}>
        <ResponsiveContainer width="100%" height="100%" minHeight={400}>
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={active}
              data={data}
              innerRadius={70}
              outerRadius={100}
              labelLine={false}
              fill="#8884d8"
              dataKey="percentage"
              label={renderCustomizedLabel}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={TRIMMINGS_COLOR_MAP[entry.name]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack justifyContent="center" sx={{ height: '100%' }}>
          {data.map(item => (
            <Stack key={item.name} spacing={4} direction="row" alignItems="center" justifyContent="flex-end">
              <Typography>{item.name}</Typography>
              <Box width={10} height={10} sx={{ backgroundColor: TRIMMINGS_COLOR_MAP[item.name] }} />
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Chart;
