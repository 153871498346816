const displayItems = [
  {
    name: "Weight",
    id: "weight",
  },
  {
    name: "Price",
    id: "price",
  },
  {
    name: "Environmental",
    id: "environmental",
  },
];

const fullDisplayItems = [
  ...displayItems,
  {
    name: "Waste Per Cover",
    id: "waste_per_cover",
  },
];

const orderingItemsShort = [
  {
    name: "Recommended",
    id: "recommended",
  },
  {
    name: "Environmental",
    id: "environmental",
  },
]

const orderingItems = [
  orderingItemsShort[0],
  {
    name: "Newest First",
    id: "newest",
  },
  {
    name: "Oldest First",
    id: "oldest",
  },
  orderingItemsShort[1],
];

const orderingFoods = [
  ...orderingItems,
  {
    name: "Focus Foods",
    id: "weight",
  }
]

const orderingFeed = [
  ...orderingItems,
  {
    name: "With Comments",
    id: "comments",
  }
]

const statusItems = [
  "SUCCESSFUL",
  "DISCARDED",
  "ESCALATED",
  "PROCESSING",
].sort();

const roleItems = ["Admin", "Labeller", "User"];

const autoLabelItems = ["Yes", "No"];

const constants = {
  title: "Filters",
  ordering: {
    label: "Sort",
    items: orderingItems,
    shortItems: orderingItemsShort,
    foodItems: orderingFoods,
    feedItems: orderingFeed,
    default: orderingItems[0],
  },
  display: {
    label: "Display",
    items: displayItems,
    fullItems: fullDisplayItems,
    default: displayItems[0],
  },
  condense: {
    label: "Condense",
    default: false,
  },
  withSecondaryWaste: {
    label: "Include Secondary Waste",
    default: false,
  },
  site: {
    label: "Site",
  },
  zone: {
    label: "Monitor",
  },
  food: {
    label: "Food",
  },
  trimmings: {
    label: "Including Trimmings",
    withoutLabel: "Not Including Trimmings",
  },
  secondaryWaste: {
    label: "Only Secondary Waste",
  },
  nonFood: {
    label: "Only Non-Food",
  },
  comments: {
    label: "Only Comments",
  },
  group: {
    label: "Group",
  },
  role: {
    label: "Role",
    items: roleItems,
    default: [],
  },
  status: {
    label: "Status",
    items: statusItems,
    default: [],
  },
  autoLabel: {
    label: "Auto",
    items: autoLabelItems,
    default: autoLabelItems[1],
  },
};

export default constants;
