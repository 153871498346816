function getRandomHexColor() {
  // Generate a random color component (0 to 255)
  const randomColorComponent = () => Math.floor(Math.random() * 256);

  // Convert the RGB values to a hexadecimal string
  const r = randomColorComponent().toString(16).padStart(2, '0');
  const g = randomColorComponent().toString(16).padStart(2, '0');
  const b = randomColorComponent().toString(16).padStart(2, '0');

  return `#${r}${g}${b}`.toUpperCase();
}

export function generateColors(numColors) {
  const colors = new Set();

  // Generate unique random colors
  while (colors.size < numColors) {
    colors.add(getRandomHexColor());
  }

  return Array.from(colors);
}

export const CHART_COLORS = [
  "#3F5D7D",
  "#A1B0D4",
  "#8B9C79",
  "#2A8E59",
  "#9C1C55",
  "#F4B7C3",
  "#8D1E2E",
  "#5B7F3E",
  "#D4A77D",
  "#E68D6F",
  "#2B6F7F",
  "#D1B6A3",
  "#4F6F4F",
  "#C6B13F",
  "#6B4F7F",
  "#F3C6C6",
  "#9A9F6E",
  "#4B9B2D",
  "#E0B7B0",
  "#3C3D91",
  "#A9B0D8",
  "#2F8F9D",
  "#F5A24B",
  "#B7D4A7",
  "#5B6F8B",
  "#E59D6F",
  "#4F6D9C",
  "#B48E4F",
  "#8D3F2E",
  "#9C6B4F",
  "#F3C9A1",
  "#6E9F4F",
  "#D76F4F",
  "#8B9F4F",
  "#D3B6D2",
  "#2B8C6E",
  "#F2B28C",
  "#7D4F6F",
  "#6C8B8C",
  "#D4A5B7",
  "#4F9B2D",
  "#7B4F6E",
  "#F6D6B5",
  "#9C4F6F",
  "#3D7F9C",
  "#8F4F2E",
  "#D1C6A1",
  "#6E5B8B",
  "#F4D3A1",
  "#9C8F6E",
  "#7F9B4F",
  "#D1A5B4",
  "#2D8C7F",
  "#F2A5D1",
  "#8B4F2D",
  "#7D4F5B",
  "#C9D4A1",
  "#6F9C4F",
  "#F5D7B6",
  "#8F6D9C",
  "#4D8B7F",
  "#D1A7F2",
  "#6E8F4F",
  "#F4C9A1",
  "#7B8C6F",
  "#9C4F7F",
  "#D6B5C9",
  "#3C9F7D",
  "#F5A24B",
  "#8B9F6E",
  "#D7C9F2",
  "#4F6D9B",
  "#9F8B4F",
  "#C9D7F2",
  "#6E4F9C",
  "#F3D2B7",
  "#7F4B6E",
  "#8C9F4F",
  "#D1A5F4",
  "#2B7F6E",
  "#F5A6D1",
  "#9C4F5B",
  "#6E8B4F",
  "#D7C9A1",
  "#4F7D9C",
  "#8B9F4F",
  "#C6A1D1",
  "#3C7F9B",
  "#F4D6B5",
  "#6E9C4F",
  "#7D4F8B",
  "#D1A6F4",
  "#8C4F2D",
  "#F5B6A1",
  "#9C8F4F",
  "#2D7F6E",
  "#D6B5C9",
  "#6E4F8B",
  "#F3D6B7",
  "#9F4F7D",
  "#7C4F6E",
  "#C9D4A1",
  "#4F6D8C",
  "#F5D7B4",
  "#9C4F8B",
  "#2D8C6E",
  "#D1A5F4",
  "#6F9B4F",
  "#F4C9A1"
]
