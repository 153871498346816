import React from 'react';
import Base from "../Base";
import {WIDGET_IDENTITY} from "../config";
import Button from "@mui/material/Button";
import {Stack} from "@mui/material";
import {formatDataQuery} from "../../../utils/date/formatDataQuery";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {selectExplore} from "../../../redux/explore";
import {mepMetricParams} from "../../explore/helpers";
import Loader from "../Loader";
import Food from "../FocusFood/components/Food";
import {prepareDataToDisplay} from "./helpers";
import Placeholder from "../components/Placeholder";
import {useWidget} from "../useWidget";

const startOfCurrentMonth = dayjs().startOf('month').format('YYYY-MM-DD')


const MostDailyWaste = () => {
  const { filters } = useSelector(selectExplore)
  const selectedSite = filters.sites[0]?.id
  const params = {
    site: selectedSite,
    startDate: startOfCurrentMonth,
    endDate: formatDataQuery(new Date()),
    metric:  mepMetricParams[filters.metric]
  }

  const { data, isLoading, title, tip, goToExplore } = useWidget(WIDGET_IDENTITY.MOST_WASTED_GROUPS, {
    fetchKey: ['api/overview/most_wasted_groups/', params],
    exploreParams: {
      params: filters,
      path: '/insights',
    }
  })

  const dataToShow = data?.chart.length ? prepareDataToDisplay(data.chart) : [];
  const mostWasted = dataToShow[0]?.value

  return (
    <Base title={title} tooltip={tip} id={WIDGET_IDENTITY.MOST_WASTED_GROUPS} value={data?.most_wasted_group} canUnpin>
      <Base.Content style={{ minHeight: '280px' }}>
        {isLoading && <Loader />}
        {!!dataToShow.length && (
          <Stack spacing={4}>
            {dataToShow.map(item => (
              <Food
                metric={filters.metric}
                name={item.name}
                subcategory={item.subName}
                value={item.value}
                unit={data?.unit}
                image_url={item.image}
                max={mostWasted}
                key={item.name}
              />
            ))}
          </Stack>
        )}
        {!dataToShow.length && !isLoading && (
          <Placeholder text="No Data" />
        )}
      </Base.Content>
      <Base.Footer>
        <Stack direction="row" justifyContent="flex-end">
          <Button disabled={isLoading} variant="link" onClick={goToExplore}>Explore Food Groups</Button>
        </Stack>
      </Base.Footer>
    </Base>
  );
};

export default MostDailyWaste;
