import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useDispatch } from "react-redux";

import patchDevice from "./axios/patchDevice";
import { updateZones } from "../../redux/zones";
import siteProps from "../../props/siteProps";

function AssociateSiteForm({ selectedDevice, setDevices, mixpanelSubmit, sites, handleCloseDialog }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        device: selectedDevice.id,
        site: selectedDevice.site_id,
      }}
      isInitialValid
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={Yup.object().shape({
        device: Yup.number().required(),
        site: Yup.number().required(),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        const data = {
          site: values.site,
        };
        const responce = await patchDevice({
          id: values.device,
          data,
          enqueueSnackbar,
        });

        if (responce.error) {
          setErrors(responce.error);
        } else {
          const selectedSite = sites.find((s) => s.id === values.site);
          setDevices((prevState) =>
            prevState.map((d) => {
              if (d.id === values.device) {
                return {
                  ...d,
                  site_id: selectedSite.id,
                  site_name: selectedSite.name,
                };
              }
              return d;
            })
          );
          dispatch(updateZones(responce.data));
          handleCloseDialog()
        }

        setSubmitting(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <FormControl fullWidth variant="standard" sx={{ my: 4 }}>
            <InputLabel id="site-select">Site</InputLabel>
            <Select
              labelId="site-select"
              name="site"
              value={values.site}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {sites.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
            {touched.site && errors.site && (
              <FormHelperText>{errors.site}</FormHelperText>
            )}
          </FormControl>

          <Button
            fullWidth
            sx={{ height: "50px", my: 4 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={mixpanelSubmit}
          >
            Save
          </Button>
        </form>
      )}
    </Formik>
  );
}

AssociateSiteForm.propTypes = {
  selectedDevice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    zone_id: PropTypes.number,
    site_id: PropTypes.number,
  }),
  setDevices: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape(siteProps)).isRequired,
};

AssociateSiteForm.defaultProps = {
  selectedDevice: null,
};

export default AssociateSiteForm;
