import React from 'react';
import {Box, Button, Stack, Switch, TextField, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {copyToClipboard} from "../../utils/copyToClipboard";
import useIsMobile from "../../hooks/useIsMobile";
import IconButton from "@mui/material/IconButton";
import {FileCopy} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {COLORS} from "../../constants/base";

const YellowSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.color.yellow,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.color.yellow,
  },
}));

const CommonForm = ({ inputProps, description, onSubmit, onSwitch, isActive, copyText, submitText, helperText }) => {
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useIsMobile()
  const handleCopy = () => {
    copyToClipboard(inputProps.value)
      .then(() => enqueueSnackbar('Copied', {variant: 'success'}))
  }
  return (
    <Stack spacing={4}>
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        {
          typeof description === 'string'
          ? <Typography>{description}</Typography>
          : description()
        }
        <YellowSwitch disabled={!inputProps?.value} onClick={onSwitch} checked={isActive} />
      </Stack>
      <Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField {...inputProps} fullWidth={isMobile} disabled variant="outlined" size="small" style={{ flex: 1 }} />
          {isMobile && (
            <IconButton disabled={!inputProps?.value} onClick={handleCopy}>
              <FileCopy color="primary" />
            </IconButton>
          )}
          {!isMobile && (
            <Button disabled={!inputProps?.value} onClick={handleCopy} variant="contained" color="secondary" size="small">{copyText}</Button>
          )}
        </Stack>
        {helperText && <Typography color={COLORS.gray_light} variant="small2">{helperText}</Typography>}
      </Box>
      <Stack direction="row">
        <Button fullWidth={isMobile} onClick={onSubmit} variant="contained" color="secondary">{submitText}</Button>
      </Stack>
    </Stack>
  );
};

export default CommonForm;
