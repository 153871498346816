import { COLORS } from "../../../constants/base";

export const initialOptions = {
  chart: {
    stacked: false,
    animations: {
      initialAnimation: {
        enabled: true,
      },
    },
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  colors: [`${COLORS.yellow}`],
  dataLabels: {
    enabled: false,
  },
  noData: {
    text: "No data",
    align: "center",
    verticalAlign: "middle",
  },
  plotOptions: {
    tickPlacement: "on",
    // tickPlacement: "between",
    bar: {
      horizontal: false,
      dataLabels: {
        position: "top",
        orientation: "vertical",
      },
    },
  },
  stroke: {
    curve: "smooth",
  },
  tooltip: {
    // x: {},
    // y: {},
  },
  xaxis: {
    // type: "datetime",
    // type: "category",
    // min: new Date(),
    // max: new Date(),
    // tickPlacement: "on",
    // labels: {},
  },
  yaxis: {
    labels: {
      formatter: (value) => parseInt(value, 10),
    },
    tooltip: {},
  },
};
