const commentsChoice = [
  "Unexpected Increase in Covers",
  "Unexpected Decrease in Covers",
  "Overproduction",
  "Preparation Issue",
  "Plate Surplus",
  "Unserved Surplus",
  "Expired/Storage Issue",
  "Staff Training Needed",
  // "Other",
];

export default commentsChoice;
