import { useEffect } from "react";

const useClickOutside = (ref, handler) => {
  const refs = Array.isArray(ref) ? ref : [ref];

  const shouldTrigger = (event) =>
    refs.every(
      (ref) => ref.current && !ref.current.contains(event.target)
    );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shouldTrigger(event)) {
        handler();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handler]);
};

export default useClickOutside;
