import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Avatar, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { faSackDollar, faScaleBalanced, faDroplet, faEarthAmerica } from "@fortawesome/free-solid-svg-icons";

import HeaderTypography from "../../../pages/typographies/BlueHeader";
import GreyTypography from "../../../pages/typographies/GreyTypography";
import { selectFilters } from "../../../redux/filters";
import { defaultFoodImage } from "../../../constants/urls";
import numberFormater from "../../../utils/formaters/numberFormater";
import HeaderValueBlock from "./HeaderValueBlock";
import formatFullDate from "../../../utils/date/formatFullDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import {COLORS} from "../../../constants/base";
import Tooltip from "../../tooltip";
import useIsMobile from "../../../hooks/useIsMobile";

function FeedCardHeader({
  food,
  site,
  processedAt,
  weight,
  value,
  water,
  environment,
  foodImage,
  reason,
}) {
  const { view } = useSelector(selectFilters);
  const isMobile = useIsMobile()
  const [foodUrl, setFoodUrl] = React.useState(
    !foodImage || !foodImage.endsWith(".svg") ? defaultFoodImage : foodImage
  );

  const errorHandler = () => {
    setFoodUrl(defaultFoodImage);
  };

  const upperValue = useMemo(() => {
    if (view.display.id === "environmental") {
      return {
        value: numberFormater({
          value: environment,
          fixed: 2,
          after: "Kg",
        }),
        tooltip: "Environmental footprint",
        icon: faEarthAmerica,
      };
    }
    else if (view.display.id === "weight") {
      return {
        value: numberFormater({ value: weight, fixed: 2, after: "Kg" }),
        tooltip: "Weight",
        icon: faScaleBalanced,
      };
    } else {
      return {
        value: numberFormater({ value, fixed: 2, before: "€" }),
        tooltip: "Value",
        icon: faSackDollar,
      };
    }
  }, [view.display]);

  const lowerValue = useMemo(() => {
    if (view.display.id === "environmental") {
      return {
        value: numberFormater({
          value: water,
          fixed: 2,
          after: "L",
        }),
        tooltip: "Water footprint",
        icon: faDroplet,
      };
    }
    return;
  }, [view.display]);

  const reasoning = useMemo(() => {
    if (!reason || typeof reason === 'string') {
      return reason
    }
    return reason?.reasoning;
  }, [reason])

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Avatar
          variant="rounded"
          src={foodUrl}
          imgProps={{ onError: errorHandler }}
        />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <HeaderTypography>{food}</HeaderTypography>
            {reasoning && (
              <Tooltip title={reasoning} placement={isMobile ? 'bottom' : "right-start"}>
                <FontAwesomeIcon style={{ marginBottom: '6px' }} size="lg" strokeWidth={5} stroke="black" color={COLORS.brandYellow} icon={faLightbulb} />
              </Tooltip>
            )}
          </Stack>
          <Typography
            variant="subtitle1"
            component="p"
            style={{ marginTop: 2 }}
          >
            {site}
          </Typography>{" "}
          <GreyTypography component="p" style={{ marginTop: 1 }}>
            {formatFullDate(processedAt)}
          </GreyTypography>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-end"
        spacing={2}
      >
        <HeaderValueBlock variant="h2" {...upperValue} />
        {lowerValue && <HeaderValueBlock variant="subtitle1" {...lowerValue} />}
      </Stack>
    </Stack>
  );
}

FeedCardHeader.propTypes = {
  food: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  processedAt: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  water: PropTypes.number.isRequired,
  environment: PropTypes.number.isRequired,
  foodImage: PropTypes.string.isRequired,
};

export default FeedCardHeader;
