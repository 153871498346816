const reviewHistory = ({
  location,
  eventParams,
  statusType,
  site,
  food,
  device,
  autoLabelType,
  history,
}) => {
  const href = location.pathname.split("/").join("");
  const p = new URLSearchParams({
    status: statusType.length ? statusType : "",
    siteId: eventParams.site || "",
    siteName: eventParams.site ? site.map((el) => el.name) : "",
    food: eventParams.food || "",
    foodName: eventParams.food ? food[0].name : "",
    deviceId: eventParams.serial ? device.map((el) => el.id) : "",
    deviceSerial: eventParams.serial || "",
    auto: autoLabelType,
    offset: eventParams.offset,
    limit: eventParams.limit,
    startDate: eventParams.startDate,
    endDate: eventParams.endDate,
  });
  const result = `/${href}/?${p}`;
  history.push(result);
  return result;
};

export default reviewHistory;
