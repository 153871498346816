import {onlyIntegers} from "../../../utils/validation/base";

const MAX_VALUE = 1000000
const MAX_ERROR = `Should be ${MAX_VALUE} or less`
export const REQUIRED_ERROR = 'Should be a number'

const DEFAULT_OCCUPANCY = 350
export const dataToChart = (data = []) => data.map(item => {
  return {
    ...item,
    'Occupancy': item.y !== null ? Number(parseFloat(item.y).toFixed(0)) : DEFAULT_OCCUPANCY,
    isSet: !item.is_default,
  }
})

export const occupancyValidation = onlyIntegers.max(MAX_VALUE, MAX_ERROR).nullable()

export const defaultsToRequest = (defaults) => {
  const payload = {}
  Object.keys(defaults).forEach(key => {
    if (defaults[key] >= 0 && defaults[key] !== null && defaults[key] !== '') {
      payload[key] = +defaults[key]
    } else {
      payload[key] = defaults.default
    }
  })
  payload.default = null
  return payload
}
