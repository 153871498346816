import { baseAxios } from "../../../utils/axios";

export const getReductions = (foodId) => {
  return baseAxios(`/api/food-reductions`, {
    params: {
      limit: 5,
      food_id: foodId,
    },
  });
};
