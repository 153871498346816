export function snakeToCamel(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => snakeToCamel(item));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/_([a-z])/g, function(match) {
      return match[1].toUpperCase();
    });

    let value = obj[key];
    if (value !== null && typeof value === 'object') {
      value = snakeToCamel(value);
    }

    acc[camelKey] = value;
    return acc;
  }, {});
}
