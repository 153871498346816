import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import BlueButton from "../components/Buttons/BlueButton";
import commentsChoice from "./configs/commentsChoice";
import CommentService from "../../services/CommentService";
import { selectFeed, updateEvent } from "../../redux/reducers/feed";
import { customCommentLabel } from "./configs/constants";

function CommentForm({ eventId, onCancel }) {
  const { status, events } = useSelector(selectFeed);

  const dispatch = useDispatch();

  const [commentId, setCommentId] = useState(null);
  const [comment, setComment] = useState(null);
  const [customComment, setCustomComment] = useState(null);

  const handleConfirm = async () => {
    const selectedComment =
      comment === "Other" || !comment ? customComment : comment;

    try {
      const response = await CommentService.createComment({
        eventId,
        comment: selectedComment,
      });

      dispatch(
        updateEvent({
          id: response.event,
          comment_id: response.id,
          comment_message: response.comment,
        })
      );
    } catch (error) {
      console.log(error);
    }

    onCancel();
  };

  const handleDelete = async () => {
    try {
      await CommentService.deleteComment({
        id: commentId,
      });

      dispatch(
        updateEvent({
          comment_message: null,
          comment_id: null,
          id: eventId,
        })
      );
    } catch (error) {
      console.log(error);
    }

    onCancel();
  };

  const handleComment = (event) => {
    setComment(event.target.value);
    setCustomComment(null);
  };

  const handleCustomComment = (event) => {
    setComment(null);
    setCustomComment(event.target.value);
  };

  useEffect(() => {
    if (status === "succeeded") {
      const event = events.find((event) => event.id === eventId);
      const eventComment = event.comment_message;
      setCommentId(event.comment_id);

      if (commentsChoice.includes(eventComment)) {
        setComment(eventComment);
        setCustomComment(null);
      } else {
        setCustomComment(eventComment);
        setComment(null);
      }
    }
  }, [events]);

  return (
    <>
      <DialogContent>
        <FormControl>
          <RadioGroup
            defaultValue="female"
            name="radio-buttons-group"
            value={customComment ? null : comment}
            onChange={handleComment}
          >
            {commentsChoice.map((choice) => (
              <FormControlLabel
                key={choice}
                value={choice}
                control={<Radio />}
                label={choice}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            multiline
            label={customCommentLabel}
            InputLabelProps={{
              shrink: true,
            }}
            minRows={1}
            maxRows={3}
            value={customComment}
            onChange={handleCustomComment}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ mb: 4, mr: 4, mt: -4 }}>
        {commentId && (
          <BlueButton
            name="Delete"
            color="error"
            variant="outlined"
            onClick={handleDelete}
          />
        )}
        {/* <BlueButton name="Cancel" onClick={onCancel} /> */}
        <BlueButton name="Confirm" onClick={handleConfirm} />
      </DialogActions>
    </>
  );
}

CommentForm.defaultProps = {
  updateParams: {},
};

CommentForm.propTypes = {};

export default CommentForm;
