import { Chip } from "@mui/material";

const OwnerChip = () => (
  <Chip
    size="small"
    label="Owner"
    color="info"
    sx={{
      fontSize: "0.5rem",
      height: "1rem",
      padding: "0.1rem",
    }}
  />
);

export default OwnerChip;
