import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import UserUpdate from "./userUpdate";
import UserDelete from "./userDelete";
import FormDialog from "../components/Dialogs/FormDialog";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import { faPen, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ActionButtons({
  dataUsers,
  setDataUsers,
  dataParentGroups,
  dataSites,
  checkedIds,
  handleDelete,
  deletingProcess,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const mixpanelTrack = useMixpanelTrack();

  const handleEditOpen = () => {
    setOpenEdit(true)
    mixpanelTrack("Users - Edit User - Open Dialog")
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true)
    mixpanelTrack("Users - Delete User - Open Dialog")
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setOpenDelete(false);
    mixpanelTrack("Users - Edit User - Close Dialog")
  };
  const handleDeleteClose = () => {
    setOpenEdit(false);
    setOpenDelete(false);
    mixpanelTrack("Users - Delete User - Close Dialog")
  };

  const mixpanelEditSubmit = () => {
    mixpanelTrack("Users - Edit User - Submit")
  };

  const handleDeleteSubmit = () => {
    handleDelete()
    mixpanelTrack("Users - Delete User - Submit")
    handleDeleteClose()
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditOpen}>
          <FontAwesomeIcon size="lg" icon={faPen} style={{ color: "rgb(0, 0, 0, 0.54)", marginRight: 10 }} />
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>
          <FontAwesomeIcon size="lg" icon={faCircleInfo} style={{ color: "rgba(0, 0, 0, 0.54)", marginRight: 10 }} />
          <Typography>Delete</Typography>
        </MenuItem>
      </Menu>
      <FormDialog
        dialogTitle=""
        disableTitle
        openForm={openEdit}
        handleCloseModel={handleEditClose}
        form={
          <UserUpdate
            dataUsers={dataUsers}
            setDataUsers={setDataUsers}
            dataParentGroups={dataParentGroups}
            dataSites={dataSites}
            handleCloseModal={handleEditClose}
            mixpanelSubmit={mixpanelEditSubmit}
          />
        }
      />
      <FormDialog
        dialogTitle={`Are you sure want to delete ${checkedIds.length} user(s)?`}
        showCross={false}
        backdropClick={!deletingProcess}
        openForm={openDelete}
        handleCloseModel={handleDeleteClose}
        form={
          <UserDelete
            deletingProcess={deletingProcess}
            handleDelete={handleDeleteSubmit}
            handleCloseModal={handleDeleteClose}
          />
        }
      />
    </div>
  );
}

ActionButtons.propTypes = {
  dataUsers: PropTypes.object.isRequired,
  setDataUsers: PropTypes.func.isRequired,
  dataParentGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSites: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkedIds: PropTypes.arrayOf(PropTypes.number),
  handleDelete: PropTypes.func.isRequired,
  deletingProcess: PropTypes.bool.isRequired,
};

ActionButtons.defaultProps = {
  checkedIds: [],
};

export default ActionButtons;
