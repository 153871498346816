import { baseAxios } from "../../../utils/axios";

const fetchFilteredSites = async ({ params }) => {
  try {
    const response = await baseAxios.get("api/sites/statistics/", { params });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default fetchFilteredSites;
