import React from "react";
import PropTypes from "prop-types";
import { Grid, Stack } from "@mui/material";

import WasteTable from "./WasteTable";
import ImageBlock from "../components/ImageBlock/ImageBlock";
import { paginationLimit } from "../../constants/base";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import useIsMobile from "../../hooks/useIsMobile";

function DataBlock({ isReview, states, width, getEvents }) {
  const isMobile = useIsMobile();

  const mixpanelTrack = useMixpanelTrack();

  const handleDiscardOpenDialog = () => {
    states.setOpen(true);
    mixpanelTrack("Waste Log - Discard - Open Dialog");
  };

  const handleUpdateOpenDialog = () => {
    states.setOpenForm(true);
    mixpanelTrack("Waste Log - Update Event - Open Dialog");
  };

  return (
    <Grid container direction={isMobile ? "column" : "row"} spacing={10}>
      <Grid item xs={isMobile ? 5 : 7} order={isMobile ? 2 : 1} sx={{ mt: 4 }}>
        <WasteTable
          states={states}
          limit={paginationLimit}
          isReview={isReview}
          width={width}
          isMobile={isMobile}
          getEvents={getEvents}
          handleDiscardOpenDialog={handleDiscardOpenDialog}
          handleUpdateOpenDialog={handleUpdateOpenDialog}
        />
      </Grid>
      <Grid item xs={isMobile ? 7 : 5} order={isMobile ? 1 : 2} sx={{ mt: 4 }}>
        <Stack spacing={8}>
          <ImageBlock
            showMoreInfo
            isDelete
            event={states.event}
            device={
              states.device || { bounding_area: states.event?.bounding_area }
            }
            handlerOpen={handleDiscardOpenDialog}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

DataBlock.propTypes = {
  isReview: PropTypes.bool,
  states: PropTypes.shape({
    event: PropTypes.shape({
      bounding_area: PropTypes.arrayOf(PropTypes.number),
    }),
    device: PropTypes.shape({}),
    setOpen: PropTypes.func.isRequired,
  }).isRequired,
  width: PropTypes.number,
};

DataBlock.defaultProps = {
  isReview: false,
  width: undefined,
};

export default DataBlock;
