import { baseAxios } from "../../../utils/axios";

const fetchFilteredDevices = async ({ params }) => {
  try {
    const response = await baseAxios.get(`api/devices/statistics?`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export default fetchFilteredDevices;
