import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateRangeInput from "../inputs/DateRangeInput";

const FilterBox = styled(Box)`
  margin: 15px;
`;

const MobileAccordion = styled(Accordion)`
  margin-top: 15px;
  border-radius: 4px;

  &::before {
    height: 0;
  }
`;

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.black,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  color: theme.palette.color.gray,
}));

function Actions({
  isLoading,
  name,
  category,
  subcategory,
  site,
  setSite,
  sites,
  date,
  setDate,
}) {
  return (
    <Box mb={2} sx={isLoading && { pointerEvents: "none", opacity: "0.7" }}>
      <Box>
        {isLoading && <Skeleton animation="wave" width={150} />}
        {!isLoading && (
          <Title variant="h1" component="h6">
            {name}
          </Title>
        )}
      </Box>
      <Box>
        {isLoading && <Skeleton animation="wave" width={250} />}
        {!isLoading && (
          <Subtitle variant="h3">{`${category} -${subcategory}`}</Subtitle>
        )}
      </Box>
      <MobileAccordion disableGutters>
        <AccordionSummary
          sx={{ marginLeft: "10px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3">Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterBox>
            <FormControl variant="standard" sx={{ width: 1 }}>
              <InputLabel id="site-label">Site</InputLabel>
              <Select
                sx={{ margin: 0 }}
                labelId="site-label"
                id="site-filter"
                value={site}
                label="Site"
                onChange={(e) => setSite(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {sites.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FilterBox>
          <FilterBox>
            <DateRangeInput value={date} setValue={setDate} />
          </FilterBox>
        </AccordionDetails>
      </MobileAccordion>
    </Box>
  );
}

Actions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  subcategory: PropTypes.string.isRequired,
  site: PropTypes.string,
  setSite: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  date: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  setDate: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  site: "",
};

export default Actions;
