import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import styled from "@emotion/styled";

const ButtonsBox = styled(Box)`
  display: flex;
  margin-top: 15px;
`;

function MobileFilters({ LeftSide, loading }) {
  return (
    <Box style={loading ? { pointerEvents: "none", opacity: 0.7 } : {}}>
      <ButtonsBox>{LeftSide}</ButtonsBox>
    </Box>
  );
}

MobileFilters.propTypes = {
  LeftSide: PropTypes.node,
  loading: PropTypes.bool.isRequired,
};

MobileFilters.defaultProps = {
  LeftSide: null,
};

export default MobileFilters;
