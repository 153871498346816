import { baseAxios } from "../../../utils/axios";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";

export const getFood = (foodId, date) => {
  return baseAxios(`/api/food-drilldowns/${foodId}/food/`, {
    params: {
      startDate: formatDataQuery(date[0]),
      endDate: formatDataQuery(date[1]),
    },
  });
};
