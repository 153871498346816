import React, { Fragment, useEffect, useMemo } from "react";
import {AppBar, Grid, Stack, Typography, useMediaQuery} from "@mui/material";

import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import SideFilter from "../../components/filter/SideFilter";
import SiteChartList from "../../components/sites/SiteChartList";
import Page from "../../layouts/Page";

function Sites() {
  const mixpanelTrack = useMixpanelTrack();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => mixpanelTrack("Sites - Page View"), []);

  const leftStyle = useMemo(
    () => ({ display: !isMobile ? "none" : "block" }),
    [isMobile]
  );

  return (
    <Page title="Sites">
      <Stack gap={8}>
        <Typography variant="title" component="h1">Sites</Typography>
        <Grid container spacing={10} sx={{ height: "100%" }}>
          <Grid item xs={12} md={4} sx={leftStyle}>
            <SideFilter />
          </Grid>
          <Grid item xs={12} md={8}>
            <SiteChartList />
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
}

Sites.propTypes = {};

Sites.defaultProps = {};

export default Sites;
