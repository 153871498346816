import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "./CircularProgress";

function CircularLoading({ loading, children }) {
  return loading ? <CircularProgress /> : children;
}

CircularLoading.defaultProps = {
  loading: false,
};

CircularLoading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CircularLoading;
