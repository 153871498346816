import React from 'react';
import {Card, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import {COLORS} from "../../constants/base";
import {mainRoutes} from "../../constants/urls";
import {useLocation, useNavigate} from "react-router-dom";
const Container = styled(Card)`
    background-color: rgba(224, 229, 242, 1);
    height: 100%;
    min-height: 380px;
    cursor: pointer;
    &:hover{
        background-color: white;
    }
`

const AddWidget = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  return (
    <Container onClick={() => navigate({ pathname: mainRoutes.widgets, search })}>
      <Stack sx={{ height: '100%' }} direction="column" justifyContent="center" alignItems="center">
        <PlusIcon width={100} height={100} stroke={COLORS.darkBlue} />
        <Typography variant="large2" fontWeight={400}>Add Widget</Typography>
      </Stack>
    </Container>
  );
};

export default AddWidget;
