import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserCreate from "./userCreate";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import { ExportCSV } from "../components/Buttons/ExportCSV";

const FilterBox = styled(Box)`
  margin: 15px;
`;

const MobileAccordion = styled(Accordion)`
  margin: 15px 0;
  &.MuiPaper-root.Mui-expanded {
    margin: 15px 0;
  }
  border-radius: 4px;
  &::before {
    height: 0;
  }
`;

function Actions({
  dataParentGroups,
  dataSites,
  dataUsers,
  setDataUsers,
  usersPath,
  selectedRole,
  setSelectedRole,
  role,
  selectedSite,
  setSelectedSite,
  sites,
  selectedParentGroup,
  setSelectedParentGroup,
  parentGroups,
}) {
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <UserCreate
          dataParentGroups={dataParentGroups}
          dataSites={dataSites}
          dataUsers={dataUsers}
          setDataUsers={setDataUsers}
        />
        <ExportCSV
          href={usersPath("/api/users/export_csv/")}
          filename="users.csv"
        />
      </Box>
      <MobileAccordion disableGutters>
        <AccordionSummary
          sx={{ marginLeft: "10px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3">Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterBox>
            <ReviewSelectStandard
              fullWidth
              value={selectedRole}
              setValue={setSelectedRole}
              items={role}
              label="Role"
              multiple
              isTitle
            />
          </FilterBox>
          <FilterBox>
            <ReviewSelectStandard
              fullWidth
              value={selectedSite}
              setValue={setSelectedSite}
              items={sites}
              label="Site"
              multiple
            />
          </FilterBox>
          <FilterBox>
            <ReviewSelectStandard
              fullWidth
              value={selectedParentGroup}
              setValue={setSelectedParentGroup}
              items={parentGroups}
              label="Parent Group"
              multiple
            />
          </FilterBox>
        </AccordionDetails>
      </MobileAccordion>
    </Box>
  );
}

Actions.propTypes = {
  dataParentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      contact_name: PropTypes.string.isRequired,
      contact_number: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  dataSites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  dataUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      employer: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      head_office: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      is_superuser: PropTypes.bool.isRequired,
      job_title: PropTypes.string.isRequired,
      last_activity: PropTypes.string,
      parent_group: PropTypes.string,
      phone_number: PropTypes.number.isRequired,
      profile_photo: PropTypes.string,
      role: PropTypes.string,
      site: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
      surname: PropTypes.string.isRequired,
    })
  ).isRequired,
  setDataUsers: PropTypes.func.isRequired,
  usersPath: PropTypes.func.isRequired,
  selectedRole: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  role: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSite: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedSite: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedParentGroup: PropTypes.arrayOf(
    PropTypes.shape({
      contact_name: PropTypes.string.isRequired,
      contact_number: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedParentGroup: PropTypes.func.isRequired,
  parentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      contact_name: PropTypes.string.isRequired,
      contact_number: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Actions;
