import React from "react";
import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";

function InsightsChartList({ data, isLoading, Card }) {
  return (
    <Box>
      {isLoading && <Box>Loading...</Box>}
      {!isLoading && !data.length && <Box>No Data</Box>}
      {!isLoading && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          {data.length > 0 &&
            data.map((item) => <Card {...item} key={item.serial || item.id} />)}
        </Stack>
      )}
    </Box>
  );
}

InsightsChartList.defaultProps = {
  data: [],
  isLoading: false,
  Card: React.Fragment,
};

InsightsChartList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  Card: PropTypes.elementType,
};

export default InsightsChartList;
