import React from "react";
import PropTypes from "prop-types";
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";
import BlueButton from "../components/Buttons/BlueButton";

function ConfirmDialog({
  onConfirm,
  onCancel,
  confirmName,
  cancelName,
  description,
}) {
  return (
    <>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ m: 4 }}>
        {onCancel && (
          <BlueButton name={cancelName} onClick={onCancel} sx={{ mx: null }} />
        )}
        <BlueButton name={confirmName} onClick={onConfirm} sx={{ mx: null }} />
      </DialogActions>
    </>
  );
}

ConfirmDialog.defaultProps = {
  onCancel: null,
  cancelName: "Cancel",
  confirmName: "Confirm",
  description: "",
};

ConfirmDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  cancelName: PropTypes.string,
  confirmName: PropTypes.string,
  description: PropTypes.string,
};

export default ConfirmDialog;
