import React from 'react';
import {Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectSites} from "../../redux/sites";
import {selectExplore, updateFilters} from "../../redux/explore";
import {ALL_SITES} from "../../constants/widgets";
import Button from "@mui/material/Button";
import {COLORS} from "../../constants/base";
import {useFormik} from "formik";
import {useExploreFilters} from "../explore/useExploreFilters";

const Filters = ({ closeModal }) => {
  const { sites } = useSelector(selectSites)
  const { filters, updateFilters } = useExploreFilters()
  const dispatch = useDispatch()
  const {values, handleChange, handleSubmit} = useFormik({
    initialValues: {
      site: filters.sites[0]?.id || ALL_SITES
    },
    onSubmit: ({ site }) => {
      if(site === ALL_SITES) {
        updateFilters({ sites: [] })
      }else {
        const siteName = sites.find(s => s.id === site)?.name
        updateFilters({ sites: [{ name: siteName, id: site}] })
      }
      closeModal()
    }
  })

  return (
    <Stack spacing={5}>
      <Typography variant="medium" sx={{ color: COLORS.darkBlue2 }}>Filters</Typography>
      <Divider />
      <form onSubmit={handleSubmit}>
        <Stack spacing={5}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="sites">Sites</InputLabel>
            <Select
              id="site"
              placeholder="All Sites"
              name="site"
              value={values.site}
              onChange={handleChange}
            >
              <MenuItem value="all">All Sites</MenuItem>
              {sites.map(site => (
                <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="secondary" type="submit">Apply filters</Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default Filters;
