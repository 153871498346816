import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

function BlockTitle({ title, sx }) {
  return (
    <Typography variant="h2" sx={sx}>
      {title}
    </Typography>
  );
}

BlockTitle.defaultProps = {
  description: "",
  sx: {},
};


BlockTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  sx: PropTypes.object,
};

export default BlockTitle;
