import React from "react";
import { Chip } from "@mui/material";
import PropTypes from "prop-types";

import { COLORS } from "../../constants/base";

function RoleChip({ role }) {
  let colorRole;
  let colorText;

  if (role === "Admin") {
    colorRole = COLORS.yellow;
    colorText = COLORS.white;
  } else if (role === "Labeller") {
    colorRole = COLORS.green;
    colorText = COLORS.white;
  } else if (role === "User") {
    colorRole = COLORS.purple;
    colorText = COLORS.white;
  } else {
    colorRole = "transparent";
    colorText = "#000";
  }

  return (
    <Chip
      size="small"
      label={role}
      sx={{
        backgroundColor: colorRole,
        color: colorText,
        width: "84px",
        fontWeight: 500,
      }}
    />
  );
}

RoleChip.propTypes = {
  role: PropTypes.string.isRequired,
};

export default RoleChip;
