import React, { useMemo } from 'react';
import {useSelector} from "react-redux";
import {selectExplore} from "../../../redux/explore";
import Base from "../Base";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {CHART_TYPE, DATE_GRAN, LABELS, METRICS} from "../../../constants/explore";
import dayjs from "dayjs";
import SimpleDialog from "../../dialogs/SimpleDialog";
import SetDailyTarget from "./components/SetDailyTarget";
import useIsMobile from "../../../hooks/useIsMobile";
import {useModal} from "mui-modal-provider";
import Chart from "./components/Chart";
import numberFormater from "../../../utils/formaters/numberFormater";
import {mepMetricParams} from "../../explore/helpers";
import Loader from "../Loader";
import Placeholder from "../components/Placeholder";
import {formatDataQuery} from "../../../utils/date/formatDataQuery";
import {mainRoutes} from "../../../constants/urls";
import {WIDGET_IDENTITY} from "../config";
import {formatCoastValue} from "../../../utils/formaters/formatCoastValue";
import {useWidget} from "../useWidget";

const CHART_TITLE = 'Daily Waste'
const starDate = dayjs('01.01.2022').format('YYYY-MM-DD')

const JourneyToDate = () => {
  const { filters } = useSelector(selectExplore)
  const selectedSite = filters.sites[0]
  const chartTitle = `${CHART_TITLE} ${selectedSite?.name ? '' : ' - Average per All Sites'}`
  const isMobile = useIsMobile()
  const { showModal } = useModal()
  const fetchKey = useMemo(() => {
    const params = {
      metric: mepMetricParams[filters.metric],
        ...(selectedSite ? { site: selectedSite.id} : {}),
    }
    return ['api/overview/journey_to_date/', params]
  }, [selectedSite, filters.metric])

  const { data, isLoading, title, goToExplore, tip, updateCache } = useWidget(WIDGET_IDENTITY.JOURNEY, {
    fetchKey,
    exploreParams: {
      path:  `/${mainRoutes.explore}`,
      params: {
        dateRange: [
          starDate,
          formatDataQuery(new Date()),
        ],
        dateGranularity: DATE_GRAN.daily,
        chartType: CHART_TYPE.line,
        ...(!selectedSite && filters.metric === METRICS.totalWaste
          ? { metric: METRICS.AVGWaste }
          : { metric: filters.metric }),
        siteId: selectedSite?.id,
        labels: LABELS.nonFood,
        rollingAverage: true,
      }
    }
  })

  const {
    daily_target,
    total_waste,
    unit,
    chart
  } = data || {}

  const totalWaste = total_waste && numberFormater({ value: total_waste, fixed: 1, after: unit})

  const showTargetModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <SetDailyTarget target={daily_target} closeModal={() => {
        targetModal.hide()
        updateCache()
      }} selectedSite={selectedSite?.id} target={daily_target} />,
    })
  }

  return (
    <Base title={title} tooltip={tip} value={formatCoastValue(totalWaste)} id={WIDGET_IDENTITY.JOURNEY}>
      <Base.Content style={{ minHeight: '280px' }}>
        {isLoading && <Loader />}
        {!!data?.chart?.length && (
          <Chart title={chartTitle} data={chart} unit={unit} target={daily_target} />
        )}
        {!data?.chart?.length && !isLoading && (
          <Placeholder text="No Data"/>
        )}
      </Base.Content>
      <Base.Footer>
        <Stack direction="row" justifyContent="space-between">
          <Button disabled={isLoading} onClick={showTargetModal} variant="link">Set Daily Target (KG)</Button>
          <Button disabled={isLoading} variant="link" onClick={goToExplore}>Explore Journey To Date</Button>
        </Stack>
      </Base.Footer>
    </Base>
  );
};

export default JourneyToDate;
