import React, { createRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar as MuiAvatar,
  FormControl,
  FormControlLabel,
  Input,
} from "@mui/material";
import styled from "@emotion/styled";
import { stringAvatar } from "../../utils/avatar";

const Avatar = styled(MuiAvatar)`
  height: 128px;
  width: 128px;
  font-size: 64px;
`;

function UserAvatar({ values, setFieldValue }) {
  const [avatarPreview, setAvatarPreview] = useState({
    url: null,
    name: "avatarPreview",
  });
  const inputFileRef = createRef();

  const cleanup = () => {
    URL.revokeObjectURL(avatarPreview.url);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (avatarPreview.url) cleanup();
    setAvatarPreview({ ...avatarPreview, url: newImage });
  };

  const handleClick = (event) => {
    if (avatarPreview.url) {
      event.preventDefault();
      setImage(null);
    }
  };

  const handleFileUpload = (event) => {
    const newImage = event.target?.files?.[0];
    if (newImage) {
      setFieldValue("file", newImage);
      setFieldValue("expansion", newImage.name.split(".").pop());
      setImage(URL.createObjectURL(newImage));
    }
  };

  return (
    <div>
      <FormControl component="fieldset" variant="standard" fullWidth>
        <FormControlLabel
          style={{
            justifyContent: "center",
            display: "flex",
            margin: "0",
          }}
          control={
            <Input
              ref={inputFileRef}
              accept="image/*"
              name="profilePhoto"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
              onClick={handleClick}
            />
          }
          label={
            <Avatar
              id="avatar-image"
              src={avatarPreview.url || values.profilePhoto}
              alt={avatarPreview.name}
              {...stringAvatar(values.firstName, values.surname)}
            />
          }
        />
      </FormControl>
    </div>
  );
}

UserAvatar.propTypes = {
  values: PropTypes.shape({
    profilePhoto: PropTypes.string,
    firstName: PropTypes.string,
    surname: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default UserAvatar;
