import { formatPrevDate } from "../../../utils/data/prevDate";
import { numberFormater } from "../../../utils/formaters/numberFormater";

const formatPrevValue = (prevValue, date) => {
  if (prevValue > 100)
    return `↑${numberFormater({
      value: prevValue - 100,
      after: "%",
    })} vs. ${formatPrevDate({ startDate: date[0], endDate: date[1] })}`;
  if (prevValue === 0) {
    return `0% vs. ${formatPrevDate({ startDate: date[0], endDate: date[1] })}`;
  }
  return `↓${numberFormater({
    value: 100 - prevValue,
    after: "%",
  })} vs. ${formatPrevDate({ startDate: date[0], endDate: date[1] })}`;
};

export default formatPrevValue;
