import { useLocation, useNavigate } from "react-router-dom";
import { checkSidebarPermitions } from "./checkSidebarPermitions";
import reduceChildRoutes from "./reduceChildRoutes";

function SidebarNavList(props) {
  const { pages, depth, toggle, user } = props;
  const navigate = useNavigate();
  const router = useLocation();
  const currentRoute = router.pathname;

  const filteredPages = pages.filter((item) => {
    if (checkSidebarPermitions(user, item.permissions) && !item.hide) {
      return true;
    }
    if (item.href === currentRoute) {
      navigate("/");
    }
    return false;
  });

  return filteredPages.reduce(
    (items, page) => reduceChildRoutes({ items, page, depth, toggle }),
    []
  );
}

export default SidebarNavList;
