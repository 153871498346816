import { enqueueSnackbar } from "notistack";
import getDevice from "./getDevice";
import getEvents from "./getEvents";

const getData = async (
  device,
  completedTasks,
  setLoadingLabelled,
  setLoadingUnlabelled,
  setLabelledEvent,
  setUnlabelledEvent,
  setDevice,
  setShowAlert,
) => {
  let params = {};
  if (device) {
    params = {
      serial: device.serial,
    };
  }

  const reset = () => {
    setLoadingLabelled(false);
    setLoadingUnlabelled(false);
  };
  setShowAlert(false)

  try {
    const response = await getEvents(params);
    const { status, data } = response;
    if (status === 204) {
      setShowAlert(true);
      setDevice(null)
      setLabelledEvent(null)
      setUnlabelledEvent(null)
    } else {
      if (device?.serial !== data?.unlabelled?.device_serial) {
       await getDevice(data.device_id, setDevice);
      }
      if (data.labelled) {
        setLabelledEvent(data.labelled);
      }

      setUnlabelledEvent(data.unlabelled);
    }
  } catch (err) {
    enqueueSnackbar(err.message, { variant: "error" });
  } finally {
    reset();
  }
};

export default getData;
