import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';

const SubmenuContext = createContext(({ routes: [] }))

const SubmenuProvider = ({ children }) => {
  const [routes, setRoutes] = useState([])
  const [title, setTitle] = useState('')
  useEffect(() => {
    if (!routes.length) {
      setTitle('')
    }
  }, [routes]);

  const value = useMemo(() => ({
    routes,
    setRoutes,
    title,
    setTitle
  }), [routes, title])
  return (
    <SubmenuContext.Provider value={value}>
      {children}
    </SubmenuContext.Provider>
  );
};

export default SubmenuProvider;

export const useSubMenu = () => useContext(SubmenuContext)
