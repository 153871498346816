class LocalStorageService {
  getLocalItem(key) {
    return localStorage.getItem(key);
  }

  removeLocalItem(key) {
    localStorage.removeItem(key);
  }

  setLocalItem(key, item) {
    if (item.constructor === Object) {
      item = JSON.stringify(item);
    }
    localStorage.setItem(key, item);
  }
}

export default new LocalStorageService();
