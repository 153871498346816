import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Box as MuiBox, ListItemButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import logo from '../icons/logo-new.png'
import useIsMobile from "../../hooks/useIsMobile";

const Brand = styled(ListItemButton)(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    minHeight: "64px",
  },
  padding: 0,

  "&:hover": {
    backgroundColor: theme.sidebar.background,
    color: theme.sidebar.color,
  },
}));

const Box = styled(MuiBox)(({ theme }) => ({
  overflow: "hidden",
  backgroundColor: theme.sidebar.background,
  color: theme.sidebar.color,
}));

function SidebarBrand() {
  const mixpanelTrack = useMixpanelTrack()
  const isMobile = useIsMobile()

  const handleClick = () => mixpanelTrack('Sidebar - Brand Clicked');

  return (
    <Box>
      <Brand component={NavLink} to="/" onClick={handleClick} style={{ width: 'fit-content' }}>
        <img src={logo} width={isMobile ? 100 : 150} />
      </Brand>
    </Box>
  );
}

SidebarBrand.propTypes = {
  toggle: PropTypes.bool.isRequired,
};

export default SidebarBrand;
