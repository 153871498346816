import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {selectUser} from "../../redux/user";
import useAppSelector from "../../hooks/useAppSelector";
import Metrics from "./Metrics";

const Header = () => {
  const user = useAppSelector(selectUser)
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography textOverflow="ellipsis" overflow="hidden" variant="title" component="h1">Welcome {user?.first_name}</Typography>
      <Metrics />
    </Stack>
  );
};

export default Header;
