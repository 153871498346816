import React from "react";
import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

const CustomButton = ({
  children,
  onClick,
  loading,
  fullWidth,
  color,
  variant,
  sx,
  ...res
}) => {
  return (
    <LoadingButton
      fullWidth={fullWidth}
      color={color}
      variant={variant}
      onClick={onClick}
      loading={loading}
      sx={{
        whiteSpace: "nowrap",
        textAlign: "center",
        padding: variant === "text" ? 0 : "0 2rem",
        height: 42,
        ...sx,
      }}
      {...res}
    >
      <Typography variant="button" >
        {children}
      </Typography>
    </LoadingButton>
  );
};

CustomButton.defaultProps = {
  loading: false,
  fullWidth: false,
  color: "primary",
  variant: "contained",
  sx: {},
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
};

export default CustomButton;
