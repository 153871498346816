import React, { useState } from "react";
import styled from "@emotion/styled";
import { Drawer as MuiDrawer } from "@mui/material";

import PropTypes from "prop-types";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import SidebarBrand from "./SidebarBrand";
import FormDialog from "../components/Dialogs/FormDialog";
import ViewAsForm from "./ViewAsForm";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import SubMenu from "./SubMenu";
import SubmenuProvider from "./SubmenuProvider";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
      border-radius: 0;
      box-shadow: none;
  }
`;

function Sidebar({ items, toggle, setToggle, showFooter = true, ...rest }) {
  const mixpanelTrack = useMixpanelTrack();
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
    mixpanelTrack("Sidebar - View As Closed");
  };
  const handleOpenDialog = () => {
    setOpen(true);
    mixpanelTrack("Sidebar - View As Opened");
  };
  const handleToggle = () => {
    setToggle((prevState) => !prevState);
    mixpanelTrack("Sidebar - Toggle Clicked");
  };

  return (
    <Drawer variant="permanent" toggle={toggle ? 1 : 0} {...rest}>
      <SubmenuProvider>
        <SidebarBrand toggle={toggle} />
        <SubMenu />
        <SidebarNav items={items} toggle={toggle} />
        {showFooter && (
          <SidebarFooter
            toggle={toggle}
            handleToggle={handleToggle}
            open={open}
          />
        )}
        <FormDialog
          showCross
          dialogTitle="View As"
          openForm={open}
          setOpenForm={handleCloseDialog}
          form={<ViewAsForm handleClose={handleCloseDialog} />}
        />
      </SubmenuProvider>
    </Drawer>
  );
}

Sidebar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggle: PropTypes.bool.isRequired,
  setToggle: PropTypes.func.isRequired,
  showFooter: PropTypes.bool,
};

Sidebar.defaultProps = {
  showFooter: true,
};

export default Sidebar;
