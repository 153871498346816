import React, {useState, useEffect, useMemo} from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { selectUser } from "../../redux/user";
import secondsToTime from "./utils/secondsToTime";
import eventUpdate from "./axios/eventUpdate";
import eventDiscard from "./axios/eventDiscard";
import getData from "./axios/getData";
import useKeyPress from "../../hooks/useKeyPress";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import getLabellingFoods from "./axios/getLabellingFoods";
import {selectFilters} from "../../redux/filters";

function withLabelling(Component) {
  return function () {
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(selectUser);
    const mixpanelTrack = useMixpanelTrack();

    const [unlabelledEvent, setUnlabelledEvent] = useState(null);
    const [labelledEvent, setLabelledEvent] = useState(null);
    const [food, setFood] = useState([]);
    const [foods, setFoods] = useState([]);
    const [foodOpen, setFoodOpen] = useState(false);
    const [device, setDevice] = useState(null);
    const [open, setOpen] = useState(false);
    const [loadingLabelled, setLoadingLabelled] = useState(true);
    const [loadingUnlabelled, setLoadingUnlabelled] = useState(true);
    const [isFetch, setIsFetch] = useState(true);
    const [isDiscard, setIsDiscard] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [progress, setProgress] = useState(0);
    const [select, setSelect] = useState("Full Waste");
    const [selectOpen, setSelectOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [backupImages, setBackupImages] = useState([]);
    const [isTrimmings, setIsTrimmings] = useState(false)

    const completedTasks = device && device.total_events - device.irl;
    const titleToEnd = device && secondsToTime(device.alt * device.irl);
    // FIXME temporary commented in case we need revert filters back. Remove id don't
    // const labellingFilters = useMemo(() => ({
    //   site: filters.site.map(({ id }) => id),
    //   serial: filters.zone.map(({ serial }) => serial)
    // }), [filters.site, filters.zone])
    //
    // useEffect(() => {
    //   setIsFetch(true)
    // }, [labellingFilters])

    const clearingValues = () => {
      setFood([]);
      setSelect("Full Waste");
      setSelectedIds([]);
      setOpen(false);
      setIsDiscard(false);
      setIsTrimmings(false)
    };

    const handleChange = (event) => {
      setSelectOpen(false);

      if (event.target.checked !== undefined) {
        setIsTrimmings(event.target.checked)
        if (event.target.checked) {
          mixpanelTrack('Labelling - Select Waste Type - Trimmings');
        }
        return
      }
      const { value } = event.target;
      setSelect(value);

      mixpanelTrack(`Labelling - Select Waste Type - ${event.target.value}`);
    };

    const handlerSubmit = async () => {
      if ((select === "Full Waste" && food.length) || isTrimmings) {
        await eventUpdate(
          unlabelledEvent,
          user,
          isTrimmings ? 'Trimmings' : select,
          food,
          setDevice,
          clearingValues,
          enqueueSnackbar,
          selectedImage,
          backupImages,
        );
        setIsFetch(true);
      }
      setIsFetch(true);
      mixpanelTrack("Labelling - Submit - Click");
    };

    const confirmAction = async (foodId) => {
      if (isDiscard) {
        await eventDiscard(
          selectedIds,
          user,
          unlabelledEvent,
          setDevice,
          clearingValues,
          enqueueSnackbar,
          foodId,
        );
      } else {
        await eventUpdate(
          unlabelledEvent,
          user,
          select,
          food,
          setDevice,
          clearingValues,
          enqueueSnackbar,
          selectedImage,
          backupImages,
        );
      }
      setIsFetch(true);

      mixpanelTrack("Labelling - Submit");
    };

    useEffect(async () => {
      if (isFetch) {
        await getData(
          device,
          completedTasks,
          setLoadingLabelled,
          setLoadingUnlabelled,
          setLabelledEvent,
          setUnlabelledEvent,
          setDevice,
          setShowAlert,
        );
        setIsFetch(false);
      }
    }, [isFetch, eventUpdate, eventDiscard]);

    useEffect(async () => {
      if (device && device.id) {
        const taskProgress =
          ((device.total_events - device.irl) * 100) / device.total_events;

        if (Number.isNaN(taskProgress)) {
          setProgress(100);
        } else {
          setProgress(taskProgress);
        }
      }
    }, [device]);

    useEffect(() => {
      if (unlabelledEvent) {
        if ((select === "Full Waste" && !food.length) && !isTrimmings) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } else {
        setIsDisabled(true);
      }
    }, [select, unlabelledEvent, food, isTrimmings]);

    useEffect(() => {
      if (unlabelledEvent) {
        setSelectedImage(unlabelledEvent.image_url)
      }
    }, [unlabelledEvent]);

    useEffect(async () => {
      setFoods(await getLabellingFoods({ siteId: device?.site_id }));
    }, [device?.site_id ]);

    useKeyPress(["Enter"], handlerSubmit);

    useEffect(() => {
      if (selectedImage) {
        const restBackup = unlabelledEvent.backup_image_url.filter(image => image && image !== selectedImage);
        // need to set selected image to the end of backup to display it on the feed page
        const images = [...restBackup, selectedImage];
        if (selectedImage !== unlabelledEvent.image_url && unlabelledEvent.image_url) {
          images.unshift(unlabelledEvent.image_url)
        }
        setBackupImages(images)
      }
    }, [selectedImage]);

    return (
      <Component
        device={device}
        completedTasks={completedTasks}
        titleToEnd={titleToEnd}
        progress={progress}
        labelledEvent={labelledEvent}
        unlabelledEvent={unlabelledEvent}
        loadingLabelled={loadingLabelled}
        setLoadingLabelled={setLoadingLabelled}
        loadingUnlabelled={loadingUnlabelled}
        setLoadingUnlabelled={setLoadingUnlabelled}
        setSelectedIds={setSelectedIds}
        setIsDiscard={setIsDiscard}
        setOpen={setOpen}
        food={food}
        open={open}
        setFood={setFood}
        foodOpen={foodOpen}
        setFoodOpen={setFoodOpen}
        select={select}
        selectOpen={selectOpen}
        setSelectOpen={setSelectOpen}
        handleChange={handleChange}
        isDisabled={isDisabled}
        handlerSubmit={handlerSubmit}
        isDiscard={isDiscard}
        confirmAction={confirmAction}
        alert={showAlert}
        isFetch={isFetch}
        setIsFetch={setIsFetch}
        foods={foods}
        setSelectedImage={setSelectedImage}
        isTrimmings={isTrimmings}
      />
    );
  };
}

withLabelling.baseComponentName = "withLabelling";

export default withLabelling;
