import React, { useEffect, useState } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormControlLabel, TextField } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { COLORS } from "../../constants/base";
import { CustomCheckbox, Alert } from "../styled/GlobalStyled";
import { errorMessage } from "../../utils/axios";
import TokenService from "../../services/TokenService";

const REFRESH_TOKEN = "refreshToken";
const ACCESS_TOKEN = "accessToken";

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [params] = useSearchParams()

  const [showPass, setShowPass] = useState(false);
  const initEmail = params.get('email') || ""

  const handleClickShowPassword = () => {
    setShowPass((prevShowPass) => !prevShowPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    sessionStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    TokenService.removeTokensCookies();
  }, []);

  return (
    <Formik
      initialValues={{
        email: initEmail,
        password: "",
        submit: false,
        keepLogin: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password, true);
          // await signIn(values.email, values.password, values.keepLogin);
          navigate("/");
        } catch (error) {
          console.warn(error);
          setStatus({ success: false });
          setErrors({ submit: errorMessage(error) });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            variant="outlined"
            type="email"
            name="email"
            label="Email"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            InputLabelProps={{ shrink: true }}
            placeholder="email@address.com"
          />
          <Box sx={{ position: "relative", display: "inline" }}>
            <TextField
              inputProps={{ maxLength: 42 }}
              variant="outlined"
              type={showPass ? "text" : "password"}
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
              InputLabelProps={{ shrink: true }}
              placeholder="password"
            />
            <IconButton
              sx={{
                position: "absolute",
                top: 38,
                right: 0,
                color: COLORS.gray_icon,
              }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
          <Button
            sx={{ marginTop: "30px", height: "50px", color: COLORS.black }}
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
          >
            Sign in with Email
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
