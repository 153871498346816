import React from "react";
import { Helmet } from "react-helmet-async";
import {Box, Stack, Typography} from "@mui/material";
import { useModal } from "mui-modal-provider";

import SimpleDialog from "../../components/dialogs/SimpleDialog";
import FormEditGroup from "../../components/groupManagement/FormEditGroup";
import GroupManagementTable from "../../components/groupManagement/GroupManagementTable";
import CustomButton from "../../components/components/Buttons/CustomButton";
import useCreateGroup from "../../components/groupManagement/hooks/useCreateGroup";
import Page from "../../layouts/Page";

function GroupManagement() {
  const { showModal } = useModal();
  const createGroup = useCreateGroup();

  function handleCreateGroup() {
    const modelEdit = showModal(SimpleDialog, {
      title: "Update Group",
      fullWidth: true,
      maxWidth: "xs",
      handleClose: () => modelEdit.hide(),
      content: (
        <FormEditGroup
          handlerClose={() => modelEdit.hide()}
          handlerConfirm={(data) => createGroup(data)}
        />
      ),
    });
  }

  return (
    <Page title="Group Management">
      <Stack spacing={8}>
        <Typography variant="title" component="h1">Group Management</Typography>
        <Box>
          <CustomButton onClick={handleCreateGroup}>Create Group</CustomButton>
        </Box>
        <GroupManagementTable />
      </Stack>
    </Page>
  );
}

export default GroupManagement;
