import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, Switch, Stack } from "@mui/material";

function SwitchFilter({ label, value, setValue, clickable }) {
  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <FormControl fullWidth>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {!clickable && (
          <Switch
            sx={{ marginLeft: "-11px" }}
            checked={value}
            onChange={handleChange}
          />
        )}

        <FormControlLabel
          label={label}
          checked={value}
          onChange={handleChange}
          control={clickable ? <Switch /> : <></>}
          labelPlacement="end"
          style={{
            userSelect: "none",
            cursor: clickable ? "pointer" : "default",
            "& .MuiFormControl-root": {
              flexDirection: clickable ? "column" : "row",
            },
          }}
        />
      </Stack>
    </FormControl>
  );
}

SwitchFilter.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  clickable: PropTypes.bool,
};

SwitchFilter.defaultProps = {
  clickable: true,
};

export default SwitchFilter;
