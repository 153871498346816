import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { withResizeDetector } from "react-resize-detector";
import PropTypes from "prop-types";

import ActionsButtons from "../../components/siteManagement/ActionsButtons";
import PageFilters from "../../components/components/pageFilter/PageFilters";
import { fetchSites, selectSites } from "../../redux/sites";
import {
  fetchParentGroups,
  selectParentGroups,
} from "../../redux/parentGroups";
import FormDialog from "../../components/components/Dialogs/FormDialog";
import { SITE_MANAGMENT_TITLE } from "../../constants/siteMenagment";
import CreateParentGroupForm from "../../components/siteManagement/CreateParentGroupForm";
import CreateSiteForm from "../../components/siteManagement/CreateSiteForm";
import SiteManagmentTable from "../../components/siteManagement/SiteManagmentTable";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import { selectFilters } from "../../redux/filters";
import Page from "../../layouts/Page";
import {Stack, Typography} from "@mui/material";

function SiteManagement({ width }) {
  const { filters } = useSelector(selectFilters);
  const { sites, isLoading: isLoadingSites } = useSelector(selectSites);
  const { parentGroups, isLoading: isLoadingParentGroups } =
    useSelector(selectParentGroups);

  const mixpanelTrack = useMixpanelTrack();

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [isCreateGroup, setIsCreateGroup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loading = useMemo(() => {
    const loadingValue = isLoading || isLoadingSites || isLoadingParentGroups;
    return loadingValue;
  }, [isLoading, isLoadingSites, isLoadingParentGroups]);

  useEffect(() => {
    dispatch(fetchSites());
    dispatch(fetchParentGroups());
    mixpanelTrack("Site Management - Open Page");
  }, []);

  const handleCreateGroup = () => {
    setOpenForm(true);
    setIsCreateGroup(true);
    mixpanelTrack("Site Management - Create Group - Open Dialog");
  };

  const handleCreateSite = () => {
    setOpenForm(true);
    setIsCreateGroup(false);
    mixpanelTrack("Site Management - Create Site - Open Dialog");
  };

  const handleCloseModel = () => {
    setOpenForm(false);
    const dialogTitle = isCreateGroup ? "Create Group" : "Create Site"
    mixpanelTrack(`Site Management - ${dialogTitle} - Close Dialog`);
  }

  const actionButtons = (
    <ActionsButtons
      handleCreateSite={handleCreateSite}
      handleCreateGroup={handleCreateGroup}
    />
  );

  const handleCrateGroupClose = () => {
    setOpenForm(false);
    mixpanelTrack("Site Management - Create Group - Close Dialog");
  }

  const mixpanelGroupSubmit = () => {
    mixpanelTrack("Site Management - Create Group - Submit");
  }

  const handleCrateSiteClose = () => {
    setOpenForm(false);
    mixpanelTrack("Site Management - Create Site - Close Dialog");
  }

  const mixpanelSiteSubmit = () => {
    mixpanelTrack("Site Management - Create Site - Submit");
  }

  const createParentGroupForm = (
    <CreateParentGroupForm
      handleClose={handleCrateGroupClose}
      mixpanelSubmit={mixpanelGroupSubmit}
    />
  );

  const createSiteForm = (
    <CreateSiteForm
      handleClose={handleCrateSiteClose}
      mixpanelSubmit={mixpanelSiteSubmit}
      setItems={setItems}
      parentGroups={parentGroups}
    />
  );

  return (
    <Page title={SITE_MANAGMENT_TITLE}>
      <Stack spacing={8}>
        <Typography variant="title" component="h1">{SITE_MANAGMENT_TITLE}</Typography>
        <PageFilters
          LeftSide={actionButtons}
          isLoading={loading}
          setIsLoading={setIsLoading}
        />
        <SiteManagmentTable
          loading={loading}
          site={filters.site}
          parentGroup={filters.group}
          sites={sites}
          items={items}
          setItems={setItems}
          width={width}
        />
        <FormDialog
          dialogTitle={isCreateGroup ? "Create Group" : "Create Site"}
          confirmAction={null}
          openForm={openForm}
          form={isCreateGroup ? createParentGroupForm : createSiteForm}
          handleCloseModel={handleCloseModel}
        />
      </Stack>
    </Page>
  );
}

SiteManagement.propTypes = {
  width: PropTypes.number,
};

SiteManagement.defaultProps = {
  width: undefined,
};

export default withResizeDetector(SiteManagement);
