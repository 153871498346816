import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useSelector } from "react-redux";
import patchSite from "./axios/patchSite";
import { selectParentGroups } from "../../redux/parentGroups";

async function updateSiteFn(
  values,
  setErrors,
  enqueueSnackbar,
  setOpenForm,
  setItems,
  siteId = null
) {
  const { error, data } = await patchSite({
    id: siteId || values.siteId,
    data: {
      parent_group: values.parentGroupId ? [{ id: values.parentGroupId }] : [],
    },
    enqueueSnackbar,
  });

  if (error) {
    setErrors(error);
  } else {
    setItems((prevState) =>
      prevState.map((s) => {
        if (s.id === data.id) {
          return { ...s, ...data };
        }
        return s;
      })
    );
    setOpenForm(false);
  }
}

function AssignToGroupForm({
  selectedSite,
  setOpenForm,
  isAssignToGroup,
  rowSelectionModel,
  setItems,
  mixpanelSubmit,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { parentGroups, isLoading, hasError } = useSelector(selectParentGroups);

  return (
    <Formik
      isInitialValid
      initialValues={{
        siteId: selectedSite.id,
        parentGroupId: selectedSite.parent_group.length
          ? selectedSite.parent_group[0].id
          : null,
      }}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={Yup.object().shape({
        siteId: Yup.number().required(),
        parentGroupId: Yup.number(),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        if (isAssignToGroup) {
          // eslint-disable-next-line no-restricted-syntax
          for (const siteId of rowSelectionModel) {
            // eslint-disable-next-line no-await-in-loop
            await updateSiteFn(
              values,
              setErrors,
              enqueueSnackbar,
              setOpenForm,
              setItems,
              siteId
            );
          }
        } else {
          await updateSiteFn(
            values,
            setErrors,
            enqueueSnackbar,
            setOpenForm,
            setItems
          );
        }

        setSubmitting(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {(errors.submit || hasError) && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
              {hasError && "Can't fetch group list"}
            </Alert>
          )}
          <FormControl fullWidth variant="standard" sx={{ my: 4 }}>
            <InputLabel id="parent-groups-select">Groups</InputLabel>
            <Select
              labelId="parent-groups-select"
              name="parentGroupId"
              disabled={isLoading}
              value={values.parentGroupId}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {parentGroups &&
                parentGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
            </Select>
            {touched.parentGroupId && errors.parentGroupId && (
              <FormHelperText>{errors.parentGroupId}</FormHelperText>
            )}
          </FormControl>
          <Button
            fullWidth
            sx={{ height: "50px", my: 4 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={mixpanelSubmit}
          >
            Save
          </Button>
        </form>
      )}
    </Formik>
  );
}

AssignToGroupForm.propTypes = {
  selectedSite: PropTypes.shape({
    id: PropTypes.number.isRequired,
    parent_group: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  isAssignToGroup: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  rowSelectionModel: PropTypes.arrayOf(PropTypes.number).isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
};

AssignToGroupForm.defaultProps = {
  selectedSite: null,
};

export default AssignToGroupForm;
