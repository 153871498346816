import {METRICS} from "./explore";

const Constants = {
    title: "Home",
    focusAreaTitle: "Focus Areas",
    // focusAreaDescription: `${fullName} - ${employer}`
}

export const OVERVIEW_AVAILABLE_METRICS = [
  METRICS.totalWaste,
  METRICS.coastWaste,
  METRICS.wasteEmission,
  METRICS.wasteLogs,
]

export default Constants;
