import { Stack, useMediaQuery } from "@mui/material";
import plateImage from "../assets/food-waste.png";
import Label from "../../components/Label";
import { STATS } from "../constants";
import { breakArray } from "../../../../utils/breakOnChunks";

const Stats = ({ data, isWide }) => {
  const stats = breakArray(STATS, 2)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const direction = (isWide || isMobile) ? "column" : "row";

  return (
    <Stack spacing={4} width={isWide ? 'auto' : "100%"} minWidth={300}>
      <Stack width="100%" justifyContent="space-between" spacing={2}>
      {stats.map(chunk => (
        <Stack direction={direction} alignItems="flex-start" justifyContent="space-between" width="100%" spacing={2}>
          {chunk.map(({ getTitle, description, icon, getDiff, id }) => (
            <Label
              diff={getDiff(data)}
              title={getTitle(data)}
              key={id}
              description={description}
              Icon={icon}
              sx={{ flex: 1 }}
            />
      ))}
        </Stack>
      ))}
      </Stack>
    </Stack>
  );
};

export default Stats;
