import { combineReducers } from "redux";

import user from "./user";
import users from "./users";
import devices from "./devices";
import selectedEvent from "./selectedEvent";
import unlabeledEvents from "./unlabeledEvents";
import sites from "./sites";
import foods from "./foods";
import foodCategories from "./foodCategories";
import foodSubcategories from "./foodSubcategories";
import parentGroups from "./parentGroups";
import baseValues from "./baseValues";
import zones from "./zones";
import profile from "./profile";
import filters from "./filters";
import feed from "./reducers/feed";
import signUpId from "./reducers/signUpId";
import explore from './explore';
import widgets from './widgets'

export const reducer = combineReducers({
  user,
  users,
  devices,
  selectedEvent,
  unlabeledEvents,
  foods,
  foodCategories,
  foodSubcategories,
  sites,
  parentGroups,
  baseValues,
  zones,
  profile,
  filters,
  feed,
  signUpId,
  explore,
  widgets,
});
