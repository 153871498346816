import React from "react";
import { closeSnackbar, SnackbarContent } from "notistack";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { styled } from "@mui/material/styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { snackBarDefaultStyles } from "./styles/snackbarStyles";

const Wrapper = styled(Box)(({ theme }) => ({
  ...theme.snackbar.error,
  ...snackBarDefaultStyles,
}));

const StyledCancelOutlinedIcon = styled(CancelOutlinedIcon)(({ theme }) => ({
  color: theme.palette.color.red,
  marginRight: theme.snackbar.icon.marginRight,
}));

const StyledCloseOutlinedIcon = styled(CloseOutlinedIcon)(({ theme }) => ({
  color: theme.snackbar.error.color,
  fontSize: theme.snackbar.icon.fontSize,
}));

const ErrorSnackbar = React.forwardRef((props, ref) => {
  const { id, message } = props;

  return (
    <SnackbarContent ref={ref} role="alert">
      <Wrapper>
        <StyledCancelOutlinedIcon />
        <Box>
          <Typography variant="subtitle1">{message}</Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => closeSnackbar(id)}
            sx={(theme) => ({ marginLeft: theme.snackbar.icon.marginLeft })}
          >
            <StyledCloseOutlinedIcon />
          </IconButton>
        </Box>
      </Wrapper>
    </SnackbarContent>
  );
});

ErrorSnackbar.displayName = "ErrorSnackbar";

ErrorSnackbar.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorSnackbar;
