import React from "react";
import PropTypes from "prop-types";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const HeaderValueBlock = ({ variant, value, tooltip, icon }) => {
  const theme = useTheme();

  const iconProps = {
    color: theme.palette.color.blue,
    style: { marginRight: '2px'}
  };

  return (
    <Tooltip title={tooltip}>
      <Typography variant={variant} style={{ marginTop: 0 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {icon && <FontAwesomeIcon icon={icon} {...iconProps} />}
          {value}
        </Stack>
      </Typography>
    </Tooltip>
  );
};

HeaderValueBlock.propTypes = {
  variant: PropTypes.string.isRequired,
  isEnvironmental: PropTypes.bool.isRequired,
  valueString: PropTypes.string.isRequired,
  valueTooltip: PropTypes.string.isRequired,
  valueIcon: PropTypes.element.isRequired,
  environmentString: PropTypes.string.isRequired,
  environmentalTooltip: PropTypes.string.isRequired,
  environmentalIcon: PropTypes.element.isRequired,
};

export default HeaderValueBlock;
