import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { baseAxios } from "../../../utils/axios";
import getTabName from "../../../utils/formaters/getTabName";
import { selectFilters } from "../../../redux/filters";

const fileName = {
  feed: "Positive Carbon Feed Export",
  sites: "Positive Carbon Sites Export",
  foods: "Positive Carbon Foods Export",
};

export function ExportDataCSV({ mixpanelExportCSV }) {
  const { filters, view } = useSelector(selectFilters);

  const [params, setParams] = useState();

  const name = getTabName();

  const filename = `${fileName[getTabName()]}.csv`;
  const href = `/api/${name}/export_csv/?`;

  const saveFile = async () => {
    const response = await baseAxios(href, { responseType: "blob", params });
    const url = window.URL.createObjectURL(response.data);

    saveAs(url, filename);
    mixpanelExportCSV();
  };

  const sx = {
    height: "42px",
  };

  useEffect(() => {
    const params = {
      startDate: dayjs(filters.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate).format("YYYY-MM-DD"),
      ordering: view.ordering.id,
    };

    if (view.secondaryWaste) params.secondaryWaste = true

    if (name !== "sites") {
      params.zone = filters.zone.map((el) => el.id);
      params.site = filters.site.map((el) => el.id);
      params.food = filters.food.map((el) => el.id);
    }

    setParams(params);
  }, [filters, view]);

  return (
    <Button
      fullWidth
      sx={sx}
      color="secondary"
      variant="contained"
      onClick={saveFile}
    >
      <Typography variant="button" mr={3} sx={{ ml: "1rem" }}>
        Export Data
      </Typography>
    </Button>
  );
}

ExportDataCSV.propTypes = {
  href: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  params: PropTypes.shape({
    is_secondary: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    food: PropTypes.string,
    site: PropTypes.string,
    zone: PropTypes.string,
  }),
  mixpanelExportCSV: PropTypes.func,
};

ExportDataCSV.defaultProps = {
  fullWidth: false,
  params: {},
  mixpanelExportCSV: () => {},
};

export default ExportDataCSV;
