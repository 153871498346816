import useIsMobile from "../../../../hooks/useIsMobile";
import {Grid, Paper, Stack, Typography, Tooltip as MuiTooltip } from "@mui/material";
import {COLORS} from "../../../../constants/base";
import {Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {CHART_AXIS, formatTicker, formatYaxis} from "../../../explore/helpers";
import React, {useMemo} from "react";
import numberFormater from "../../../../utils/formaters/numberFormater";
import {formatCoastValue} from "../../../../utils/formaters/formatCoastValue";
import {DATE_GRAN} from "../../../../constants/explore";

const CustomTooltip = ({ active, payload, label }) => {
  if(active && payload?.length) {
    const data = payload[0]
    const waste = numberFormater({ value: data.payload?.['Waste'], fixed: 1, after: data.unit})
    const rolling = numberFormater({ value: data.payload?.rolling_y, fixed: 1 })

    return (
      <Paper sx={{ padding: '20px' }} key={label}>
        <Stack>
          <Typography>{formatTicker(DATE_GRAN.daily)(label)}</Typography>
          <Typography>7-day rolling average: {rolling}</Typography>
          <Typography color={data?.color}>actual waste: {formatCoastValue(waste)}</Typography>
        </Stack>
      </Paper>
    )
  }
  return null
}

const Chart = ({ title, data, unit, target }) => {
  const chartData = useMemo(() => data
    ? data.map(item => ({...item, ['Waste']: item.y }))
    : [],
    [data]
  )
  const isMobile = useIsMobile()
  const formattedTarget = numberFormater({ value: target, fixed: 1, after: unit })
  return (
    <Grid container sx={{ height: '100%' }}>
      {!isMobile && (
        <Grid item xs={1} sx={{ position: 'relative'}}>
          <MuiTooltip title={title} placement="right">
            <Typography
              whiteSpace="nowrap"
              sx={{
                overflow: 'hidden',
                writingMode: 'vertical-lr',
                transform: 'rotate(180deg)',
                minHeight: 0,
                position: 'absolute',
                bottom: 0,
                textOverflow: 'ellipsis',
                height: '100%'
              }}
              textOverflow="ellipsis"
              variant="h2" color={COLORS.inputBorder}
              textAlign="center"
            >
              {title}
            </Typography>
          </MuiTooltip>
        </Grid>
        )}
      <Grid item xs={12} md={11}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={"100%"}
            height={"100%"}
            data={chartData}
            margin={{
              top: 20,
              right: 40,
              bottom: 30,
              left: isMobile ? -20 : 0,
            }}
          >
            <XAxis stroke={COLORS.gray_light} textAnchor="start" angle={45} dataKey={'x'}
                   tickFormatter={formatTicker(CHART_AXIS)}/>
            <YAxis stroke={COLORS.gray_light} tickFormatter={formatYaxis} />
            <Tooltip content={<CustomTooltip />}/>
            <Line
              dataKey="rolling_y"
              unit={unit}
              stroke={COLORS.brandYellow}
              dot={false}
            />
            <ReferenceLine y={target} stroke="red" label={{ value: `Target: ${formattedTarget}`, position: 'insideBottomLeft'}} strokeDasharray="3 3" />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default Chart
