import React, { useEffect } from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import SignInComponent from "../../components/auth/SignIn";
import { COLORS } from "../../constants/base";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import FormLayout from "../../components/auth/FormLayout";
import useIsMobile from "../../hooks/useIsMobile";

function SignIn() {
  const navigate = useNavigate();
  const mixpanelTrack = useMixpanelTrack()
  const isMobile = useIsMobile()

  useEffect(() => {
    mixpanelTrack('Sign In - Page Visit');
  }, [])

  const handleForgotPassword = () => {
    mixpanelTrack("Forgot Password - Click");
    navigate("/auth/reset-password")
  }

  return (
    <FormLayout
      showBackButton={!isMobile}
      title='Log into your Account'
      headTitle="Sign In"
      backPath="/auth/sign-in"
    >
      <SignInComponent />
      <Stack spacing={2} direction="row" textAlign="left" mt={2}>
        <Typography sx={{ color: COLORS.darkBlue2 }} variant="body1"> Forgot your Password?</Typography>
        <Button
          sx={{
            color: COLORS.darkBlue2,
            fontSize: "13px",
            fontWeight: 400,
            padding: 0,
            textDecoration: 'underline'
          }}
          onClick={handleForgotPassword}
        >
          Click Here
        </Button>
      </Stack>
    </FormLayout>
  );
}

export default SignIn;
