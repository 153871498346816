import React from "react";
import {
  Typography as MuiTypography,
  Stack,
  Avatar as MuiAvatar,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { stringAvatar } from "../../utils/avatar";
import useAppSelector from "../../hooks/useAppSelector";
import { selectUser } from "../../redux/user";
import WhiteBlock from "../blocks/WhiteBlock";
import SummaryInformation from "./SummaryInformation";
import DetailAction from "./DetailAction";
import { COLORS } from "../../constants/base";

const Typography = styled(MuiTypography)(spacing);

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
  font-size: 64px;
  line-height: 128px;
`;

function Details() {
  const {
    first_name: firstName,
    surname,
    profile_photo: photoUrl,
    employer,
  } = useAppSelector(selectUser);

  const userName = [firstName, surname].join(" ");

  return (
    <WhiteBlock>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Box
          sx={{
            paddingTop: "5rem",
            width: "100%",
            textAlign: "center",
            borderRadius: "1rem",
            background: `linear-gradient(180deg, ${COLORS.accentBlue} 0%, rgba(253,187,45,0) 50%)`,
          }}
        >
          <Avatar
            id="avatar-image"
            src={photoUrl}
            alt={userName}
            sx={{ textAlign: "center", border: "0.25rem solid white" }}
          >
            {stringAvatar(firstName, surname).children}
          </Avatar>
          <Typography variant="h5" component="div" gutterBottom>
            {userName}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            gutterBottom
            style={{ marginTop: 0 }}
          >
            {employer}
          </Typography>
        </Box>
        <SummaryInformation />
        <DetailAction />
      </Stack>
    </WhiteBlock>
  );
}

export default Details;
