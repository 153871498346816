import axios from "axios";
import jwtDecode from "jwt-decode";
import * as qs from "qs";
import { authUrls } from "../constants/urls";
import { VIEW_AS_PATHS } from "../constants/admin";
import TokenService from "../services/TokenService";
import LocalStorageService from "../services/LocalStorageService";
import { appConfig } from "../config";
import {
  getLocalStorageValue,
  getSessionStorageValue,
  setLocalStorageValue,
  setSessionStorageValue,
} from "./storage";
import {pipe} from "./pipe";

// const controller = new AbortController();

const REFRESH_TOKEN = "refreshToken";
const ACCESS_TOKEN = "accessToken";

const axiosInstance = axios.create({
  baseURL: appConfig.apiURL || window.location.origin,
  // signal: controller.signal,
  params: {},
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "comma" });
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const isOldRefreshToken = () => {
  const token =
    getLocalStorageValue(REFRESH_TOKEN) ||
    getSessionStorageValue(REFRESH_TOKEN);
  const decodedToken = jwtDecode(token);
  return Date.now() / 1000 - decodedToken.iat > 60;
};

const formatUrl = (url) => {
  const start = url.startsWith("/") ? 1 : 0;

  if (url.endsWith("/")) {
    return url.slice(start);
  }
  return `${url.slice(start)}/`;
};

const requestConfig = (config) => {
  if (
    config.url.includes("/auth/") &&
    !config.url.includes("/change/") &&
    !config.url.includes("/refresh/")
  ) {
    delete config.headers.common.Authorization;
  } else {
    const token = new URL(document.location).searchParams.get("token") || null;
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    if (
      !getLocalStorageValue(ACCESS_TOKEN) &&
      !window.location.pathname.includes("auth/")
    ) {
      // console.log(
      //   "getLocalStorageValue(ACCESS_TOKEN)",
      //   getLocalStorageValue(ACCESS_TOKEN)
      // );

      window.location = "/auth/sign-in";
      // delete config.headers.common.Authorization;
      // throw new Error("Token not set");
    }
  }

  if (config.url.includes("?")) {
    const parsedUrl = config.url.split("?");
    config.url = formatUrl(parsedUrl[0]);

    if (parsedUrl.length > 1 && parsedUrl[1]) {
      parsedUrl[1].split("&").map((row) => {
        const [key, value] = row.split("=");
        config.params[key] = value.includes(",") ? value.split(",") : value;
      });
    }
  } else {
    config.url = formatUrl(config.url);
  }

  const pageLocation = window.location.pathname;
  if (VIEW_AS_PATHS.some((el) => el.startsWith(pageLocation))) {
    const viewAs = JSON.parse(LocalStorageService.getLocalItem("viewAs"));
    if (!config.env.viewAs && viewAs) {
      config.params.viewAs = viewAs.siteIds;
    }
  }

  return config;
};

const responseError = (error, instance, isPromise = true) => {
  const originalConfig = error.config;

  if (originalConfig && originalConfig.url.includes("auth")) {
    return Promise.reject(error);
  }

  if (error && error?.response.status === 401 && !originalConfig._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalConfig.headers.Authorization = `Bearer ${token}`;
          return instance(originalConfig);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalConfig._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      instance
        .post(authUrls.tokenRefresh, {
          refresh:
            getLocalStorageValue(REFRESH_TOKEN) ||
            getSessionStorageValue(REFRESH_TOKEN),
        })
        .then(({ data }) => {
          const { access, refresh } = data;

          const isLocalStorage = getLocalStorageValue(REFRESH_TOKEN);
          if (isLocalStorage) {
            setLocalStorageValue(ACCESS_TOKEN, access);
            setLocalStorageValue(REFRESH_TOKEN, refresh);
          } else {
            setSessionStorageValue(ACCESS_TOKEN, access);
            setSessionStorageValue(REFRESH_TOKEN, refresh);
          }

          // eslint-disable-next-line no-use-before-define
          setAxiosBearer(access);
          originalConfig.headers.Authorization = `Bearer ${access}`;

          processQueue(null, access);
          resolve(instance(originalConfig));
        })
        .catch((err) => {
          sessionStorage.clear();
          localStorage.clear();
          TokenService.removeTokensCookies();
          processQueue(err, null);
          reject(err);
          // controller.abort();

          if (!window.location.pathname.includes("auth/")) {
            window.location = "/auth/sign-in";
          }
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  if (isOldRefreshToken() && failedQueue.length) {
    processQueue(
      error,
      getLocalStorageValue(ACCESS_TOKEN) || getSessionStorageValue(ACCESS_TOKEN)
    );
  }

  if (isPromise) {
    return Promise.reject(error);
  }
  return error.response;
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return responseError(error, axiosInstance, false);
  }
);

axiosInstance.interceptors.request.use(
  function (config) {
    return requestConfig(config);
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const baseAxios = axios.create({
  baseURL: appConfig.apiURL || window.location.origin,
  // signal: controller.signal,
  params: {},
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "comma" });
  },
});

export const setAxiosBearer = (token) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  baseAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setAxiosToken = (token) => {
  axiosInstance.defaults.headers.common.Authorization = `Token ${token}`;
  baseAxios.defaults.headers.common.Authorization = `Token ${token}`;
};

baseAxios.interceptors.request.use(
  function (config) {
    return requestConfig(config);
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

baseAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return responseError(error, baseAxios, true);
  }
);

export function errorMessage(error, fields) {
  if (error?.response?.data && fields) {
    const message = fields.reduce((acc, field) => (!acc ? error.response.data[field] : acc), '')
    if (Array.isArray(message)) return message[0];
    return message
  }
  return (
    (error.response && error.response.data && error.response.data.detail) ||
    error.detail ||
    error.message ||
    "Something went wrong"
  );
}

export const fetcher = ([key, params]) => baseAxios.get(key, { params })
  .then(res => res.data)

export const checkResponseMessage = message => response => {
  const msg = errorMessage(response, ['message']);
  return msg.includes(message)
}
