import React from 'react';
import styled from "@emotion/styled";
import logoImg from '../../assets/logo.png'
import {Stack} from "@mui/material";

const Container = styled(Stack)(({ theme}) => ({
  height: '70px',
  backgroundColor: theme.palette.color.accentBlue,
}))

const AuthHeader = ({ isMobile }) => {
  return (
    <Container direction="row" alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'}>
      <img width={isMobile ? 136 : 198} src={logoImg} />
    </Container>
  );
};

export default AuthHeader;
