export const MULTI_TABS_NAMES = ["insights", "settings", "feed"];

export function getTabName() {
  const pathList = window.location.pathname.split("/");

  if (pathList.length === 1) return null;

  if (!MULTI_TABS_NAMES.includes(pathList[1])) return null;

  return pathList[pathList.length - 1];
}

export default getTabName;
