import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from '@mui/styles';
import CircularLoading from "../../progress/CircularLoading";


const useStyles = makeStyles({
  buttonGroup: {
    borderRadius: '16px',
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      borderRight: 'none',
    },
  },
});
const MenuButton = ({ color, options, isLoading }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const activeBtn = options[activeIndex]
  const classes = useStyles();

  const handleMenuItemClick = (event, index) => {
    setActiveIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        fullWidth
        color={color}
        className={classes.buttonGroup}
      >
        <Button disabled={isLoading} onClick={activeBtn.onClick}>
          {isLoading ? 'Exporting...' : activeBtn.title}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{ maxWidth: '50px'}}
          ref={anchorRef}
          disabled={isLoading}
        >
          <ArrowDropDownIcon/>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.title}
                      disabled={option.disabled}
                      selected={option.title === activeBtn.title}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
export default MenuButton
