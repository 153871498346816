import { baseAxios } from "../utils/axios";
import { urls } from "./configs/urls";
import {axiosUpdateUser} from "../utils/users/axiosUpdateUser";
import {updateUser} from "../redux/user";

const PATH = urls.summaryInformation;

class UserService {
  async summaryInformation() {
    const response = await baseAxios.get(PATH);
    return response.data;
  }
  updateUser(id, data) {
    return async dispatch => {
      return baseAxios.patch(`${urls.users}/${id}`, data)
        .then(res => dispatch(updateUser(res.data)))
    }
  }
}

export default new UserService();
