import React from "react";
import PropTypes from "prop-types";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/de'

// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import {useLang} from "../../hooks/useLang";

const DATE_TODAY = dayjs(new Date().toUTCString());
const MIN_DATE = dayjs("2022-01-01");

const CustomDatePicker = styled(MobileDatePicker)(({ theme, full }) => ({
  minWidth: full ? "100%" : "130px",
  maxWidth: full ? "100%" : "160px",
  "& .MuiIconButton-root": {
    marginRight: 0,
  },
  "& .MuiSvgIcon-root": {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

function DateInput({ label, date, setDate, fullWidth, newDesign, disabled, maxDate, disabledFuture = true, variant }) {
  const handleChange = (value) => {
    setDate(value);
  };
  const { lang } = useLang()

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={lang}
    >
      <CustomDatePicker
        closeOnSelect
        showDaysOutsideCurrentMonth
        disableFuture={disabledFuture}
        full={fullWidth}
        label={label}
        value={dayjs(date)}
        onChange={handleChange}
        {...(newDesign ? {} : {slotProps: { textField: { variant: variant || 'standard'}}})}
        format="DD/MM/YYYY"
        // components={{ OpenPickerIcon: CalendarTodayIcon }}
        views={["year", "month", "day"]}
        minDate={MIN_DATE}
        maxDate={maxDate || DATE_TODAY}
        showOpenPickerIconInMobile={true}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

DateInput.defaultProps = {
  fullWidth: false,
};

export default DateInput;
