import React from "react";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

function TimeInput({
  setFieldValue,
  fieldValue,
  styles,
  name,
  label = "",
  handleBlur,
  touchedField,
  errorsField,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        value={dayjs(fieldValue)}
        slotProps={{
          textField: {
            sx: styles,
            name,
            variant: "standard",
            label,
            error: Boolean(touchedField && errorsField),
            helperText: touchedField && errorsField,
            onBlur: handleBlur,
          },
        }}
        ampm={false}
        format="HH:mm"
      />
    </LocalizationProvider>
  );
}

TimeInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldValue: PropTypes.any.isRequired,
  styles: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  touchedField: PropTypes.bool,
  errorsField: PropTypes.shape({}),
};

TimeInput.defaultProps = {
  label: "",
  styles: {},
  touchedField: undefined,
  errorsField: undefined,
};

export default TimeInput;
