import PropTypes from "prop-types";

export const shortParentGroupProps = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};
export const parentGroupProps = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  contact_name: PropTypes.string.isRequired,
  contact_number: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  signup_id: PropTypes.number,
};

export default { shortParentGroupProps, parentGroupProps };
