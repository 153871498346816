import PropTypes from "prop-types";

import { paginationLimit } from "../../constants/base";
import { formatDataQuery } from "../../utils/date/formatDataQuery";

export function getFoodParams({ foods, zone, site, date, pageLimit, ordering }) {
  const params = { limit: pageLimit, offset: 0 };

  if (foods && foods.length) params.foods = foods.map((el) => el.id);
  if (zone && zone.length) params.zone = zone.map((el) => el.id);
  if (site && site.length) params.site = site.map((el) => el.id);
  if (date && date.length) {
    if (date[0] > date[1]) {
      params.startDate = formatDataQuery(date[1]);
      params.endDate = formatDataQuery(date[0]);
    } else {
      params.startDate = formatDataQuery(date[0]);
      params.endDate = formatDataQuery(date[1]);
    }
  }
  if (ordering) params.ordering = ordering;

  return params;
}

getFoodParams.propTypes = {
  foods: PropTypes.arrayOf(PropTypes.object),
  setOpenForm: PropTypes.func.isRequired,
  foodPath: PropTypes.func.isRequired,
  ordering: PropTypes.string,
};

getFoodParams.defaultProps = {
  foods: [],
  pageLimit: paginationLimit,
  ordering: null,
};

export default getFoodParams;
