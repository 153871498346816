import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import PropTypes from "prop-types";
import {
  AppBar as MuiAppBar,
  Grid,
  IconButton as MuiIconButton, Stack,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarFilter from "./NavbarFilter";
import {useNavigate} from "react-router-dom";
import SidebarBrand from "../sidebar/SidebarBrand";
import LangSwitcher from "./LangSwitcher";
const AppBar = styled(MuiAppBar)(({ theme }) => ({
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  background: theme.palette.navbar.mobile.background,
  color: theme.palette.navbar.mobile.color,
  borderRadius: 0,
  boxShadow: "0px 2px 4px rgba(245, 246, 248, 0.1)", // Add shadow color here
}));

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function Navbar({ isMobile, isLgUp, onDrawerToggle, children }) {
  const navigate = useNavigate()
  return (
    <AppBar position="sticky" elevation={0} isMobile={isMobile}>
      <Grid container alignItems="center" p={isLgUp ? '0 3rem' : '0 1rem'}>
        {isMobile && (
         <SidebarBrand />
        )}
        {!isMobile && (
          <Grid item sx={{ display: { xs: "block", md: "block", lg: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item xs />
        <Stack direction="row" spacing={4} alignItems="center">
          {isMobile && (
            <Grid item>
              <NavbarFilter />
            </Grid>
          )}
          <NavbarUserDropdown />
          <LangSwitcher />
        </Stack>
      </Grid>
    </AppBar>
  );
}

Navbar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLgUp: PropTypes.bool.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withTheme(Navbar);
