import React from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useModal } from "mui-modal-provider";
import UserEditFrom from "./UserEditFrom";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../utils/axios";
import SimpleDialog from "../dialogs/SimpleDialog";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import { selectUser } from "../../redux/user";
import useAppSelector from "../../hooks/useAppSelector";
import BlueButton from "../ui/BlueButton";

function DetailAction() {
  const { id } = useAppSelector(selectUser);

  const { signOut } = useAuth();

  const navigate = useNavigate();

  const { showModal } = useModal();

  const signOutHandle = () => {
    signOut();
  };

  async function deleteConfigHandler() {
    const response = await axiosInstance.delete(`api/users/${id}/`);

    if (response.status === 204) {
      navigate("/auth/sign-in");
      await signOut();
    }
  }

  const deleteHandle = () => {
    const deleteModal = showModal(SimpleDialog, {
      title: `Delete user`,
      content: (
        <ConfirmDialog
          description="Are you sure you want to delete your profile?"
          onConfirm={deleteConfigHandler}
          onCancel={() => deleteModal.hide()}
        />
      ),
    });
  };

  function editHandler() {
    const editModal = showModal(SimpleDialog, {
      title: "Edit user",
      maxWidth: "xs",
      fullWidth: true,
      handleClose: () => editModal.hide(),
      content: <UserEditFrom onCancel={() => editModal.hide()} />,
    });
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      sx={{ width: "100%", maxWidth: "20rem" }}
    >
      <BlueButton fullWidth size="large" onClick={signOutHandle}>
        Sign Out
      </BlueButton>
      <BlueButton fullWidth size="large" onClick={editHandler}>
        Edit Profile
      </BlueButton>
      <BlueButton fullWidth size="large" onClick={deleteHandle}>
        Delete Profile
      </BlueButton>
    </Stack>
  );
}

export default DetailAction;
