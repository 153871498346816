import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { fetchDevices, selectDevices } from "../../redux/devices";
import patchDevice from "./axios/patchDevice";

function AssignDeviceToSite({ selectedSite, setOpenForm, setItems, mixpanelSubmit }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { devices, isLoading, hasError } = useSelector(selectDevices);

  useEffect(() => {
    dispatch(fetchDevices());
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        siteId: selectedSite.id,
        deviceList: (selectedSite.devices || []).map((d) => d.id),
      }}
      isInitialValid
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={Yup.object().shape({
        siteId: Yup.number().required(),
        deviceList: Yup.array(),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        const items = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const deviceId of values.deviceList) {
          // eslint-disable-next-line no-await-in-loop
          const { data, error } = await patchDevice({
            id: deviceId,
            data: { site: values.siteId },
            enqueueSnackbar,
          });

          if (error) {
            setErrors(error);
          } else {
            items.push(data);
          }
        }

        if (items.length) {
          setItems((prevState) =>
            prevState.map((item) => {
              if (item.id === values.siteId) {
                return { ...item, items };
              }
              return item;
            })
          );
        }

        setOpenForm(false);
        setSubmitting(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {(errors.submit || hasError) && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
              {hasError && "Can't fetch device list"}
            </Alert>
          )}

          <FormControl fullWidth variant="standard" sx={{ my: 4 }}>
            <InputLabel id="device-list-select">Device</InputLabel>
            <Select
              multiple
              labelId="device-list-select"
              name="deviceList"
              disabled={isLoading}
              value={values.deviceList}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {devices.map((device) => {
                const deviceName =
                  device.device_name ||
                  `${device.serial} - ${device.site_name}`;
                const name =
                  deviceName.slice(-2).trim() === "-"
                    ? deviceName.slice(0, -3)
                    : deviceName;
                return (
                  <MenuItem key={device.id} value={device.id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
            {touched.zone && errors.zone && (
              <FormHelperText>{errors.zone}</FormHelperText>
            )}
          </FormControl>

          <Button
            fullWidth
            sx={{ height: "50px", my: 4 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || isLoading || hasError}
            onClick={mixpanelSubmit}
          >
            Save
          </Button>
        </form>
      )}
    </Formik>
  );
}

AssignDeviceToSite.propTypes = {
  selectedSite: PropTypes.shape({
    id: PropTypes.number.isRequired,
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        device_name: PropTypes.string,
      })
    ).isRequired,
  }),
  setOpenForm: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
};

AssignDeviceToSite.defaultProps = {
  selectedSite: null,
};

export default AssignDeviceToSite;
