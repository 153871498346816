import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";

import MobileFilters from "./MobileFilters";
import DesktopFilters from "./DesktopFilters";
import useMixpanelTrack from "../../../hooks/useMixpanelTrack";

function PageFilters({
  LeftSide,
  handleSubmit,
  isLoading,
  setIsLoading,
  isFullWidth,
  isReverceOrering,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const mixpanelTrack = useMixpanelTrack();

  const handleSubmitAction = () => {
    setIsLoading(true);
    handleSubmit();
    setIsLoading(false);
    mixpanelTrack("Filter - Submit");
  };

  const filterProps = {
    handleSubmit: handleSubmitAction,
    LeftSide,
    loading: isLoading,
    isFullWidth,
    isReverceOrering,
  };

  if (matches) {
    return <MobileFilters {...filterProps} />;
  }

  return <DesktopFilters {...filterProps} />;
}

PageFilters.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  LeftSide: PropTypes.node,
  FilterComponets: PropTypes.node.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isReverceOrering: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

PageFilters.defaultProps = {
  isReverceOrering: false,
  isFullWidth: false,
  title: null,
  LeftSide: null,
};

export default PageFilters;
