import { getOrdering } from "../../../utils/data/getOrdering";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";

export function getSiteParams({ date, sortModel, ordering }) {
  const paginationLimit = 10000;
  const params = { limit: paginationLimit, offset: 0 };

  if (date && date.length) {
    if (date[0] > date[1]) {
      params.startDate = formatDataQuery(date[1]);
      params.endDate = formatDataQuery(date[0]);
    } else {
      params.startDate = formatDataQuery(date[0]);
      params.endDate = formatDataQuery(date[1]);
    }
  }

  const order = ordering || getOrdering(sortModel);
  if (order) params.ordering = order;

  return params;
}

export default getSiteParams;
