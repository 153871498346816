import { baseAxios } from "../utils/axios";

const URLS = {
  sendMagicLink: 'api/auth/magic-login/',
  verifyMagicLink: 'api/auth/magic-login/verify/',
}

class Auth {
  constructor(apiAgent) {
    this.apiAgent = apiAgent
  }
  async createMagicLink(email) {
    return this.apiAgent.post(URLS.sendMagicLink, { email })
  }

  async verifyMagicToken(token) {
    return this.apiAgent.post(URLS.verifyMagicLink, { sesame: token })
  }
}

export default new Auth(baseAxios)
