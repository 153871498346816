import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { COLORS } from "../../constants/base";

function handleRenderCell({ formattedValue }) {
  return (
    <Typography variant="body1" sx={{ color: COLORS.tableBlack }}>
      {formattedValue}
    </Typography>
  );
}

handleRenderCell.propTypes = {
  formattedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.node,
  ]).isRequired,
};

export default handleRenderCell;
