import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {AppBar, Box, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import SideFilter from "../../components/filter/SideFilter";
import FeedCard from "../../components/cards/FeedCard";
import Pagination from "../../components/paginations/Pagination";
import { PAGE_LIMIT } from "../../components/paginations/configs/constants";
import { selectFeed, setFeeds, setStatus, STATUS } from "../../redux/reducers/feed";
import FeedUserInfoCard from "../../components/feed/FeedUserInfoCard";
import Backdrop from "../../components/backdrop/Backdrop";
import NoEvents from "../../components/feed/NoEvents";
import { selectFilters } from "../../redux/filters";
import { formatDataQuery } from "../../utils/date/formatDataQuery";
import Page from "../../layouts/Page";
import CircularLoading from "../../components/progress/CircularLoading";
import useWindowSize from "../../hooks/useWindowSize";
import useSWR from "swr";
import { fetcher } from "../../utils/axios";

function getShowingCount({ count, limit, offset }) {
  return offset + limit < count ? limit : count - offset;
}

getShowingCount.defaultProps = {
  count: 0,
  limit: PAGE_LIMIT,
  offset: 0,
};

getShowingCount.propTypes = {
  count: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
};

const REFETCH_INTERVAL = 60 * 1000 // one minute

function Feed() {
  const dispatch = useDispatch();

  const { filters, view } = useSelector(selectFilters);

  const gridRef = useRef(null);
  const userInfoRef = useRef(null);
  const filterRef = useRef(null);

  const [eventParams, setEventParams] = React.useState({
    limit: PAGE_LIMIT,
    offset: 0,
  });
  const { height } = useWindowSize()

  const [offset, setOffset] = React.useState(0);

  const { status, events, count } = useSelector(selectFeed);

  const isLoading = useMemo(() => status === STATUS.LOADING, [status]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    const sp = { ...Object.fromEntries(searchParams.entries()) };
    if (sp.foodId && sp.foodName)
      sp.food = [{ id: sp.foodId, name: sp.foodName }];
    if (sp.siteId && sp.siteName)
      sp.site = [{ id: sp.siteId, name: sp.siteName }];
    if (sp.zoneId && sp.zoneName)
      sp.zone = [{ id: sp.zoneId, name: sp.zoneName }];
    if (sp.startDate) sp.startDate = new Date(sp.startDate);
    if (sp.endDate) sp.endDate = new Date(sp.endDate);

    return {
      ...eventParams,
      ...sp,
      offset: offset,
      limit: PAGE_LIMIT,
    };
  }, [eventParams, offset]);

  const fetchParams = useMemo(() => {
    const formattedData = {
      startDate: formatDataQuery(filters.startDate),
      endDate: formatDataQuery(filters.endDate),
    };

    if (filters.startDate > filters.endDate) {
      formattedData.startDate = formatDataQuery(filters.endDate);
      formattedData.endDate = formatDataQuery(filters.startDate);
    }

    if (filters.trimmings !== null) formattedData.trimmings = filters.trimmings;
    if (filters.secondaryWaste) formattedData.secondaryWaste = true;
    if (filters.nonFood) formattedData.nonFood = true;
    if (filters.comments) formattedData.comments = true;

    return {
      ordering: view.ordering?.id || "recommended",
      ...formattedData,
      offset,
      limit: PAGE_LIMIT,
      site: filters.site.map((el) => el.id),
      zone: filters.zone.map((el) => el.id),
      food: filters.food.map((el) => el.id),
    }
  }, [filters, offset])


  const { data, isLoading: isDataFetching } = useSWR(['api/feed', fetchParams], fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    refreshInterval: REFETCH_INTERVAL,
    onSuccess: () => dispatch(setStatus(STATUS.SUCCESS)),
  })

  useEffect(() => {
      if (data) {
        dispatch(setFeeds(data))
      }
    }, [data])

  useEffect(() => {
    if ( isDataFetching) {
      dispatch(setStatus(STATUS.LOADING))
    }
  }, [isDataFetching])

  useEffect(() => {
    if (gridRef.current && status === STATUS.SUCCESS) {
      window.scrollTo(0, 0);
    }
  }, [status, offset]);

  return (
    <Page title="Feed">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        spacing={4}
        sx={{ mb: 8 }}
        ref={gridRef}
      >
        <Typography variant="title" component="h1">Feed</Typography>
        <Box>
          Showing{" "}
          {getShowingCount({
            count,
            offset,
            limit: PAGE_LIMIT,
          })}{" "}
          of {count}
        </Box>
      </Stack>
      <Grid container spacing={6} sx={{ minHeight: isMobile ? "100%" : `${height * 0.7}px` }}>
        <Grid
          item
          xs={12}
          md={4}
          ref={filterRef}
          sx={{ display: !isMobile ? "none" : "block" }}
        >
          <SideFilter />
        </Grid>
        <Grid item xs={12} md={6}>
          <CircularLoading loading={isLoading}>
            {!isLoading && events.length !== 0 && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
              >
                {events.map((item) => (
                  <Backdrop  key={item.id} open={isLoading}>
                    <FeedCard
                      {...item}
                      loading={status === "loading"}
                      params={params}
                    />
                  </Backdrop>
                ))}
                <Pagination
                  count={count}
                  offset={offset}
                  setOffset={setOffset}
                  disabled={isLoading}
                  sx={{ mx: "-1rem" }}
                />
              </Stack>
            )}
            {!events.length && <NoEvents hide={!!events.length} />}
          </CircularLoading>
        </Grid>
        {isMobile && (
          <Grid item xs={12} md={2} ref={userInfoRef}>
            <AppBar
              position="sticky"
              elevation={0}
              sx={{ top: userInfoRef?.current?.offsetTop || 0, zIndex: 4 }}
            >
              <FeedUserInfoCard />
            </AppBar>
          </Grid>
        )}
      </Grid>
    </Page>
  );
}

Feed.propTypes = {};

Feed.defaultProps = {};

export default Feed;
