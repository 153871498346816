import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import dayjs from "dayjs";
import 'dayjs/locale/de'

import createSite from "./axios/createSite";
import { addSite } from "../../redux/sites";
import {useLang} from "../../hooks/useLang";

function CreateSiteForm({ handleClose, mixpanelSubmit, setItems, parentGroups }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { lang } = useLang()
  return (
    <Formik
      initialValues={{
        name: "",
        contactName: "",
        contactNumber: undefined,
        longitude: undefined,
        latitude: undefined,
        goLiveDate: null,
        group: null,
        clientSiteId: null,
      }}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={Yup.object().shape({
        name: Yup.string().min(1).max(255).required("Name is required"),
        contactName: Yup.string()
          .min(1)
          .max(255)
          .required("Contact Name is required"),
        contactNumber: Yup.number().required("Contact Number is required"),
        longitude: Yup.number().required("Longitude is required"),
        latitude: Yup.number().required("Latitude is required"),
        capacity: Yup.number().required("Capacity is required"),
        goLiveDate: Yup.date().nullable(),
        group: Yup.number().nullable(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = {
          name: values.name,
          contact_name: values.contactName,
          contact_number: values.contactNumber,
          longitude: values.longitude,
          latitude: values.latitude,
          capacity: values.capacity,
          client_site_id: values.clientSiteId || null,
        };

        if (values.capacity) {
          data.go_live_date = dayjs(values.goLiveDate).toISOString();
        }

        if (values.group) {
          data.parent_group = [values.group];
        }

        const { data: responseData, error } = await createSite({
          data,
          enqueueSnackbar,
        });

        if (error) {
          // eslint-disable-next-line no-prototype-builtins
          if (error.hasOwnProperty("response")) {
            setStatus("Wrong data");
            const errors = error.response.data;
            errors.contactName = errors.contact_name;
            errors.contactNumber = errors.contact_number;
            errors.goLiveDate = errors.go_live_date;
            setErrors(errors);
          } else {
            setStatus("Something went wrong");
            setErrors(error);
          }
        } else {
          setStatus("New group created successfully");
          dispatch(addSite(responseData));
          setItems((prevItems) => [...prevItems, responseData]);
          handleClose();
        }

        setSubmitting(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            {touched.submit && errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              fullWidth
              variant="standard"
              type="text"
              name="name"
              label="Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="text"
              name="contactName"
              label="Contact Name"
              value={values.contactName}
              error={Boolean(touched.contactName && errors.contactName)}
              helperText={touched.contactName && errors.contactName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="number"
              name="contactNumber"
              label="Contact Number"
              value={values.contactNumber}
              error={Boolean(touched.contactNumber && errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="number"
              name="longitude"
              label="Longitude"
              value={values.longitude}
              error={Boolean(touched.longitude && errors.longitude)}
              helperText={touched.longitude && errors.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="number"
              name="latitude"
              label="Latitude"
              value={values.latitude}
              error={Boolean(touched.latitude && errors.latitude)}
              helperText={touched.latitude && errors.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <FormControl
              fullWidth
              variant="standard"
              sx={{ marginBottom: "1rem" }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={lang}
              >
                <DatePicker
                  sx={{
                    "& .MuiIconButton-root": {
                      marginRight: 0,
                    },
                  }}
                  label="Go Live Date"
                  value={values.date}
                  onChange={(day) => setFieldValue("goLiveDate", dayjs(day))}
                  slotProps={{ textField: { variant: "standard" } }}
                  components={{ OpenPickerIcon: CalendarTodayIcon }}
                />
              </LocalizationProvider>
              {touched.goLiveDate && errors.goLiveDate && (
                <FormHelperText>{errors.goLiveDate}</FormHelperText>
              )}
            </FormControl>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              name="capacity"
              label="Capacity"
              value={values.capacity}
              error={Boolean(touched.capacity && errors.capacity)}
              helperText={touched.capacity && errors.capacity}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />

            <FormControl fullWidth variant="standard" sx={{ my: 4 }}>
              <InputLabel id="zone-select">Group</InputLabel>
              <Select
                labelId="zone-select"
                name="group"
                value={values.group}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {parentGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
              {touched.group && errors.group && (
                <FormHelperText>{errors.group}</FormHelperText>
              )}
            </FormControl>
            <TextField
              fullWidth
              variant="standard"
              name="clientSiteId"
              label="Client Site Id"
              value={values.clientSiteId}
              error={Boolean(touched.clientSiteId && errors.clientSiteId)}
              helperText={touched.clientSiteId && errors.clientSiteId}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />

            <Button
              fullWidth
              sx={{ height: "50px", my: 4 }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={mixpanelSubmit}
            >
              Create Site
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

CreateSiteForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  parentGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CreateSiteForm.defaultProps = {};

export default CreateSiteForm;
