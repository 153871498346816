import {baseAxios} from "../axios";

const getFoodCoast = ({ foodId, siteId}) => {
  return baseAxios('api/food-costs/recent-cost/', { params: {
      food: foodId,
      ...(siteId ? { site: siteId } : {}),
    }}).then((res) => res.data?.value)
    .catch(() => null);
}

export default getFoodCoast;
