import React, {useRef} from "react";
import {Divider, Paper, Popover} from "@mui/material";
import PropTypes from "prop-types";

import SidebarNavList from "./SidebarNavList";
import { COLORS } from "../../constants/base";
import styled from "@emotion/styled";
import {isAdmin} from "./isAdmin";

const SubMenu = styled(Paper)`
    height: 100%;
    width: 300px;
    background-color: #8c8c8c;
`
function SidebarNavSection(props) {
  const {
    title,
    pages,
    toggle,
    user,
    component: Component = "nav",
    ...rest
  } = props;
  const ref = useRef()

  return (
    <Component {...rest} ref={ref}>
      <SidebarNavList pages={pages} depth={0} toggle={toggle} user={user} />
      {title === "main" && isAdmin(user.role) && <Divider color={COLORS.white} />}
    </Component>
  );
}

SidebarNavSection.propTypes = {
  title: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    })
  ).isRequired,
  toggle: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  component: PropTypes.string,
};

SidebarNavSection.defaultProps = {
  component: "nav",
};

export default SidebarNavSection;
