import { createSlice } from "@reduxjs/toolkit";

import { startDateValue } from "../utils/date/startDateValue";
import { endDateValue } from "../utils/date/endDateValue";
import { formatDataQuery } from "../utils/date/formatDataQuery";

const initialState = {
  baseValues: {
    startDate: formatDataQuery(startDateValue()),
    endDate: formatDataQuery(endDateValue()),
  },
};

export const baseValuesSlice = createSlice({
  name: "baseValues",
  initialState,
  reducers: {
    updateBaseValues: (state, { payload }) => {
      state.baseValues = { startDate: payload[0], endDate: payload[1] };
    },
  },
});

export const { updateBaseValues } = baseValuesSlice.actions;

export const selectBaseValues = (state) => state.baseValues;

export default baseValuesSlice.reducer;
