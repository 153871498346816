import {
  faDesktop,
  faNetworkWired,
  faCarrot,
  faChartSimple,
  faHouse,
  faBars,
  faMagnifyingGlass,
  faGear,
  faBarsProgress,
  faCalendarCheck,
  faClipboardCheck,
  faLaptop,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'

const mainMenu = [
  {
    href: "overview",
    icon: faHouse,
    title: "Home",
    permissions: ["User", "Admin", "Labeller"],
  },
  {
    href: "feed",
    icon: faBars,
    title: "Feed",
    permissions: ["User", "Admin", "Labeller"],
  },
  {
    href: "insights",
    icon: faChartSimple,
    title: "Insights",
    permissions: ["User", "Admin", "Labeller"],
    children: [
      {
        href: "/insights/foods",
        title: "Foods",
        icon: faCarrot,
      },
      {
        href: "/insights/monitors",
        title: "Monitors",
        icon: faDesktop,
      },
      {
        href: "/insights/sites",
        title: "Sites",
        icon: faNetworkWired,
      },
    ],
  },
  {
    href: 'explore',
    icon: faMagnifyingGlass,
    title: 'Explore',
    permissions: ['User', "Admin", "Labeller"],
  },
  {
    href: "settings",
    icon: faGear,
    title: "Settings",
    permissions: ["User", "Admin", "Labeller"],
    children: [
      {
        href: "/settings/profile",
        title: "Profile",
      },
      {
        href: "/settings/data-connector",
        title: "Data Connector",
      },
    ],
  },
];

export const adminMenu = [
  {
    href: "review",
    icon: faBarsProgress,
    title: "Review",
    permissions: ["Admin"],
    hide: true,
  },
  {
    href: "labelling",
    icon: faCalendarCheck,
    title: "Labelling",
    permissions: ["Admin", "Labeller"],
  },
  {
    href: "verification",
    icon: faClipboardCheck,
    title: "Verification",
    permissions: ["Admin", "Labeller"],
  },
  {
    href: "device-management",
    icon: faLaptop,
    title: "Device Management",
    permissions: ["Admin"],
    hide: true,
  },
  {
    href: "site-management",
    icon: faChartSimple,
    title: "Site Management",
    permissions: ["Admin"],
    hide: true,
  },
  {
    href: "group-management",
    icon: faChartSimple,
    title: "Group Management",
    permissions: ["Admin"],
    hide: true,
  },
  {
    href: "users",
    icon: faUserGroup,
    title: "Users",
    permissions: ["Admin"],
  },
];

const navItems = [
  {
    title: "main",
    href: "",
    pages: mainMenu,
    permissions: ["User", "Admin", "Labeller"],
  },
  {
    title: "admin",
    href: "",
    pages: adminMenu,
    permissions: ["Admin", "Labeller"],
  },
];

export default navItems;
