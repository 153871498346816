import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import {Card, CardContent, Divider, FormGroup, Stack, Typography} from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SwitchFilter from "./components/SwitchFilter";
import constants from "./configs/constants";
import { fetchSites, selectSites } from "../../redux/sites";
import { fetchZones, selectZones } from "../../redux/zones";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import { fetchFoods, selectFoods } from "../../redux/foods";
import {
  selectFilters,
  updateDisplay,
  updateFilters,
} from "../../redux/filters";
import useToSearchParams from "../../hooks/useToSearchParams";
import CustomButton from "../components/Buttons/CustomButton";
import formatInitialListState from "../../utils/formaters/formatInitialListState";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import ExportDataCSV from "../components/Buttons/ExportDataCSV";
import AddRecipeButton from "../components/Buttons/AddRecipeButton";
import DateInput from "../inputs/DateInput";
import {LABELS} from "../../constants/explore";
import objectsEqual from "../../utils/data/objectsEqual";

const foodOrder = {
  name: "Focus Foods",
  id: "weight",
}

function SideFilter({ setEventParams, setOffset }) {
  const mixpanelTrack = useMixpanelTrack();

  const toSearchParams = useToSearchParams();

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, view } = useSelector(selectFilters);
  const { sites, isLoading: isLoadingSites } = useSelector(selectSites);
  const { foods, isLoading: isLoadingFoods } = useSelector(selectFoods);
  const { zones, isLoading: isLoadingZones } = useSelector(selectZones);

  const isFeedPage = useMemo(() => pathname.includes("feed"), [pathname]);
  const isSitesPage = useMemo(() => pathname.includes("sites"), [pathname]);
  const isMonitorsPage = useMemo(
    () => pathname.includes("monitors"),
    [pathname]
  );
  const isFoodsPage = useMemo(() => pathname.includes("foods"), [pathname]);

  React.useEffect(() => {
    if (!isLoadingSites && sites.length === 0) {
      dispatch(fetchSites({ fields: ["id", "name"] }));
    }
    if (!isLoadingFoods && zones.length === 0) {
      dispatch(fetchZones());
    }
    if (!isLoadingZones && foods.length === 0) {
      const foodParams = {
        date: [filters.startDate, filters.endDate],
      };
      if (params.zone.length > 0) {
        foodParams.serial = params.zone.map((zone) => zone.serial);
      }
      dispatch(fetchFoods(foodParams));
    }
  }, [dispatch]);

  const selectedSites = React.useMemo(() => {
    const siteList = formatInitialListState({
      ids: searchParams.getAll("siteId"),
      names: searchParams.getAll("siteName"),
    });
    return siteList.length ? siteList : filters.site;
  }, []);
  const selectedZones = React.useMemo(() => {
    const zoneList = formatInitialListState({
      ids: searchParams.getAll("zoneId"),
      names: searchParams.getAll("zoneName"),
      otherName: "serial",
      otherValue: searchParams.getAll("zoneSerial"),
    });
    return zoneList.length ? zoneList : filters.zone;
  }, []);
  const selectedFoods = React.useMemo(() => {
    const foodList = formatInitialListState({
      ids: searchParams.getAll("foodId"),
      names: searchParams.getAll("foodName"),
    });
    return foodList.length ? foodList : filters.food;
  }, []);
  const [ordering, setOrdering] = React.useState(filters.ordering);
  const [site, setSite] = React.useState(selectedSites);
  const [zone, setZone] = React.useState(selectedZones);
  const [food, setFood] = React.useState(selectedFoods);
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [trimmings, setTrimmings] = useState(
    filters.trimmings === true ? true : null
  );
  const [withoutTrimmings, setWithoutTrimmings] = useState(
    filters.trimmings === false ? true : null
  );
  const [secondaryWaste, setSecondaryWaste] = useState(filters.secondaryWaste);
  const [nonFood, setNonFood] = useState(filters.nonFood);
  const [display, setDisplay] = useState(view.display)
  const withoutOrdering =
    isSitesPage || isMonitorsPage || isFoodsPage || isFeedPage;

  const params = React.useMemo(() => {
    const pageParams = {
      site: site.filter((s) => s.id),
      zone: zone.filter((z) => z.id),
      food: food.filter((f) => f.id),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      trimmings:
        trimmings === true ? true : withoutTrimmings === true ? false : null,
      secondaryWaste,
      nonFood,
    };

    if (!withoutOrdering) {
      pageParams.ordering = ordering;
    }

    return pageParams;
  }, [
    site,
    zone,
    food,
    startDate,
    endDate,
    ordering,
    trimmings,
    withoutTrimmings,
    secondaryWaste,
    nonFood,
  ]);

  const submitHandler = () => {
    const dates = { startDate: params.startDate, endDate: params.endDate };

    if (params.startDate > params.endDate) {
      const temp = params.startDate;
      setStartDate(params.endDate);
      setEndDate(temp);
      dates.startDate = endDate;
      dates.endDate = startDate;
    }
    dispatch(updateDisplay({ display }));
    dispatch(updateFilters({ ...filters, ...params, ...dates }));
    const searchParams = toSearchParams({ ...params, ...dates });

    delete searchParams.display;
    delete searchParams.condense;

    setSearchParams(searchParams);
    setEventParams(searchParams);
    setOffset(0);

    const foodParams = {
      date: [filters.startDate, filters.endDate],
    };
    if (params.zone.length > 0) {
      foodParams.serial = params.zone.map((zone) => zone.serial);
    }
    dispatch(fetchFoods(foodParams));
  };

  const withoutTrimmingsHandler = (value) => {
    setWithoutTrimmings(value === true ? true : null);
    setTrimmings(null);
    setSecondaryWaste(false);
    setNonFood(false);
  };

  const secondaryWasteHandler = (value) => {
    dispatch(updateDisplay({ secondaryWaste: value }));
    setTrimmings(false);
    setWithoutTrimmings(null);
    setSecondaryWaste(value);
    setNonFood(false);
  };

  const nonFoodHandler = (value) => {
    setTrimmings(false);
    setWithoutTrimmings(null);
    setSecondaryWaste(false);
    setNonFood(value);
  };

  const mixpanelExportCSV = () => {
    mixpanelTrack("Export CSV - Sites");
  };

  const handleDisplay = (value) => {
    dispatch(updateDisplay({ display: value }));
  };

  const handleCondense = (value) => {
    dispatch(updateDisplay({ condense: value }));
  };

  const handleWithSecondaryWaste = (value) => {
    console.log("withSecondaryWaste", value)
    dispatch(updateDisplay({ withSecondaryWaste: value }));
  };

  const handleOrdering = (value) => {
    if (withoutOrdering) {
      dispatch(updateDisplay({ ordering: value }));
    } else {
      setOrdering(value);
    }
  };

  const displayItems = useMemo(() => {
    // return constants.display.items
    if (isSitesPage || isMonitorsPage) {
      return constants.display.fullItems;
    } else {
      const items = constants.display.items;
      if (!items.find((i) => i.id === view.display.id)) {
        dispatch(updateDisplay({ display: constants.display.items[0] }));
      }
      return items;
    }
  }, [pathname]);

  function getOrderItems() {
    switch (true) {
      case isFoodsPage:
        return constants.ordering.foodItems;
      case isSitesPage:
      case isMonitorsPage:
        return constants.ordering.shortItems;
      case isFeedPage:
        return constants.ordering.feedItems;
      default:
        return constants.ordering.items;
    }
  }

  const orderingItems = useMemo(() => {
    const items = getOrderItems();
    const label = searchParams.get('labels')
    const isFocusFood = label === LABELS.focusFood

    if(withoutOrdering && isFocusFood) {
      dispatch(updateDisplay({
        ordering: foodOrder
      }))
    }

    if (!items.find((i) => i.id === view.ordering.id)) {
      dispatch(updateDisplay({ ordering: constants.ordering.shortItems[0] }));
    }

    return items;
  }, [pathname]);

  return (
    <Card sx={{ padding: '30px 25px', maxWidth: '428px', minWidth: '300px'}}>
      <CardContent sx={{ padding: 0 }}>
        <Stack spacing={5}>
          <Typography variant="medium">Filters</Typography>
          <Divider />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            {(isFeedPage || isSitesPage || isMonitorsPage || isFoodsPage) && (
              <>
                <ReviewSelectStandard
                  fullWidth
                  label={constants.ordering.label}
                  value={withoutOrdering ? view.ordering : ordering}
                  setValue={handleOrdering}
                  items={orderingItems}
                  variant="outlined"
                />
                <ReviewSelectStandard
                  fullWidth
                  label={constants.display.label}
                  value={display}
                  setValue={setDisplay}
                  items={displayItems}
                  variant="outlined"
                />
              </>
            )}
            {!isSitesPage && (
              <ReviewSelectStandard
                fullWidth
                multiple
                label={constants.site.label}
                value={site}
                setValue={setSite}
                items={sites}
                variant="outlined"
              />
            )}
            {!isSitesPage && (
              <ReviewSelectStandard
                fullWidth
                multiple
                label={constants.zone.label}
                value={zone}
                setValue={setZone}
                items={zones}
                variant="outlined"
              />
            )}
            {(isFeedPage || isFoodsPage) && (
              <ReviewSelectStandard
                fullWidth
                multiple
                label={constants.food.label}
                value={food}
                setValue={setFood}
                items={foods}
                variant="outlined"
              />
            )}
            <>
              <DateInput
                isStartDate
                fullWidth
                label="Start Date"
                date={startDate}
                setDate={setStartDate}
                variant="outlined"
              />
              <DateInput
                fullWidth
                label="End Date"
                date={endDate}
                setDate={setEndDate}
                variant="outlined"
              />
            </>
            {isFoodsPage && (
              <SwitchFilter
                label={constants.withSecondaryWaste.label}
                value={view.withSecondaryWaste}
                setValue={handleWithSecondaryWaste}
              />
            )}
            {(isSitesPage || isMonitorsPage || isFoodsPage) && (
              <SwitchFilter
                label={constants.condense.label}
                value={view.condense}
                setValue={handleCondense}
              />
            )}
            {isFeedPage && (
              <FormGroup>
                <SwitchFilter
                  label={constants.trimmings.withoutLabel}
                  value={withoutTrimmings}
                  setValue={withoutTrimmingsHandler}
                />
                <SwitchFilter
                  label={constants.secondaryWaste.label}
                  value={secondaryWaste}
                  setValue={secondaryWasteHandler}
                />
                <SwitchFilter
                  label={constants.nonFood.label}
                  value={nonFood}
                  setValue={nonFoodHandler}
                />
              </FormGroup>
            )}
            <CustomButton fullWidth onClick={submitHandler} color="secondary">
              Apply filters
            </CustomButton>
            {(isSitesPage || isFoodsPage || isFeedPage) && (
              <>
                <Divider />
                <ExportDataCSV mixpanelExportCSV={mixpanelExportCSV} />
              </>
            )}
            {isFoodsPage && <AddRecipeButton />}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

SideFilter.defaultProps = {
  setEventParams: () => {},
  setOffset: () => {},
};

SideFilter.propTypes = {
  setEventParams: PropTypes.func,
  setOffset: PropTypes.func,
};

export default SideFilter;
