import React from 'react';
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";

import gmailImg from '../../assets/gmail.png';
import outlookImg from '../../assets/outlook.png';
import FormLayout from "../../components/auth/FormLayout";
import {useParams} from "react-router-dom";
import {COLORS} from "../../constants/base";
import styled from "@emotion/styled";
import useAuth from "../../hooks/useAuth";
import {useSnackbar} from "notistack";

const Image = ({ src }) => (
  <img src={src} width={18} />
)

const goTo = url => window.open(url)
const GMAIL_URL = 'https://mail.google.com/'
const OUTLOOK_URL = 'https://outlook.office.com/'

const CheckEmail = () => {
  const { email } = useParams()
  const { sendMagicLink } = useAuth()
  const { enqueueSnackbar } = useSnackbar();

  const sendLinkAgain = () => sendMagicLink(email)
    .then(() => enqueueSnackbar('Link has been sent', { variant: "success"}))
  return (
    <FormLayout showBackButton backPath="/auth/sign-in" title="Check your E-mail" headTitle="Sign-In">
      <Stack>
        <div>
          <Typography sx={{ color: COLORS.darkBlue2, display: 'inline', marginRight: "2px" }} variant="body1">
            We sent an email to you at { email }. It has a magic link that'll log you in.
            Make sure to check your spam folder & If you haven't received an email after a minute.
          </Typography>
          <Button
            sx={{
            color: COLORS.darkBlue2,
            fontSize: "13px",
            fontWeight: 400,
            padding: 0,
            marginBottom: "2px",
            textDecoration: 'underline',
            display: 'inline'
          }}
            onClick={sendLinkAgain}
          >
            Click here to try again
          </Button>
        </div>
        <Stack direction="row">
          <Button
            sx={{ flex: 1, padding: "20px 5px", color: COLORS.darkBlue2,}}
            startIcon={<Image src={gmailImg} />}
            onClick={() => goTo(GMAIL_URL)}
            >
            Check Gmail
          </Button>
          <Button
            sx={{ flex: 1, padding: "20px 5px", color: COLORS.darkBlue2,}}
            startIcon={<Image src={outlookImg} />}
            onClick={() => goTo(OUTLOOK_URL)}
            >
            Check Outlook
          </Button>
        </Stack>
      </Stack>
    </FormLayout>
  );
};

export default CheckEmail;
