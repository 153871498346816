import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "16px",
}));

function BlueButton({ children, size, ...props }) {
  return (
    <StyledButton disableElevation variant="contained" size={size} {...props}>
      {children}
    </StyledButton>
  );
}

BlueButton.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
};

BlueButton.defaultProps = {
  size: "medium",
};

export default BlueButton;
