import React, {useEffect, useState} from 'react';
import {Grid, Typography, Tooltip as MuiTooltip, Stack} from "@mui/material";
import {COLORS} from "../../../../constants/base";
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {dataToChart} from "../helpers";
import {CHART_AXIS, formatTicker} from "../../../explore/helpers";
import OccupancyBar from "./OccupancyBar";
import useIsMobile from "../../../../hooks/useIsMobile";
import dayjs from "dayjs";
import {pipe} from "../../../../utils/pipe";
import {DATE_GRAN} from "../../../../constants/explore";
import Paper from "@mui/material/Paper";
import {translate} from "../../../../utils/lang";
import {useLang} from "../../../../hooks/useLang";

const formatTickMobile = date => dayjs(date).format('DD/MM')
const getChartData = isMobile => ( chartData = []) => isMobile ? chartData.slice(-7) : chartData

const CustomTooltip = ({ label, payload }) => {
  const data = payload?.length ? payload[0] : {}
  const { lang, getLocale, isCached } = useLang()
  useEffect(() => {
    if (data?.name && !isCached(data?.name)) {
      translate([data?.name], lang)
    }
  }, [data?.name]);
  return (
    <Paper className="no-translate" sx={{ padding: '20px' }} key={data?.value}>
      <Stack>
        <Typography>{pipe(label, formatTicker(DATE_GRAN.daily))}</Typography>
        <Typography>{`${getLocale(data.name)}: ${data.value}`}</Typography>
      </Stack>
    </Paper>
  )
}
const Chart = ({ title, data, onBarClick }) => {
  const isMobile = useIsMobile()
  const chartData = pipe(data?.chart, getChartData(isMobile), dataToChart)
  const direction = isMobile ? 'vertical' : 'horizontal'
  const isVertical = direction === 'vertical'
  const barSize = 50
  const chartHeight = isVertical ? chartData.length * barSize : undefined
  const [ground, setGround] = useState()

  return (
    <Grid container sx={{ height: '100%' }}>
      {!isMobile && (
        <Grid item xs={1} sx={{ position: 'relative'}}>
          <MuiTooltip title={title} placement="right">
            <Typography
              whiteSpace="nowrap"
              sx={{
                overflow: 'hidden',
                writingMode: 'vertical-lr',
                transform: 'rotate(180deg)',
                minHeight: 0,
                position: 'absolute',
                bottom: 0,
                textOverflow: 'ellipsis',
                height: '100%'
              }}
              textOverflow="ellipsis"
              variant="h2" color={COLORS.inputBorder}
              textAlign="center"
            >
              {title}
            </Typography>
          </MuiTooltip>
        </Grid>
      )}
      <Grid item xs={11}>
        <ResponsiveContainer width="100%" height="100%" minHeight={chartHeight}>
          <BarChart
            width={"100%"}
            height={"100%"}
            data={chartData}
            layout={direction}
            margin={{
              top: 20,
              right: 40,
              bottom: 30,
            }}
          >
            <XAxis stroke={COLORS.gray_light} textAnchor="start" angle={45} hide={isVertical} type={isVertical ? 'number' : undefined} dataKey={isVertical ? undefined : 'x'}
                   tickFormatter={formatTicker(CHART_AXIS)}/>
            <YAxis type={isVertical ? 'category' : undefined } stroke={COLORS.gray_light} dataKey={isVertical ? 'x' : undefined} tickFormatter={isVertical && formatTickMobile}/>
            <Tooltip content={<CustomTooltip />}/>
            <Bar
              dataKey="Occupancy"
              barSize={isVertical ? 30 : undefined}
              stackId="a"
              shape={<OccupancyBar direction={direction} isVertical={isVertical} onClick={onBarClick} ground={ground} setGround={setGround}/>}
            />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default Chart;
