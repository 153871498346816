import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useModal } from "mui-modal-provider";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  fetchParentGroups,
  selectParentGroups,
} from "../../redux/parentGroups";
import SimpleDialog from "../dialogs/SimpleDialog";
import FormEditGroup from "./FormEditGroup";
import FormDeleteGroup from "./FormDeleteGroup";
import useUpdateGroup from "./hooks/useUpdateGroup";
import useColumnsGroupManagementTable from "./hooks/useColumnsGroupManagementTable";

function GroupManagementTable() {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { parentGroups, isLoading } = useSelector(selectParentGroups);

  const updateGroup = useUpdateGroup();

  useEffect(() => {
    dispatch(fetchParentGroups());
  }, []);

  const handleEdit = (data) => {
    const modelEdit = showModal(SimpleDialog, {
      title: `Update Group ${data.name}`,
      fullWidth: true,
      maxWidth: "xs",
      handleClose: () => modelEdit.hide(),
      content: (
        <FormEditGroup
          data={data}
          handlerConfirm={(data) => updateGroup(data)}
          handlerClose={() => modelEdit.hide()}
        />
      ),
    });
  };

  const handleDelete = ({ id, name }) => {
    const modelDelete = showModal(SimpleDialog, {
      title: `Delete Group ${name}`,
      fullWidth: true,
      maxWidth: "xs",
      handleClose: () => modelDelete.hide(),
      content: (
        <FormDeleteGroup id={id} handleCancel={() => modelDelete.hide()} />
      ),
    });
  };

  const columns = useColumnsGroupManagementTable(handleEdit, handleDelete);

  return (
    <Paper elevation={0} sx={{ mt: 4 }}>
      <DataGrid
        autoHeight
        hideFooter
        disableColumnMenu
        disableRowSelectionOnClick
        rows={parentGroups}
        columns={columns}
        loading={isLoading}
      />
    </Paper>
  );
}

export default GroupManagementTable;
