import React, {useEffect, useState} from 'react';
import {useExploreFilters} from "./useExploreFilters";
import {Stack, Grid, Box, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {COLORS} from "../../constants/base";
import {LABELS_LABEL, METRICS_LABEL} from "../../constants/explore";
import { appConfig } from "../../config";

const StyledBox = styled(Box)`
    background-color: ${({ head }) => head && COLORS.brandYellow};
    padding: 8px;
    height: 100%
`

const FilterReport = ({ filters }) => {

  const dateRange = `From ${filters.dateRange[0].toLocaleDateString()} to ${filters.dateRange[1].toLocaleDateString()}`;
  return (
    <Stack sx={{ border: `1px solid ${COLORS.gray_light}`, borderRadius: '6px', width: '100%'}}>
      <Grid container sx={{ borderBottom: `1px solid ${COLORS.gray_light}`}}>
        <Grid item xs={4}>
          <StyledBox head>
            <Typography color={COLORS.darkBlue2}>Metric</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={8}>
          <StyledBox>
            <Typography color={COLORS.darkBlue2}>
              {METRICS_LABEL[filters.metric]}
            </Typography>
          </StyledBox>
        </Grid>
      </Grid>
      <Grid container sx={{ borderBottom: `1px solid ${COLORS.gray_light}`}}>
        <Grid item xs={4}>
          <StyledBox head>
            <Typography color={COLORS.darkBlue2}>Labels</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={8}>
          <StyledBox>
            <Typography color={COLORS.darkBlue2}>
              {filters.labels}
            </Typography>
          </StyledBox>
        </Grid>
      </Grid>
      <Grid container sx={{ borderBottom: `1px solid ${COLORS.gray_light}`}}>
        <Grid item xs={4}>
          <StyledBox head>
            <Typography color={COLORS.darkBlue2}>Date</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={8}>
          <StyledBox>
            <Typography color={COLORS.darkBlue2}>
              {dateRange}
            </Typography>
          </StyledBox>
        </Grid>
      </Grid>
      <Grid container sx={{ borderBottom: `1px solid ${COLORS.gray_light}`}}>
        <Grid item xs={4}>
          <StyledBox head>
            <Typography color={COLORS.darkBlue2}>Sites</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={8}>
          <StyledBox>
            <Typography color={COLORS.darkBlue2}>
              {filters.sites.length
                ? filters.sites.map(site => site.name).join(', ')
                : 'All'
              }
            </Typography>
          </StyledBox>
        </Grid>
      </Grid>
      <Grid container sx={{ borderBottom: `1px solid ${COLORS.gray_light}`}}>
        <Grid item xs={4}>
          <StyledBox head>
            <Typography color={COLORS.darkBlue2}>Food</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={8}>
          <StyledBox>
            <Typography color={COLORS.darkBlue2}>
              {filters.food.length
                ? filters.food.map(item => item.name).join(', ')
                : 'All'
              }
            </Typography>
          </StyledBox>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default FilterReport;
