import { userPermission } from "./config/constants";
import { isMobile } from "react-device-detect";

export const checkSidebarPermitions = (user, permissions) => {
  if (!user.id) return false;

  if (!permissions) return true;

  if (!permissions.includes(userPermission) && isMobile) return false;

  if (!permissions.includes(user.role)) return false;

  return true;
};
