import { useDispatch } from "react-redux";
import GroupService from "../../../services/GroupService";
import { addParentGroup } from "../../../redux/parentGroups";

const useCreateGroup = () => {
  const dispatch = useDispatch();

  const useFunc = async (data) => {
    try {
      delete data.id;
      const response = await GroupService.create({ data });
      dispatch(addParentGroup(response));
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  return useFunc;
};

export default useCreateGroup;
