import { baseAxios } from "../../../utils/axios";

const getLabellingFoods = async ({ siteId }) => {
  const url = "api/food-descriptions";
  const params = siteId ? { site_id: siteId } : {};

  const response = await baseAxios.get(url, { params });
  return response.data.map(food => ({...food, name: food.food_with_description })) || [];
};

export default getLabellingFoods;
