import React from "react";

import ResetPasswordComponent from "../../components/auth/ResetPassword";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import FormLayout from "../../components/auth/FormLayout";

function ResetPassword() {
  const mixpanelTrack = useMixpanelTrack()

  mixpanelTrack("Reset Password - Page View")
  return (
    <FormLayout
      showBackButton
      backPath="/auth/sign-in-with-password"
      title="Reset Password"
      headTitle="Reset Password"
    >
      <ResetPasswordComponent />
    </FormLayout>
  );
}

export default ResetPassword;
