import { baseAxios } from "../utils/axios";
import { urls } from "./configs/urls";

const PATH = urls.signUpId;

class SignUpIdService {
  async getAvailable() {
    const commentPath = `${PATH}/available`;
    const response = await baseAxios.get(commentPath);
    return response.data;
  }
}

export default new SignUpIdService();
