import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { withResizeDetector } from "react-resize-detector";
import PropTypes from "prop-types";

import PageFilters from "../../components/components/pageFilter/PageFilters";
import { fetchSites, selectSites } from "../../redux/sites";
import { fetchZones, selectZones } from "../../redux/zones";
import {
  fetchParentGroups,
} from "../../redux/parentGroups";
import DeviceManagmentTable from "../../components/deviceManagement/DeviceManagmentTable";
import { DEVICE_MANAGMENT_TITLE } from "../../constants/deviceMenagment";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import Page from "../../layouts/Page";
import {Box, Stack, Typography} from "@mui/material";

function DeviceManagement({ width }) {
  const mixpanelTrack = useMixpanelTrack();

  const { sites, isLoading: isLoadingSites } = useSelector(selectSites);
  const { zones, isLoading: isLoadingZones } = useSelector(selectZones);

  const dispatch = useDispatch();

  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loading = useMemo(() => {
    const loadingValue = isLoading || isLoadingZones || isLoadingSites;
    return loadingValue;
  }, [isLoading, isLoadingZones, isLoadingSites]);

  useEffect(() => {
    dispatch(fetchSites());
    dispatch(fetchZones());
    dispatch(fetchParentGroups());
    mixpanelTrack("Device Management - Page View");
  }, []);

  return (
    <Page title={DEVICE_MANAGMENT_TITLE}>
      <Stack spacing={8}>
        <Typography variant="title" component="h1">{DEVICE_MANAGMENT_TITLE}</Typography>
        <PageFilters isLoading={loading} setIsLoading={setIsLoading} />
        <DeviceManagmentTable
          devices={devices}
          setDevices={setDevices}
          loading={loading}
          zones={zones}
          sites={sites}
          width={width}
        />
      </Stack>
    </Page>
  );
}

DeviceManagement.propTypes = {
  width: PropTypes.number,
};

DeviceManagement.defaultProps = {
  width: undefined,
};

export default withResizeDetector(DeviceManagement);
