import React, { useEffect } from "react";
import * as Yup from "yup";
import { FieldArray, Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

import { Alert } from "../styled/GlobalStyled";
import { selectUser } from "../../redux/user";
import axiosInstance from "../../utils/axios";
import { fetchFoods, selectFoods, updateFoods } from "../../redux/foods";

const MAX_INGREDIENT = 3;

const IngredientButton = styled(Button)(({ theme }) => ({
  paddingLeft: 0,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  color: theme.palette.color.grayIcon,
}));

function EnterSubmit() {
  const { handleSubmit } = useFormikContext();
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  return null;
}

function NewRecipeForm({
  setFoodList,
  handleAddRecipeClose,
  handleMixpanelSubmit,
}) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { foods } = useSelector(selectFoods);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (foods.length === 0) {
      dispatch(fetchFoods({ labelling: true }));
    }
  }, [dispatch]);

  return (
    <DialogContent>
      <Formik
        initialValues={{
          name: undefined,
          ingredients: [{ id: undefined, quantity: undefined }],
        }}
        isInitialValid
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Recipe Name is required"),
          ingredients: Yup.array()
            .of(
              Yup.object({
                id: Yup.number().required("Required"),
                quantity: Yup.number("Only numbers")
                  .moreThan(0, "Must be positive number")
                  .required("Required"),
              })
            )
            .min(1, "Minimum of 1 ingredient")
            .max(MAX_INGREDIENT, `Maximun of ${MAX_INGREDIENT} ingredients`)
            .ensure(),
        })}
        onSubmit={async (values) => {
          const data = { ...values, user_id: user.id };
          const response = await axiosInstance.post(
            "api/food-recipe-lookups/",
            data
          );
          if (response.status === 201) {
            dispatch(updateFoods(response.data));
            setFoodList((prevValue) => [response.data, ...prevValue]);
            enqueueSnackbar("New recipe was successfully created.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Oops, something went wrong.", {
              variant: "error",
            });
          }
          handleAddRecipeClose();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}

            <TextField
              variant="standard"
              type="text"
              name="name"
              label="Recipe Name"
              value={values.name}
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <FieldArray
              name="ingredients"
              validateOnChange={false}
              render={(arrayHelpers) => {
                const { ingredients } = values;
                const ingredientIds = ingredients.map((el) => el.id);

                return (
                  <div>
                    {errors.ingredients &&
                      typeof errors.ingredients === "string" && (
                        <Alert mt={2} mb={3} severity="warning">
                          {errors.ingredients}
                        </Alert>
                      )}
                    {ingredients &&
                      ingredients.length > 0 &&
                      ingredients.map((el, index) => (
                        <Stack
                          key={el.id}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={4}
                          sx={{ mt: "0.5rem", mb: "0.5rem" }}
                        >
                          <FormControl
                            fullWidth
                            variant="standard"
                            error={Boolean(
                              Array.isArray(errors.ingredients) &&
                                errors.ingredients[index] &&
                                errors.ingredients[index].id
                            )}
                          >
                            <InputLabel id={`ingredient-label-${index}`}>
                              Ingredient
                            </InputLabel>
                            <Select
                              labelId={`ingredient-label-${index}`}
                              value={el && el.id}
                              onChange={(event) => {
                                arrayHelpers.replace(index, {
                                  ...el,
                                  id: event.target.value,
                                });
                              }}
                            >
                              {foods &&
                                foods
                                  .filter((f) => {
                                    return (
                                      (ingredientIds.includes(f.id) &&
                                        ingredientIds.indexOf(f.id) ===
                                          index) ||
                                      (ingredientIds.indexOf(f.id) !== index &&
                                        !ingredientIds.includes(f.id))
                                    );
                                  })
                                  .map((food) => (
                                    <MenuItem key={food.id} value={food.id}>
                                      {food.name}
                                    </MenuItem>
                                  ))}
                            </Select>
                            {errors.ingredients &&
                              Array.isArray(errors.ingredients) &&
                              errors.ingredients[index] &&
                              errors.ingredients[index].id && (
                                <FormHelperText>
                                  {errors.ingredients[index].id}
                                </FormHelperText>
                              )}
                          </FormControl>
                          <TextField
                            label="Quantity (KG)"
                            variant="standard"
                            type="number"
                            sx={{ width: "120" }}
                            value={el.quantity}
                            error={Boolean(
                              errors.ingredients &&
                                Array.isArray(errors.ingredients) &&
                                errors.ingredients[index] &&
                                errors.ingredients[index].quantity
                            )}
                            helperText={
                              errors.ingredients &&
                              Array.isArray(errors.ingredients) &&
                              errors.ingredients[index] &&
                              errors.ingredients[index].quantity
                            }
                            onChange={(event) => {
                              arrayHelpers.replace(index, {
                                ...el,
                                quantity: event.target.value,
                              });
                            }}
                          />
                          <IconButton
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Stack>
                      ))}
                    <IngredientButton
                      onClick={() => {
                        arrayHelpers.push({
                          id: undefined,
                          quantity: undefined,
                        });
                      }}
                    >
                      <AddCircleIcon sx={{ ml: 0, mr: 2 }} />
                      <Typography> Add Ingredient</Typography>
                    </IngredientButton>
                  </div>
                );
              }}
            />
            <EnterSubmit />
            <Button
              sx={{ height: "50px" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={handleMixpanelSubmit}
            >
              Save Recipe
            </Button>
          </form>
        )}
      </Formik>
    </DialogContent>
  );
}

NewRecipeForm.propTypes = {
  setFoodList: PropTypes.func.isRequired,
  handleAddRecipeClose: PropTypes.func.isRequired,
  handleMixpanelSubmit: PropTypes.func.isRequired,
};

export default NewRecipeForm;
