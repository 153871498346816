import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import 'dayjs/locale/de'
import 'dayjs/locale/en';
dayjs.extend(utc);

export const localDateFormat = (value, options = { weekDay: false, lang: 'en' }) => {
  const mask = options.weekDay ? "ddd MMM D, YYYY" : "MMM D, YYYY"
  if (value) {
    dayjs.locale(options.lang)
    const date = dayjs(value);
    return date.utc().format(mask);
  }
  return null;
};

export const localDate = (value) => {
  const date = new Date(value);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const dateString = new Intl.DateTimeFormat(
    navigator.language,
    options
  ).format(date);

  return dateString;
};

export const localDateForUsers = (value) => {
  const date = new Date(value);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const dateString = new Intl.DateTimeFormat(
    navigator.language,
    options
  ).format(date);

  return dateString;
};

export default localDate;

export const getUtcDate = date => {
  try {
    return dayjs(date).utc()
  } catch (e) {
    return null
  }
}
