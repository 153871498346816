import React, {useEffect} from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import PropTypes from "prop-types";
import { SnackbarProvider } from "notistack";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CookiesProvider } from "react-cookie";
import { MixpanelProvider } from "react-mixpanel-browser";
import ModalProvider from "mui-modal-provider";
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";
import SuccessSnackbar from "./components/snackbars/SuccessSnackbar";
import ErrorSnackbar from "./components/snackbars/ErrorSnackbar";
import InfoSnackbar from "./components/snackbars/InfoSnackbar";
import { appConfig } from "./config";
import {window} from "mixpanel-browser/src/utils";

const clientSideEmotionCache = createEmotionCache();

if (appConfig.weglotApiKey) {
  window.Weglot.initialize({
    api_key: appConfig.weglotApiKey,
    hide_switcher: true,
    cache: true,
    excluded_blocks: [
      {
        value: ".rs-picker-toggle-value" // All elements with this class won't be translated
      },
      {
        value: ".apexcharts-tooltip-text-y-label"
      },
      {
        value: ".apexcharts-tooltip-text-y-value"
      },
      {
        value: ".no-translate"
      }
    ],
  });
}

function App({ emotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  const configMixpanl = {
    debug: !appConfig.isProduction,
    track_pageview: true,
    persistence: "localStorage",
  };

  return (
    <GoogleOAuthProvider clientId={appConfig.googleClientId}>
      <CacheProvider value={emotionCache}>
        <HelmetProvider>
          <Helmet titleTemplate="%s | Food Menu" defaultTitle="Food Menu" />
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={createTheme(theme)}>
                <CookiesProvider>
                  <MixpanelProvider
                    config={configMixpanl}
                    token={appConfig.MIXPANEL_TOKEN}
                  >
                    <AuthProvider>
                      <SnackbarProvider
                        preventDuplicate
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        TransitionProps={{ direction: "down" }}
                        Components={{
                          success: SuccessSnackbar,
                          error: ErrorSnackbar,
                          info: InfoSnackbar,
                        }}
                      >
                        <ModalProvider>{content}</ModalProvider>
                      </SnackbarProvider>
                    </AuthProvider>
                  </MixpanelProvider>
                </CookiesProvider>
              </MuiThemeProvider>
            </LocalizationProvider>
          </Provider>
        </HelmetProvider>
      </CacheProvider>
    </GoogleOAuthProvider>
  );
}

App.propTypes = {
  emotionCache: PropTypes.shape(),
};

App.defaultProps = {
  emotionCache: clientSideEmotionCache,
};

export default App;
