import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography, Button } from "@mui/material";
import { faCommentMedical, faComment } from "@fortawesome/free-solid-svg-icons";

import TagChip from "../../chips/TagChip";
import EventImage from "../../components/ImageBlock/EventImage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function FeedCardContent({
  event,
  boundingArea,
  tags,
  comment,
  handleComment,
}) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ borderRadius: "1rem", my: 4 }}
      >
        <Box
          sx={{
            borderRadius: "1rem",
            overflow: "hidden",
            position: "relative",
            minHeight: "200px",
            flex: 1,
            maxWidth: '560px',
          }}
          data-testid="blob-event-image"
        >
          <EventImage
            disableMargin
            event={event}
            boundingArea={boundingArea || [0, 0, 1920, 1080]}
            enableSwitch={false}
            isFeedPage
          />
        </Box>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        sx={{ mt: 4 }}
      >
        {tags.map((tag, index) => (
          <TagChip key={index} label={tag} />
        ))}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        sx={{ fontSize: "0.5rem" }}
      >
        <Typography sx={{ flex: 1, mx: 0, fontSize: "1.5em" }}>
          {comment}
        </Typography>
        <Button
          startIcon={<FontAwesomeIcon icon={comment ? faComment : faCommentMedical } />}
          onClick={handleComment}
          sx={{ minWidth: 0 }}
        >
          {comment ? "Edit" : "Add Comment"}
        </Button>
      </Stack>
    </>
  );
}

FeedCardContent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    backup_image_url: PropTypes.arrayOf(PropTypes.string),
    food: PropTypes.number,
    image_url: PropTypes.string,
    logged_at: PropTypes.string,
    item_weight: PropTypes.string,
    site: PropTypes.string,
  }),
  boundingArea: PropTypes.arrayOf(PropTypes.number).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  comment: PropTypes.string,

  handleComment: PropTypes.func.isRequired,
};

FeedCardContent.defaultProps = {
  comment: null,
  event: null,
};

export default FeedCardContent;
