import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import PropTypes from "prop-types";

function RecipeChip(props) {
  return (
    <Chip
      label="RECIPE"
      size="small"
      variant="outlined"
      color="info"
      {...props}
      onDelete={null}
      sx={{ ml: 2 }}
    />
  );
}

function SelectFood({ food, setFood, open, setOpen, options, variant = 'standard' }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const closeSelect = () => {
    setIsOpen(false);
    if (setOpen) setOpen(false);
  };

  const openSelect = () => {
    setIsOpen(true);
    if (setOpen) setOpen(true);
  };

  const handlerFood = (_event, newValue) => {
    const selectedFood = [newValue.at(-1)];
    if (selectedFood[0]) setFood(selectedFood);
    else setFood([]);
  };

  const renderInput = (params) => (
    <TextField {...params} label={t("Food")} variant={variant} value={food} />
  );

  const renderTags = (value, getTagProps) => {
    const index = value.length - 1;
    const option = value[index];
    return (
      <Box>
        {option && option.name}
        {option.is_recipe && <RecipeChip {...getTagProps({ index })} />}
      </Box>
    );
  };

  const renderOption = (props, option) => (
    <Box
      data-testid={`food-${option.id}`}
      component="li"
      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
      {...props}
    >
      {option.name}
      {option.is_recipe && <RecipeChip />}
    </Box>
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      freeSolo
      autoHighlight
      blurOnSelect
      autoComplete
      open={isOpen}
      onOpen={openSelect}
      onClose={closeSelect}
      options={options}
      value={food}
      onChange={handlerFood}
      isOptionEqualToValue={(option) => {
        return food.length && food[0].id === option.id;
      }}
      getOptionLabel={(option) => `${option.name}_${option.id}`}
      renderInput={renderInput}
      renderTags={renderTags}
      renderOption={renderOption}
      data-testid="labelling-foods"
    />
  );
}

SelectFood.propTypes = {
  food: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_recipe: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setFood: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default SelectFood;
