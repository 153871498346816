import React from "react";
import { PropTypes } from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAppSelector from "../../hooks/useAppSelector";
import {selectUser} from "../../redux/user";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const userState = useAppSelector(selectUser)
  const location = useLocation();

  if (isInitialized && !isAuthenticated) {
    if (!location.pathname.includes("auth/")) {
      return <Navigate to="/auth/sign-in" />;
    }
  }

  if (
    isAuthenticated &&
    user &&
    user.id &&
    user.role.toLowerCase() === "user" &&
    !user.site.length &&
    !user.parent_group
  ) {
    return <Navigate to="auth/sign-in" />;
  }

  if(isAuthenticated && !userState?.is_onboarded) {
    return <Navigate to="onboarding" />
  }

  if (location.pathname === "/") {
    return <Navigate to="overview" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
