import React from 'react';
import DataCard from "./Container";
import CommonForm from "./CommonForm";
import {useConnector} from "./DataConnectorProvider";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {selectProfile} from "../../redux/profile";
import {baseAxios, errorMessage} from "../../utils/axios";
import {KEY_TYPE} from "../../constants/dataConnector";
import {getErrorMessage} from "source-map-explorer/lib/app-error";
import {localDateForUsers} from "../../utils/date/localDate";

const ExportLink = () => {
  const { tokens, updateCache } = useConnector()
  const { enqueueSnackbar } = useSnackbar()
  const { data } = useSelector(selectProfile)
  const generateExportLink = () => baseAxios.post('/api/tokens/create_bulk_export_key/')
    .then((res) => {
      enqueueSnackbar('Done', {variant: 'success'})
      updateCache({...tokens, bulk_export_link: res.data})
    })
    .catch((error) => enqueueSnackbar(errorMessage(error), { variant: 'error' }))


  const toggleActive = () => baseAxios.patch(`/api/tokens/${data.id}/`, {
    key_type: KEY_TYPE.BulkExportKey,
    is_active: !tokens?.bulk_export_link?.is_active,
  })
    .then(res => updateCache({...tokens, bulk_export_link: res.data}))
    .catch((error) => enqueueSnackbar(errorMessage(error), { variant: 'error' }))

  const expiredDate = tokens?.bulk_export_link?.expired_at && `Expires at ${localDateForUsers(tokens?.bulk_export_link?.expired_at)}`
  return (
    <DataCard title="Bulk Export Link">
      <CommonForm
        submitText="Generate New Link"
        onSubmit={generateExportLink}
        onSwitch={toggleActive}
        isActive={tokens?.bulk_export_link?.is_active}
        copyText="Copy Link"
        inputProps={{ value: tokens?.bulk_export_link?.link}}
        helperText={expiredDate}
        description="Generate a bulk export link to connect to your data management systems"
      />
    </DataCard>
  );
};

export default ExportLink;
