import React from 'react';
import {DialogContent, Divider, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import { ReactComponent as InfoIcon} from '../assets/icons/info.svg'

const SPACING = 4

const Footer = ({ children }) => (
  <Stack spacing={SPACING} mt={SPACING}>
    <Divider />
    {children}
  </Stack>
)

const BaseModal = ({ title, tooltip, children}) => {
  return (
    <DialogContent>
      <Stack spacing={SPACING}>
        <Stack direction="row" spacing={5} alignItems="center">
          <Typography variant="medium">{title}</Typography>
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <IconButton>
                <InfoIcon width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Divider />
        <Stack spacing={SPACING}>
          {children}
        </Stack>
      </Stack>
    </DialogContent>
  );
};

BaseModal.Footer = Footer

export default BaseModal;
