import React from "react";
import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";

const BlueButton = ({ name, onClick, loading, fullWidth, color, variant, sx }) => {
  return (
    <LoadingButton
      fullWidth={fullWidth}
      color={color}
      variant={variant}
      onClick={onClick}
      loading={loading}
      sx={{
        padding: "0 2rem",
        height: 42,
        ...sx,
      }}
    >
      {name}
    </LoadingButton>
  );
};

BlueButton.defaultProps = {
  loading: false,
  fullWidth: false,
  variant: "contained",
  color: "primary",
  sx: {},
};

BlueButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
};

export default BlueButton;
