import React from "react";
import PropTypes from "prop-types";

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { chartColors } from "../configs/constants";
import {
  tooltipNameFormatter,
  valueFormatter,
} from "../../overview/utils/formaters";
import { getDiffDays } from "../../../utils/data/prevDate";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";

const renderLegend = (props) => {
  return (
    <ul style={{ ...props, listStyleType: "none", paddingLeft: 0 }}>
      {props.payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ ...entry }}>
          {entry.value}
          <h3 style={{ marginTop: 0 }}>
            {entry.payload.payload.payload?.percentage || 0}%
          </h3>
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip = ({ payload, days, unit }) => {
  if (!payload.length) {
    return null
  }
  const label = payload[0]?.name
  const value = payload[0]?.value
  return (
    <Paper sx={{ padding: '20px' }} key={label}>
      <Typography>
        {tooltipNameFormatter({ name: label, days })}: {valueFormatter({ value: Number(value).toFixed(2), unit})}
      </Typography>
    </Paper>
  )
}

const InsightPieChart = ({ data, width, height, unit }) => {
  const days = data.length
    ? getDiffDays(data[data.length - 1].name, data[0].name)
    : null;

  return (
    <ResponsiveContainer>
      <PieChart
        margin={{ top: 12, right: 12, left: 12, bottom: 12 }}
        width={width}
        height={height}
        data={data}
      >
        <Legend
          align="left"
          verticalAlign="middle"
          layout="vertical"
          content={renderLegend}
        />
        <Tooltip
          content={<CustomTooltip days={days} unit={unit} />}
        />
        <Pie
          // label={<PieLabel />}
          labelLine={false}
          dataKey="value"
          nameKey="name"
          data={data}
          // innerRadius={"70%"}
          outerRadius={"100%"}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

InsightPieChart.defaultProps = {
  width: 250,
  height: 250,
  unit: "",
};

InsightPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  unit: PropTypes.string,
};

export default InsightPieChart;
