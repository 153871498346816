import React, { useEffect, useRef, useState } from "react";
import { CircularProgress as CircularProgressMui, Stack } from "@mui/material";
import styled from "@emotion/styled";

export const CircularProgressStyled = styled(CircularProgressMui)(({ theme }) => ({
  color: theme.palette.color.gray,
}));

function CircularProgress({ initSize }) {
  const ref = useRef(null);
  const [size, setSize] = useState(initSize || 40);

  useEffect(() => {
    if (ref.current && !initSize) {
      const wrapHeight = ref.current.getBoundingClientRect().height;
      const wrapWidth = ref.current.getBoundingClientRect().width;
      const smallerValue = wrapHeight <= wrapWidth ? wrapHeight : wrapWidth;
      setSize(smallerValue / 3);
    } else {
      setSize(initSize || 40);
    }
  }, [ref.current, initSize]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
      ref={ref}
    >
      <CircularProgressStyled size={size} />
    </Stack>
  );
}

export default CircularProgress;
