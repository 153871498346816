import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, DialogContent, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import TimeInput from "./TimeInput";
import updateSite from "./axios/updateSite";
import createUpdateSiteInfo from "./axios/createUpdateSiteInfo";
import setTimeOrDefault from "./utils/setTimeOrDefault";
import AutocompleteAddress from "./AutocompleteAddress";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import getSiteInfo from "./axios/getSiteInfo";
// import { LoadScript, useJsApiLoader } from "@react-google-maps/api";
// import { appConfig } from "../../config";

const boxStyles = { display: "flex", alignItems: "end" };
const paddingLeft = { paddingLeft: 5 };
const paddingRight = { paddingRight: 5 };

function UpdateSiteForm({ selectedSite, setSiteList, date, onCloce }) {
  const { enqueueSnackbar } = useSnackbar();

  const mixpanelTrack = useMixpanelTrack();

  const [siteInfo, setSiteInfo] = React.useState({});

  useEffect(
    async () => {
      try {
        const response = await getSiteInfo(selectedSite.id);
        setSiteInfo(response.data);
      } catch (error) {
        setSiteInfo({});
      }

      mixpanelTrack("Sites - Edit Site - Open Modal");
    },
    []
  );

  console.log(siteInfo, 'info')

  const initialValues = {
    siteName: selectedSite.name || "",
    contactName: selectedSite.contact_name || "",
    contactNumber: selectedSite.contact_number || "",
    address: siteInfo.address || "",
    breakfastTimeStart: setTimeOrDefault(
      siteInfo.breakfast_time_start || "08:00:00"
    ),
    breakfastTimeEndLunchTimeStart: setTimeOrDefault(
      siteInfo.breakfast_time_end || "12:00:00"
    ),
    lunchTimeEndDinnerTimeStart: setTimeOrDefault(
      siteInfo.lunch_time_end || "16:00:00"
    ),
    dinnerTimeEnd: setTimeOrDefault(siteInfo.dinner_time_end || "23:59:00"),
    coversPerDay: siteInfo.covers_per_day || 350,
    wastePerCover: siteInfo.waste_per_cover || 0.36,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: appConfig.mapsApiKey,
  //   libraries: ["places", "maps"],
  // });

  return (
    <DialogContent>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          siteName: Yup.string().max(255).required("Site name is required"),
          contactName: Yup.string()
            .max(255)
            .required("Contact name is required"),
          contactNumber: Yup.string().matches(
            phoneRegExp,
            "Phone number is not valid"
          ),
          address: Yup.string()
            .max(255)
            .required("Address is required")
            .typeError("This field must be filled"),
          breakfastTimeStart: Yup.string()
            .required("Time is required")
            .typeError("This field must be filled"),
          breakfastTimeEndLunchTimeStart: Yup.string()
            .required("Time is required")
            .typeError("This field must be filled"),
          lunchTimeEndDinnerTimeStart: Yup.string()
            .required("Time is required")
            .typeError("This field must be filled"),
          dinnerTimeEnd: Yup.string()
            .required("Time is required")
            .typeError("This field must be filled"),
          coversPerDay: Yup.number()
            .max(999999999)
            .required("Field is required"),
          wastePerCover: Yup.number()
            .max(999999999)
            .required("Field is required"),
        })}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            const siteInfoRes = await createUpdateSiteInfo({
              address: values.address,
              breakfastTimeStart: values.breakfastTimeStart,
              breakfastTimeEnd: values.breakfastTimeEndLunchTimeStart,
              lunchTimeStart: values.breakfastTimeEndLunchTimeStart,
              lunchTimeEnd: values.lunchTimeEndDinnerTimeStart,
              dinnerTimeStart: values.lunchTimeEndDinnerTimeStart,
              dinnerTimeEnd: values.dinnerTimeEnd,
              coversPerDay: values.coversPerDay,
              wastePerCover: values.wastePerCover,
              siteId: selectedSite.id,
              siteInfo,
            });
            const siteRes = await updateSite({
              siteId: selectedSite.id,
              siteName: values.siteName,
              contactName: values.contactName,
              contactNumber: values.contactNumber,
              date,
            });
            if (
              siteRes.status === 200 &&
              (siteInfoRes.status === 200 || siteInfoRes.status === 201)
            ) {
              setSiteList((prevState) =>
                prevState.map((el) =>
                  el.id === selectedSite.id ? siteRes.data : el
                )
              );
              enqueueSnackbar("Site was successfully edited.", {
                variant: "success",
              });
            }
            onCloce();
          } catch (error) {
            console.warn(error);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              <Box>
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  name="siteName"
                  label="Site Name"
                  value={values.siteName}
                  error={Boolean(touched.siteName && errors.siteName)}
                  helperText={touched.siteName && errors.siteName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  name="contactName"
                  label="Contact Name"
                  value={values.contactName}
                  error={Boolean(touched.contactName && errors.contactName)}
                  helperText={touched.contactName && errors.contactName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="standard"
                  type="tel"
                  name="contactNumber"
                  label="Contact Number"
                  value={values.contactNumber}
                  error={Boolean(touched.contactNumber && errors.contactNumber)}
                  helperText={touched.contactNumber && errors.contactNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                {/* <LoadScript googleMapsApiKey={appConfig.mapsApiKey} libraries={['places']}> */}
                <AutocompleteAddress
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  isLoaded={true}
                  // isLoaded={isLoaded}
                />
                {/* </LoadScript> */}
              </Box>
              <Box sx={boxStyles}>
                <TimeInput
                  setFieldValue={setFieldValue}
                  fieldValue={values.breakfastTimeStart}
                  styles={paddingRight}
                  name="breakfastTimeStart"
                  label="Breakfast Times"
                  handleBlur={handleBlur}
                  touchedField={touched.breakfastTimeStart}
                  errorsField={errors.breakfastTimeStart}
                />
                <TimeInput
                  setFieldValue={setFieldValue}
                  fieldValue={values.breakfastTimeEndLunchTimeStart}
                  styles={paddingLeft}
                  name="breakfastTimeEndLunchTimeStart"
                  handleBlur={handleBlur}
                  touchedField={touched.breakfastTimeEndLunchTimeStart}
                  errorsField={errors.breakfastTimeEndLunchTimeStart}
                />
              </Box>
              <Box sx={boxStyles}>
                <TimeInput
                  setFieldValue={setFieldValue}
                  fieldValue={values.breakfastTimeEndLunchTimeStart}
                  styles={paddingRight}
                  name="breakfastTimeEndLunchTimeStart"
                  label="Lunch Times"
                  handleBlur={handleBlur}
                  touchedField={touched.breakfastTimeEndLunchTimeStart}
                  errorsField={errors.breakfastTimeEndLunchTimeStart}
                />
                <TimeInput
                  setFieldValue={setFieldValue}
                  fieldValue={values.lunchTimeEndDinnerTimeStart}
                  styles={paddingLeft}
                  name="lunchTimeEndDinnerTimeStart"
                  handleBlur={handleBlur}
                  touchedField={touched.lunchTimeEndDinnerTimeStart}
                  errorsField={errors.lunchTimeEndDinnerTimeStart}
                />
              </Box>
              <Box sx={boxStyles}>
                <TimeInput
                  setFieldValue={setFieldValue}
                  fieldValue={values.lunchTimeEndDinnerTimeStart}
                  styles={paddingRight}
                  name="lunchTimeEndDinnerTimeStart"
                  label="Dinner Times"
                  handleBlur={handleBlur}
                  touchedField={touched.lunchTimeEndDinnerTimeStart}
                  errorsField={errors.lunchTimeEndDinnerTimeStart}
                />
                <TimeInput
                  setFieldValue={setFieldValue}
                  fieldValue={values.dinnerTimeEnd}
                  styles={paddingLeft}
                  name="dinnerTimeEnd"
                  handleBlur={handleBlur}
                  touchedField={touched.dinnerTimeEnd}
                  errorsField={errors.dinnerTimeEnd}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="standard"
                  type="number"
                  name="coversPerDay"
                  label="Covers Per Day (Default)"
                  value={values.coversPerDay}
                  error={Boolean(touched.coversPerDay && errors.coversPerDay)}
                  helperText={touched.coversPerDay && errors.coversPerDay}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="standard"
                  type="number"
                  step="0.1"
                  name="wastePerCover"
                  label="Waste Per Cover - Baseline (KG)"
                  value={values.wastePerCover}
                  error={Boolean(touched.wastePerCover && errors.wastePerCover)}
                  helperText={touched.wastePerCover && errors.wastePerCover}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Button
                sx={{ marginTop: "10px", height: "48px" }}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={() => mixpanelTrack("Sutes - Edit Site - Submit")}
              >
                Edit Site
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </DialogContent>
  );
}

UpdateSiteForm.propTypes = {
  selectedSite: PropTypes.object,
  setSiteList: PropTypes.func.isRequired,
  date: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};

UpdateSiteForm.defaultProps = {
  selectedSite: null,
};

export default UpdateSiteForm;
