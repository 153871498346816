import React, { useEffect, useState } from "react";
import { Badge, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { DayCalendarSkeleton } from "@mui/x-date-pickers";
import FormDialog from "../components/Dialogs/FormDialog";
import FormUpdateCovers from "./FormUpdateCovers";
import { baseAxios } from "../../utils/axios";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import getSiteInfo from "./axios/getSiteInfo";
import 'dayjs/locale/de'
import {useLang} from "../../hooks/useLang";

const CustomStaticDatePicker = styled(StaticDatePicker)(({ theme }) => ({
  width: "450px",
  height: "620px",
  "& .MuiPickersToolbar-root .MuiTypography-root": {
    color: "#fff",
    fontSize: "30px",
  },
  "& .MuiPickersToolbar-root .MuiTypography-overline": {
    fontSize: "12px",
  },
  "& .MuiPickersToolbar-root": {
    height: "100px",
    backgroundColor: theme.palette.color.blue,
    padding: "10px 22px",
  },
  "& .MuiDayCalendar-header .MuiTypography-root": {
    margin: "0 12px",
  },
  "& .MuiDateCalendar-root": {
    width: "100%",
    maxHeight: "100%",
  },
  "& .MuiPickersSlideTransition-root": {
    minHeight: "374px",
  },
}));

const CustomBadge = styled(Badge)(({ theme }) => ({
  width: "60px",
  height: "60px",
  "& .MuiBadge-badge, & .MuiButtonBase-root": {
    fontSize: "13px",
  },
  "& .MuiBadge-badge": {
    right: 30,
    top: 45,
    color: theme.palette.color.blue,
  },
  "& .MuiButtonBase-root": {
    width: "48px",
    height: "48px",
  },
  "& .MuiPickersDay-root": {
    margin: "6px",
  },
}));

const CustomDayCalendarSkeleton = styled(DayCalendarSkeleton)(() => ({
  marginTop: "10px",
  "& .MuiSkeleton-circular": {
    width: "48px !important",
    height: "48px !important",
    margin: "5px !important",
  },
}));

function ServerDay({
  dailyCovers,
  siteInfo,
  day,
  outsideCurrentMonth,
  handleUpdateDailyOpen,
  ...other
}) {
  const badgeContent = () => {
    if (!outsideCurrentMonth) {
      const checkValue =
        dailyCovers.length &&
        dailyCovers.find((el) => dayjs(el.day).unix() === dayjs(day).unix());
      if (dailyCovers.length && checkValue) {
        return checkValue.covers_per_day;
      }
      return Object.keys(siteInfo).length ? siteInfo.covers_per_day : 350;
    }
    return undefined;
  };

  return (
    <CustomBadge
      max={9999}
      key={day.toString()}
      overlap="circular"
      badgeContent={badgeContent()}
    >
      <PickersDay
        {...other}
        onClick={handleUpdateDailyOpen}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </CustomBadge>
  );
}

function DailyCovers({
  // dailyCovers,
  // setDailyCovers,
  // siteInfo,
  setSiteList,
  siteId,
  onClose
}) {
  const [value, setValue] = useState(dayjs());
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dailyCovers, setDailyCovers] = useState([]);
  const [siteInfo, setSiteInfo] = useState({});
  const { lang } = useLang()
  const mixpanelTrack = useMixpanelTrack();

  const fetchCovers = async (date) => {
    const newDate = new Date(date);
    const endMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
    const p = new URLSearchParams({
      startDate: dayjs(newDate).format("YYYY-MM-DD"),
      endDate: dayjs(endMonth).format("YYYY-MM-DD"),
    });
    const response = await baseAxios.get(`api/daily-covers/${siteId}/?${p}`);
    if (response.status === 200) {
      setDailyCovers(response.data);
    }
    setIsLoading(false);
  };

  const handleMonth = async (date) => {
    setIsLoading(true);
    setDailyCovers([]);
    await fetchCovers(date);
  };

  const handleCalendarClose = () => {
    onClose(false);
    mixpanelTrack("Sites - Calendar - Close Modal");
  };

  const handleUpdateDailyOpen = () => {
    setOpen(true);
    mixpanelTrack("Sites - Update Daily Covers - Open Modal");
  };

  const handleUpdateDailyClose = () => {
    setOpen(false);
    mixpanelTrack("Sites - Update Daily Covers - Close Modal");
  };

  const mixpanelSubmit = () =>
    mixpanelTrack("Sites - Update Daily Covers - Submit");

  useEffect(
    () => async () => {
      try {
        const response = await getSiteInfo(siteId);
        setSiteInfo(response.data);
      } catch (error) {
        setSiteInfo({});
      }

      mixpanelTrack("Sites - Edit Site - Open Modal");
    },
    []
  );

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
        <CustomStaticDatePicker
          value={value}
          orientation="portrait"
          onChange={(newValue) => setValue(newValue)}
          onAccept={handleCalendarClose}
          onClose={handleCalendarClose}
          onMonthChange={handleMonth}
          loading={isLoading}
          renderLoading={() => <CustomDayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            actionBar: {
              actions: ["accept"],
            },
            day: { dailyCovers, siteInfo, handleUpdateDailyOpen },
          }}
        />
      </LocalizationProvider>
      <FormDialog
        dialogTitle="Update Daily Covers"
        openForm={open}
        handleCloseModel={handleUpdateDailyClose}
        form={
          <FormUpdateCovers
            dailyCovers={dailyCovers}
            setDailyCovers={setDailyCovers}
            siteInfo={siteInfo}
            setSiteList={setSiteList}
            day={value}
            siteId={siteId}
            handleClose={handleUpdateDailyClose}
            mixpanelSubmit={mixpanelSubmit}
          />
        }
      />
    </Box>
  );
}

ServerDay.propTypes = {
  dailyCovers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      day: PropTypes.string,
      covers_per_day: PropTypes.number,
    })
  ),
  siteInfo: PropTypes.shape({
    covers_per_day: PropTypes.number,
  }).isRequired,
  day: PropTypes.shape({}).isRequired,
  outsideCurrentMonth: PropTypes.bool.isRequired,
  handleUpdateDailyOpen: PropTypes.func.isRequired,
};

ServerDay.defaultProps = {
  dailyCovers: [],
};

DailyCovers.propTypes = {
  // dailyCovers: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.number.isRequired,
  //     day: PropTypes.string,
  //     covers_per_day: PropTypes.number,
  //   })
  // ),
  // setDailyCovers: PropTypes.func.isRequired,
  siteInfo: PropTypes.shape({
    covers_per_day: PropTypes.number,
  }).isRequired,
  setSiteList: PropTypes.func.isRequired,
  siteId: PropTypes.number,
};

DailyCovers.defaultProps = {
  dailyCovers: [],
  siteId: null,
};

export default DailyCovers;
