import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseAxios } from "../utils/axios";

export const fetchZones = createAsyncThunk(
  "zones/fetchZones",
  async (props) => {
    try {
      const { site } = props || {};
      const params = {};
      if (site && site.length) params.site = site.map((el) => el.id);
      const response = await baseAxios("api/devices/device_zones/", { params });
      return response.data;
    } catch (error) {
      return [];
    }
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  zones: [],
};

export const zonesSlice = createSlice({
  name: "zones",
  initialState,
  reducers: {
    addZone: (state, { payload }) => {
      state.zones = [payload, ...state.zones];
    },
    updateZones: (state, { payload }) => {
      state.zones = state.zones.map((zone) => {
        if (zone.id === payload.id) {
          return payload;
        }
        return zone;
      });
    },
  },
  extraReducers: {
    [fetchZones.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchZones.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.zones = [...action.payload];
    },
    [fetchZones.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

export const { addZone, updateZones } = zonesSlice.actions;

export const selectZones = (state) => state.zones;

export default zonesSlice.reducer;
