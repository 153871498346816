import React, {useState} from 'react';
import {Grid, Input, Stack, TextField} from "@mui/material";
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg'
import {Link, useLocation} from "react-router-dom";
import useSWR from "swr";
import {fetcher} from "../../utils/axios";
import PreviewWidget from "../../components/widgets/PreviewWidget";
import {Helmet} from "react-helmet-async";
import WidgetSkeleton from "../../components/widgets/WidgetSkeleton";
import debounce from 'lodash.debounce'
import makeStyles from "@mui/styles/makeStyles";


const widgetToDisplay = ({ name, image_url: imageUrl, identifier, description}) => ({
  name,
  imageUrl,
  identifier,
  description,
})

const useStyles = makeStyles(theme => ({
  input: {
    "&::placeholder": {
      textAlign: "center"
    }
  }
}));

const Widgets = () => {
  const [search, setSearch] = useState('')
  const { data = [], isLoading } = useSWR(['/api/focus-areas/', { name: search}], fetcher)
  const classes = useStyles();
  const { search: query } = useLocation()
  const set = debounce(setSearch, 1000)
  const handleSearch = event => {
    set(event.target.value)
  }
  return (
    <>
      <Helmet title="Widgets" />
      <Stack sx={{ paddingTop: '40px' }} gap={10}>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Link to={`/overview/${query}`}>
            <LeftArrowIcon width={25} height={25} />
          </Link>
          <TextField
            name="search" sx={{ flex: 1 }}
            InputLabelProps={{ shrink: true}}
            placeholder="Search Widgets"
            onChange={handleSearch}
            InputProps={{ classes: { input: classes.input } }}
          />
        </Stack>
        <Grid container spacing={8} alignItems="stretch">
          {isLoading && (
            <>
              <Grid item xs={12} md={6}>
                <WidgetSkeleton />
              </Grid>
              <Grid item xs={12} md={6}>
                <WidgetSkeleton />
              </Grid>
              <Grid item xs={12} md={6}>
                <WidgetSkeleton />
              </Grid>
              <Grid item xs={12} md={6}>
                <WidgetSkeleton />
              </Grid>
            </>
          )}
          {data.map(widget => (
            <Grid item xs={12} md={6} key={widget.id}>
              <PreviewWidget {...widgetToDisplay(widget)} />
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <PreviewWidget suggest />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default Widgets;
