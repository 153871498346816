"use strict";
function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = function(target, all) {
    for(var name in all)__defProp(target, name, {
        get: all[name],
        enumerable: true
    });
};
var __copyProps = function(to, from, except, desc) {
    if (from && typeof from === "object" || typeof from === "function") {
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            var _loop = function() {
                var key = _step.value;
                if (!__hasOwnProp.call(to, key) && key !== except) __defProp(to, key, {
                    get: function() {
                        return from[key];
                    },
                    enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable
                });
            };
            for(var _iterator = __getOwnPropNames(from)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true)_loop();
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    }
    return to;
};
var __toESM = function(mod, isNodeMode, target) {
    return target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(// If the importer is in node compatibility mode or this is not an ESM
    // file that has been converted to a CommonJS file using a Babel-
    // compatible transform (i.e. "__esModule" has not been set), then set
    // "default" to the CommonJS "module.exports" for node compatibility.
    isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", {
        value: mod,
        enumerable: true
    }) : target, mod);
};
var __toCommonJS = function(mod) {
    return __copyProps(__defProp({}, "__esModule", {
        value: true
    }), mod);
};
// src/index.ts
var src_exports = {};
__export(src_exports, {
    CountrySelector: function() {
        return CountrySelector;
    },
    CountrySelectorDropdown: function() {
        return CountrySelectorDropdown;
    },
    DialCodePreview: function() {
        return DialCodePreview;
    },
    FlagImage: function() {
        return FlagImage;
    },
    PhoneInput: function() {
        return PhoneInput;
    },
    buildCountryData: function() {
        return buildCountryData;
    },
    defaultCountries: function() {
        return defaultCountries;
    },
    getActiveFormattingMask: function() {
        return getActiveFormattingMask;
    },
    getCountry: function() {
        return getCountry;
    },
    guessCountryByPartialNumber: function() {
        return guessCountryByPartialNumber;
    },
    parseCountry: function() {
        return parseCountry;
    },
    removeDialCode: function() {
        return removeDialCode;
    },
    usePhoneInput: function() {
        return usePhoneInput;
    }
});
module.exports = __toCommonJS(src_exports);
// src/components/CountrySelector/CountrySelector.tsx
var import_react6 = __toESM(require("react"));
// src/data/countryData.ts
var defaultCountries = [
    [
        "Afghanistan",
        "af",
        "93"
    ],
    [
        "Albania",
        "al",
        "355"
    ],
    [
        "Algeria",
        "dz",
        "213"
    ],
    [
        "Andorra",
        "ad",
        "376"
    ],
    [
        "Angola",
        "ao",
        "244"
    ],
    [
        "Antigua and Barbuda",
        "ag",
        "1268"
    ],
    [
        "Argentina",
        "ar",
        "54",
        "(..) ........",
        0
    ],
    [
        "Armenia",
        "am",
        "374",
        ".. ......"
    ],
    [
        "Aruba",
        "aw",
        "297"
    ],
    [
        "Australia",
        "au",
        "61",
        {
            default: ". .... ....",
            "/^4/": "... ... ...",
            "/^5(?!50)/": "... ... ...",
            "/^1(3|8)00/": ".... ... ...",
            "/^13/": ".. .. ..",
            "/^180/": "... ...."
        },
        0,
        []
    ],
    [
        "Austria",
        "at",
        "43"
    ],
    [
        "Azerbaijan",
        "az",
        "994",
        "(..) ... .. .."
    ],
    [
        "Bahamas",
        "bs",
        "1242"
    ],
    [
        "Bahrain",
        "bh",
        "973"
    ],
    [
        "Bangladesh",
        "bd",
        "880"
    ],
    [
        "Barbados",
        "bb",
        "1246"
    ],
    [
        "Belarus",
        "by",
        "375",
        "(..) ... .. .."
    ],
    [
        "Belgium",
        "be",
        "32",
        "... .. .. .."
    ],
    [
        "Belize",
        "bz",
        "501"
    ],
    [
        "Benin",
        "bj",
        "229"
    ],
    [
        "Bhutan",
        "bt",
        "975"
    ],
    [
        "Bolivia",
        "bo",
        "591"
    ],
    [
        "Bosnia and Herzegovina",
        "ba",
        "387"
    ],
    [
        "Botswana",
        "bw",
        "267"
    ],
    [
        "Brazil",
        "br",
        "55",
        "(..) ........."
    ],
    [
        "British Indian Ocean Territory",
        "io",
        "246"
    ],
    [
        "Brunei",
        "bn",
        "673"
    ],
    [
        "Bulgaria",
        "bg",
        "359"
    ],
    [
        "Burkina Faso",
        "bf",
        "226"
    ],
    [
        "Burundi",
        "bi",
        "257"
    ],
    [
        "Cambodia",
        "kh",
        "855"
    ],
    [
        "Cameroon",
        "cm",
        "237"
    ],
    [
        "Canada",
        "ca",
        "1",
        "(...) ...-....",
        1,
        [
            "204",
            "226",
            "236",
            "249",
            "250",
            "289",
            "306",
            "343",
            "365",
            "387",
            "403",
            "416",
            "418",
            "431",
            "437",
            "438",
            "450",
            "506",
            "514",
            "519",
            "548",
            "579",
            "581",
            "587",
            "604",
            "613",
            "639",
            "647",
            "672",
            "705",
            "709",
            "742",
            "778",
            "780",
            "782",
            "807",
            "819",
            "825",
            "867",
            "873",
            "902",
            "905"
        ]
    ],
    [
        "Cape Verde",
        "cv",
        "238"
    ],
    [
        "Caribbean Netherlands",
        "bq",
        "599",
        "",
        1
    ],
    [
        "Central African Republic",
        "cf",
        "236"
    ],
    [
        "Chad",
        "td",
        "235"
    ],
    [
        "Chile",
        "cl",
        "56"
    ],
    [
        "China",
        "cn",
        "86",
        "... .... ...."
    ],
    [
        "Colombia",
        "co",
        "57",
        "... ... ...."
    ],
    [
        "Comoros",
        "km",
        "269"
    ],
    [
        "Congo",
        "cd",
        "243"
    ],
    [
        "Congo",
        "cg",
        "242"
    ],
    [
        "Costa Rica",
        "cr",
        "506",
        "....-...."
    ],
    [
        "C\xf4te d'Ivoire",
        "ci",
        "225",
        ".. .. .. .. .."
    ],
    [
        "Croatia",
        "hr",
        "385"
    ],
    [
        "Cuba",
        "cu",
        "53"
    ],
    [
        "Cura\xe7ao",
        "cw",
        "599",
        "",
        0
    ],
    [
        "Cyprus",
        "cy",
        "357",
        ".. ......"
    ],
    [
        "Czech Republic",
        "cz",
        "420",
        "... ... ..."
    ],
    [
        "Denmark",
        "dk",
        "45",
        ".. .. .. .."
    ],
    [
        "Djibouti",
        "dj",
        "253"
    ],
    [
        "Dominica",
        "dm",
        "1767"
    ],
    [
        "Dominican Republic",
        "do",
        "1",
        "",
        2
    ],
    [
        "Ecuador",
        "ec",
        "593"
    ],
    [
        "Egypt",
        "eg",
        "20"
    ],
    [
        "El Salvador",
        "sv",
        "503",
        "....-...."
    ],
    [
        "Equatorial Guinea",
        "gq",
        "240"
    ],
    [
        "Eritrea",
        "er",
        "291"
    ],
    [
        "Estonia",
        "ee",
        "372",
        ".... ......"
    ],
    [
        "Ethiopia",
        "et",
        "251"
    ],
    [
        "Fiji",
        "fj",
        "679"
    ],
    [
        "Finland",
        "fi",
        "358",
        ".. ... .. .."
    ],
    [
        "France",
        "fr",
        "33",
        ". .. .. .. .."
    ],
    [
        "French Guiana",
        "gf",
        "594"
    ],
    [
        "French Polynesia",
        "pf",
        "689"
    ],
    [
        "Gabon",
        "ga",
        "241"
    ],
    [
        "Gambia",
        "gm",
        "220"
    ],
    [
        "Georgia",
        "ge",
        "995"
    ],
    [
        "Germany",
        "de",
        "49",
        ".... ........"
    ],
    [
        "Ghana",
        "gh",
        "233"
    ],
    [
        "Greece",
        "gr",
        "30"
    ],
    [
        "Grenada",
        "gd",
        "1473"
    ],
    [
        "Guadeloupe",
        "gp",
        "590",
        "",
        0
    ],
    [
        "Guam",
        "gu",
        "1671"
    ],
    [
        "Guatemala",
        "gt",
        "502",
        "....-...."
    ],
    [
        "Guinea",
        "gn",
        "224"
    ],
    [
        "Guinea-Bissau",
        "gw",
        "245"
    ],
    [
        "Guyana",
        "gy",
        "592"
    ],
    [
        "Haiti",
        "ht",
        "509",
        "....-...."
    ],
    [
        "Honduras",
        "hn",
        "504"
    ],
    [
        "Hong Kong",
        "hk",
        "852",
        ".... ...."
    ],
    [
        "Hungary",
        "hu",
        "36"
    ],
    [
        "Iceland",
        "is",
        "354",
        "... ...."
    ],
    [
        "India",
        "in",
        "91",
        ".....-....."
    ],
    [
        "Indonesia",
        "id",
        "62"
    ],
    [
        "Iran",
        "ir",
        "98",
        "... ... ...."
    ],
    [
        "Iraq",
        "iq",
        "964"
    ],
    [
        "Ireland",
        "ie",
        "353",
        ".. ......."
    ],
    [
        "Israel",
        "il",
        "972",
        "... ... ...."
    ],
    [
        "Italy",
        "it",
        "39",
        "... .......",
        0
    ],
    [
        "Jamaica",
        "jm",
        "1876"
    ],
    [
        "Japan",
        "jp",
        "81",
        ".. .... ...."
    ],
    [
        "Jordan",
        "jo",
        "962"
    ],
    [
        "Kazakhstan",
        "kz",
        "7",
        "... ...-..-..",
        0
    ],
    [
        "Kenya",
        "ke",
        "254"
    ],
    [
        "Kiribati",
        "ki",
        "686"
    ],
    [
        "Kosovo",
        "xk",
        "383"
    ],
    [
        "Kuwait",
        "kw",
        "965"
    ],
    [
        "Kyrgyzstan",
        "kg",
        "996",
        "... ... ..."
    ],
    [
        "Laos",
        "la",
        "856"
    ],
    [
        "Latvia",
        "lv",
        "371",
        ".. ... ..."
    ],
    [
        "Lebanon",
        "lb",
        "961"
    ],
    [
        "Lesotho",
        "ls",
        "266"
    ],
    [
        "Liberia",
        "lr",
        "231"
    ],
    [
        "Libya",
        "ly",
        "218"
    ],
    [
        "Liechtenstein",
        "li",
        "423"
    ],
    [
        "Lithuania",
        "lt",
        "370"
    ],
    [
        "Luxembourg",
        "lu",
        "352"
    ],
    [
        "Macau",
        "mo",
        "853"
    ],
    [
        "Macedonia",
        "mk",
        "389"
    ],
    [
        "Madagascar",
        "mg",
        "261"
    ],
    [
        "Malawi",
        "mw",
        "265"
    ],
    [
        "Malaysia",
        "my",
        "60",
        "..-....-...."
    ],
    [
        "Maldives",
        "mv",
        "960"
    ],
    [
        "Mali",
        "ml",
        "223"
    ],
    [
        "Malta",
        "mt",
        "356"
    ],
    [
        "Marshall Islands",
        "mh",
        "692"
    ],
    [
        "Martinique",
        "mq",
        "596"
    ],
    [
        "Mauritania",
        "mr",
        "222"
    ],
    [
        "Mauritius",
        "mu",
        "230"
    ],
    [
        "Mexico",
        "mx",
        "52",
        "... ... ....",
        0
    ],
    [
        "Micronesia",
        "fm",
        "691"
    ],
    [
        "Moldova",
        "md",
        "373",
        "(..) ..-..-.."
    ],
    [
        "Monaco",
        "mc",
        "377"
    ],
    [
        "Mongolia",
        "mn",
        "976"
    ],
    [
        "Montenegro",
        "me",
        "382"
    ],
    [
        "Morocco",
        "ma",
        "212"
    ],
    [
        "Mozambique",
        "mz",
        "258"
    ],
    [
        "Myanmar",
        "mm",
        "95"
    ],
    [
        "Namibia",
        "na",
        "264"
    ],
    [
        "Nauru",
        "nr",
        "674"
    ],
    [
        "Nepal",
        "np",
        "977"
    ],
    [
        "Netherlands",
        "nl",
        "31",
        ".. ........"
    ],
    [
        "New Caledonia",
        "nc",
        "687"
    ],
    [
        "New Zealand",
        "nz",
        "64",
        "...-...-...."
    ],
    [
        "Nicaragua",
        "ni",
        "505"
    ],
    [
        "Niger",
        "ne",
        "227"
    ],
    [
        "Nigeria",
        "ng",
        "234"
    ],
    [
        "North Korea",
        "kp",
        "850"
    ],
    [
        "Norway",
        "no",
        "47",
        "... .. ..."
    ],
    [
        "Oman",
        "om",
        "968"
    ],
    [
        "Pakistan",
        "pk",
        "92",
        "...-......."
    ],
    [
        "Palau",
        "pw",
        "680"
    ],
    [
        "Palestine",
        "ps",
        "970"
    ],
    [
        "Panama",
        "pa",
        "507"
    ],
    [
        "Papua New Guinea",
        "pg",
        "675"
    ],
    [
        "Paraguay",
        "py",
        "595"
    ],
    [
        "Peru",
        "pe",
        "51"
    ],
    [
        "Philippines",
        "ph",
        "63",
        ".... ......."
    ],
    [
        "Poland",
        "pl",
        "48",
        "...-...-..."
    ],
    [
        "Portugal",
        "pt",
        "351"
    ],
    [
        "Puerto Rico",
        "pr",
        "1",
        "",
        3,
        [
            "787",
            "939"
        ]
    ],
    [
        "Qatar",
        "qa",
        "974"
    ],
    [
        "R\xe9union",
        "re",
        "262"
    ],
    [
        "Romania",
        "ro",
        "40"
    ],
    [
        "Russia",
        "ru",
        "7",
        "(...) ...-..-..",
        1
    ],
    [
        "Rwanda",
        "rw",
        "250"
    ],
    [
        "Saint Kitts and Nevis",
        "kn",
        "1869"
    ],
    [
        "Saint Lucia",
        "lc",
        "1758"
    ],
    [
        "Saint Vincent and the Grenadines",
        "vc",
        "1784"
    ],
    [
        "Samoa",
        "ws",
        "685"
    ],
    [
        "San Marino",
        "sm",
        "378"
    ],
    [
        "S\xe3o Tom\xe9 and Pr\xedncipe",
        "st",
        "239"
    ],
    [
        "Saudi Arabia",
        "sa",
        "966"
    ],
    [
        "Senegal",
        "sn",
        "221"
    ],
    [
        "Serbia",
        "rs",
        "381"
    ],
    [
        "Seychelles",
        "sc",
        "248"
    ],
    [
        "Sierra Leone",
        "sl",
        "232"
    ],
    [
        "Singapore",
        "sg",
        "65",
        "....-...."
    ],
    [
        "Slovakia",
        "sk",
        "421"
    ],
    [
        "Slovenia",
        "si",
        "386"
    ],
    [
        "Solomon Islands",
        "sb",
        "677"
    ],
    [
        "Somalia",
        "so",
        "252"
    ],
    [
        "South Africa",
        "za",
        "27"
    ],
    [
        "South Korea",
        "kr",
        "82",
        "... .... ...."
    ],
    [
        "South Sudan",
        "ss",
        "211"
    ],
    [
        "Spain",
        "es",
        "34",
        "... ... ..."
    ],
    [
        "Sri Lanka",
        "lk",
        "94"
    ],
    [
        "Sudan",
        "sd",
        "249"
    ],
    [
        "Suriname",
        "sr",
        "597"
    ],
    [
        "Swaziland",
        "sz",
        "268"
    ],
    [
        "Sweden",
        "se",
        "46",
        "... ... ..."
    ],
    [
        "Switzerland",
        "ch",
        "41",
        ".. ... .. .."
    ],
    [
        "Syria",
        "sy",
        "963"
    ],
    [
        "Taiwan",
        "tw",
        "886"
    ],
    [
        "Tajikistan",
        "tj",
        "992"
    ],
    [
        "Tanzania",
        "tz",
        "255"
    ],
    [
        "Thailand",
        "th",
        "66"
    ],
    [
        "Timor-Leste",
        "tl",
        "670"
    ],
    [
        "Togo",
        "tg",
        "228"
    ],
    [
        "Tonga",
        "to",
        "676"
    ],
    [
        "Trinidad and Tobago",
        "tt",
        "1868"
    ],
    [
        "Tunisia",
        "tn",
        "216"
    ],
    [
        "Turkey",
        "tr",
        "90",
        "... ... .. .."
    ],
    [
        "Turkmenistan",
        "tm",
        "993"
    ],
    [
        "Tuvalu",
        "tv",
        "688"
    ],
    [
        "Uganda",
        "ug",
        "256"
    ],
    [
        "Ukraine",
        "ua",
        "380",
        "(..) ... .. .."
    ],
    [
        "United Arab Emirates",
        "ae",
        "971"
    ],
    [
        "United Kingdom",
        "gb",
        "44",
        ".... ......"
    ],
    [
        "United States",
        "us",
        "1",
        "(...) ...-....",
        0
    ],
    [
        "Uruguay",
        "uy",
        "598"
    ],
    [
        "Uzbekistan",
        "uz",
        "998",
        ".. ... .. .."
    ],
    [
        "Vanuatu",
        "vu",
        "678"
    ],
    [
        "Vatican City",
        "va",
        "39",
        ".. .... ....",
        1
    ],
    [
        "Venezuela",
        "ve",
        "58"
    ],
    [
        "Vietnam",
        "vn",
        "84"
    ],
    [
        "Yemen",
        "ye",
        "967"
    ],
    [
        "Zambia",
        "zm",
        "260"
    ],
    [
        "Zimbabwe",
        "zw",
        "263"
    ]
];
// src/style/buildClassNames.ts
var classPrefix = "react-international-phone-";
var joinClasses = function() {
    for(var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++){
        classes[_key] = arguments[_key];
    }
    return classes.filter(function(v) {
        return !!v;
    }).join(" ").trim();
};
var classNamesWithPrefix = function() {
    for(var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++){
        classes[_key] = arguments[_key];
    }
    return joinClasses.apply(void 0, _to_consumable_array(classes)).split(" ").map(function(className) {
        return "".concat(classPrefix).concat(className);
    }).join(" ");
};
var buildClassNames = function(param) {
    var addPrefix = param.addPrefix, rawClassNames = param.rawClassNames;
    return joinClasses.apply(void 0, [
        classNamesWithPrefix.apply(void 0, _to_consumable_array(addPrefix))
    ].concat(_to_consumable_array(rawClassNames)));
};
// src/utils/common/applyMask.ts
var applyMask = function(param) {
    var value = param.value, mask = param.mask, maskSymbol = param.maskSymbol, _param_offset = param.offset, offset = _param_offset === void 0 ? 0 : _param_offset, _param_trimNonMaskCharsLeftover = param.trimNonMaskCharsLeftover, trimNonMaskCharsLeftover = _param_trimNonMaskCharsLeftover === void 0 ? false : _param_trimNonMaskCharsLeftover;
    if (value.length < offset) return value;
    var savedValuePart = value.slice(0, offset);
    var valueToMask = value.slice(offset);
    var result = savedValuePart;
    var charsPlaced = 0;
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = mask.split("")[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var maskChar = _step.value;
            if (charsPlaced >= valueToMask.length) {
                if (!trimNonMaskCharsLeftover && maskChar !== maskSymbol) {
                    result += maskChar;
                    continue;
                }
                break;
            }
            if (maskChar === maskSymbol) {
                result += valueToMask[charsPlaced];
                charsPlaced += 1;
            } else {
                result += maskChar;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return result;
};
// src/utils/common/isNumeric.ts
var isNumeric = function(str) {
    if (!str) return false;
    return /^\d+$/.test(str);
};
// src/utils/common/removeNonDigits.ts
var removeNonDigits = function(value) {
    return value.replace(/\D/g, "");
};
// src/utils/common/scrollToChild.ts
var scrollToChild = function(parent, child) {
    var initialDisplayValue = parent.style.display;
    if (initialDisplayValue !== "block") {
        parent.style.display = "block";
    }
    var parentPosition = parent.getBoundingClientRect();
    var childPosition = child.getBoundingClientRect();
    var topOffset = childPosition.top - parentPosition.top;
    var bottomOffset = parentPosition.bottom - childPosition.bottom;
    var isChildVisible = topOffset >= 0 && bottomOffset >= 0;
    if (!isChildVisible) {
        if (Math.abs(topOffset) < Math.abs(bottomOffset)) {
            parent.scrollTop += topOffset;
        } else {
            parent.scrollTop -= bottomOffset;
        }
    }
    parent.style.display = initialDisplayValue;
};
// src/utils/countryUtils/buildCountryData.ts
var buildCountryData = function(parsedCountry) {
    var name = parsedCountry.name, iso2 = parsedCountry.iso2, dialCode = parsedCountry.dialCode, format = parsedCountry.format, priority = parsedCountry.priority, areaCodes = parsedCountry.areaCodes;
    var countryData = [
        name,
        iso2,
        dialCode,
        format,
        priority,
        areaCodes
    ];
    for(var i = 0; i < countryData.length; i += 1){
        if (i === 0) continue;
        var prevValue = countryData[i - 1];
        var currentValue = countryData[i];
        if (prevValue === void 0 && currentValue !== void 0) {
            var stringifiedCountry = JSON.stringify(countryData, function(_k, v) {
                return v === void 0 ? "__undefined" : v;
            }).replace(/"__undefined"/g, "undefined");
            throw new Error("[react-international-phone] invalid country values passed to buildCountryData. Check ".concat(prevValue, " in: ").concat(stringifiedCountry));
        }
    }
    return countryData.filter(function(v) {
        return v !== void 0;
    });
};
// src/hooks/usePhoneInput.ts
var import_react3 = require("react");
// src/utils/phoneUtils/removeDialCode.ts
var removeDialCode = function(param) {
    var phone = param.phone, dialCode = param.dialCode, _param_prefix = param.prefix, prefix = _param_prefix === void 0 ? "+" : _param_prefix, _param_charAfterDialCode = param.charAfterDialCode, charAfterDialCode = _param_charAfterDialCode === void 0 ? " " : _param_charAfterDialCode;
    if (!phone || !dialCode) {
        return phone;
    }
    var result = phone;
    if (result.startsWith(prefix)) {
        result = result.replace(prefix, "");
    }
    if (!result.startsWith(dialCode)) {
        return phone;
    }
    result = result.replace(dialCode, "");
    if (result.startsWith(charAfterDialCode)) {
        result = result.replace(charAfterDialCode, "");
    }
    return result;
};
// src/utils/phoneUtils/formatPhone.ts
var formatPhone = function(phone, config) {
    var shouldForceDialCode = config.disableDialCodeAndPrefix ? false : config.forceDialCode;
    var shouldInsertDialCodeOnEmpty = config.disableDialCodeAndPrefix ? false : config.insertDialCodeOnEmpty;
    var phoneValue = phone;
    var handleResult = function(result) {
        if (config.trimNonDigitsEnd) {
            return result.trim();
        }
        return result;
    };
    if (!phoneValue) {
        if (shouldInsertDialCodeOnEmpty && !phoneValue.length || shouldForceDialCode) {
            return handleResult("".concat(config.prefix).concat(config.dialCode).concat(config.charAfterDialCode));
        }
        return handleResult(phoneValue);
    }
    phoneValue = removeNonDigits(phoneValue);
    if (phoneValue === config.dialCode && !config.disableDialCodeAndPrefix) {
        return handleResult("".concat(config.prefix).concat(config.dialCode).concat(config.charAfterDialCode));
    }
    if (config.dialCode.startsWith(phoneValue) && !config.disableDialCodeAndPrefix) {
        if (shouldForceDialCode) {
            return handleResult("".concat(config.prefix).concat(config.dialCode).concat(config.charAfterDialCode));
        }
        return handleResult("".concat(config.prefix).concat(phoneValue));
    }
    if (!phoneValue.startsWith(config.dialCode) && !config.disableDialCodeAndPrefix) {
        if (shouldForceDialCode) {
            return handleResult("".concat(config.prefix).concat(config.dialCode).concat(config.charAfterDialCode));
        }
        if (phoneValue.length < config.dialCode.length) {
            return handleResult("".concat(config.prefix).concat(phoneValue));
        }
    }
    var slicePhone = function() {
        var mainPartStartIndex = config.dialCode.length;
        var phoneLeftSide2 = phoneValue.slice(0, mainPartStartIndex);
        var phoneRightSide2 = phoneValue.slice(mainPartStartIndex);
        return {
            phoneLeftSide: phoneLeftSide2,
            phoneRightSide: phoneRightSide2
        };
    };
    var _slicePhone = slicePhone(), phoneLeftSide = _slicePhone.phoneLeftSide, phoneRightSide = _slicePhone.phoneRightSide;
    phoneLeftSide = "".concat(config.prefix).concat(phoneLeftSide).concat(config.charAfterDialCode);
    phoneRightSide = applyMask({
        value: phoneRightSide,
        mask: config.mask,
        maskSymbol: config.maskChar,
        trimNonMaskCharsLeftover: config.trimNonDigitsEnd || config.disableDialCodeAndPrefix && phoneRightSide.length === 0
    });
    if (config.disableDialCodeAndPrefix) {
        phoneLeftSide = "";
    }
    return handleResult("".concat(phoneLeftSide).concat(phoneRightSide));
};
// src/utils/phoneUtils/getCursorPosition.ts
var getCursorPosition = function(param) {
    var phoneBeforeInput = param.phoneBeforeInput, phoneAfterInput = param.phoneAfterInput, phoneAfterFormatted = param.phoneAfterFormatted, cursorPositionAfterInput = param.cursorPositionAfterInput, _param_leftOffset = param.leftOffset, leftOffset = _param_leftOffset === void 0 ? 0 : _param_leftOffset, deletion = param.deletion;
    if (cursorPositionAfterInput < leftOffset) {
        return leftOffset;
    }
    if (!phoneBeforeInput) {
        return phoneAfterFormatted.length;
    }
    var afterInputPointIndex = null;
    for(var index = cursorPositionAfterInput - 1; index >= 0; index -= 1){
        if (isNumeric(phoneAfterInput[index])) {
            afterInputPointIndex = index;
            break;
        }
    }
    if (afterInputPointIndex === null) {
        for(var index1 = 0; index1 < phoneAfterInput.length; index1 += 1){
            if (isNumeric(phoneAfterFormatted[index1])) {
                return index1;
            }
        }
        return phoneAfterInput.length;
    }
    var digitIndex = 0;
    for(var index2 = 0; index2 < afterInputPointIndex; index2 += 1){
        if (isNumeric(phoneAfterInput[index2])) {
            digitIndex += 1;
        }
    }
    var cursorPosition = 0;
    var digitsCounter = 0;
    for(var index3 = 0; index3 < phoneAfterFormatted.length; index3 += 1){
        cursorPosition += 1;
        if (isNumeric(phoneAfterFormatted[index3])) {
            digitsCounter += 1;
        }
        if (digitsCounter >= digitIndex + 1) {
            break;
        }
    }
    if (deletion !== "backward") {
        while(!isNumeric(phoneAfterFormatted[cursorPosition]) && cursorPosition < phoneAfterFormatted.length){
            cursorPosition += 1;
        }
    }
    return cursorPosition;
};
// src/utils/phoneUtils/toE164.ts
var toE164 = function(param) {
    var phone = param.phone, prefix = param.prefix;
    return phone ? "".concat(prefix).concat(removeNonDigits(phone)) : "";
};
// src/utils/handlePhoneChange.ts
function handlePhoneChange(param) {
    var value = param.value, country = param.country, insertDialCodeOnEmpty = param.insertDialCodeOnEmpty, trimNonDigitsEnd = param.trimNonDigitsEnd, countries = param.countries, prefix = param.prefix, charAfterDialCode = param.charAfterDialCode, forceDialCode = param.forceDialCode, disableDialCodeAndPrefix = param.disableDialCodeAndPrefix, defaultMask = param.defaultMask, countryGuessingEnabled = param.countryGuessingEnabled, disableFormatting = param.disableFormatting;
    var _country, _countryGuessResult, _countryGuessResult1;
    var inputPhone = value;
    if (disableDialCodeAndPrefix) {
        inputPhone = inputPhone.startsWith("".concat(prefix)) ? inputPhone : "".concat(prefix).concat(country.dialCode).concat(inputPhone);
    }
    var countryGuessResult = countryGuessingEnabled ? guessCountryByPartialNumber({
        phone: inputPhone,
        countries: countries,
        currentCountryIso2: (_country = country) === null || _country === void 0 ? void 0 : _country.iso2
    }) : void 0;
    var _countryGuessResult_country;
    var formatCountry = (_countryGuessResult_country = (_countryGuessResult = countryGuessResult) === null || _countryGuessResult === void 0 ? void 0 : _countryGuessResult.country) !== null && _countryGuessResult_country !== void 0 ? _countryGuessResult_country : country;
    var inputValue = formatPhone(inputPhone, {
        prefix: prefix,
        mask: getActiveFormattingMask({
            phone: inputPhone,
            country: formatCountry,
            defaultMask: defaultMask,
            disableFormatting: disableFormatting
        }),
        maskChar: MASK_CHAR,
        dialCode: formatCountry.dialCode,
        trimNonDigitsEnd: trimNonDigitsEnd,
        charAfterDialCode: charAfterDialCode,
        forceDialCode: forceDialCode,
        insertDialCodeOnEmpty: insertDialCodeOnEmpty,
        disableDialCodeAndPrefix: disableDialCodeAndPrefix
    });
    var resultCountry = countryGuessingEnabled && !((_countryGuessResult1 = countryGuessResult) === null || _countryGuessResult1 === void 0 ? void 0 : _countryGuessResult1.fullDialCodeMatch) ? country : formatCountry;
    var phone = toE164({
        phone: disableDialCodeAndPrefix ? "".concat(resultCountry.dialCode).concat(inputValue) : inputValue,
        prefix: prefix
    });
    return {
        phone: phone,
        inputValue: inputValue,
        country: resultCountry
    };
}
// src/utils/handleUserInput.ts
var getDeletionType = function(inputType) {
    var _inputType, _inputType1;
    var _inputType_toLocaleLowerCase_includes;
    var isDeletion = (_inputType_toLocaleLowerCase_includes = (_inputType = inputType) === null || _inputType === void 0 ? void 0 : _inputType.toLocaleLowerCase().includes("delete")) !== null && _inputType_toLocaleLowerCase_includes !== void 0 ? _inputType_toLocaleLowerCase_includes : false;
    if (!isDeletion) return void 0;
    return ((_inputType1 = inputType) === null || _inputType1 === void 0 ? void 0 : _inputType1.toLocaleLowerCase().includes("forward")) ? "forward" : "backward";
};
var handleUserInput = function(e, param) {
    var country = param.country, insertDialCodeOnEmpty = param.insertDialCodeOnEmpty, phoneBeforeInput = param.phoneBeforeInput, prefix = param.prefix, charAfterDialCode = param.charAfterDialCode, forceDialCode = param.forceDialCode, disableDialCodeAndPrefix = param.disableDialCodeAndPrefix, countryGuessingEnabled = param.countryGuessingEnabled, defaultMask = param.defaultMask, disableFormatting = param.disableFormatting, countries = param.countries;
    var _inputType, _nativeEvent, _inputType1;
    var nativeEvent = e.nativeEvent;
    var inputType = nativeEvent.inputType;
    var deletion = getDeletionType(inputType);
    var isInserted = !!((_inputType = inputType) === null || _inputType === void 0 ? void 0 : _inputType.startsWith("insertFrom"));
    var isTyped = inputType === "insertText";
    var nativeEventData = (_nativeEvent = nativeEvent) === null || _nativeEvent === void 0 ? void 0 : _nativeEvent.data;
    var lastTypedChar = nativeEventData || void 0;
    var userInput = e.target.value;
    var _e_target_selectionStart;
    var cursorPositionAfterInput = (_e_target_selectionStart = e.target.selectionStart) !== null && _e_target_selectionStart !== void 0 ? _e_target_selectionStart : 0;
    if ((_inputType1 = inputType) === null || _inputType1 === void 0 ? void 0 : _inputType1.includes("history")) {
        return {
            inputValue: phoneBeforeInput,
            phone: toE164({
                phone: phoneBeforeInput,
                prefix: prefix
            }),
            cursorPosition: phoneBeforeInput.length,
            country: country
        };
    }
    if (isTyped && !isNumeric(lastTypedChar) && // allow type prefix when input value is empty
    userInput !== prefix) {
        var _lastTypedChar;
        var _lastTypedChar_length;
        return {
            inputValue: phoneBeforeInput,
            phone: toE164({
                phone: disableDialCodeAndPrefix ? "".concat(country.dialCode).concat(phoneBeforeInput) : phoneBeforeInput,
                prefix: prefix
            }),
            cursorPosition: cursorPositionAfterInput - ((_lastTypedChar_length = (_lastTypedChar = lastTypedChar) === null || _lastTypedChar === void 0 ? void 0 : _lastTypedChar.length) !== null && _lastTypedChar_length !== void 0 ? _lastTypedChar_length : 0),
            country: country
        };
    }
    if (forceDialCode && // dial code has been changed
    !userInput.startsWith("".concat(prefix).concat(country.dialCode)) && // was not inserted with ctrl+v
    !isInserted) {
        var inputValue = userInput ? phoneBeforeInput : "".concat(prefix).concat(country.dialCode).concat(charAfterDialCode);
        return {
            inputValue: inputValue,
            phone: toE164({
                phone: inputValue,
                prefix: prefix
            }),
            cursorPosition: prefix.length + country.dialCode.length + charAfterDialCode.length,
            // set cursor position after dial code
            country: country
        };
    }
    var _handlePhoneChange = handlePhoneChange({
        value: userInput,
        country: country,
        trimNonDigitsEnd: deletion === "backward",
        // trim values if user deleting chars (delete mask's whitespace and brackets)
        insertDialCodeOnEmpty: insertDialCodeOnEmpty,
        countryGuessingEnabled: countryGuessingEnabled,
        countries: countries,
        prefix: prefix,
        charAfterDialCode: charAfterDialCode,
        forceDialCode: forceDialCode,
        disableDialCodeAndPrefix: disableDialCodeAndPrefix,
        disableFormatting: disableFormatting,
        defaultMask: defaultMask
    }), newPhone = _handlePhoneChange.phone, newInputValue = _handlePhoneChange.inputValue, newCountry = _handlePhoneChange.country;
    var newCursorPosition = getCursorPosition({
        cursorPositionAfterInput: cursorPositionAfterInput,
        phoneBeforeInput: phoneBeforeInput,
        phoneAfterInput: userInput,
        phoneAfterFormatted: newInputValue,
        leftOffset: forceDialCode ? prefix.length + country.dialCode.length + charAfterDialCode.length : 0,
        deletion: deletion
    });
    return {
        phone: newPhone,
        inputValue: newInputValue,
        cursorPosition: newCursorPosition,
        country: newCountry
    };
};
// src/hooks/useHistoryState.ts
var import_react2 = require("react");
// src/utils/common/isShallowEqualObjects.ts
var isShallowEqualObjects = function(object1, object2) {
    var keys1 = Object.keys(object1);
    var keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = keys1[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var key = _step.value;
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return true;
};
// src/hooks/useTimer.ts
var import_react = require("react");
var useTimer = function() {
    var prevTimeRef = (0, import_react.useRef)();
    var currentTimeRef = (0, import_react.useRef)(Date.now());
    var check = function() {
        var newTime = Date.now();
        var difference = prevTimeRef.current ? newTime - currentTimeRef.current : void 0;
        prevTimeRef.current = currentTimeRef.current;
        currentTimeRef.current = newTime;
        return difference;
    };
    return {
        check: check
    };
};
// src/hooks/useHistoryState.ts
var defaultConfig = {
    size: 20,
    overrideLastItemDebounceMS: -1
};
function useHistoryState(initialValue, config) {
    var _$_object_spread = _object_spread({}, defaultConfig, config), size = _$_object_spread.size, overrideLastItemDebounceMS = _$_object_spread.overrideLastItemDebounceMS, onChange = _$_object_spread.onChange;
    var _ref = _sliced_to_array((0, import_react2.useState)(initialValue), 2), state = _ref[0], _setState = _ref[1];
    var _ref1 = _sliced_to_array((0, import_react2.useState)([
        state
    ]), 2), history = _ref1[0], setHistory = _ref1[1];
    var _ref2 = _sliced_to_array((0, import_react2.useState)(0), 2), pointer = _ref2[0], setPointer = _ref2[1];
    var timer = useTimer();
    var setState = function(value, config2) {
        var // use value of config.overrideLastItem if passed
        _config2, _onChange;
        if (// compare entries if passed value is object
        typeof value === "object" && typeof state === "object" && isShallowEqualObjects(value, state) || value === state) {
            return;
        }
        var isOverridingEnabled = overrideLastItemDebounceMS > 0;
        var timePassedSinceLastChange = timer.check();
        var debounceTimePassed = isOverridingEnabled && timePassedSinceLastChange !== void 0 ? timePassedSinceLastChange > overrideLastItemDebounceMS : true;
        var shouldOverrideLastItem = ((_config2 = config2) === null || _config2 === void 0 ? void 0 : _config2.overrideLastItem) !== void 0 ? config2.overrideLastItem : !debounceTimePassed;
        if (shouldOverrideLastItem) {
            setHistory(function(prev) {
                return _to_consumable_array(prev.slice(0, pointer)).concat([
                    value
                ]);
            });
        } else {
            var isSizeOverflow = history.length >= size;
            setHistory(function(prev) {
                return _to_consumable_array(prev.slice(isSizeOverflow ? 1 : 0, pointer + 1)).concat([
                    value
                ]);
            });
            if (!isSizeOverflow) {
                setPointer(function(prev) {
                    return prev + 1;
                });
            }
        }
        _setState(value);
        (_onChange = onChange) === null || _onChange === void 0 ? void 0 : _onChange(value);
    };
    var undo = function() {
        var _onChange;
        if (pointer <= 0) {
            return {
                success: false
            };
        }
        var value = history[pointer - 1];
        _setState(value);
        setPointer(function(prev) {
            return prev - 1;
        });
        (_onChange = onChange) === null || _onChange === void 0 ? void 0 : _onChange(value);
        return {
            success: true,
            value: value
        };
    };
    var redo = function() {
        var _onChange;
        if (pointer + 1 >= history.length) {
            return {
                success: false
            };
        }
        var value = history[pointer + 1];
        _setState(value);
        setPointer(function(prev) {
            return prev + 1;
        });
        (_onChange = onChange) === null || _onChange === void 0 ? void 0 : _onChange(value);
        return {
            success: true,
            value: value
        };
    };
    return [
        state,
        setState,
        undo,
        redo
    ];
}
// src/hooks/usePhoneInput.ts
var MASK_CHAR = ".";
var defaultConfig2 = {
    defaultCountry: "us",
    value: "",
    prefix: "+",
    defaultMask: "............",
    // 12 chars
    charAfterDialCode: " ",
    historySaveDebounceMS: 200,
    disableCountryGuess: false,
    disableDialCodePrefill: false,
    forceDialCode: false,
    disableDialCodeAndPrefix: false,
    disableFormatting: false,
    countries: defaultCountries,
    preferredCountries: []
};
var usePhoneInput = function(param) {
    var _param_defaultCountry = param.defaultCountry, defaultCountry = _param_defaultCountry === void 0 ? defaultConfig2.defaultCountry : _param_defaultCountry, _param_value = param.value, value = _param_value === void 0 ? defaultConfig2.value : _param_value, _param_countries = param.countries, countries = _param_countries === void 0 ? defaultConfig2.countries : _param_countries, _param_prefix = param.prefix, prefix = _param_prefix === void 0 ? defaultConfig2.prefix : _param_prefix, _param_defaultMask = param.defaultMask, defaultMask = _param_defaultMask === void 0 ? defaultConfig2.defaultMask : _param_defaultMask, _param_charAfterDialCode = param.charAfterDialCode, charAfterDialCode = _param_charAfterDialCode === void 0 ? defaultConfig2.charAfterDialCode : _param_charAfterDialCode, _param_historySaveDebounceMS = param.historySaveDebounceMS, historySaveDebounceMS = _param_historySaveDebounceMS === void 0 ? defaultConfig2.historySaveDebounceMS : _param_historySaveDebounceMS, _param_disableCountryGuess = param.disableCountryGuess, disableCountryGuess = _param_disableCountryGuess === void 0 ? defaultConfig2.disableCountryGuess : _param_disableCountryGuess, _param_disableDialCodePrefill = param.disableDialCodePrefill, disableDialCodePrefill = _param_disableDialCodePrefill === void 0 ? defaultConfig2.disableDialCodePrefill : _param_disableDialCodePrefill, tmp = param.forceDialCode, forceDialCodeConfig = tmp === void 0 ? defaultConfig2.forceDialCode : tmp, _param_disableDialCodeAndPrefix = param.disableDialCodeAndPrefix, disableDialCodeAndPrefix = _param_disableDialCodeAndPrefix === void 0 ? defaultConfig2.disableDialCodeAndPrefix : _param_disableDialCodeAndPrefix, _param_disableFormatting = param.disableFormatting, disableFormatting = _param_disableFormatting === void 0 ? defaultConfig2.disableFormatting : _param_disableFormatting, onChange = param.onChange, inputRefProp = param.inputRef;
    var countryGuessingEnabled = !disableCountryGuess;
    var forceDialCode = disableDialCodeAndPrefix ? false : forceDialCodeConfig;
    var phoneFormattingConfig = {
        countries: countries,
        prefix: prefix,
        charAfterDialCode: charAfterDialCode,
        forceDialCode: forceDialCode,
        disableDialCodeAndPrefix: disableDialCodeAndPrefix,
        defaultMask: defaultMask,
        countryGuessingEnabled: countryGuessingEnabled,
        disableFormatting: disableFormatting
    };
    var ref = (0, import_react3.useRef)(null);
    var inputRef = inputRefProp || ref;
    var setCursorPosition = function(cursorPosition) {
        Promise.resolve().then(function() {
            var _document, _inputRef_current;
            if (typeof window === "undefined" || inputRef.current !== ((_document = document) === null || _document === void 0 ? void 0 : _document.activeElement)) {
                return;
            }
            (_inputRef_current = inputRef.current) === null || _inputRef_current === void 0 ? void 0 : _inputRef_current.setSelectionRange(cursorPosition, cursorPosition);
        });
    };
    var _useHistoryState = _sliced_to_array(useHistoryState(function() {
        var defaultCountryFull = getCountry({
            value: defaultCountry,
            field: "iso2",
            countries: countries
        });
        if (!defaultCountryFull) {
            console.error('[react-international-phone]: can not find a country with "'.concat(defaultCountry, '" iso2 code'));
        }
        var initialCountry = defaultCountryFull || // fallback to "us" if user provided not valid country
        getCountry({
            value: "us",
            field: "iso2",
            countries: countries
        });
        var _handlePhoneChange = handlePhoneChange(_object_spread({
            value: value,
            country: initialCountry,
            insertDialCodeOnEmpty: !disableDialCodePrefill
        }, phoneFormattingConfig)), phone2 = _handlePhoneChange.phone, inputValue2 = _handlePhoneChange.inputValue, formatCountry = _handlePhoneChange.country;
        setCursorPosition(inputValue2.length);
        return {
            phone: phone2,
            inputValue: inputValue2,
            country: formatCountry.iso2
        };
    }, {
        overrideLastItemDebounceMS: historySaveDebounceMS,
        onChange: function(param) {
            var inputValue2 = param.inputValue, phone2 = param.phone, country2 = param.country;
            if (!onChange) return;
            var fullCountry2 = getFullCountry(country2);
            onChange({
                phone: phone2,
                inputValue: inputValue2,
                country: fullCountry2
            });
        }
    }), 4), _useHistoryState_ = _useHistoryState[0], phone = _useHistoryState_.phone, inputValue = _useHistoryState_.inputValue, country = _useHistoryState_.country, updateHistory = _useHistoryState[1], undo = _useHistoryState[2], redo = _useHistoryState[3];
    var getFullCountry = (0, import_react3.useCallback)(function(iso2) {
        return getCountry({
            value: iso2,
            field: "iso2",
            countries: countries
        });
    }, [
        countries
    ]);
    var fullCountry = (0, import_react3.useMemo)(function() {
        return getFullCountry(country);
    }, [
        country,
        getFullCountry
    ]);
    (0, import_react3.useEffect)(function() {
        var input = inputRef.current;
        if (!input) return;
        var onKeyDown = function(e) {
            if (!e.key) return;
            var ctrlPressed = e.ctrlKey;
            var shiftPressed = e.shiftKey;
            var zPressed = e.key.toLowerCase() === "z";
            if (!ctrlPressed || !zPressed) return;
            shiftPressed ? redo() : undo();
        };
        input.addEventListener("keydown", onKeyDown);
        return function() {
            input.removeEventListener("keydown", onKeyDown);
        };
    }, [
        inputRef,
        undo,
        redo
    ]);
    var handlePhoneValueChange = function(e) {
        e.preventDefault();
        var _handleUserInput = handleUserInput(e, _object_spread({
            country: fullCountry,
            phoneBeforeInput: inputValue,
            insertDialCodeOnEmpty: false
        }, phoneFormattingConfig)), newPhone = _handleUserInput.phone, newInputValue = _handleUserInput.inputValue, newCountry = _handleUserInput.country, newCursorPosition = _handleUserInput.cursorPosition;
        updateHistory({
            inputValue: newInputValue,
            phone: newPhone,
            country: newCountry.iso2
        });
        setCursorPosition(newCursorPosition);
        return value;
    };
    var setCountry = function(countryIso2) {
        var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
            focusOnInput: false
        };
        var newCountry = getCountry({
            value: countryIso2,
            field: "iso2",
            countries: countries
        });
        if (!newCountry) {
            console.error('[react-international-phone]: can not find a country with "'.concat(countryIso2, '" iso2 code'));
            return;
        }
        var inputValue2 = disableDialCodeAndPrefix ? "" : "".concat(prefix).concat(newCountry.dialCode).concat(charAfterDialCode);
        updateHistory({
            inputValue: inputValue2,
            phone: "".concat(prefix).concat(newCountry.dialCode),
            country: newCountry.iso2
        });
        if (options.focusOnInput) {
            Promise.resolve().then(function() {
                var _inputRef_current;
                (_inputRef_current = inputRef.current) === null || _inputRef_current === void 0 ? void 0 : _inputRef_current.focus();
            });
        }
    };
    var _ref = _sliced_to_array((0, import_react3.useState)(false), 2), initialized = _ref[0], setInitialized = _ref[1];
    (0, import_react3.useEffect)(function() {
        if (!initialized) {
            setInitialized(true);
            if (value !== phone) {
                var _onChange;
                (_onChange = onChange) === null || _onChange === void 0 ? void 0 : _onChange({
                    inputValue: inputValue,
                    phone: phone,
                    country: fullCountry
                });
            }
            return;
        }
        if (value === phone) return;
        var _handlePhoneChange = handlePhoneChange(_object_spread({
            value: value,
            country: fullCountry,
            insertDialCodeOnEmpty: !disableDialCodePrefill
        }, phoneFormattingConfig)), newPhone = _handlePhoneChange.phone, newInputValue = _handlePhoneChange.inputValue, newCountry = _handlePhoneChange.country;
        updateHistory({
            phone: newPhone,
            inputValue: newInputValue,
            country: newCountry.iso2
        });
    }, [
        value
    ]);
    return {
        phone: phone,
        // Phone in E164 format
        inputValue: inputValue,
        // Formatted phone string. Value that should be rendered inside input element.
        country: fullCountry,
        // Current country object.
        setCountry: setCountry,
        // Country setter.
        handlePhoneValueChange: handlePhoneValueChange,
        // Change handler for input component
        inputRef: inputRef
    };
};
// src/utils/countryUtils/getActiveFormattingMask.ts
var getActiveFormattingMask = function(param) {
    var phone = param.phone, country = param.country, _param_defaultMask = param.defaultMask, defaultMask = _param_defaultMask === void 0 ? "............" : _param_defaultMask, _param_disableFormatting = param.// 12 chars
    disableFormatting, disableFormatting = _param_disableFormatting === void 0 ? false : _param_disableFormatting;
    var format = country.format;
    var handleReturn = function(mask) {
        return disableFormatting ? mask.replace(new RegExp("[^".concat(MASK_CHAR, "]"), "g"), "") : mask;
    };
    if (!format) {
        return handleReturn(defaultMask);
    }
    if (typeof format === "string") {
        return handleReturn(format);
    }
    if (!format["default"]) {
        console.error("[react-international-phone]: default mask for ".concat(country.iso2, " is not provided"));
        return handleReturn(defaultMask);
    }
    var matchedFormatKey = Object.keys(format).find(function(key) {
        if (key === "default") return false;
        var isValidRegex = key.charAt(0) === "/" && key.charAt(key.length - 1) === "/";
        if (!isValidRegex) {
            console.error('[react-international-phone]: format regex "'.concat(key, '" for ').concat(country.iso2, " is not valid"));
            return false;
        }
        var regex = new RegExp(key.substring(1, key.length - 1));
        var valueWithoutDialCode = phone.replace(country.dialCode, "");
        return regex.test(removeNonDigits(valueWithoutDialCode));
    });
    return handleReturn(matchedFormatKey ? format[matchedFormatKey] : format["default"]);
};
// src/utils/countryUtils/parseCountry.ts
var parseCountry = function(countryData) {
    var _countryData = _sliced_to_array(countryData, 6), name = _countryData[0], iso2 = _countryData[1], dialCode = _countryData[2], format = _countryData[3], priority = _countryData[4], areaCodes = _countryData[5];
    return {
        name: name,
        iso2: iso2,
        dialCode: dialCode,
        format: format,
        priority: priority,
        areaCodes: areaCodes
    };
};
// src/utils/countryUtils/getCountry.ts
var constructFieldNotSupportedErrorMessage = function(field) {
    return 'Field "'.concat(field, '" is not supported');
};
var getCountry = function(param) {
    var field = param.field, value = param.value, _param_countries = param.countries, countries = _param_countries === void 0 ? defaultCountries : _param_countries;
    if ([
        "priority"
    ].includes(field)) {
        throw new Error(constructFieldNotSupportedErrorMessage(field));
    }
    var country = countries.find(function(country2) {
        var parsedCountry = parseCountry(country2);
        return value === parsedCountry[field];
    });
    if (!country) return void 0;
    return parseCountry(country);
};
// src/utils/countryUtils/guessCountryByPartialNumber.ts
var guessCountryByPartialNumber = function(param) {
    var partialPhone = param.phone, _param_countries = param.countries, countries = _param_countries === void 0 ? defaultCountries : _param_countries, currentCountryIso2 = param.currentCountryIso2;
    var emptyResult = {
        country: void 0,
        fullDialCodeMatch: false
    };
    if (!partialPhone) {
        return emptyResult;
    }
    var phone = removeNonDigits(partialPhone);
    if (!phone) {
        return emptyResult;
    }
    var result = emptyResult;
    var updateResult = function(param) {
        var country = param.country, fullDialCodeMatch = param.fullDialCodeMatch;
        var _result_country, _result_country1;
        var sameDialCode = country.dialCode === ((_result_country = result.country) === null || _result_country === void 0 ? void 0 : _result_country.dialCode);
        var _country_priority, _result_country_priority;
        var newPriorityValueLower = ((_country_priority = country.priority) !== null && _country_priority !== void 0 ? _country_priority : 0) < ((_result_country_priority = (_result_country1 = result.country) === null || _result_country1 === void 0 ? void 0 : _result_country1.priority) !== null && _result_country_priority !== void 0 ? _result_country_priority : 0);
        if (!sameDialCode || newPriorityValueLower) {
            result = {
                country: country,
                fullDialCodeMatch: fullDialCodeMatch
            };
        }
    };
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = countries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var c = _step.value;
            var parsedCountry = parseCountry(c);
            var dialCode = parsedCountry.dialCode, areaCodes = parsedCountry.areaCodes;
            if (phone.startsWith(dialCode)) {
                var isNewDialCodeLonger = result.country ? Number(dialCode) >= Number(result.country.dialCode) : true;
                if (areaCodes) {
                    var phoneWithoutDialCode = phone.substring(dialCode.length);
                    var _iteratorNormalCompletion1 = true, _didIteratorError1 = false, _iteratorError1 = undefined;
                    try {
                        for(var _iterator1 = areaCodes[Symbol.iterator](), _step1; !(_iteratorNormalCompletion1 = (_step1 = _iterator1.next()).done); _iteratorNormalCompletion1 = true){
                            var areaCode = _step1.value;
                            if (phoneWithoutDialCode.startsWith(areaCode)) {
                                return {
                                    country: parsedCountry,
                                    fullDialCodeMatch: true
                                };
                            }
                        }
                    } catch (err) {
                        _didIteratorError1 = true;
                        _iteratorError1 = err;
                    } finally{
                        try {
                            if (!_iteratorNormalCompletion1 && _iterator1.return != null) {
                                _iterator1.return();
                            }
                        } finally{
                            if (_didIteratorError1) {
                                throw _iteratorError1;
                            }
                        }
                    }
                }
                if (isNewDialCodeLonger || dialCode === phone || !result.fullDialCodeMatch) {
                    updateResult({
                        country: parsedCountry,
                        fullDialCodeMatch: true
                    });
                }
            }
            if (result.fullDialCodeMatch) continue;
            if (phone.length < dialCode.length) {
                if (dialCode.startsWith(phone)) {
                    var isNewCodeLess = result.country ? Number(dialCode) <= Number(result.country.dialCode) : true;
                    if (isNewCodeLess) {
                        updateResult({
                            country: parsedCountry,
                            fullDialCodeMatch: false
                        });
                    }
                }
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    if (currentCountryIso2) {
        var // countries have same dial code
        _result_country;
        var currentCountry = getCountry({
            value: currentCountryIso2,
            field: "iso2",
            countries: countries
        });
        if (!currentCountry) {
            return result;
        }
        var getAreaCodesPartialMatch = function(country) {
            var _country;
            if (!((_country = country) === null || _country === void 0 ? void 0 : _country.areaCodes)) return false;
            var phoneWithoutDialCode = phone.substring(country.dialCode.length);
            return country.areaCodes.some(function(areaCode) {
                return areaCode.startsWith(phoneWithoutDialCode);
            });
        };
        var currentAreaCodePartiallyMatch = currentCountry ? getAreaCodesPartialMatch(currentCountry) : false;
        var shouldSaveCurrentCountry = !!result && ((_result_country = result.country) === null || _result_country === void 0 ? void 0 : _result_country.dialCode) === currentCountry.dialCode && result.country !== currentCountry && result.fullDialCodeMatch && // current country area-code is still partially match input
        (!currentCountry.areaCodes || currentAreaCodePartiallyMatch);
        if (shouldSaveCurrentCountry) {
            result = {
                country: currentCountry,
                fullDialCodeMatch: true
            };
        }
    }
    return result;
};
// src/components/FlagImage/FlagImage.tsx
var import_react4 = __toESM(require("react"));
var incrementCodepoint = function(codePoint, incrementBy) {
    var decimal = parseInt(codePoint, 16);
    return Number(decimal + incrementBy).toString(16);
};
var alphabet = "abcdefghijklmnopqrstuvwxyz";
var A_LETTER_CODEPOINT = "1f1e6";
var codepoints = alphabet.split("").reduce(function(obj, currentLetter, index) {
    return _object_spread_props(_object_spread({}, obj), // construct alphabet by incrementing A letter codepoint value
    _define_property({}, currentLetter, incrementCodepoint(A_LETTER_CODEPOINT, index)));
}, {});
var getFlagCodepointByIso2 = function(iso2) {
    return [
        codepoints[iso2[0]],
        codepoints[iso2[1]]
    ].join("-");
};
var FlagImage = function(_param) {
    var iso2 = _param.iso2, size = _param.size, src = _param.src, _param_protocol = _param.protocol, protocol = _param_protocol === void 0 ? "https" : _param_protocol, disableLazyLoading = _param.disableLazyLoading, className = _param.className, style = _param.style, restProps = _object_without_properties(_param, [
        "iso2",
        "size",
        "src",
        "protocol",
        "disableLazyLoading",
        "className",
        "style"
    ]);
    if (!iso2) {
        return /* @__PURE__ */ import_react4.default.createElement("img", _object_spread({
            className: buildClassNames({
                addPrefix: [
                    "flag-emoji"
                ],
                rawClassNames: [
                    className
                ]
            }),
            width: size,
            height: size
        }, restProps));
    }
    var getSrc = function() {
        if (src) return src;
        var flagCodepoint = getFlagCodepointByIso2(iso2);
        return "".concat(protocol, "://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/").concat(flagCodepoint, ".svg");
    };
    return /* @__PURE__ */ import_react4.default.createElement("img", _object_spread({
        className: buildClassNames({
            addPrefix: [
                "flag-emoji"
            ],
            rawClassNames: [
                className
            ]
        }),
        src: getSrc(),
        width: size,
        height: size,
        draggable: false,
        "data-country": iso2,
        loading: disableLazyLoading ? void 0 : "lazy",
        style: _object_spread({
            width: size,
            height: size
        }, style),
        alt: ""
    }, restProps));
};
// src/components/CountrySelector/CountrySelectorDropdown.tsx
var import_react5 = __toESM(require("react"));
var SEARCH_DEBOUNCE_MS = 1e3;
var CountrySelectorDropdown = function(_param) {
    var show = _param.show, _param_dialCodePrefix = _param.dialCodePrefix, dialCodePrefix = _param_dialCodePrefix === void 0 ? "+" : _param_dialCodePrefix, selectedCountry = _param.selectedCountry, _param_countries = _param.countries, countries = _param_countries === void 0 ? defaultCountries : _param_countries, _param_preferredCountries = _param.preferredCountries, preferredCountries = _param_preferredCountries === void 0 ? [] : _param_preferredCountries, flags = _param.flags, onSelect = _param.onSelect, onClose = _param.onClose, styleProps = _object_without_properties(_param, [
        "show",
        "dialCodePrefix",
        "selectedCountry",
        "countries",
        "preferredCountries",
        "flags",
        "onSelect",
        "onClose"
    ]);
    var listRef = (0, import_react5.useRef)(null);
    var lastScrolledCountry = (0, import_react5.useRef)();
    var orderedCountries = (0, import_react5.useMemo)(function() {
        if (!preferredCountries || !preferredCountries.length) {
            return countries;
        }
        var preferred = [];
        var rest = _to_consumable_array(countries);
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            var _loop = function() {
                var iso2 = _step.value;
                var index = rest.findIndex(function(c) {
                    return parseCountry(c).iso2 === iso2;
                });
                if (index !== -1) {
                    var preferredCountry = rest.splice(index, 1)[0];
                    preferred.push(preferredCountry);
                }
            };
            for(var _iterator = preferredCountries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true)_loop();
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        return preferred.concat(rest);
    }, [
        countries,
        preferredCountries
    ]);
    var searchRef = (0, import_react5.useRef)({
        updatedAt: void 0,
        value: ""
    });
    var updateSearch = function(newChar) {
        var isSearchDelayPassed = searchRef.current.updatedAt && /* @__PURE__ */ new Date().getTime() - searchRef.current.updatedAt.getTime() > SEARCH_DEBOUNCE_MS;
        searchRef.current = {
            value: isSearchDelayPassed ? newChar : "".concat(searchRef.current.value).concat(newChar),
            updatedAt: /* @__PURE__ */ new Date()
        };
        var searchedCountryIndex = orderedCountries.findIndex(function(c) {
            return parseCountry(c).name.toLowerCase().startsWith(searchRef.current.value);
        });
        if (searchedCountryIndex !== -1) {
            setFocusedItemIndex(searchedCountryIndex);
        }
    };
    var getCountryIndex = (0, import_react5.useCallback)(function(country) {
        return orderedCountries.findIndex(function(c) {
            return parseCountry(c).iso2 === country;
        });
    }, [
        orderedCountries
    ]);
    var _ref = _sliced_to_array((0, import_react5.useState)(getCountryIndex(selectedCountry)), 2), focusedItemIndex = _ref[0], setFocusedItemIndex = _ref[1];
    var resetFocusedItemIndex = function() {
        if (lastScrolledCountry.current === selectedCountry) return;
        setFocusedItemIndex(getCountryIndex(selectedCountry));
    };
    var handleCountrySelect = (0, import_react5.useCallback)(function(country) {
        var _onSelect;
        setFocusedItemIndex(getCountryIndex(country.iso2));
        (_onSelect = onSelect) === null || _onSelect === void 0 ? void 0 : _onSelect(country);
    }, [
        onSelect,
        getCountryIndex
    ]);
    var moveFocusedItem = function(to) {
        var lastPossibleIndex = orderedCountries.length - 1;
        var getNewIndex = function(currentIndex) {
            if (to === "prev") return currentIndex - 1;
            if (to === "next") return currentIndex + 1;
            if (to === "last") return lastPossibleIndex;
            return 0;
        };
        setFocusedItemIndex(function(v) {
            var newIndex = getNewIndex(v);
            if (newIndex < 0) return 0;
            if (newIndex > lastPossibleIndex) return lastPossibleIndex;
            return newIndex;
        });
    };
    var handleKeyDown = function(e) {
        e.stopPropagation();
        if (e.key === "Enter") {
            e.preventDefault();
            var focusedCountry = parseCountry(orderedCountries[focusedItemIndex]);
            handleCountrySelect(focusedCountry);
            return;
        }
        if (e.key === "Escape") {
            var _onClose;
            (_onClose = onClose) === null || _onClose === void 0 ? void 0 : _onClose();
            return;
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            moveFocusedItem("prev");
            return;
        }
        if (e.key === "ArrowDown") {
            e.preventDefault();
            moveFocusedItem("next");
            return;
        }
        if (e.key === "PageUp") {
            e.preventDefault();
            moveFocusedItem("first");
            return;
        }
        if (e.key === "PageDown") {
            e.preventDefault();
            moveFocusedItem("last");
            return;
        }
        if (e.key === " ") {
            e.preventDefault();
        }
        if (e.key.length === 1 && !e.altKey && !e.ctrlKey && !e.metaKey) {
            updateSearch(e.key.toLocaleLowerCase());
        }
    };
    var scrollToFocusedCountry = (0, import_react5.useCallback)(function() {
        if (!listRef.current || focusedItemIndex === void 0) return;
        var focusedCountry = parseCountry(orderedCountries[focusedItemIndex]).iso2;
        if (focusedCountry === lastScrolledCountry.current) return;
        var element = listRef.current.querySelector('[data-country="'.concat(focusedCountry, '"]'));
        if (!element) return;
        scrollToChild(listRef.current, element);
        lastScrolledCountry.current = focusedCountry;
    }, [
        focusedItemIndex,
        orderedCountries
    ]);
    (0, import_react5.useEffect)(function() {
        scrollToFocusedCountry();
    }, [
        focusedItemIndex,
        scrollToFocusedCountry
    ]);
    (0, import_react5.useEffect)(function() {
        if (!listRef.current) return;
        if (show) {
            listRef.current.focus();
        } else {
            resetFocusedItemIndex();
        }
    }, [
        show
    ]);
    (0, import_react5.useEffect)(function() {
        resetFocusedItemIndex();
    }, [
        selectedCountry
    ]);
    return /* @__PURE__ */ import_react5.default.createElement("ul", {
        ref: listRef,
        role: "listbox",
        className: buildClassNames({
            addPrefix: [
                "country-selector-dropdown"
            ],
            rawClassNames: [
                styleProps.className
            ]
        }),
        style: _object_spread({
            display: show ? "block" : "none"
        }, styleProps.style),
        onKeyDown: handleKeyDown,
        onBlur: onClose,
        tabIndex: -1,
        "aria-activedescendant": "react-international-phone__".concat(parseCountry(orderedCountries[focusedItemIndex]).iso2, "-option")
    }, orderedCountries.map(function(c, index) {
        var _flags, _flag;
        var country = parseCountry(c);
        var isSelected = country.iso2 === selectedCountry;
        var isFocused = index === focusedItemIndex;
        var isPreferred = preferredCountries.includes(country.iso2);
        var isLastPreferred = index === preferredCountries.length - 1;
        var flag = (_flags = flags) === null || _flags === void 0 ? void 0 : _flags.find(function(f) {
            return f.iso2 === country.iso2;
        });
        return /* @__PURE__ */ import_react5.default.createElement(import_react5.default.Fragment, {
            key: country.iso2
        }, /* @__PURE__ */ import_react5.default.createElement("li", {
            "data-country": country.iso2,
            role: "option",
            "aria-selected": isSelected,
            "aria-label": "".concat(country.name, " ").concat(dialCodePrefix).concat(country.dialCode),
            id: "react-international-phone__".concat(country.iso2, "-option"),
            className: buildClassNames({
                addPrefix: [
                    "country-selector-dropdown__list-item",
                    isPreferred && "country-selector-dropdown__list-item--preferred",
                    isSelected && "country-selector-dropdown__list-item--selected",
                    isFocused && "country-selector-dropdown__list-item--focused"
                ],
                rawClassNames: [
                    styleProps.listItemClassName
                ]
            }),
            onClick: function() {
                return handleCountrySelect(country);
            },
            style: styleProps.listItemStyle,
            title: country.name
        }, /* @__PURE__ */ import_react5.default.createElement(FlagImage, {
            iso2: country.iso2,
            src: (_flag = flag) === null || _flag === void 0 ? void 0 : _flag.src,
            className: buildClassNames({
                addPrefix: [
                    "country-selector-dropdown__list-item-flag-emoji"
                ],
                rawClassNames: [
                    styleProps.listItemFlagClassName
                ]
            }),
            style: styleProps.listItemFlagStyle
        }), /* @__PURE__ */ import_react5.default.createElement("span", {
            className: buildClassNames({
                addPrefix: [
                    "country-selector-dropdown__list-item-country-name"
                ],
                rawClassNames: [
                    styleProps.listItemCountryNameClassName
                ]
            }),
            style: styleProps.listItemCountryNameStyle
        }, country.name), /* @__PURE__ */ import_react5.default.createElement("span", {
            className: buildClassNames({
                addPrefix: [
                    "country-selector-dropdown__list-item-dial-code"
                ],
                rawClassNames: [
                    styleProps.listItemDialCodeClassName
                ]
            }),
            style: styleProps.listItemDialCodeStyle
        }, dialCodePrefix, country.dialCode)), isLastPreferred ? /* @__PURE__ */ import_react5.default.createElement("hr", {
            className: buildClassNames({
                addPrefix: [
                    "country-selector-dropdown__preferred-list-divider"
                ],
                rawClassNames: [
                    styleProps.preferredListDividerClassName
                ]
            }),
            style: styleProps.preferredListDividerStyle
        }) : null);
    }));
};
// src/components/CountrySelector/CountrySelector.tsx
var CountrySelector = function(_param) {
    var selectedCountry = _param.selectedCountry, onSelect = _param.onSelect, disabled = _param.disabled, hideDropdown = _param.hideDropdown, _param_countries = _param.countries, countries = _param_countries === void 0 ? defaultCountries : _param_countries, _param_preferredCountries = _param.preferredCountries, preferredCountries = _param_preferredCountries === void 0 ? [] : _param_preferredCountries, flags = _param.flags, renderButtonWrapper = _param.renderButtonWrapper, styleProps = _object_without_properties(_param, [
        "selectedCountry",
        "onSelect",
        "disabled",
        "hideDropdown",
        "countries",
        "preferredCountries",
        "flags",
        "renderButtonWrapper"
    ]);
    var _ref = _sliced_to_array((0, import_react6.useState)(false), 2), showDropdown = _ref[0], setShowDropdown = _ref[1];
    var fullSelectedCountry = (0, import_react6.useMemo)(function() {
        if (!selectedCountry) return void 0;
        return getCountry({
            value: selectedCountry,
            field: "iso2",
            countries: countries
        });
    }, [
        countries,
        selectedCountry
    ]);
    var countrySelectorRef = (0, import_react6.useRef)(null);
    var handleKeyDown = function(e) {
        if (!e.key) return;
        if ([
            "ArrowUp",
            "ArrowDown"
        ].includes(e.key)) {
            e.preventDefault();
            setShowDropdown(true);
        }
    };
    var renderSelectorButton = function() {
        var _fullSelectedCountry, _flags_find, _flags;
        var rootProps = {
            title: (_fullSelectedCountry = fullSelectedCountry) === null || _fullSelectedCountry === void 0 ? void 0 : _fullSelectedCountry.name,
            onClick: function() {
                return setShowDropdown(function(v) {
                    return !v;
                });
            },
            // Need this to close dropdown on selector button click
            // https://stackoverflow.com/a/28963938
            onMouseDown: function(e) {
                return e.preventDefault();
            },
            onKeyDown: handleKeyDown,
            disabled: hideDropdown || disabled,
            role: "combobox",
            "aria-label": "Country selector",
            "aria-haspopup": "listbox",
            "aria-expanded": showDropdown
        };
        var buttonContent = /* @__PURE__ */ import_react6.default.createElement("div", {
            className: buildClassNames({
                addPrefix: [
                    "country-selector-button__button-content"
                ],
                rawClassNames: [
                    styleProps.buttonContentWrapperClassName
                ]
            }),
            style: styleProps.buttonContentWrapperStyle
        }, /* @__PURE__ */ import_react6.default.createElement(FlagImage, {
            iso2: selectedCountry,
            src: (_flags = flags) === null || _flags === void 0 ? void 0 : (_flags_find = _flags.find(function(f) {
                return f.iso2 === selectedCountry;
            })) === null || _flags_find === void 0 ? void 0 : _flags_find.src,
            className: buildClassNames({
                addPrefix: [
                    "country-selector-button__flag-emoji",
                    disabled && "country-selector-button__flag-emoji--disabled"
                ],
                rawClassNames: [
                    styleProps.flagClassName
                ]
            }),
            style: _object_spread({
                visibility: selectedCountry ? "visible" : "hidden"
            }, styleProps.flagStyle)
        }), !hideDropdown && /* @__PURE__ */ import_react6.default.createElement("div", {
            className: buildClassNames({
                addPrefix: [
                    "country-selector-button__dropdown-arrow",
                    disabled && "country-selector-button__dropdown-arrow--disabled",
                    showDropdown && "country-selector-button__dropdown-arrow--active"
                ],
                rawClassNames: [
                    styleProps.dropdownArrowClassName
                ]
            }),
            style: styleProps.dropdownArrowStyle
        }));
        if (renderButtonWrapper) {
            return renderButtonWrapper({
                children: buttonContent,
                rootProps: rootProps
            });
        }
        return /* @__PURE__ */ import_react6.default.createElement("button", _object_spread_props(_object_spread({}, rootProps), {
            type: "button",
            className: buildClassNames({
                addPrefix: [
                    "country-selector-button",
                    showDropdown && "country-selector-button--active",
                    disabled && "country-selector-button--disabled",
                    hideDropdown && "country-selector-button--hide-dropdown"
                ],
                rawClassNames: [
                    styleProps.buttonClassName
                ]
            }),
            "data-country": selectedCountry,
            style: styleProps.buttonStyle
        }), buttonContent);
    };
    return /* @__PURE__ */ import_react6.default.createElement("div", {
        className: buildClassNames({
            addPrefix: [
                "country-selector"
            ],
            rawClassNames: [
                styleProps.className
            ]
        }),
        style: styleProps.style,
        ref: countrySelectorRef
    }, renderSelectorButton(), /* @__PURE__ */ import_react6.default.createElement(CountrySelectorDropdown, _object_spread({
        show: showDropdown,
        countries: countries,
        preferredCountries: preferredCountries,
        flags: flags,
        onSelect: function(country) {
            var _onSelect;
            setShowDropdown(false);
            (_onSelect = onSelect) === null || _onSelect === void 0 ? void 0 : _onSelect(country);
        },
        selectedCountry: selectedCountry,
        onClose: function() {
            setShowDropdown(false);
        }
    }, styleProps.dropdownStyleProps)));
};
// src/components/DialCodePreview/DialCodePreview.tsx
var import_react7 = __toESM(require("react"));
var DialCodePreview = function(param) {
    var dialCode = param.dialCode, prefix = param.prefix, disabled = param.disabled, style = param.style, className = param.className;
    return /* @__PURE__ */ import_react7.default.createElement("div", {
        className: buildClassNames({
            addPrefix: [
                "dial-code-preview",
                disabled && "dial-code-preview--disabled"
            ],
            rawClassNames: [
                className
            ]
        }),
        style: style
    }, "".concat(prefix).concat(dialCode));
};
// src/components/PhoneInput/PhoneInput.tsx
var import_react8 = __toESM(require("react"));
var PhoneInput = (0, import_react8.forwardRef)(function(_param, ref) {
    var value = _param.value, onChange = _param.onChange, _param_countries = _param.countries, countries = _param_countries === void 0 ? defaultCountries : _param_countries, _param_preferredCountries = _param.preferredCountries, preferredCountries = _param_preferredCountries === void 0 ? [] : _param_preferredCountries, hideDropdown = _param.hideDropdown, showDisabledDialCodeAndPrefix = _param.showDisabledDialCodeAndPrefix, disableFocusAfterCountrySelect = _param.disableFocusAfterCountrySelect, flags = _param.flags, style = _param.style, className = _param.className, inputStyle = _param.inputStyle, inputClassName = _param.inputClassName, countrySelectorStyleProps = _param.countrySelectorStyleProps, dialCodePreviewStyleProps = _param.dialCodePreviewStyleProps, inputProps = _param.inputProps, placeholder = _param.placeholder, disabled = _param.disabled, name = _param.name, onFocus = _param.onFocus, onBlur = _param.onBlur, required = _param.required, autoFocus = _param.autoFocus, usePhoneInputConfig = _object_without_properties(_param, [
        "value",
        "onChange",
        "countries",
        "preferredCountries",
        "hideDropdown",
        "showDisabledDialCodeAndPrefix",
        "disableFocusAfterCountrySelect",
        "flags",
        "style",
        "className",
        "inputStyle",
        "inputClassName",
        "countrySelectorStyleProps",
        "dialCodePreviewStyleProps",
        "inputProps",
        "placeholder",
        "disabled",
        "name",
        "onFocus",
        "onBlur",
        "required",
        "autoFocus"
    ]);
    var _country;
    var _usePhoneInput = usePhoneInput(_object_spread_props(_object_spread({
        value: value,
        countries: countries
    }, usePhoneInputConfig), {
        onChange: function(data) {
            var _onChange;
            (_onChange = onChange) === null || _onChange === void 0 ? void 0 : _onChange(data.phone, {
                country: data.country,
                inputValue: data.inputValue
            });
        }
    })), phone = _usePhoneInput.phone, inputValue = _usePhoneInput.inputValue, inputRef = _usePhoneInput.inputRef, country = _usePhoneInput.country, setCountry = _usePhoneInput.setCountry, handlePhoneValueChange = _usePhoneInput.handlePhoneValueChange;
    var showDialCodePreview = usePhoneInputConfig.disableDialCodeAndPrefix && showDisabledDialCodeAndPrefix && ((_country = country) === null || _country === void 0 ? void 0 : _country.dialCode);
    (0, import_react8.useImperativeHandle)(ref, function() {
        if (!inputRef.current) return null;
        return Object.assign(inputRef.current, {
            // extend input ref with additional properties
            setCountry: setCountry,
            state: {
                phone: phone,
                inputValue: inputValue,
                country: country
            }
        });
    }, [
        inputRef,
        setCountry,
        phone,
        inputValue,
        country
    ]);
    var _usePhoneInputConfig_prefix;
    return /* @__PURE__ */ import_react8.default.createElement("div", {
        ref: ref,
        className: buildClassNames({
            addPrefix: [
                "input-container"
            ],
            rawClassNames: [
                className
            ]
        }),
        style: style
    }, /* @__PURE__ */ import_react8.default.createElement(CountrySelector, _object_spread({
        onSelect: function(country2) {
            return setCountry(country2.iso2, {
                focusOnInput: !disableFocusAfterCountrySelect
            });
        },
        flags: flags,
        selectedCountry: country.iso2,
        countries: countries,
        preferredCountries: preferredCountries,
        disabled: disabled,
        hideDropdown: hideDropdown
    }, countrySelectorStyleProps)), showDialCodePreview && /* @__PURE__ */ import_react8.default.createElement(DialCodePreview, _object_spread({
        dialCode: country.dialCode,
        prefix: (_usePhoneInputConfig_prefix = usePhoneInputConfig.prefix) !== null && _usePhoneInputConfig_prefix !== void 0 ? _usePhoneInputConfig_prefix : "+",
        disabled: disabled
    }, dialCodePreviewStyleProps)), /* @__PURE__ */ import_react8.default.createElement("input", _object_spread({
        onChange: handlePhoneValueChange,
        value: inputValue,
        type: "tel",
        ref: inputRef,
        className: buildClassNames({
            addPrefix: [
                "input",
                disabled && "input--disabled"
            ],
            rawClassNames: [
                inputClassName
            ]
        }),
        placeholder: placeholder,
        disabled: disabled,
        style: inputStyle,
        name: name,
        onFocus: onFocus,
        onBlur: onBlur,
        autoFocus: autoFocus,
        required: required
    }, inputProps)));
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
    CountrySelector: CountrySelector,
    CountrySelectorDropdown: CountrySelectorDropdown,
    DialCodePreview: DialCodePreview,
    FlagImage: FlagImage,
    PhoneInput: PhoneInput,
    buildCountryData: buildCountryData,
    defaultCountries: defaultCountries,
    getActiveFormattingMask: getActiveFormattingMask,
    getCountry: getCountry,
    guessCountryByPartialNumber: guessCountryByPartialNumber,
    parseCountry: parseCountry,
    removeDialCode: removeDialCode,
    usePhoneInput: usePhoneInput
});
