import React, {forwardRef, useRef, useState} from "react";
import styled from "@emotion/styled";
import {NavLink, useLocation, useSearchParams} from "react-router-dom";
import { rgba } from "polished";

import {Box, Chip, Collapse, ListItemButton, ListItemText, Paper, Popover} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import {mainRoutes} from "../../constants/urls";
import {useDispatch} from "react-redux";
import {resetFilters} from "../../redux/explore";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Popper from "@mui/material/Popper";
import {useSubMenu} from "./SubmenuProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ padding: '5px 10px' }}>
    <NavLink {...props} />
  </div>
));

CustomRouterLink.displayName = "CustomRouterLink";

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 6)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    border-radius: 10px;

  svg {
    color: ${(props) => props.theme.sidebar.icon.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &.${(props) => props.activeclassname} {
      background-color: ${({ theme }) => theme.sidebar.active.background};
    svg,
    span {
      color: ${(props) => props.theme.sidebar.active.color};
    }
  }
`;

const Title = styled(ListItemText)(({ theme, toggle, depth }) => ({
  margin: 0,
  span: {
    minWidth: "200px",
    color: rgba(theme.sidebar.color, depth && depth > 0 ? 0.7 : 1),
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontWeight: 500,
    padding: `0 ${theme.spacing(4)}`,
  },

  ...(!toggle && {
    display: "none",
    transition: "display 0.5s",
  }),
  ...(toggle && {
    display: "block",
    transition: "display 1s",
  }),
}));

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

function SidebarNavListItem(props) {
  const {
    title,
    href,
    depth = 0,
    children,
    icon,
    badge,
    open: openProp = false,
    toggle,
    hasChildren,
    subRoutes,
  } = props;
  const mixpanelTrack = useMixpanelTrack();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(openProp);
  const { search } = useLocation()
  const { setRoutes, title: subTitle, setTitle } = useSubMenu()

  const handleToggle = () => setOpen((state) => !state);

  const handleClick = (event) => {
    if (hasChildren) {
      event.preventDefault()
      if (subTitle === title) {
        setRoutes([])
        return;
      }
      setRoutes(subRoutes)
      setTitle(title)
      return
    }
    setRoutes([])
    mixpanelTrack(`Sidebar - navigate to: ${href}`)
  };

  if (children) {
    return (
      <>
        <Item depth={depth} onClick={handleToggle}>
          {icon && (
            <FontAwesomeIcon icon={icon} />
          )}
          <Title depth={depth}>
            {t(title)}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  }

  return (
    <Item
      id={`nav-item-${href}`}
      depth={depth}
      onClick={handleClick}
      component={CustomRouterLink}
      to={{
        pathname: href,
        search,
      }}
      activeclassname="active"
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <Title depth={depth} toggle={toggle ? 1 : 0}>
        {title}
        {badge && <Badge label={badge} />}
      </Title>
      {hasChildren && (
        <ChevronRightIcon />
      )}
    </Item>
  );
}

SidebarNavListItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  depth: PropTypes.number,
  children: PropTypes.node,
  badge: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  open: PropTypes.bool,
  toggle: PropTypes.bool.isRequired,
};

SidebarNavListItem.defaultProps = {
  depth: 0,
  children: undefined,
  badge: undefined,
  open: undefined,
};

export default SidebarNavListItem;
