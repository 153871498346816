import {useSelector} from "react-redux";
import {selectExplore} from "../../redux/explore";
import React, {useCallback} from "react";
import SimpleDialog from "../dialogs/SimpleDialog";
import ConfirmModal from "../../layouts/ConfirmModal";
import {useModal} from "mui-modal-provider";
import useIsMobile from "../../hooks/useIsMobile";

const TEXT = "This target will be set for all users and override any previous site target. Continue?"
const CLEAR_TEXT = "The target will clear for all users. Continue?"

export const useChangeTarget = (options = {}) => {
  const { filters } = useSelector(selectExplore)
  const isSiteSelected = !!filters.sites.length
  const { showModal } = useModal()
  const isMobile = useIsMobile()

  const showConfirmModal = (cb, props) => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal
        onConfirm={cb}
        onClose={() => targetModal.hide()}
        title={props.clear ? CLEAR_TEXT : TEXT}
        closeModal={() => targetModal.hide()}
      />,
    })
  }

  const confirm = useCallback((submit, modalProps = {}) => props => {
    if (options.shouldShow ? !options.shouldShow(props) : !isSiteSelected) {
      return submit(props)
    }
    showConfirmModal(() => submit(props), modalProps)
  }, [isSiteSelected])

  return { confirm }
}
