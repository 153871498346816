import React from "react";
import { saveAs } from "file-saver";
import { Button, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PropTypes from "prop-types";

import { baseAxios } from "../../../utils/axios";

export function ExportCSV({ href, params, filename, fullWidth, mixpanelExportCSV }) {

  const saveFile = async () => {
    const response = await baseAxios(href, { responseType: "blob", params });
    const url = window.URL.createObjectURL(response.data);

    saveAs(url, filename);
    mixpanelExportCSV()
  };

  const sx = {
    height: "42px",
  };

  return (
    <Button
      fullWidth={fullWidth}
      sx={sx}
      color="primary"
      variant="contained"
      onClick={saveFile}
    >
      <Typography variant="button" mr={3} sx={{ ml: "1rem" }}>
        Export
      </Typography>
      <CloudDownloadIcon sx={{ mb: "2px", mr: "1rem" }} />
    </Button>
  );
}

ExportCSV.propTypes = {
  href: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  params: PropTypes.shape({
    is_secondary: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    food: PropTypes.string,
    site: PropTypes.string,
    zone: PropTypes.string,
  }),
  mixpanelExportCSV: PropTypes.func.isRequired,
};

ExportCSV.defaultProps = {
  fullWidth: false,
  params: {},
};

export default ExportCSV;
