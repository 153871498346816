import { getLocalStorageValue, getSessionStorageValue } from "../storage";

export const getUser = () => {
  let userStr = getSessionStorageValue("user");
  if (!userStr) {
    userStr = getLocalStorageValue("user");
  }

  if (userStr && userStr !== "undefined") {
    try {
      return JSON.parse(userStr);
    } catch (error) {
      console.error(error);
    }
  }

  return null;
};
