import React from "react";
import styled from "@emotion/styled";
import { Box, ListItemText, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";

import { CustomSelectInput } from "../../../inputs/CustomSelectInput";

const StyledSelect = styled(Select)(() => ({
  fontSize: "12px",
  marginLeft: "0.5rem",
}));

function ChartSelect({ value, setValue, items, label, setLoading }) {
  const handleChange = (event) => {
    setValue(event.target.value);
    setLoading(true);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "350px",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {label}
      <StyledSelect
        value={value}
        input={<CustomSelectInput />}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {items.map((item) => (
          <MenuItem key={item.replace(" ", "_")} value={item}>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
  );
}

ChartSelect.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  setLoading: PropTypes.func,
};

ChartSelect.defaultProps = {
  setLoading: () => {},
};

export default ChartSelect;
