import { baseAxios } from "../../../utils/axios";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";

export const getStatistic = (foodId, site, date) => {
  return baseAxios(`/api/food-drilldowns/${foodId}/statistics/`, {
    params: {
      site,
      startDate: formatDataQuery(date[0]),
      endDate: formatDataQuery(date[1]),
    },
  });
};
