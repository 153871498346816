import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search topics…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
      Discard: "Discard",
      Escalate: "Escalate",
      Submit: "Submit",
      "Average Time of Label": "Average Time of Label",
      "Images Remaining to the Labeled": "Images Remaining to the Labeled",
      "Total Time to Label": "Total Time to Label",
      "Logged Time": "Logged Time",
      "Raw Weight": "Raw Weight",
      "Item Weight": "Item Weight",
      "Food Label": "Food Label",
      "Predicted Label": "Predicted Label",
      Food: "Food",
      Device: "Device",
      "Category Labelling": "Category Labelling",
      "You need to select a device": "You need to select a device",
      "You need to select a food": "You need to select a food",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bon retour",
      "We've missed you": "Tu nous as manqué",
      Discard: "Défausser",
      Escalate: "Escalade",
      Submit: "Soumettre",
      "Average Time of Label": "Temps moyen d'étiquetage",
      "Images Remaining to the Labeled": "Images restantes pour l'étiqueté",
      "Total Time to Label": "Temps total d'étiquetage",
      "Logged Time": "Heure enregistrée",
      "Raw Weight": "Poids brutt",
      "Item Weight": "Poids de l'article",
      "Food Label": "Étiquette d'aliment",
      "Predicted Label": "Libellé prédit",
      Food: "Aliments",
      Device: "Dispositif",
      "Category Labelling": "Étiquetage des catégories",
      "You need to select a device": "Vous devez sélectionner un appareil",
      "You need to select a food": "Vous devez sélectionner un aliment",
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
      "We've missed you": "Wir haben dich vermisst",
      Discard: "Verwerfen",
      Escalate: "Eskalieren",
      Submit: "Einreichen",
      "Average Time of Label": "Durchschnittliche Zeit des Etiketts",
      "Images Remaining to the Labeled":
        "Bilder, die dem Beschrifteten verbleiben",
      "Total Time to Label": "Gesamtzeit zum Etikettieren",
      "Logged Time": "Protokollierte Zeit",
      "Raw Weight": "Rohgewicht",
      "Item Weight": "Artikelgewicht",
      "Food Label": "Lebensmitteletikett",
      "Predicted Label": "Vorhergesagtes Etikett",
      Food: "Lebensmittel",
      Device: "Gerät",
      "Category Labelling": "Kategoriekennzeichnung",
      "You need to select a device": "Sie müssen ein Gerät auswählen",
      "You need to select a food": "Sie müssen ein Lebensmittel auswählen",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
