import dayjs from "dayjs";

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    // placement: "bottomStart",
  },
  {
    label: "This week",
    value: [new Date(dayjs().startOf("week")), new Date(dayjs())],
  },
  {
    label: "This Month",
    value: [new Date(dayjs().date(1)), new Date(dayjs())],
  },
  {
    label: "Yesterday",
    value: [
      new Date(dayjs().subtract(1, "day")),
      new Date(dayjs().subtract(1, "day")),
    ],
  },
  {
    label: "Last week",
    value: [
      new Date(dayjs().subtract(7, "day").startOf("week")),
      new Date(dayjs().subtract(7, "day").endOf("week")),
    ],
  },
  {
    label: "Last month",
    value: [
      new Date(dayjs().subtract(1, "month").startOf("month")),
      new Date(dayjs().subtract(1, "month").endOf("month")),
    ],
  },
  {
    label: "Last 7 days",
    value: [new Date(dayjs().subtract(6, "day")), new Date()],
  },
  {
    label: "Last 30 days",
    value: [new Date(dayjs().subtract(29, "day")), new Date()],
  },
  {
    label: "This Year",
    value: [new Date(dayjs().startOf("year")), new Date(dayjs())],
  },
];

export default predefinedRanges;
