import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, Typography } from "@mui/material";

import SignUpComponent from "../../components/auth/SignUp";
import { Wrapper } from "../../components/styled/GlobalStyled";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";

function SignUp() {
  const mixpanelTrack = useMixpanelTrack();

  mixpanelTrack("Sign Up - Page Visit");

  return (
    <Wrapper elevation={0}>
      <Box>
        <Typography mb={3} variant="large" component="h1" align="left">
          Sign Up
        </Typography>
      </Box>
      <Helmet title="Sign Up" />

      <SignUpComponent />
    </Wrapper>
  );
}

export default SignUp;
