import PropTypes from "prop-types";

import { baseAxios } from "../../../utils/axios";

const getSites = async ({ data, enqueueSnackbar }) => {
  try {
    const response = await baseAxios.get("api/sites/", { params: data });
    return response.data;
  } catch (error) {
    console.warn(error);
    enqueueSnackbar("Oops, something went wrong.", {
      variant: "error",
    });
    return [];
  }
};

getSites.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    device: PropTypes.number,
  }),
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default getSites;
