export const getLocalStorageValue = (key) => {
  return localStorage.getItem(key);
};

export const setLocalStorageValue = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeLocalStorageValue = (key) => {
  return localStorage.removeItem(key);
};

export const getSessionStorageValue = (key) => {
  return sessionStorage.getItem(key);
};

export const setSessionStorageValue = (key, value) => {
  return sessionStorage.setItem(key, value);
};

export const removeSessionStorageValue = (key) => {
  return sessionStorage.removeItem(key);
};
