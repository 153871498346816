import React, {useMemo} from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import {Box, Card, List, Paper} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SidebarNavSection from "./SidebarNavSection";

import { selectUser } from "../../redux/user";
import useAppSelector from "../../hooks/useAppSelector";

import "../../vendor/perfect-scrollbar.css";
import { checkSidebarPermitions } from "./checkSidebarPermitions";

const baseScrollbar = (props) => css`
  background-color: ${props.theme.sidebar.background};
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
  .ps__rail-x {
    display: none;
  }
`;

export const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SubMenu = styled(Paper)`
    background-color: #0a5dc2;
    height: 100%;
    position: fixed;
    top: 0;
    width: 300px;
    z-index: 10;
`

function SidebarNav({ items, toggle }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;
  const user = useAppSelector(selectUser);

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map(
              (item) =>
                checkSidebarPermitions(user, item.permissions) && (
                  <SidebarNavSection
                    component="div"
                    key={item.title}
                    pages={item.pages}
                    title={t(item.title)}
                    toggle={toggle}
                    user={user}
                  />
                )
            )}
        </Items>
      </List>
    </ScrollbarComponent>
  );
}

SidebarNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      pages: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          icon: PropTypes.object.isRequired,
          title: PropTypes.string.isRequired,
          permissions: PropTypes.arrayOf(PropTypes.string.isRequired)
            .isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  toggle: PropTypes.bool.isRequired,
};

export default SidebarNav;
