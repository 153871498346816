import React from "react";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";

const StyledPaper = styled(Paper)(() => ({
  padding: "1rem 1.5rem",
  display: "flex",
  minHeight: 165,
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.black,
}));

const Subtitle = styled(Typography)(() => ({
  color: "red",
  cursor: "pointer",
  display: "-webkit-box",
  "-webkit-line-clamp": "3 !important",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const defaultFoodImage = (event) => {
  event.target.src =
    "https://deviceimagefeed.blob.core.windows.net/foodimages/Frucht.svg";
};

function ReductionCard({ cardList, gridColumn, food }) {
  if (cardList.length) {
    return cardList.map((el) => (
      <Box gridColumn={gridColumn} key={el.key}>
        <StyledPaper key={`block-${el.key}`} elevation={3}>
          <Box sx={{ height: 40 }}>
            <img
              style={{ width: 40, marginBottom: 4 }}
              src={food.image_url}
              onError={defaultFoodImage}
              alt=""
            />
          </Box>
          <Title variant="subtitle1">{el.title}</Title>
          <Tooltip title={el.subtitle}>
            <Subtitle variant="subtitle1">{el.subtitle}</Subtitle>
          </Tooltip>
        </StyledPaper>
      </Box>
    ));
  }
  return (
    <Box gridColumn={gridColumn}>
      <StyledPaper elevation={3}>
        <Box>
          <EventNoteOutlinedIcon sx={{ margin: 4, fontSize: 26 }} />
        </Box>
        <Title variant="subtitle1">Not found any reductions</Title>
      </StyledPaper>
    </Box>
  );
}

ReductionCard.propTypes = {
  food: PropTypes.shape({
    id: PropTypes.number,
    category_name: PropTypes.string,
    image_url: PropTypes.string,
    name: PropTypes.string,
    subcategory_name: PropTypes.string,
  }),
  gridColumn: PropTypes.string.isRequired,
  cardList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ).isRequired,
};

ReductionCard.defaultProps = {
  food: {},
};

export default ReductionCard;
