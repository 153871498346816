import React from "react";
import { Box, Chip, Stack, Avatar } from "@mui/material";
import TimeAgo from "timeago-react";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { stringAvatar } from "../../utils/avatar";
import RoleChip from "../chips/RoleChip";
import OwnerChip from "../ui/OwnerChip";

function getTeamsColumns({ isMobile, dismissUser }) {
  return [
    {
      field: "first_name",
      headerName: "Name",
      sortable: true,
      flex: 3,
      valueGetter: ({ row }) => `${row.first_name} ${row.surname}`,
      renderCell: ({ value, row }) => {
        const Wrapper = isMobile ? Box : Stack;

        return (
          <Wrapper
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {!isMobile && (
              <Avatar
                id="avatar-image"
                src={row.profile_photo}
                alt={value}
                sx={{ textAlign: "center" }}
              >
                {stringAvatar(row.first_name, row.surname).children}
              </Avatar>
            )}{" "}
            <span>{value}</span> {row.is_owner && <OwnerChip />}
          </Wrapper>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      sortable: true,
      flex: 2,
      renderCell: ({ value }) => <RoleChip role={value} />,
    },
    {
      field: "last_activity",
      headerName: "Last Active",
      sortable: true,
      flex: 2,
      renderCell: ({ value }) => {
        if (value)
          return <TimeAgo datetime={value} locale={navigator.language} />;
        return null;
      },
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      flex: 1,
      maxWidth: 50,
      minWidth: 30,
      align: "right",
      headerAlign: "right",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            showInMenu
            key={`action-to-site-${id}`}
            // icon={<LeaderboardOutlinedIcon />}
            label="Remove"
            onClick={() => dismissUser(id)}
          />,
        ];
      },
    },
  ];
}

export default getTeamsColumns;
