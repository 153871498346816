import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../utils/axios";

export const fetchFoodCategories = createAsyncThunk(
  "foodCategories/fetchFoodCategories",
  async () => {
    const response = await axiosInstance("api/food-categories/", {
      method: "GET",
    });
    return response.data;
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  foodCategories: [],
};

export const foodCategoriesSlice = createSlice({
  name: "foodCategories",
  initialState,
  // reducers: {
  //   updateFoods: (state, { payload }) => state.items.push(payload),
  //   clearFoods: (state) => (state.items.length = 0),
  // },
  extraReducers: {
    [fetchFoodCategories.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchFoodCategories.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.foodCategories = [...action.payload];
    },
    [fetchFoodCategories.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

// export const { updateFoods, clearFoods } = foodsSlice.actions;

export const selectFoodCategories = (state) => state.foodCategories;

export default foodCategoriesSlice.reducer;
