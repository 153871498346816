import React from "react";
import { Box, Paper, Skeleton } from "@mui/material";
import PropTypes from "prop-types";

function ReductionCardSkeleton({ gridColumn }) {
  return (
    <Box gridColumn={gridColumn}>
      <Paper elevation={3} sx={{ padding: "15px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 5,
          }}
        >
          <Skeleton
            variant="circular"
            animation="wave"
            width={40}
            height={40}
          />
        </Box>
        <Box>
          <Skeleton animation="wave" widht={30} />
        </Box>
        <Box>
          <Skeleton variant="rounded" animation="wave" height={50} />
        </Box>
      </Paper>
    </Box>
  );
}

ReductionCardSkeleton.propTypes = {
  gridColumn: PropTypes.string.isRequired,
};

export default ReductionCardSkeleton;
