import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { selectFilters } from "../../redux/filters";
import InsightsChartList from "../sites/InsightsChartList";
import FoodChartCard from "../card/FoodChartCard";
import fetchStatisticFoods from "../../utils/events/fetchStatisticFoods";
import getFoodParams from "./getFoodParams";
import { fetchProfile, selectProfile } from "../../redux/profile";
import { selectUser } from "../../redux/user";

const weightSort = (a, b) => (b.item_weight || 0) - (a.item_weight || 0);

function orderFoodsData(data, ordering, focusFood) {
  switch (ordering) {
    case "newest":
      return data.sort((a, b) => new Date(a.last_wasted || 0) - new Date(b.last_wasted || 0));
    case "oldest":
      return data.sort((a, b) => new Date(b.last_wasted || 0) - new Date(a.last_wasted || 0));
    case "environmental":
      return data.sort((a, b) => (b.co2_captured || 0) - (a.co2_captured || 0));
    case "weight":
      const focusFoodData = [];
      const otherFoodData = [];

      data.forEach((food) => {
        if (focusFood.includes(food.id)) {
          focusFoodData.push(food);
        } else {
          otherFoodData.push(food);
        }
      });

      focusFoodData.sort(weightSort);
      otherFoodData.sort(weightSort);

      return [...focusFoodData, ...otherFoodData];
    default:
      return data.sort((a, b) => (b.value || 0) - (a.value || 0));
  }
}

function filterFoodsData(data, withSecondaryWaste) {
  if (!withSecondaryWaste) {
    return data.filter((food) => !food.name.includes("Secondary - "));
  }
  return data;
}

function FoodsChartList({ data, setData }) {
  const dispatch = useDispatch();

  const { filters, view } = useSelector(selectFilters);
  const { data: profile } = useSelector(selectProfile);
  const user = useSelector(selectUser);

  const [formattedData, setFormattedData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);

    const params = getFoodParams({
      foods: filters.food,
      zone: filters.zone,
      site: filters.site,
      date: [filters.startDate, filters.endDate],
      pageLimit: 10000,
    });

    const { results } = await fetchStatisticFoods({ params });

    setData(results);

    setIsLoading(false);
  }, [filters]);

  useEffect(() => {
    const filteredResults = filterFoodsData(data, view.withSecondaryWaste);
    const orderedData = orderFoodsData(
      filteredResults,
      view.ordering.id,
      profile.focus_food
    );
    setFormattedData(orderedData);
  }, [data]);

  useEffect(() => {
    if ((isLoading, !data.length)) return null;

    const filteredResults = filterFoodsData(data, view.withSecondaryWaste);
    const orderedData = orderFoodsData(
      filteredResults,
      view.ordering.id,
      profile.focus_food
    );
    setFormattedData(orderedData);
  }, [view.withSecondaryWaste, view.ordering, profile.focus_food]);

  useEffect(() => {
    if (user.id) dispatch(fetchProfile(user.id));
  }, [user.id]);

  return (
    <InsightsChartList
      data={formattedData}
      isLoading={isLoading}
      Card={FoodChartCard}
    />
  );
}

FoodsChartList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setData: PropTypes.func,
};

FoodsChartList.defaultProps = {
  data: [],
  setData: () => {},
};

export default FoodsChartList;
