import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SimpleDialog = ({
  title,
  content,
  action,
  maxWidth,
  fullWidth,
  handleClose,
  ...props
}) => {
  return (
    <Dialog maxWidth={maxWidth} fullWidth={fullWidth} {...props}>
      <DialogTitle>{title}</DialogTitle>
      {content}
      {action && <DialogActions sx={{ py: 4, px: 6 }}>{action}</DialogActions>}
      {handleClose && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Dialog>
  );
};

SimpleDialog.defaultProps = {
  action: null,
  maxWidth: "sm",
  fullWidth: false,
  handleClose: null,
};

SimpleDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  action: PropTypes.node,
  handleClose: PropTypes.func,
};

export default SimpleDialog;
