import dayjs from "dayjs";

export const generateGroups = (data, view) => {
  switch (view) {
    case "Hourly":
      return data.reduce((previous, current) => {
        const title = dayjs(new Date(current.x).getTime()).format("MMM DD");
        if (previous.find((el) => el.title === title)) {
          return previous.map((el) => {
            if (el.title === title) {
              return { ...el, cols: ++el.cols };
            }
            return el;
          });
        }
        return [...previous, { title, cols: 1 }];
      }, []);
    case "Weekly":
      return [];
    case "Monthly":
      return [];
    default:
      return [];
  }
};
