export const formatCoastValue = value => {
  try {
    const prefix = value?.indexOf('€')
    if ( !prefix || prefix === -1 ) {
      return value
    }
    return `€${value.replace('€', '')}`
  } catch (e) {
    return value
  }
}
