import React from "react";
import { Helmet } from "react-helmet-async";

import { Typography } from "@mui/material";

import { Wrapper } from "../../components/styled/GlobalStyled";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import FormLayout from "../../components/auth/FormLayout";

function ResetPasswordDone() {
  const mixpanelTrack = useMixpanelTrack()

  mixpanelTrack("Reset Password - Page View")

  return (
    <FormLayout title="Reset Password" headTitle="Reset Password">
      <Typography variant='small1' color="darkblue">
        We have sent you a link to reset your password
      </Typography>
    </FormLayout>
  );
}

export default ResetPasswordDone;
