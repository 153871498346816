import React, { Fragment, memo, useMemo } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Box, Divider, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  faDesktop,
  faCircleInfo,
  faSackDollar,
  faScaleBalanced,
  faDroplet,
  faEarthAmerica,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons'

import InsightPieChart from "./components/InsightPieChart";
import { selectFilters } from "../../redux/filters";
import { getPreviousDiffDates } from "../../utils/data/prevDate";
import InsightsChartFooter from "./components/InsightsChartFooter";
import InsightsChartHeader from "./components/InsightsChartHeader";
import { formatDataQuery } from "../../utils/date/formatDataQuery";
import numberFormater from "../../utils/formaters/numberFormater";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ZoneChartCard(props) {
  const navigate = useNavigate();

  const { filters, view } = useSelector(selectFilters);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const values = useMemo(() => {
    const { id } = view.display;

    switch (id) {
      case "price":
        return [
          {
            value: numberFormater({
              value: props.value,
              fixed: isMobile ? 2 : 0,
              before: "€",
            }),
            tooltip: "Value",
            icon: faSackDollar,
          },
        ];
      case "environmental":
        return [
          {
            value: numberFormater({
              value: props.environmental,
              fixed: isMobile ? 2 : 0,
              after: "KG",
            }),
            tooltip: "Environmental footprint",
            icon: faEarthAmerica,
          },
          {
            value: numberFormater({
              value: props.water,
              fixed: isMobile ? 2 : 0,
              after: "L",
            }),
            tooltip: "Water footprint",
            icon: faDroplet,
          },
        ];
      case "waste_per_cover":
        return [
          {
            value: numberFormater({
              value: props.waste_per_cover,
              fixed: isMobile ? 2 : 0,
              after: "KG",
            }),
            tooltip: "Waste Per Cover",
            icon: faTrashCan,
          },
          {
            value: numberFormater({
              value: props.baseline,
              fixed: isMobile ? 2 : 0,
              after: "%",
            }),
            tooltip: "Baseline",
            icon: faDesktop,
          },
        ];
      case 'weight':
        return [
          {
            value: numberFormater({
              value: props.weight,
              fixed: isMobile ? 2 : 0,
              after: "KG",
            }),
            tooltip: "Weight",
            icon: faScaleBalanced,
          },
        ]
      default:
        return [
          {
            value: numberFormater({ value: 0, fixed: 0 }),
            tooltip: "",
          },
          {
            value: numberFormater({ value: 0, fixed: 0 }),
            tooltip: "",
          },
        ];
    }
  }, [view.display, isMobile]);

  const handleEdit = () => {
    let path = "/overview/?";
    path += new URLSearchParams({
      siteId: props.site_id || "",
      siteName: props.site_name || "",
      zoneId: props.id || "",
      zoneName: props.name || "",
      startDate: formatDataQuery(filters.startDate),
      endDate: formatDataQuery(filters.endDate),
    });
    // mixpanelTrack("Zones - Navigate to Overview");
    navigate(path);
  };

  const prevDate = getPreviousDiffDates({ ...filters });

  const buttons = useMemo(
    () => [
      {
        name: "Info",
        icon: <FontAwesomeIcon icon={faCircleInfo} />,
        onClick: handleEdit,
      },
    ],
    []
  );

  const chartDate = useMemo(() => {
    const total =
      props.unserved_waste + props.preparation_waste + props.plate_waste;
    return [
      {
        name: "Unserved",
        value: props.unserved_waste,
        percentage: Number(((props.unserved_waste / total) * 100).toFixed(0)),
      },
      {
        name: "Preparation",
        value: props.preparation_waste,
        percentage: Number(
          ((props.preparation_waste / total) * 100).toFixed(0)
        ),
      },
      {
        name: "Consumer",
        value: props.plate_waste,
        percentage: Number(((props.plate_waste / total) * 100).toFixed(0)),
      },
    ];
  }, []);

  return (
    <Paper elevation={3} sx={{ p: "1rem 2rem" }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={4}
      >
        <InsightsChartHeader
          title={props.device_name}
          upperValue={values[0]}
          lowerValue={values[1]}
          diffValue={props.prev_weight === 0 ? 0 : props.weight / props.prev_weight * 100}
          // diffValue={props.prev_weight}
          unit="kg"
          startDate={dayjs(props.prev_start_date)}
          endDate={dayjs(props.prev_end_date)}
        />
        {!view.condense && (
          <>
            <Divider />
            <Box style={{ height: "250px" }}>
              <InsightPieChart
                data={chartDate}
                width={250}
                height={250}
                unit="kg"
              />
            </Box>
            <Divider />
            <InsightsChartFooter buttons={buttons} />
          </>
        )}
      </Stack>
    </Paper>
  );
}

ZoneChartCard.defaultProps = {
  focusAreasData: [],
};

ZoneChartCard.propTypes = {
  identifier: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ZoneChartCard;
