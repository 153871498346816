import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { baseAxios } from "../utils/axios";
import { formatDataQuery } from "../utils/date/formatDataQuery";

export const fetchFoods = createAsyncThunk(
  "foods/fetchFoods",
  async (props) => {
    const {
      only,
      fields,
      labelling,
      site,
      serial,
      zone,
      is_secondary: isSecondary,
      date,
    } = props || {};
    const params = {};

    if (labelling) params.labelling = true;
    if (fields) params.fields = fields.join(",");
    if (only) params.only = only.join(",");
    if (site) params.site = site;
    if (serial) params.serial = serial;
    if (zone) params.zone = zone;
    if (isSecondary !== undefined) params.isSecondary = isSecondary;
    if (date && date.length) {
      params.startDate = formatDataQuery(date[0]);
      params.endDate = formatDataQuery(date[1]);
    }

    const response = await baseAxios.get("api/foods/", { params });
    return response.data?.results || response.data || [];
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  foods: [],
};

export const foodsSlice = createSlice({
  name: "foods",
  initialState,
  reducers: {
    updateFoods: (state, { payload }) => {
      state.foods = [payload, ...state.foods];
    },
    clearFoods: (state) => {
      state.foods.length = 0;
    },
    resetFoods: () => initialState,
  },
  extraReducers: {
    [fetchFoods.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchFoods.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.foods = [...action.payload];
    },
    [fetchFoods.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

export const { updateFoods, clearFoods, resetFoods } = foodsSlice.actions;

export const selectFoods = (state) => state.foods;

export default foodsSlice.reducer;
