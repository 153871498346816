import React from "react";
import PropTypes from "prop-types";
import { DialogContent } from "@mui/material";

import EventImage from "../../components/ImageBlock/EventImage";

const ExpandImages = ({ event, boundingArea }) => {
  return (
    <DialogContent>
      <EventImage event={event} boundingArea={boundingArea} expand />
    </DialogContent>
  );
};

ExpandImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ExpandImages;
