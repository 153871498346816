import React from "react";
import {Stack, Typography} from "@mui/material";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import MuiGrid from "@mui/material/Grid";

import useAppSelector from "../../hooks/useAppSelector";
import { selectUser } from "../../redux/user";
import Details from "../../components/profiles/Details";
import Notifications from "../../components/profiles/Notifications";
import Sites from "../../components/profiles/Sites";
import Teams from "../../components/profiles/Teams";
import DataCircularLoader from "../../components/progress/DataCircularLoader";
import Page from "../../layouts/Page";

const Grid = styled(MuiGrid)(spacing);

function Profile() {
  const { id } = useAppSelector(selectUser);

  return (
    <Page title="Profile">
      <Stack gap={8}>
        <Typography variant="title" component="h1">Profile</Typography>
        <Grid container spacing={6}>
          {id ? (
            <>
              <Grid item xs={12} lg={4}>
                <Details />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={8}
                >
                  <Notifications />
                  <Teams />
                  <Sites />
                </Stack>
              </Grid>
            </>
          ) : (
            <DataCircularLoader />
          )}
        </Grid>
      </Stack>
    </Page>
  );
}

export default Profile;
