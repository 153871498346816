import axiosInstance from "../axios";

export const axiosUpdateUser = async ({
  id,
  profilePhoto,
  role,
  firstName,
  surname,
  email,
  phoneNumber,
  employer,
  jobTitle,
  parentGroup,
  site,
  reduceWasteReason,
  wasteManagementLevel,
  mostCommonWaste,
  wasteManagementFamiliarity,
}) => {
  const url = `api/users/${id}`;
  const data = {
    profile_photo: profilePhoto,
    role,
    first_name: firstName,
    surname,
    email,
    phone_number: phoneNumber,
    employer,
    job_title: jobTitle,
    parent_group: parentGroup,
    site,
    reduce_waste_reason: reduceWasteReason,
    waste_management_level: wasteManagementLevel,
    most_common_waste: mostCommonWaste,
    sustainable_waste_management_familiarity: wasteManagementFamiliarity,
  };

  const res = await axiosInstance.put(url, data);
  return res;
};
