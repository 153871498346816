import dayjs from "dayjs";
import { baseAxios } from "../../../utils/axios";

const createUpdateSiteInfo = async ({ id, day, cover, siteId }) => {
  let res;
  let data = {
    covers_per_day: cover,
    is_default: false,
  };
  if (id) {
    res = await baseAxios.patch(`api/daily-covers/${id}`, data);
  } else {
    data = {
      day: dayjs(day).format("YYYY-MM-DD"),
      covers_per_day: cover,
      site: siteId,
    };
    res = await baseAxios.post("api/daily-covers/", data);
  }
  return res;
};

export default createUpdateSiteInfo;

export const resetCoversToDefault = async ({ id, cover }) => baseAxios.patch(
  `api/daily-covers/${id}`, {
    covers_per_day: cover,
    is_default: true,
   }
  )
