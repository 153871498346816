import React, {useEffect, useState} from 'react';
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const LangSwitcher = () => {
  const [langs, setLangs] = useState([])
  const [anchor, setAnchor] = useState(null)

  const openMenu = (event) => setAnchor(event.currentTarget)
  const closeMenu = () => setAnchor(null)

  useEffect(() => {
    const checkWeglotInitialization = setInterval(() => {
      if (window.Weglot?.initialized) {
        setLangs(window.Weglot.getAvailableLanguages());
        clearInterval(checkWeglotInitialization);
      }
    }, 1000);

    return () => clearInterval(checkWeglotInitialization);
  }, []);

  const currentLang = window.Weglot?.getCurrentLang()
  const changeLang = lng => {
    window.Weglot?.switchTo(lng)
    closeMenu()
  }

  if (!langs.length) {
    return null
  }

  return (
    <Stack>
      <IconButton onClick={openMenu}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography color="white">{currentLang?.toUpperCase()}</Typography>
          <FontAwesomeIcon size="xs" icon={faAngleDown} color="white"/>
        </Stack>
      </IconButton>
      <Menu open={!!anchor} anchorEl={anchor} onClose={closeMenu}>
        {langs.map(lang => (
          <MenuItem key={lang} onClick={() => changeLang(lang)}>
            <Typography sx={{ textTransform: 'uppercase' }}>{lang}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default LangSwitcher;
