import {pipe} from "../../../utils/pipe";
import {defaultFoodImage} from "../../../constants/urls";

export const sortByMostWasted = data => {
  const copy = [...data]
  copy.sort((a, b) => b.value - a.value)
  return copy
}

const mapCategories = data => data.map(({ name, value, food}) => ({
  name: name,
  subName: food?.name,
  image: food?.image_url || defaultFoodImage,
  value: value
}))
export const prepareDataToDisplay = data => pipe(
  data,
  sortByMostWasted,
  mapCategories,
)
