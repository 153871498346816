import {
  headerHeight,
  minHeight,
  minRowCount,
  rowHeight,
} from "../constants/dataGrid";

const useResizeDataGrid = ({ setHeight, ref, data, isFooter = true }) => {
  const onResize = (setTableHeight, reference, currentData) => {
    if (!reference.current) {
      return setTableHeight(`${minHeight}px`);
    }

    // fixHeight, it's a minimum height which works with ref.offsetTop (fixHeight >= 10)
    const fixHeigth = 10;
    const fooderHeight = 51;

    const chatwootHeight = 100;
    const { clientHeight } = document.documentElement;
    const marginTop = reference.current.offsetTop - fixHeigth;
    const newHeight = clientHeight - marginTop - chatwootHeight;
    let dataHeight = headerHeight + rowHeight + rowHeight * currentData.length; // 53

    if (!isFooter) {
      dataHeight -= fooderHeight;
    }

    if (minHeight > dataHeight) {
      setTableHeight(`${isFooter ? minHeight : minHeight - fooderHeight}px`);
    } else if (dataHeight > newHeight) {
      if (newHeight < minHeight && currentData.length > minRowCount) {
        const height = headerHeight + rowHeight + rowHeight * minRowCount; // 53
        setTableHeight(`${height}px`);
      } else {
        setTableHeight(`${newHeight}px`);
      }
    } else {
      setTableHeight(`${dataHeight}px`);
    }
    return undefined;
  };

  onResize(setHeight, ref, data);
};

export default useResizeDataGrid;
