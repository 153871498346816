import React from "react";
import {
  Box as MuiBox,
  Tooltip,
  Typography as MuiTypography,
} from "@mui/material";
import PropTypes from "prop-types";

const Box = styled(MuiBox)`
  overflow: hidden;
`;

const Typography = styled(MuiTypography)(({ theme, isBottom }) => ({
  textOverflow: "ellipsis",

  ...(isBottom && {
    color: theme.palette.color.tableGray,
  }),

  ...(!isBottom && {
    color: theme.palette.color.tableBlack,
  }),
}));

import styled from "@emotion/styled";

function handleRenderTooltipDoubleCell(top, bottom, topTooltip, bottomTooltip) {
  return (
    <Box>
      <Tooltip title={topTooltip || top}>
        <Typography noWrap variant="subtitle1">
          {top}
        </Typography>
      </Tooltip>
      {bottom && (
        <Tooltip title={bottomTooltip || bottom}>
          <Typography noWrap isBottom variant="body1">
            {bottom}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
}

handleRenderTooltipDoubleCell.propTypes = {
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
    .isRequired,
  bottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  topTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  bottomTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};

handleRenderTooltipDoubleCell.defaultProps = {
  bottom: null,
  topTooltip: null,
  bottomTooltip: null,
};

export default handleRenderTooltipDoubleCell;
