import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import App from "./App";
import { appConfig } from "./config";
import { ThemeProvider } from "./contexts/ThemeContext";

Sentry.init({
  dsn: appConfig.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "localhost:8000",
        "https://foodsight-sandbox.azurewebsites.net",
        "https://foodsight-sandbox.azurewebsites.net",
        "https://foodsight-production.azurewebsites.net",
        "https://web.positivecarbon.com",
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (appConfig.isProduction) {
  mixpanel.init(appConfig.MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
