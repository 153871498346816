import React from 'react';
import {Stack, Typography} from "@mui/material";
import ApiToken from "../../components/dataConnector/ApiToken";
import ExportLink from "../../components/dataConnector/ExportLink";
import DataConnectorProvider from "../../components/dataConnector/DataConnectorProvider";
import Page from "../../layouts/Page";
const DataConnector = () => {
  return (
    <Page title="API connect">
      <Stack spacing={8}>
        <Typography variant="title" component="h1">Data Connector & Export</Typography>
        <DataConnectorProvider>
          <Stack spacing={6}>
            <ApiToken />
            <ExportLink />
          </Stack>
        </DataConnectorProvider>
      </Stack>
    </Page>
  );
};

export default DataConnector;
