import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import SignUpIdService from "../../services/SignUpIdService";

export const fetchAvailableSignUpId = createAsyncThunk(
  "signUpId/fetchAvailableSignUpId",
  async () => {
    try {
      const data = await SignUpIdService.getAvailable();
      return data;
    } catch (err) {
      throw err;
    }
  }
);

const initialState = {
  status: "idle",
  signUpIds: [],
  error: null,
};

export const signUpIdSlice = createSlice({
  name: "signUpId",
  initialState,
  reducers: {
    updateSignUpId: (state, { payload }) => {
      state.signUpIds = state.signUpIds.map((el) => {
        if (el.id === payload.id) {
          return [...el, ...payload];
        }
        return el;
      });
    },
    addSignUpId: (state, { payload }) => {
      state.signUpIds = [...state.signUpIds, payload];
    },
    clearSignUpId: (state) => {
      state.signUpIds.length = 0;
    },
  },
  extraReducers: {
    [fetchAvailableSignUpId.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAvailableSignUpId.fulfilled]: (state, { payload }) => {
      state.status = "succeeded";
      state.signUpIds = [...(payload || [])];
    },
    [fetchAvailableSignUpId.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});

export const { updateSignUpId, addSignUpId, clearSignUpId } =
  signUpIdSlice.actions;

export const selectSignUpId = (state) => state.signUpId;

export default signUpIdSlice.reducer;
