import React from "react";
import { Typography, Box } from "@mui/material";

function InformationBlock({ name, value }) {
  return (
    <Box sx={{ minWidth: 95, textAlign: "center" }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ mb: 0 }}>
        {value}
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        {name}
      </Typography>
    </Box>
  );
}

export default InformationBlock;
