import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectFilters } from "../../redux/filters";
import getSites from "./axios/getSites";
import InsightsChartList from "./InsightsChartList";
import SiteChartCard from "../card/SiteChartCard";

function orderSitesData(data, ordering) {
  if (ordering === "environmental") {
    return data.sort((a, b) => b.environmental - a.environmental);
  } else {
    return data.sort((a, b) => b.value - a.value);
  }
}

function SiteChartList() {
  const { filters, view } = useSelector(selectFilters);

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);

    const newData = await getSites({
      date: [filters.startDate, filters.endDate],
      ordering: filters.ordering.id,
    });
    setData(newData);

    setIsLoading(false);
  }, [filters]);

  return (
    <InsightsChartList
      data={orderSitesData(data, view.ordering.id)}
      isLoading={isLoading}
      Card={SiteChartCard}
    />
  );
}

SiteChartList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setData: PropTypes.func,
};

SiteChartList.defaultProps = {
  data: [],
  setData: () => {},
};

export default SiteChartList;
