import React from "react";
import MuiCheckIcon from "@mui/icons-material/Check";
import MuiCloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

import styled from "@emotion/styled";

const CheckIcon = styled(MuiCheckIcon)(({ theme }) => ({
  color: theme.palette.color.green,
}));

const CloseIcon = styled(MuiCloseIcon)(({ theme }) => ({
  color: theme.palette.color.red,
}));

function handleRenderCellCheck({ value }) {
  return value ? <CheckIcon /> : <CloseIcon />;
}

handleRenderCellCheck.propTypes = {
  value: PropTypes.string.isRequired,
};

export default handleRenderCellCheck;
