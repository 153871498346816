import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { withResizeDetector } from "react-resize-detector";
import { useDispatch } from "react-redux";
import {AppBar, Grid, Stack, Typography, useMediaQuery} from "@mui/material";

import { fetchSites } from "../../redux/sites";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import ZonesChartList from "../../components/zones/ZonesChartList";
import SideFilter from "../../components/filter/SideFilter";
import Page from "../../layouts/Page";
// import MapWithMarkers from "../../components/map/MapWithMarkers";

function Zones() {
  const dispatch = useDispatch();

  const mixpanelTrack = useMixpanelTrack();

  useEffect(() => {
    dispatch(fetchSites());
    mixpanelTrack("Monitors - Page View");
  }, [dispatch]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [data, setData] = useState([]);

  return (
    <Page title="Monitors">
      <Stack gap={8}>
        <Typography variant="title" component="h1">Monitors</Typography>
        <Grid container spacing={10} sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: !isMobile ? "none" : "block" }}
          >
            <SideFilter />
          </Grid>
          <Grid item xs={12} md={8}>
            <ZonesChartList data={data} setData={setData} />
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
}

Zones.propTypes = {};

Zones.defaultProps = {};

export default withResizeDetector(Zones);
