import jwt from "jwt-decode";
import TokenService from "../../services/TokenService";
import { getLocalStorageValue, getSessionStorageValue } from "../storage";

const ACCESS_TOKEN = "accessToken";

export const getUserId = () => {
  const accessToken =
    TokenService.getAccessToken() ||
    getLocalStorageValue(ACCESS_TOKEN) ||
    getSessionStorageValue(ACCESS_TOKEN);

  if (accessToken) {
    const { user_id: userId } = jwt(accessToken);
    return userId;
  }

  return null;
};
