import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { saveAs } from "file-saver";
import {filtersToExploreParams, saveAsImage } from "./helpers";
import {useExploreFilters} from "./useExploreFilters";
import MenuButton from "../components/Buttons/MenuButton";
import {baseAxios} from "../../utils/axios";
import {useSelector} from "react-redux";
import {selectExplore} from "../../redux/explore";
import {exploreDataToTableData} from "./chart/helpers";
import {useSearchParams} from "react-router-dom";
import {LABELS_LABEL, METRICS_LABEL} from "../../constants/explore";
import ExportWorker from './export.worker'
import {useLang} from "../../hooks/useLang";
import {translate} from "../../utils/lang";

const ExportButton = () => {
  const { filters } = useExploreFilters()
  const { data } = useSelector(selectExplore)
  const params = filtersToExploreParams(filters)
  const [searchParams] = useSearchParams()
  const legend = LABELS_LABEL[searchParams.get('labels')]
  const metric = METRICS_LABEL[filters.metric]
  const [exporting, setExporting] = useState(false)
  const chartData = useMemo(() => data?.chart ? exploreDataToTableData(data.chart) : [], [data])
  const [worker, setWorker] = useState()
  const { lang } = useLang()
  const saveFile = async () => {
    const response = await baseAxios('api/explorer/export_csv/', { responseType: "blob", params });
    const url = window.URL.createObjectURL(response.data);

    saveAs(url, 'report.csv');
  };

  useEffect(() => {
    const wrkr = new ExportWorker();
    wrkr.onmessage = function (event, error) {
      if (error || event.data.error) {
        setExporting(false)
        return;
      }
      saveAs(event.data, "report.pdf");
      setExporting(false)
    };

    // Save the worker instance to state
    setWorker(wrkr);

    // Clean up the worker when the component unmounts
    return () => {
      wrkr.terminate();
    };
  }, []);

  const withLoading = (cd) => (...args) => {
    setExporting(true)
    Promise.resolve(cd(...args))
      .finally(() => setExporting(false))
  }

  const savePdf = useCallback(
    async () => {
      const translationCache = localStorage.getItem('wg-translations') || {}
      const cache = await translate(['Date', 'Waste', 'changes'], lang)
      const props = {
        data: { chart: chartData, total: data?.total_waste, unit: data?.unit},
        legend,
        metric,
        filters,
        lang,
        translations: cache
      }
      setExporting(true)
      worker.postMessage(props)
    },
    [data, metric, legend, filters, chartData, lang]
  )

  const exportOptions = useMemo(() => (
      [
        {
          title: 'Download Data as CSV',
          onClick: () => withLoading(saveFile)(),
        },
        {
          title: 'Download as image',
          onClick: () => withLoading(saveAsImage)(filters),
        },
        {
          title: 'Download as pdf',
          onClick: savePdf,
        },
      ]
    ),
    [filters, savePdf, worker]
  )

  return <MenuButton color="secondary" options={exportOptions} isLoading={exporting}/>;
};

export default ExportButton;
