import { useEffect, useState } from "react";
import {pipe} from "../utils/pipe";
import {CACHE_KEY, LANG_KEY} from "../constants/lang";

export const useLang = () => {
  const [lang, setLang] = useState(localStorage.getItem(LANG_KEY) || 'en')
  const getCache = () => {
    try {
      return pipe(localStorage.getItem(CACHE_KEY) || {}, JSON.parse)
    } catch (e) {
      return {}
    }
  }
  const getLocale = locale => {
    const cache = getCache()
    return cache[locale]?.[lang] || locale
  }

  const isCached = locale => {
    const cache = getCache()
    return !!cache[locale]
  }

  useEffect(() => {
    const handleStorageChange = (e) => {
      setLang(e);
    };

    if (window.Weglot) {
      window.Weglot.on("languageChanged", handleStorageChange)
    }

    return () => {
      window?.Weglot?.off("languageChanged", handleStorageChange);
    };
  }, []);

  return {
    lang,
    getLocale,
    isCached,
  }
}
