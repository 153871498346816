import PropTypes from "prop-types";

import { PAGE_LIMIT } from "../configs/constants";

function getPageNumber({ limit, offset }) {
  return offset / limit + 1;
}

getPageNumber.propTypes = {
  limit: PropTypes.number,
  offset: PropTypes.number,
};

getPageNumber.defaultProps = {
  offset: 0,
  limit: PAGE_LIMIT,
};

export default getPageNumber;
