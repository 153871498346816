import {formatDataQuery} from "../../../utils/date/formatDataQuery";
import {baseAxios} from "../../../utils/axios";

export const getCovers = ({siteId, startDate, endDate}) => {
  const params = {
    startDate: formatDataQuery(startDate),
    endDate: formatDataQuery(endDate || startDate)
  }
  return baseAxios(`api/daily-covers/${siteId}/?startDate=${params.startDate}&endDate=${params.endDate}`)
    .then(res => res.data)
}
