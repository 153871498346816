import { Chip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import { localDateTime } from "../../../utils/date/localDateTime";
import { weightWithKG } from "../../../utils/formaters/weightWithKG";
import { COLORS } from "../../../constants/base";
import { titleCase } from "../../../utils/formaters/titleCase";
import { COLUMNS_WIDTH } from "../../../constants/dataGrid";
import handleRenderDoubleCell from "../../../utils/table/handleRenderDoubleCell";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const reviewColumns = (handleRenderCell, handlerUpdate, handlerDiscard) => {
  return [
    {
      field: "status",
      headerName: "Status",
      ...COLUMNS_WIDTH.dateTime,
      renderCell: (params) => {
        const { status } = params.row;
        const label = titleCase(status);
        let colorBg = "";
        let colorText = "";

        if (status === "SUCCESSFUL") {
          colorBg = COLORS.green;
          colorText = COLORS.white;
        } else if (status === "DISCARDED") {
          colorBg = COLORS.red;
          colorText = COLORS.white;
        } else if (status === "ESCALATED") {
          colorBg = COLORS.yellow;
          colorText = COLORS.white;
        } else {
          colorBg = COLORS.accent_blue;
          colorText = COLORS.white;
        }

        return (
          <Chip
            size="small"
            label={label}
            sx={{
              backgroundColor: colorBg,
              color: colorText,
              width: "90px",
              fontWeight: 500,
            }}
          />
        );
      },
    },
    {
      field: "food",
      headerName: "Food",
      ...COLUMNS_WIDTH.default,
      renderCell: ({ row }) => {
        return (
          <Typography
            variant="body1"
            sx={{ color: COLORS.table_gray, fontWeight: 700 }}
          >
            {row.food_name}
          </Typography>
        );
      },
    },
    {
      field: "item_weight",
      headerName: "Weight",
      ...COLUMNS_WIDTH.dateTime,
      valueFormatter: ({ value }) => weightWithKG(value),
      renderCell: handleRenderCell,
      sortComparator: (v1, v2) => v1 - v2,
    },
    {
      field: "logged_at",
      headerName: "Date",
      type: "dateTime",
      ...COLUMNS_WIDTH.dateTime,
      valueFormatter: ({ value }) => localDateTime(value),
      renderCell: ({ formattedValue }) => {
        if (!formattedValue) return "-";

        const split = formattedValue.split(", ");
        const formattedDate = `${split[0]}, ${split[1]}`;
        const time = `${split[2]}`;
        return handleRenderDoubleCell(formattedDate, time);
      },
    },
    {
      field: "site_name",
      headerName: "Site",
      ...COLUMNS_WIDTH.default,
      renderCell: handleRenderCell,
    },
    {
      field: "actions",
      type: "actions",
      ...COLUMNS_WIDTH.actionMenu,
      sortable: false,
      getActions: () => [
        <GridActionsCellItem
          key="action-update"
          icon={<FontAwesomeIcon icon={faPen} size="lg" />}
          label="Update"
          onClick={handlerUpdate}
          showInMenu
        />,
        <GridActionsCellItem
          key="action-discard"
          icon={<FontAwesomeIcon icon={faTrash} size="lg" />}
          label="Discard"
          onClick={handlerDiscard}
          showInMenu
        />,
      ],
    },
  ];
};

export default reviewColumns;
