import React from 'react';
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";

const Page = ({ children, title }) => {
  return (
    <Box sx={{ paddingTop: '40px', minHeight: '100%' }}>
      {title && <Helmet title={title} />}
      {children}
    </Box>
  );
};

export default Page;
