import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const setTimeOrDefault = (value) => {
  return dayjs(value, "HH:mm:ss").utc().format();
};

export default setTimeOrDefault;
