import { baseAxios } from "../utils/axios";

const PATH = "api/comments";

class CommentService {
  async createComment({ eventId, comment }) {
    const response = await baseAxios.post(PATH, {
      comment,
      event: eventId,
    });

    return response.data;
  }

  async updateComment({ id, eventId, comment }) {
    const commentPath = `${PATH}/${id}`;

    const response = await baseAxios.patch(commentPath, {
      comment,
      event: eventId,
    });

    return response.data;
  }

  async deleteComment({ id }) {
    const commentPath = `${PATH}/${id}`;

    const response = await baseAxios.delete(commentPath);

    return response.data;
  }

  async getComments({ id }) {
    const commentPath = `${PATH}/${id}`;

    const response = await baseAxios.get(commentPath);

    return response.data;
  }
}

export default new CommentService();
