import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { Typography } from "@mui/material";

import {Link, useParams} from "react-router-dom";
import ResetPasswordCompleteComponent from "../../components/auth/ResetPasswordComplete";

import axios from "../../utils/axios";

import { Wrapper } from "../../components/styled/GlobalStyled";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import FormLayout from "../../components/auth/FormLayout";

const passwordTokenCheck = (uid64, token) => {
  const request = axios.get(`/api/auth/password/reset/${uid64}/${token}/`);

  return request
    .then((res) => res.data)
    .catch((error) => {
      return { message: error.error, success: false };
    });
};

function ResetPasswordComplete() {
  const mixpanelTrack = useMixpanelTrack()
  const { uid64, token } = useParams();
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    passwordTokenCheck(uid64, token).then((data) => {
      setMessage(data.message);
      setSuccess(data.success);
    });
  }, []);

  mixpanelTrack("Reset Password - Page View")

  return (
    <FormLayout title="Reset Password" headTitle="Reset Password">
      {!success && (
        <Typography variant='small1' color="darkblue">
          Something went wrong.<Link to="/auth/reset-password">Click here to try again </Link>
        </Typography>
      )}

      {success && (
        <ResetPasswordCompleteComponent token={token} uid64={uid64} />
      )}
    </FormLayout>
  );
}

export default ResetPasswordComplete;
