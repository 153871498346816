import React from 'react';
import Modal from "../../../../layouts/Modal";
import {Button, Stack, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import useSWR from "swr";
import {fetcher} from "../../../../utils/axios";
import numberFormater from "../../../../utils/formaters/numberFormater";
import * as Yup from "yup";
import {setMonthlyTarget} from "../../../sites/axios/setMonthlyTarget";
import {useChangeTarget} from "../../useChangeTarget";

const TITLE = 'Set Daily Average Goal'

const formattedTarget = (value) => numberFormater({ value, fixed: 2 })


const showRecommended = (value) => {
  if(value) {
    return typeof value === 'string'
      ? (
        <Typography variant="small1">{value}</Typography>
      )
      : (
        <Typography variant="small1">Recommended Goal: {formattedTarget(value)}kg</Typography>
      )
  }
  return null
}

const allowedValueRegex = /^\d+(\.\d{0,3})?$/
const MAX_THRESHOLD = 1000000000 // 1B

const SetDailyTarget = ({ selectedSite, closeModal, target = null }) => {
  const { confirm } = useChangeTarget()
  const {values, handleChange, handleSubmit, errors, dirty, isValid} = useFormik({
    initialValues: {
      threshold: target,
    },
    onSubmit: confirm(submit),
    validationSchema: Yup.object().shape({
      threshold: Yup.number("number")
        .max(MAX_THRESHOLD, `Threshold can not be more than ${MAX_THRESHOLD}`)
        .required(`Threshold should be a number from 0.001 to ${MAX_THRESHOLD}`)
    }),
  })
  const getRecommendedKey = selectedSite ? ['api/overview/recommended_waste_target?widget=journey_to_date', { site: selectedSite }] : null
  const { data: recommended } = useSWR(getRecommendedKey, fetcher, { revalidateOnFocus: false })

  const handleThresholdChange = event => {
    const value = event.target.value
    if(!value || allowedValueRegex.test(value)) {
      handleChange(event)
    }
  }

  function submit(values) {
    return setMonthlyTarget({...values, siteId: selectedSite, level: 'daily' })
      .then(() => closeModal())
  }

  const reset = () => submit({ threshold: null })

  return (
    <Modal title={TITLE}>
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextField
            name="threshold"
            onChange={handleThresholdChange}
            value={values.threshold}
            error={errors.threshold}
            helperText={errors.threshold}
            label="Daily Average Goal"
            InputLabelProps={{ shrink: true }}
            placeholder="Target"
            type="number"
          />
          {!!target && (
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="text"
                onClick={confirm(reset, { clear: true })}
              >
                Clear
              </Button>
            </Stack>
          )}
          {!!recommended && (
            <Typography>{showRecommended(recommended.proposed_target || recommended.message)}</Typography>
          )}
        </Stack>
        <Modal.Footer>
          <Button disabled={!isValid || !dirty} type="submit" variant="contained" color="secondary" fullWidth>Set Daily Average Goal</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SetDailyTarget;
