import {appConfig} from "../config";
import {pipe} from "./pipe";
import axios from "axios";
import {CACHE_KEY} from "../constants/lang";

export const getLang = () => {
  if (window.Weglot) {
    return window.Weglot.getCurrentLang()
  }
  return 'en'
}

const getCache = () => {
  try {
    return pipe(localStorage.getItem(CACHE_KEY) || {}, JSON.parse)
  }catch (e) {
    return {}
  }
}

export const translate = async (words, to, from = 'en') => {
  const cache = getCache()
  const wordsToTranslate = words.filter(word => !cache[word])

  if (!wordsToTranslate.length || !appConfig.weglotApiKey || to === from) {
    return Promise.resolve({});
  }
  const payload = wordsToTranslate.map(word => ({
    w: word,
    t: 1,
  }))
  return axios.post(`https://api.weglot.com/translate?api_key=${appConfig.weglotApiKey}`, {
    l_from: from,
    l_to: to,
    words: payload,
    request_url: window.location.href,
  })
    .then(res => res.data)
    .then(data => {
      const mergedCache = data.from_words.reduce((acc, item, index) => {
        acc[item] = {
          [to]: data.to_words[index]
        }
        return acc
      }, cache)
      localStorage.setItem('wg-translations', JSON.stringify(mergedCache))
      return mergedCache
    })
}
