import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../utils/axios";

export const fetchUnlabeledEvents = createAsyncThunk(
  "unlabeledEvents/fetchUnlabeledEvents",
  async ({ params }) => {
    if (params && params.serial) {
      const response = await axiosInstance("api/events/", { params });
      return response.data;
    }
    return [];
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  items: [],
};

export const unlabeledEventsSlice = createSlice({
  name: "unlabeledEvents",
  initialState,
  reducers: {
    setUnlabeledEvents: (state, { payload }) => {
      state.items = [...payload];
    },
    updateUnlabeledEvents: (state, { payload }) => {
      const newItemams = state.items.map((el) => {
        return el.id === payload.id ? payload : el;
      });

      state.items = [...newItemams];
    },
    removeUnlabeledEvents: (state, { payload }) => {
      state.items = state.items.map((el) => {
        if (el.id !== payload.id) return el;
        return undefined;
      });
    },
    addUnlabeledEvents: (state, { payload }) => {
      state.items = [...state.items, payload];
    },
    clearUnlabeledEvents: (state) => {
      state.items = [];
    },
  },
  extraReducers: {
    [fetchUnlabeledEvents.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchUnlabeledEvents.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.items = [...action.payload];
    },
    [fetchUnlabeledEvents.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

export const {
  updateUnlabeledEvents,
  clearUnlabeledEvents,
  setUnlabeledEvents,
  removeUnlabeledEvents,
  addUnlabeledEvents,
} = unlabeledEventsSlice.actions;

export const selectUnlabeledEvents = (state) => state.unlabeledEvents;

export default unlabeledEventsSlice.reducer;
