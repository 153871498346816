import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { withResizeDetector } from "react-resize-detector";

import PropTypes from "prop-types";
import UserTable from "../../components/usersManagement/usersTable";
import Filters from "../../components/usersManagement/Filters";
import Actions from "../../components/usersManagement/Actions";
import withUsers from "../../components/usersManagement/withUsers";
import { shortParentGroupProps } from "../../props/parentGroupProps";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import Page from "../../layouts/Page";
import {Stack, Typography} from "@mui/material";

function Users({
  matches,
  usersPath,
  role,
  setSelectedRole,
  selectedRole,
  sites,
  selectedSite,
  setSelectedSite,
  parentGroups,
  selectedParentGroup,
  setSelectedParentGroup,
  dataUsers,
  setDataUsers,
  paginationModel,
  setPaginationModel,
  sortModel,
  setSortModel,
  dataParentGroups,
  dataSites,
  limit,
  width,
}) {
  const mixpanelTrack = useMixpanelTrack();

  const mixpanelExportCSV = () => {
    mixpanelTrack("Users - Export CSV")
  };

  const filterProps = {
    dataParentGroups,
    dataSites,
    dataUsers,
    setDataUsers,
    usersPath,
    selectedRole,
    setSelectedRole,
    role,
    selectedSite,
    setSelectedSite,
    sites,
    selectedParentGroup,
    setSelectedParentGroup,
    parentGroups,
    mixpanelExportCSV,
  };

  useEffect(() => {
    mixpanelTrack("Users - Page View")
  }, []);


  return (
    <Page title="Users">
      <Stack spacing={8}>
        <Typography variant="title" component="h1">Users</Typography>
        {matches ? <Actions {...filterProps} /> : <Filters {...filterProps} />}
        <UserTable
          selectedRole={selectedRole}
          selectedSite={selectedSite}
          selectedParentGroup={selectedParentGroup}
          dataUsers={dataUsers}
          setDataUsers={setDataUsers}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          sortModel={sortModel}
          setSortModel={setSortModel}
          dataParentGroups={dataParentGroups}
          dataSites={dataSites}
          limit={limit}
          width={width}
        />
      </Stack>
    </Page>
  );
}

Users.propTypes = {
  matches: PropTypes.bool.isRequired,
  usersPath: PropTypes.func.isRequired,
  role: PropTypes.arrayOf(PropTypes.oneOf(["Admin", "Labeller", "User"]))
    .isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  selectedRole: PropTypes.arrayOf(PropTypes.string).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSite: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedSite: PropTypes.func.isRequired,
  parentGroups: PropTypes.arrayOf(PropTypes.shape(shortParentGroupProps))
    .isRequired,
  selectedParentGroup: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedParentGroup: PropTypes.func.isRequired,
  dataUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDataUsers: PropTypes.func.isRequired,
  paginationModel: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
  setPaginationModel: PropTypes.func.isRequired,
  sortModel: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      sort: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSortModel: PropTypes.func.isRequired,
  dataParentGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataSites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  limit: PropTypes.number.isRequired,
  width: PropTypes.number,
};

Users.defaultProps = {
  width: undefined,
};

export default withUsers(withResizeDetector(Users));
