import dayjs from "dayjs";

export const localDateTime = (value) => {
  if (value) {
    const noTimeZone = value.replace(/(([+-]\d{2}:\d{2})|Z)$/, '')
    const date = dayjs(noTimeZone);
    return date.format("MMM D, YYYY, h:mm:ss A");
  }
  return undefined;
};
