import {useSelector} from "react-redux";
import {selectUser} from "../../redux/user";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {adminMenu} from "../sidebar/dashboardItems";
import CircularLoading from "../progress/CircularLoading";

const AdminGuard = ({ children }) => {
  const { role } = useSelector(selectUser)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isReady, setIsReady] = useState(false)
  const permissions = useMemo(
    () => adminMenu.find(page => pathname.startsWith(`/${page.href}`))?.permissions,
    [pathname]
  )

  useEffect(() => {
    if (role) {
      if (permissions && !permissions.includes(role)) {
        navigate('/')
      } else {
        setIsReady(true)
      }
    }
  }, [permissions, role]);

  return <CircularLoading loading={!isReady}>
    {children}
  </CircularLoading>
};

export default AdminGuard;
