import React, {useState} from 'react';
import {DialogContent, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {selectExplore} from "../../../../redux/explore";
import {baseAxios} from "../../../../utils/axios";
import {useFormik} from "formik";
import numberFormater from "../../../../utils/formaters/numberFormater";
import * as Yup from 'yup'
import {useChangeTarget} from "../../useChangeTarget";
import {COLORS} from "../../../../constants/base";

const allowedValueRegex = /^\d+(\.\d{0,3})?$/
const MAX_THRESHOLD = 1000000000 // 1B

const SetMonthlyTargetForm = ({ closeModal, recommendedTarget, target = 0 }) => {
  const { filters } = useSelector(selectExplore)
  const [isLoading, setIsLoading] = useState(false)
  const siteId = filters.sites[0]?.id
  const { confirm } = useChangeTarget()
  const {values, handleChange, handleSubmit, errors, isValid, dirty } = useFormik({
    initialValues: {
      threshold: target,
    },
    onSubmit: confirm(submit),
    validationSchema: Yup.object().shape({
      threshold: Yup.number("number")
        .max(MAX_THRESHOLD, `Threshold can not be more than ${MAX_THRESHOLD}`)
        .required(`Threshold should be a number from 0.001 to ${MAX_THRESHOLD}`)
    })
  })
  const adviceTarget = numberFormater({ value: recommendedTarget?.target, fixed: 2, after: 'kg'})

  function submit(value) {
    setIsLoading(true)
    return baseAxios.post('api/sites/set_monthly_target/', {
      threshold: value.threshold,
      site: siteId || null,
    })
      .then(() => closeModal())
      .finally(() => setIsLoading(false))
  }

  const handleThresholdChange = event => {
    const value = event.target.value
    if(!value || allowedValueRegex.test(value)) {
      handleChange(event)
    }
  }

  const reset = () => submit({ threshold: null, site: siteId || null})

  return (
    <DialogContent>
      <form onSubmit={handleSubmit}>
        <Stack spacing={6}>
          <Stack spacing={2}>
            <TextField
              name="threshold"
              onChange={handleThresholdChange}
              InputLabelProps={{ shrink: true }}
              placeholder="Threshold (kg)"
              variant="outlined"
              type="number"
              value={values.threshold}
              error={errors.threshold}
              helperText={errors.threshold}
            />
            {!!target && (
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={confirm(reset, { clear: true })}
                >
                  Clear
                </Button>
              </Stack>
            )}
            {recommendedTarget?.target && <Typography variant="small1">Recommended Target: {adviceTarget}</Typography>}
            {recommendedTarget?.message && <Typography variant="small1">{recommendedTarget.message}</Typography>}
          </Stack>
          <Button type="submit" disabled={!dirty || !isValid || isLoading} variant="contained" color="secondary">
            {isLoading ? 'Processing...' : 'Set Monthly Target'}
          </Button>
        </Stack>
      </form>
    </DialogContent>
  );
};

export default SetMonthlyTargetForm;
