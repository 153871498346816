import React from "react";
import PropTypes from "prop-types";

import {
  Cell,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { chartColors } from "../configs/constants";
import {
  tooltipNameFormatter,
  valueFormatter,
} from "../../overview/utils/formaters";
import { getDiffDays } from "../../../utils/data/prevDate";
import { useSelector } from "react-redux";
import { selectFilters } from "../../../redux/filters";
import { useTheme } from "@emotion/react";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";

const CustomTooltip = ({ payload, days, unit }) => {
  if (!payload?.length) {
    return null
  }
  const label = payload[0]?.name
  const value = payload[0]?.value
  return (
    <Paper sx={{ padding: '20px' }} key={value}>
      <Typography>
        {tooltipNameFormatter({ name: label, days })}: {valueFormatter({ value: Number(value).toFixed(2), unit})}
      </Typography>
    </Paper>
  )
}

const InsightLineChart = ({ data, width, height, unit }) => {
  const theme = useTheme();

  const { filters } = useSelector(selectFilters);

  const days = getDiffDays(filters.endDate, filters.startDate);

  const { yellow } = theme.palette.color;

  const dotStyle = {
    stroke: yellow,
    fill: yellow,
    width: "0.5rem",
  };

  const activeDotStyle = {
    ...dotStyle,
    width: "1rem",
  };

  return (
    <ResponsiveContainer>
      <LineChart
        margin={{ top: 12, right: 12, left: 12, bottom: 12 }}
        width={width}
        height={height}
        data={data}
      >
        <Tooltip
         content={<CustomTooltip days={days} unit={unit}/>}
        />
        <XAxis
          hide={false}
          dataKey="name"
          axisLine={false}
          tickLine={false}
          interval="preserveStartEnd"
          tickFormatter={(value) => tooltipNameFormatter({ name: value, days })}
        />
        <YAxis hide={true} dataKey={"value"} />
        <Line
          labelLine={false}
          dataKey="value"
          nameKey="name"
          data={data}
          dot={dotStyle}
          activeDot={activeDotStyle}
          stroke={yellow}
          strokeWidth={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index]} />
          ))}
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

InsightLineChart.defaultProps = {
  width: 250,
  height: 250,
  unit: "",
};

InsightLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  unit: PropTypes.string,
};

export default InsightLineChart;
