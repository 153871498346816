import React, {useEffect, useMemo, useState} from 'react';
import Modal from "../../layouts/Modal";
import useSWR from "swr";
import {fetcher} from "../../utils/axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Loader from "../widgets/Loader";
import {enqueueSnackbar} from "notistack";
import SelectFood from "../labelling/SelectFood";

const ConfirmModal = ({ onSubmit, close, status, site }) => {
  const url = status ? `api/food-descriptions/?status=${status}` : 'api/food-descriptions/'
  const params = {
    ...(site ? { site_id: site } : {})
  }
  const { data, isLoading, error } = useSWR([url, params], fetcher)
  const [food, setFood] = useState()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const handleSubmit = () => {
    if (food) {
      setLoading(true)
      onSubmit(food[0]?.id)
        .then(close)
        .finally(() => {
          setLoading(false)
          close()
        })
    }
  }
  const handleChange = (event) => {
    setFood(event.target.value)
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Oops, something went wrong.", {
        variant: "error",
        autoHideDuration: 1500,
      });
      close()
    }
  }, [error]);

  const options = useMemo(() => data?.map(({ id, food_with_description: label }) => ({
    name: label,
    id,
  })), [data])

  return (
    <Modal title="Relabel as">
      {isLoading && <Loader />}
      {data && (
        <SelectFood
          food={food}
          setFood={setFood}
          options={options}
          setOpen={setOpen}
          open={open}
          variant="outlined"
        />
      )}
      <Modal.Footer>
        <Button type="button" onClick={handleSubmit} disabled={!food?.length} variant="contained" color="secondary">
          {loading ? 'Submitting...' : 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
