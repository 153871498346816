import React from "react";

import PropTypes from "prop-types";

import { Box, Button } from "@mui/material";
import { COLORS } from "../../constants/base";
import useKeyPress from "../../hooks/useKeyPress";

function UserDelete({ handleDelete, handleCloseModal, deletingProcess }) {
  useKeyPress(["Enter"], handleDelete);

  return (
    <Box style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        sx={{ width: "185px", height: "44px" }}
        disabled={deletingProcess}
        type="submit"
        color="primary"
        variant="contained"
        onClick={handleCloseModal}
      >
        No
      </Button>
      <Button
        sx={{
          width: "185px",
          color: COLORS.red,
          borderColor: COLORS.red,
          height: "44px",
        }}
        disabled={deletingProcess}
        type="submit"
        variant="outlined"
        color="error"
        onClick={handleDelete}
      >
        Yes
      </Button>
    </Box>
  );
}

UserDelete.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  deletingProcess: PropTypes.bool.isRequired,
};

export default UserDelete;
