import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";
import { LargeButton } from "../Buttons/LargeButton";

function YesNoDialog({ dialogTitle, confirmAction, open, setOpen, handleCloseDialog }) {
  const handleClose = () => {
    handleCloseDialog ? handleCloseDialog() : setOpen(false);
  };

  const handleAgree = (e) => {
    confirmAction(e);
    handleClose();
  };

  useEffect(
    (e) => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          if (open) {
            handleAgree(e);
            handleCloseDialog ? handleCloseDialog() : setOpen(false);
          }
        }
      };
      document.addEventListener("keydown", keyDownHandler);
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    },
    [open]
  );

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="simple-dialog-title"
          sx={{
            padding: "1.5rem 2rem",
          }}
        >
          <Typography component="span" variant="h1">
            {dialogTitle}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            padding: "1.5rem 2rem",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <LargeButton
              data-testid="no-btn"
              onClick={handleClose}
              color="primary"
              variant="contained"
              size="large"
              autoFocus
            >
              No
            </LargeButton>
            <LargeButton
              data-testid="yes-btn"
              onClick={handleAgree}
              color="warning"
              variant="outlined"
              size="large"
            >
              Yes
            </LargeButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

YesNoDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  confirmAction: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func,
};

YesNoDialog.defaultProps = {
  handleCloseDialog: null,
};

export default YesNoDialog;
