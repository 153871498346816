import PropTypes from "prop-types";
import { shortParentGroupProps } from "./parentGroupProps";

export const shortSiteProps = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  parent_group: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape(shortParentGroupProps)),
  ]),
};

export default shortSiteProps;
