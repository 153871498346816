import React from "react";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

function ActionsButtons({ handleCreateGroup, handleCreateSite }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack
      direction="row"
      justifyContent={matches ? "space-between" : "flex-start"}
      alignItems="center"
      spacing={2}
      // sx={{ width: "100%" }}
    >
      <Button
        fullWidth
        variant="contained"
        style={{
          height: "42px",
        }}
        onClick={handleCreateGroup}
      >
        <Typography variant="button" sx={{ mx: "1rem" }}>
          Create Group
        </Typography>
      </Button>
      <Button
        fullWidth
        variant="contained"
        style={{
          height: "42px",
        }}
        onClick={handleCreateSite}
      >
        <Typography variant="button" sx={{ mx: "1rem" }}>
          Create Site
        </Typography>
      </Button>
    </Stack>
  );
}

ActionsButtons.propTypes = {
  handleCreateGroup: PropTypes.func.isRequired,
  handleCreateSite: PropTypes.func.isRequired,
};

export default ActionsButtons;
