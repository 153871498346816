import React from "react";
import { Alert as MuiAlert, Paper, Checkbox } from "@mui/material";
import { styled as muistyled } from "@mui/material/styles";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

import { COLORS } from "../../constants/base";

export const Alert = styled(MuiAlert)(spacing);

export const Wrapper = styled(Paper)(spacing);

const Icon = muistyled("span")(() => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  border: "2px solid #d5d5d5",

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
    border: "none",
  },
}));

const CheckedIcon = muistyled(Icon)({
  backgroundColor: COLORS.accent_blue,
  border: "none",

  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='16' height='16' viewBox='-2.5 0 15 8' fill='none' " +
      "xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.49995 5.85737L1.52117 3.87859L1.39995 3.75737L1.27873 " +
      "3.87859L0.578733 4.57859L0.457515 4.6998L0.578733 4.82102L3.37873 7.62102L3.49995 7.74224L3.62117 " +
      "7.62102L9.62117 1.62102L9.74239 1.4998L9.62117 1.37859L8.92117 0.678586L8.79995 0.557368L8.67873 " +
      "0.678586L3.49995 5.85737Z' fill='white' stroke='white' stroke-width='0.342857'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

export const CustomCheckbox = React.forwardRef((props, ref) => {
  return (
    <Checkbox
      ref={ref}
      sx={{ padding: 0 }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<CheckedIcon />}
      icon={<Icon />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
});

CustomCheckbox.displayName = "CustomCheckbox";
