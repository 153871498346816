import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const REFRESH_TOKEN = "refreshToken";
const ACCESS_TOKEN = "accessToken";

class TokenService {
  getCookiesInstance() {
    return new Cookies();
  }

  getCookies(key) {
    const cookies = this.getCookiesInstance();
    return cookies.get(key);
  }

  removeCookies(key) {
    const cookies = this.getCookiesInstance();
    return cookies.remove(key);
  }

  setTokenCookies(token, isRefresh = false, expires = false) {
    const cookies = this.getCookiesInstance();
    let decodedToken;
    if (!token) decodedToken = null;
    else decodedToken = jwtDecode(token);
    const key = isRefresh ? REFRESH_TOKEN : ACCESS_TOKEN;

    cookies.set(key, token, {
      path: "/",
      expires: expires && decodedToken && new Date(decodedToken.exp * 1000),
      domain: window.location.hostname,
      secure: window.location.protocol === "https:",
    });
  }

  getRefreshToken() {
    return this.getCookies(REFRESH_TOKEN);
  }

  getAccessToken() {
    return this.getCookies(ACCESS_TOKEN);
  }

  setRefreshToken(value, keepMe = false) {
    this.setTokenCookies(value, true, keepMe);
  }

  setAccessToken(value, keepMe = false) {
    this.setTokenCookies(value, false, keepMe);
  }

  removeTokensCookies() {
    this.removeCookies(REFRESH_TOKEN);
    this.removeCookies(ACCESS_TOKEN);
  }
}

export default new TokenService();
