import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";

import {
  Card as MuiCard,
  CardContent,
  Paper,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

import Chart from "react-apexcharts";

import { useSelector } from "react-redux";
import { getChartData } from "./axios/getChartData";
import { metricList } from "../../utils/data/metricList";
import { viewList } from "../../utils/data/viewList";

import { initialOptions } from "../overview/utils/initialOptions";
import {
  formaterDate,
  formaterDateLable,
  formaterValue,
} from "../overview/utils/formaters";
import { generateGroups } from "../overview/utils/generateGroups";
import { seriesName } from "./chart/seriesName";
import { selectUser } from "../../redux/user";
import ChartSelect from "../core/form/select/ChartSelect";
import {useLang} from "../../hooks/useLang";
import {translate} from "../../utils/lang";

const CardDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.dark_gray,
}));

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
  transition: width 1s;

  & .apexcharts-canvas {
    width: 100% !important;
    transition: width 1s;
  }

  & svg {
    width: 100% !important;
    transition: width 1s;
  }
`;

function DrilldownChart({ foodId, site, date }) {
  const user = useSelector(selectUser);
  const chartRef = useRef();
  const [data, setData] = useState([]);
  const [metric, setMetric] = useState(metricList()[0]);
  const [view, setView] = useState(viewList[1]);
  const [dates, setDates] = useState({ min: Date(), max: Date() });
  const {lang, getLocale, isCached} = useLang()
  const [isReady, setIsReady] = useState(false)
  useEffect(async () => {
    const response = await getChartData(foodId, site, date, metric, view);
    if (response && response.chart) {
      setData(response.chart);
      setDates({
        min: response.chart.sort()[0].x,
        max: response.chart.sort()[response.chart.length - 1].x,
      });
    } else {
      setData([]);
      setDates({ min: Date(), max: Date() });
    }
  }, [metric, view, date, site]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.ctx.updateOptions({
        ...initialOptions,
        xaxis: {
          ...initialOptions.xaxis,
          showDuplicates: true,
          min: new Date(dates.min).getTime(),
          max: new Date(dates.max).getTime(),
          categories: data.map((el) => el.x).sort(),
          labels: {
            show: true,
            formatter: (value) => formaterDateLable(value, view),
          },
          group: {
            groups: generateGroups(data, view),
          },
          tooltip: {
            enabled: true,
            formatter: (value) => formaterDate(value, view),
          },
        },
        tooltip: {
          x: {
            formatter: (value) => formaterDate(value, view),
          },
          y: {
            formatter: (value) => formaterValue(value, metric),
          },
        },
      });
    }
  }, [view, metric, data]);

  useEffect(() => {
    translate(["Total Weight", "Total CO2", "Total Value"], lang)
      .then(() => setIsReady(true))
  }, []);

  const series = useMemo(
    () => [
      {
        name: seriesName(metric, getLocale),
        data,
      },
    ],
    [data, metric, isReady]
  );

  return (
    <Box sx={{ marginBottom: "50px" }}>
      <Box mb={3}>
        <CardDescription variant="small1">Trends</CardDescription>
      </Box>
      <Paper elevation={3}>
        <MuiCard>
          <CardContent>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={4}
            >
              <ChartSelect
                label="Metric"
                value={metric}
                setValue={setMetric}
                items={metricList(user)}
              />
              <ChartSelect
                label="View"
                value={view}
                setValue={setView}
                items={viewList}
              />
            </Stack>
            <ChartWrapper>
              {!!initialOptions && (
                <Chart
                  ref={chartRef}
                  options={initialOptions}
                  series={series}
                  type="bar"
                  height="100%"
                />
              )}
            </ChartWrapper>
          </CardContent>
        </MuiCard>
      </Paper>
    </Box>
  );
}

DrilldownChart.propTypes = {
  foodId: PropTypes.string.isRequired,
  site: PropTypes.string,
  date: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};

DrilldownChart.defaultProps = {
  site: "",
};

export default DrilldownChart;
