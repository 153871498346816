import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import EventImage from "./EventImage";
import { selectFoods } from "../../../redux/foods";
import { COLORS } from "../../../constants/base";
import { localDate } from "../../../utils/date/localDate";
import { localTime } from "../../../utils/date/localTime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const eventWeight = ({ item, raw }) => {
  const itemValue = Number(item).toFixed(2);
  const rawValue = Number(raw).toFixed(2);
  return `${itemValue} kg (${rawValue} kg)`;
};

function ImageBlock({
  showMoreInfo,
  event,
  device,
  isFirst,
  isDelete,
  setSelectedIds,
  setOpen,
  handlerOpen,
  loading,
  setLoading,
  setSelectedImage,
  setImageReady,
}) {
  const { foods } = useSelector(selectFoods);
  const [boundingArea, setBoundingArea] = useState([0, 0, 1920, 1080]);
  const [foodName, setFoodName] = useState(" ");

  useEffect(() => {
    if (event) {
      if (device && Array.isArray(device.bounding_area)) {
        setBoundingArea(device.bounding_area);
      } else if (Array.isArray(event.bounding_area)) {
        setBoundingArea(event.bounding_area);
      }

      const food = foods.find((el) => el.id === event.food);
      if (food) {
        setFoodName(food.name);
      }
    }
  }, [event]);

  const showDeviceName = (currentDevice) => {
    if (device) {
      return currentDevice.site_name
        ? currentDevice.site_name
        : currentDevice.serial;
    }
    return " ";
  };

  return (
    <Paper
      elevation={1}
      style={{
        width: "100%",
        padding: "1rem",
        position: "relative",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        style={{ height: "23px" }}
      >
        {event ? (
          <Typography data-testid="show-device-name" variant="h3">
            {showDeviceName(device)}
          </Typography>
        ) : (
          <Skeleton
            data-testid="device-name-skeleton"
            variant="text"
            animation="wave"
            width="100%"
          />
        )}
        {event && isDelete && (
          <IconButton
            data-testid="delete-icon-btn"
            onClick={() => {
              handlerOpen ? handlerOpen() : setOpen(true);
              setSelectedIds([event.id]);
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </IconButton>
        )}
      </Stack>
      <Box sx={{ m: 1, position: "relative" }}>
        <EventImage
          isFirst={isFirst}
          event={event}
          boundingArea={boundingArea}
          setLoading={setLoading}
          loading={loading}
          setSelectedImage={setSelectedImage}
          setImageReady={setImageReady}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack sx={{ minHeight: "43px", width: "50%" }}>
          {event ? (
            <Typography
              data-testid="food-name"
              variant="h3"
              sx={{ minHeight: "23px" }}
            >
              {event.food_name || foodName}
            </Typography>
          ) : (
            <Skeleton
              data-testid="food-name-skeleton"
              variant="text"
              animation="wave"
              width="50%"
            />
          )}
          {event ? (
            <Typography
              data-testid="event-weight"
              variant="button"
              sx={{ color: COLORS.blue }}
            >
              {event
                ? eventWeight({
                    item: event.item_weight,
                    raw: event.raw_weight,
                  })
                : " "}
            </Typography>
          ) : (
            <Skeleton
              data-testid="event-name-skeleton"
              variant="text"
              animation="wave"
              width="50%"
            />
          )}
        </Stack>
        {event ? (
          <Typography
            data-testid="event-date"
            variant="h3"
            sx={{ color: COLORS.black, fontWeight: 400 }}
            align="right"
          >
            {event ? localDate(event.logged_at) : " "}
            <br />
            {event ? localTime(event.logged_at) : " "}
          </Typography>
        ) : (
          <Skeleton
            data-testid="event-date-skeleton"
            variant="rounded"
            animation="wave"
            height="43px"
            width="50%"
          />
        )}
      </Stack>
      {showMoreInfo && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mt={4}
          >
            <Box sx={{ minHeight: "43px", width: "50%" }}>
              {event ? (
                <Typography variant="small2" sx={{ fontWeight: 700 }}>
                  Category
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
              {event ? (
                <Typography
                  variant="h3"
                  sx={{ color: COLORS.black, fontWeight: 400 }}
                >
                  {event ? `${event.category_name}` : " "}
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {event ? (
                <Typography variant="small2" sx={{ fontWeight: 700 }}>
                  CO2 Impact
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
              {event ? (
                <Typography
                  variant="h3"
                  sx={{ color: COLORS.black, fontWeight: 400 }}
                  align="right"
                >
                  {event ? `${event.co2_impact} kg` : "0kg"}
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mt={3}
          >
            <Box sx={{ minHeight: "43px", width: "50%" }}>
              {event ? (
                <Typography variant="small2" sx={{ fontWeight: 700 }}>
                  Sub-category
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
              {event ? (
                <Typography
                  variant="h3"
                  sx={{ color: COLORS.black, fontWeight: 400 }}
                >
                  {event ? `${event.subcategory_name}` : " "}
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {event ? (
                <Typography variant="small2" sx={{ fontWeight: 700 }}>
                  Value(€)
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
              {event ? (
                <Typography
                  variant="h3"
                  sx={{ color: COLORS.black, fontWeight: 400 }}
                  align="right"
                >
                  {event ? `€${event.value}` : "0€"}
                </Typography>
              ) : (
                <Skeleton variant="text" animation="wave" width="50%" />
              )}
            </Box>
          </Stack>
        </>
      )}

      <Backdrop open={loading} sx={{ position: "absolute" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
}

ImageBlock.propTypes = {
  showMoreInfo: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bounding_area: PropTypes.arrayOf(PropTypes.number),
    food: PropTypes.number,
    item_weight: PropTypes.string,
    raw_weight: PropTypes.string,
    logged_at: PropTypes.string,
    category_name: PropTypes.string,
    co2_impact: PropTypes.number,
    subcategory_name: PropTypes.string,
    value: PropTypes.number,
  }),
  device: PropTypes.shape({
    bounding_area: PropTypes.arrayOf(PropTypes.number),
  }),
  isFirst: PropTypes.bool,
  isDelete: PropTypes.bool,
  setSelectedIds: PropTypes.func,
  setOpen: PropTypes.func,
  handlerOpen: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

ImageBlock.defaultProps = {
  showMoreInfo: false,
  isFirst: undefined,
  isDelete: undefined,
  event: null,
  device: null,
  loading: false,
  setLoading: () => {},
  setSelectedIds: () => {},
  setOpen: () => {},
  handlerOpen: null,
};

export default ImageBlock;
