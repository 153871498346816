import React from 'react';
import useAppSelector from "../../hooks/useAppSelector";
import {selectUser} from "../../redux/user";
import {Navigate, useLocation} from "react-router-dom";

const OnboardingGuard = ({ children}) => {
  const user = useAppSelector(selectUser)
  const location = useLocation();


  if(user.is_onboarded) {
    return <Navigate to="/overview" />
  }
  return children;
};

export default OnboardingGuard;
