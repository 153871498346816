import PropTypes from "prop-types";

import { PAGE_LIMIT } from "../configs/constants";

function getPageCount({ count, limit }) {
  return Math.ceil(count / limit);
}

getPageCount.propTypes = {
  count: PropTypes.number,
  limit: PropTypes.number,
};

getPageCount.defaultProps = {
  count: 0,
  limit: PAGE_LIMIT,
};

export default getPageCount;
