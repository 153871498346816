import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../utils/axios";

export const fetchSelectedEvent = createAsyncThunk(
  "selectedEvent/fetchSelectedEvent",
  async (id) => {
    const response = await axiosInstance(`api/events/${id}`);
    return response.data;
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  event: null,
};

export const selectedEventSlice = createSlice({
  name: "selectedEvent",
  initialState,
  reducers: {
    updateSelectedEvent: (state, { payload }) => state.items.push(payload),
    clearSelectedEvent: (state) => {
      state.items.length = 0;
    },
  },
  extraReducers: {
    [fetchSelectedEvent.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchSelectedEvent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.items = action.payload;
    },
    [fetchSelectedEvent.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

export const { updateSelectedEvent, clearSelectedEvent } =
  selectedEventSlice.actions;

export const selectSelectedEvent = (state) => state.selectedEvent;

export default selectedEventSlice.reducer;
