import { baseAxios } from "../axios";

const axiosDeleteUser = async ({ id, setDataUsers, enqueueSnackbar }) => {
  try {
    const response = await baseAxios.delete(`api/users/${id}`);
    setDataUsers((prevState) => prevState.filter((el) => el.id !== id));
    enqueueSnackbar("User was successfully deleted.", {
      variant: "success",
    });
    return response;
  } catch (error) {
    enqueueSnackbar("Oops, something went wrong.", {
      variant: "error",
    });
  }
  return undefined;
};

export default axiosDeleteUser;
