import React, { useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";

import { LargeButton } from "../components/Buttons/LargeButton";
import { titleCase } from "../../utils/formaters/titleCase";
import { CustomCheckbox } from "../styled/GlobalStyled";

function FormUpdate({
  foodList,
  food,
  setFood,
  statusList,
  status,
  setStatus,
  isTrimmings,
  setIsTrimmings,
  updateHandler,
}) {
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeFood = (_event, newValue) => {
    setFood(newValue);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        updateHandler();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div
      style={{
        marginBottom: "0.5rem",
      }}
    >
      <FormControl variant="standard" fullWidth>
        <InputLabel id="status-select-standard-label">Status</InputLabel>
        <Select
          labelId="status-select-standard-label"
          id="status-select-standard"
          value={status}
          onChange={handleChangeStatus}
        >
          {statusList.map((el) => (
            <MenuItem key={el} value={el}>
              {titleCase(el)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Autocomplete
        value={food}
        options={foodList}
        getOptionLabel={(option) => option.name}
        id="food-select-standard"
        onChange={handleChangeFood}
        renderInput={(params) => (
          <TextField {...params} label="Food" variant="standard" fullWidth />
        )}
        sx={{ m: "1rem 0" }}
      />
      <Box sx={{ m: "1.5rem 0" }}>
        <FormControlLabel
          control={
            <CustomCheckbox
              sx={{ padding: "0 11px" }}
              checked={isTrimmings}
              onChange={() => setIsTrimmings((prev) => !prev)}
              name="trimmings"
            />
          }
          label="Trimmings"
        />
      </Box>
      <LargeButton
        onClick={updateHandler}
        color="primary"
        variant="contained"
        size="large"
        sx={{ fontWeight: 500 }}
      >
        Update Event
      </LargeButton>
    </div>
  );
}

FormUpdate.propTypes = {
  foodList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  food: PropTypes.shape({}),
  setFood: PropTypes.func.isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  isTrimmings: PropTypes.bool.isRequired,
  setIsTrimmings: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
};

FormUpdate.defaultProps = {
  food: null,
  statusList: [],
  status: null,
};

export default FormUpdate;
