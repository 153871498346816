import { InputBase } from "@mui/material";
import styled from "@emotion/styled";

import { COLORS } from "../../constants/base";

export const CustomSelectInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    color: COLORS.blue,
  },
}));
