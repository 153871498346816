import { formatDataQuery } from "../../../utils/date/formatDataQuery";
import { baseAxios } from "../../../utils/axios";

export const getFoods = async ({
  site,
  device,
  zone,
  date,
  isSecondary,
  fields,
  only,
}) => {
  const foodParams = {};
  if (site && site.length) foodParams.site = site[0].id;
  if (device && device.length) foodParams.serial = device[0].serial;
  if (zone && zone.length) foodParams.zone = zone[0].name;
  if (date && date.length) {
    foodParams.startDate = formatDataQuery(date[0]);
    foodParams.endDate = formatDataQuery(date[1]);
  }
  if (isSecondary !== null) foodParams.is_secondary = isSecondary;
  if (fields) foodParams.fields = fields;
  if (only) foodParams.only = only;

  try {
    const newFoods = await baseAxios.get("api/foods/", {
      params: foodParams,
    });

    return newFoods.data;
  } catch (error) {
    console.warn(error);
    return [];
  }
};

export default getFoods;
