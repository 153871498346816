import {baseAxios} from "../../../utils/axios";

export const setDefaultCovers = ({ data, siteId }) => {
  const payload = {
    default: data.default,
    monday_default: data.mondayDefault,
    tuesday_default: data.tuesdayDefault,
    wednesday_default: data.wednesdayDefault,
    thursday_default: data.thursdayDefault,
    friday_default: data.fridayDefault,
    saturday_default: data.saturdayDefault,
    sunday_default: data.sundayDefault,
  }
  return baseAxios.post(`api/daily-covers/set_defaults/${siteId}/`, payload)
}
