import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {baseAxios} from "../utils/axios";

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (id) => {
    const response = await baseAxios.get(`api/profile/${id}`);
    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async ({ id, payload }) => {
    const response = await baseAxios.patch(`api/profile/${id}`, payload);
    return response.data;
  }
);

const initialState = {
  loading: false,
  error: null,
  data: {
    id: null,
    totalWaste: false,
    totalValue: false,
    categoryBreakdown: false,
    secondaryWaste: false,
    foodSubcategory: false,
    serviceBreakdown: false,
    trimmings: false,
    user: null,
    focus_food: [],
    focus_areas: [],
    pinnedWidgets: null,
    daily_report: false,
    weekly_report: false,
    monthly_report: false,
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,

  reducers: {
    setProfile: (state, { payload }) => {
      state.data = payload;
    },
    removeProfile: () => initialState,
  },
  extraReducers: {
    [fetchProfile.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.data = initialState.data;
    },
    [fetchProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },
    [fetchProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.data = initialState.data;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    }
  },
});

export const { setProfile, removeProfile } = profileSlice.actions;

export const selectProfile = (state) => state.profile;
export const selectFocusFood = (state) => state.profile.data.focus_food

export default profileSlice.reducer;
