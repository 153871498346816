import React from 'react';
import {Dialog} from "@mui/material";
import ConfirmModal from "../../feed/ConfirmModal";
import useIsMobile from "../../../hooks/useIsMobile";
import SimpleDialog from "../../dialogs/SimpleDialog";

const RelabelDialog = ({ isOpen, onSubmit, onClose, status, site }) => {
  const isMobile = useIsMobile();
  return (
    <div>
      <SimpleDialog
        open={isOpen}
        handleClose={onClose}
        fullWidth
        fullScreen={isMobile}
        maxWidth="xs"
        content={<ConfirmModal site={site} status={status} onSubmit={onSubmit} onClose={onClose} />}
      />
    </div>
  );
};

export default RelabelDialog;
