import getDeviceParams from "./getDeviceParams";
import fetchFilteredDevices from "./fetchFilteredDevices";

const getDevices = async ({
  page,
  date,
  sortModel,
  setRowCount,
  site,
  zone,
  ordering,
}) => {
  let params = {};
  params = getDeviceParams({
    page,
    date,
    sortModel,
    ordering,
  });
  if (site && site.length) params.site = site.map((el) => el.id);
  if (zone && zone.length) params.zone = zone.map((el) => el.id);

  const newDevices = await fetchFilteredDevices({ params });

  if (setRowCount) setRowCount(newDevices.count || [0]);

  return newDevices || [];
};

export default getDevices;
