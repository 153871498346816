import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, CardActions } from "@mui/material";
import CustomButton from "../components/Buttons/CustomButton";
import GroupService from "../../services/GroupService";
import { useSnackbar } from "notistack";
import {useDispatch} from "react-redux";
import {removeParentGroup} from "../../redux/parentGroups";

function FormDeleteGroup({ id, handleCancel }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  async function handleConfirm() {
    dispatch(removeParentGroup(id))
      .unwrap()
      .catch(error => enqueueSnackbar(error, { variant: "error" }))
      .finally(handleCancel)
  }

  return (
    <Card>
      <CardActions>
        <CustomButton onClick={handleCancel}>Cancel</CustomButton>
        <CustomButton onClick={handleConfirm}>Confirm</CustomButton>
      </CardActions>
    </Card>
  );
}

FormDeleteGroup.propTypes = {
  id: PropTypes.number.isRequired,
};

export default FormDeleteGroup;
