import React, { useState } from 'react';
import {Paper, Popover, Portal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";
import { useSubMenu } from "./SubmenuProvider";
import {Link, useLocation} from "react-router-dom";
import {COLORS} from "../../constants/base";

const Wrapper = styled(Paper)`
    width: 300px;
    height: 100%;
    background: ${({ theme}) => theme.sidebar.submenu.background};
`

const CustomLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.palette.color.accentBlue};
    padding: 5px 10px;
    border-radius: 10px;
    &:hover {
        background-color: ${COLORS.gray_light};
    }
`

const SubMenu = () => {
  const [ref, setRef] = useState()
  const theme = useTheme()
  const { routes, setRoutes } = useSubMenu()
  const { search } = useLocation()
  const cleanRoutes = () => setRoutes([])
  return (
    <div ref={setRef}>
        <Popover
          open={!!routes.length}
          onClose={cleanRoutes}
          anchorEl={ref}
          role={undefined}
          anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
          PaperProps={{
            style: {
              backgroundColor: theme.sidebar?.submenu?.background,
              width: '300px',
              height: '100%',
              padding: '20px 15px'
            }
          }}
          style={{
            zIndex: 0,
            marginTop: 20
          }}
          disablePortal
        >
          <Stack spacing={2} className="sublink-list">
            {routes.map(({ href, title }) => (
              <CustomLink className="sublink" onClick={cleanRoutes} key={title} to={{ pathname: href, search }}>
                {title}
              </CustomLink>
            ))}
            {/* Its fixes the issue with translation */}
            <p></p>
          </Stack>
        </Popover>
    </div>
  );
};

export default SubMenu;
