import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { localDateTime } from "../../../utils/date/localDateTime";
import { weightWithKG } from "../../../utils/formaters/weightWithKG";
import { COLORS } from "../../../constants/base";
import { floatFormatter } from "../../../utils/formaters/floatFormatter";
import handleRenderTooltipDoubleCell from "../../../utils/table/handleRenderTooltipDoubleCell";
import { COLUMNS_WIDTH } from "../../../constants/dataGrid";

const unidentifiedAndIdentifiedColumns = (
  handleRenderCellIdentified,
  handlerUpdate,
  handlerDiscard
) => {
  return [
    {
      field: "logged_at",
      headerName: "Date",
      type: "dateTime",
      ...COLUMNS_WIDTH.dateTime,
      valueFormatter: ({ value }) => localDateTime(value),
      renderCell: ({ formattedValue }) => {
        const split = formattedValue.split(", ");
        const date = `${split[0]}, ${split[1]}`;
        const time = `${split[2]}`;
        return handleRenderCellIdentified(date, time);
      },
    },
    {
      field: "site_name",
      headerName: "Site",
      ...COLUMNS_WIDTH.name,
      renderCell: ({ row, value }) => {
        const bottomValue = row.zone_name || row.kitchen_location
        return handleRenderTooltipDoubleCell(value, bottomValue, value, bottomValue)
      }
    },
    {
      field: "food_name",
      headerName: "Food",
      ...COLUMNS_WIDTH.name,
      renderCell: ({ row, value }) => {
        if (row.category_name) {
          const bottomValue = row.category_name.split(" - ").pop()
          return handleRenderTooltipDoubleCell(value, bottomValue, value, bottomValue)
        }
        return undefined;
      },
    },
    {
      field: "item_weight",
      headerName: "Amount",
      ...COLUMNS_WIDTH.default,
      valueFormatter: ({ value }) => weightWithKG(value),
      renderCell: ({ formattedValue, row }) => {
        return handleRenderCellIdentified(formattedValue, `€${row.value}`);
      },
      sortComparator: (v1, v2) => v1 - v2,
    },
    {
      field: "co2_impact",
      headerName: "Impact",
      ...COLUMNS_WIDTH.default,
      valueFormatter: ({ value }) => floatFormatter({ value, after: "kg" }),
      renderCell: ({ formattedValue, row }) => {
        const water = floatFormatter({
          value: row.water,
          fixed: 1,
          before: "💧",
          after: "L",
        });
        return handleRenderCellIdentified(formattedValue, water);
      },
    },
    {
      field: "trimmings",
      headerName: "Trims",
      type: "boolean",
      ...COLUMNS_WIDTH.min,
      renderCell: ({ value }) => {
        return value ? (
          <CheckIcon
            style={{
              color: COLORS.green,
            }}
          />
        ) : (
          <CloseIcon
            style={{
              color: COLORS.red,
            }}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      ...COLUMNS_WIDTH.actionMenu,
      sortable: false,
      getActions: () => [
        <GridActionsCellItem
          showInMenu
          key="action-update"
          icon={<EditIcon sx={{ fontSize: "24px" }} />}
          label="Update"
          onClick={handlerUpdate}
        />,
        <GridActionsCellItem
          showInMenu
          key="action-discard"
          icon={<DeleteIcon sx={{ fontSize: "24px" }} />}
          label="Discard"
          onClick={handlerDiscard}
        />,
      ],
    },
  ];
};

export default unidentifiedAndIdentifiedColumns;
