import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  Divider,
  Stack,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styled from "@emotion/styled";
import { styled as muistyled } from "@mui/material/styles";

import * as Yup from "yup";
import { Formik, FieldArray, Field, useFormikContext } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import { useSnackbar } from "notistack";
import { COLORS } from "../../constants/base";

import UserAvatar from "./userAvatar";

import { axiosCreateUser } from "../../utils/users/axiosCreateUser";
import { axiosUpdateUser } from "../../utils/users/axiosUpdateUser";
import uploadFileToBlob from "../../utils/uploadImage";
import getUserPhotoBlobName from "../../utils/users/getUserFilePath";
import { Alert } from "../styled/GlobalStyled";
import { titleCase } from "../../utils/formaters/titleCase";
import {isPhoneValid} from "../../utils/validation/isPhoneValid";
import MuiPhone from "../inputs/MuiPhone";
import { userPassword } from "../../utils/validation/password";

const PasswordTextField = styled(TextField)(() => ({
  "& .MuiFormHelperText-root": {
    whiteSpace: "pre-line",
  },
}));

function UserCreateModal({
  setDataUsers,
  dataParentGroups,
  dataSites,
  handleCloseModal,
  mixpanelSubmit,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const StyledFormControl = styled(FormControl)`
    margin: 5px 0;
  `;

  const AddSiteButton = muistyled(Button)(({ theme }) => ({
    padding: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.color.grayIcon,
  }));

  const switchColor = (role) => {
    if (role === "User") return COLORS.purple;
    if (role === "Labeller") return COLORS.green;
    if (role === "Admin") return COLORS.yellow;
    return COLORS.disabled;
  };

  const StyledSelectRole = muistyled(Select)(({ role }) => ({
    color: COLORS.white,
    margin: "10px",
    textAlignLast: "center",
    "& .MuiSelect-outlined": {
      padding: "2px",
      width: "80px",
      backgroundColor: switchColor(role),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      display: !role ? 'inline' : 'none',
    },
    "& .MuiSelect-iconOutlined": {
      color: COLORS.white,
    },
    "& .MuiTypography-root": {
      lineHeight: "22px",
    },
    "&.Mui-focused .MuiSelect-icon": {
      color: COLORS.white,
    },
  }));

  const [showPass, setShowPass] = useState(false);

  const handleClickShowPassword = () => {
    setShowPass((prevShowPass) => !prevShowPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function SiteLogic() {
    const { values } = useFormikContext();
    useEffect(() => {
      values.site = values.site.map((elem) =>
        elem !== "" ? parseInt(elem, 10) : ""
      );
    }, [values]);
    return null;
  }

  function EnterSubmit() {
    const { handleSubmit } = useFormikContext();
    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSubmit();
        }
      };
      document.addEventListener("keydown", keyDownHandler);
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, []);
    return null;
  }

  return (
    <Formik
      initialValues={{
        file: "",
        expansion: "",
        role: "User",
        firstName: "",
        surname: "",
        email: "",
        phoneNumber: "",
        employer: "",
        jobTitle: "",
        password: "",
        confirmPassword: "",
        parentGroup: "",
        site: [""],
        isActive: true,
        headOffice: false,
        submitError: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        surname: Yup.string().max(255).required("Last name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        phoneNumber: Yup.string().test('is-phone-valid', 'Phone number is invalid', isPhoneValid)
          .required("Phone number is required"),
        employer: Yup.string().max(255).required("Employer is required"),
        jobTitle: Yup.string().max(255).required("Job title is required"),
        password: userPassword,
        confirmPassword: Yup.string()
          .when("password", {
            is: (val) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ),
          })
          .required("Confirm password is required"),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        try {
          const response = await axiosCreateUser({
            role: values.role,
            firstName: values.firstName,
            surname: values.surname,
            email: values.email,
            phoneNumber: values.phoneNumber,
            employer: values.employer,
            jobTitle: values.jobTitle,
            password: values.password,
            parentGroup: values.parentGroup,
            site: values.site.filter((elem) => elem),
            isActive: values.isActive,
            headOffice: values.headOffice,
          });
          if (response.status === 201) {
            const user = response.data;
            setDataUsers((prevState) => [user, ...prevState]);
            enqueueSnackbar("User was successfully created.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Oops, something went wrong.", {
              variant: "error",
            });
          }
          if (values.file) {
            console.log(response)
            const { file } = values;
            const blobName = getUserPhotoBlobName({ file, id: response?.data?.id });
            const url = await uploadFileToBlob({
              blobName,
              file,
            });

            if (url) {
              const image = `${url}?${Date.now()}`;
              const res = await axiosUpdateUser({
                id: response?.data?.id,
                profilePhoto: image,
                firstName: values.firstName,
                surname: values.surname,
                email: values.email,
                phoneNumber: values.phoneNumber,
              });
              if (res.status === 200) {
                const usr = res.data;
                setDataUsers((prevState) =>
                  prevState.map((el) => (el.id === usr.id ? { ...usr } : el))
                );
              }
            }
          }
          handleCloseModal();
        } catch (error) {
          if (error.response.data) {
            setErrors({
              submitError: titleCase(Object.values(error.response.data)[0][0]),
            });
          } else {
            setErrors({ submitError: "Something went wrong" });
          }
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0",
              }}
            >
              <UserAvatar values={values} setFieldValue={setFieldValue} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  -
                </Typography>
                <Box>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 120 }}
                  >
                    <StyledSelectRole
                      role={values.role}
                      id="demo-simple-select-standard"
                      label="Role"
                      name="role"
                      onChange={handleChange}
                      value={values.role}
                      IconComponent={ExpandMoreIcon}
                    >
                      <MenuItem value="">
                        <Typography variant="subtitle2">
                          <em>None</em>
                        </Typography>
                      </MenuItem>
                      <MenuItem value="User">
                        <Typography variant="subtitle2">User</Typography>
                      </MenuItem>
                      <MenuItem value="Labeller">
                        <Typography variant="subtitle2">Labeller</Typography>
                      </MenuItem>
                      <MenuItem value="Admin">
                        <Typography variant="subtitle2">Admin</Typography>
                      </MenuItem>
                    </StyledSelectRole>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Divider />
            {errors.submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submitError}
              </Alert>
            )}
            <Box sx={{ padding: "0 15px" }}>
              <Box sx={{ display: "flex" }}>
                <div style={{ paddingRight: "20px" }}>
                  <TextField
                    variant="standard"
                    type="text"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <TextField
                    variant="standard"
                    type="text"
                    name="surname"
                    label="Last Name"
                    value={values.surname}
                    error={Boolean(touched.surname && errors.surname)}
                    helperText={touched.surname && errors.surname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </Box>
              <TextField
                variant="standard"
                type="email"
                name="email"
                label="Email"
                fullWidth
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <MuiPhone
                variant="standard"
                type="tel"
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                value={values.phoneNumber}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                onBlur={handleBlur}
                onChange={data => setFieldValue('phoneNumber', data)}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                spacing={4}
              >
                  <TextField
                    variant="standard"
                    type="text"
                    name="employer"
                    label="Employer"
                    value={values.employer}
                    error={Boolean(touched.employer && errors.employer)}
                    helperText={touched.employer && errors.employer}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="jobTitle">Job Title</InputLabel>
                    <Select
                      id="jobTitle"
                      name="jobTitle"
                      value={values.jobTitle}
                      error={Boolean(touched.jobTitle && errors.jobTitle)}
                      helperText={touched.jobTitle && errors.jobTitle}
                      onChange={handleChange}
                    >
                      <MenuItem value="Head Chef">Head Chef</MenuItem>
                      <MenuItem value="Sous or Junior Chef">Sous or Junior Chef</MenuItem>
                      <MenuItem value="Manager">Manager</MenuItem>
                      <MenuItem value="Sustainability Manager">Sustainability Manager</MenuItem>
                      <MenuItem value="Owner/Corporate Manager">Owner/Corporate Manager</MenuItem>
                    </Select>
                  </FormControl>
              </Stack>
              <Box sx={{ position: "relative" }}>
                <PasswordTextField
                  inputProps={{ maxLength: 42 }}
                  variant="standard"
                  type={showPass ? "text" : "password"}
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 18,
                    right: 0,
                    color: COLORS.gray_icon,
                  }}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>
              <Box sx={{ position: "relative"}}>
                <TextField
                  inputProps={{ maxLength: 42 }}
                  variant="standard"
                  type={showPass ? "text" : "password"}
                  name="confirmPassword"
                  label="Confirm password"
                  value={values.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 18,
                    right: 0,
                    color: COLORS.gray_icon,
                  }}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>
              <StyledFormControl variant="standard" fullWidth>
                <InputLabel id="parentGroup-select">Parent Group</InputLabel>
                <Select
                  labelId="parentGroup-select"
                  name="parentGroup"
                  onChange={handleChange}
                  value={values.parentGroup}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dataParentGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
              <FieldArray
                name="site"
                render={(arraySites) => (
                  <div>
                    {values.site.map((id, index) => (
                      <Stack
                        key={id}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <StyledFormControl variant="standard" fullWidth>
                          <InputLabel id="site-select">Site</InputLabel>
                          <Field as={Select} name={`site.${index}`}>
                            <MenuItem value="">None</MenuItem>
                            {dataSites.map((site) => {
                              if (values.site.includes(site.id)) {
                                if (values.site[index] === site.id) {
                                  return (
                                    <MenuItem key={site.id} value={site.id}>
                                      {site.name}
                                    </MenuItem>
                                  );
                                }
                              }
                              return (
                                <MenuItem key={site.id} value={site.id}>
                                  {site.name}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </StyledFormControl>
                        <IconButton onClick={() => arraySites.remove(index)}>
                          <ClearIcon />
                        </IconButton>
                      </Stack>
                    ))}
                    <AddSiteButton
                      startIcon={<AddCircleIcon />}
                      type="button"
                      onClick={() => {
                        arraySites.push("");
                      }}
                    >
                      Add Site
                    </AddSiteButton>
                  </div>
                )}
              />
              <SiteLogic />
              <EnterSubmit />
              <Button
                sx={{ marginTop: "10px", height: "50px" }}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={mixpanelSubmit}
              >
                Add User
              </Button>
            </Box>
          </div>
        </form>
      )}
    </Formik>
  );
}

UserCreateModal.propTypes = {
  setDataUsers: PropTypes.func.isRequired,
  dataParentGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataSites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
};

export default UserCreateModal;
