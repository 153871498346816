import React from 'react';
import {Box, Skeleton, Stack} from "@mui/material";

const WidgetSkeleton = () => {
  return (
    <Stack>
      <Box>
        <Skeleton variant="rectangular" width="100%" height={280} animation="wave" />
      </Box>
      <Stack alignItems="center">
        <Skeleton width={200} height={50} animation="wave" />
        <Skeleton width={250} height={30} animation="wave" />
      </Stack>
    </Stack>
  );
};

export default WidgetSkeleton;
