import { COLORS } from "./base";

export const paginationLimit = 50;
export const overviewPaginationLimit = 20;
export const minRowCount = 5;
export const rowHeight = 64;
export const headerHeight = 56;

export const rowBuffer = 20;

export const rowThreshold = 20;

export const minHeight = headerHeight + rowHeight + 53;

export const sortingOrder = ["asc", "desc"];

export const COLUMNS_WIDTH = {
  default: {
    flex: 1,
    minWidth: 100,
  },
  name: {
    flex: 2,
    minWidth: 150,
  },
  min: {
    flex: 1,
    minWidth: 50,
  },
  actionMenu: {
    minWidth: 40,
    flex: 1,
    align: "right",
    headerAlign: 'right',
  },
}

export const gridStyles = {
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
    backgroundColor: "#b8b8b8",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
    background: "#888",
  },
  "& .MuiDataGrid-overlay": {
    backgroundColor: "rgba(158, 158, 158, 0.1)",
    "& .MuiCircularProgress-root": {
      zIndex: 2,
    },
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: COLORS.table_gray,
    fontWeight: 500,
    fontSize: "13px",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    transition: ".1s all linear",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "transparent",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
};
