import { getSiteParams } from "../utils/getSiteParams";
import fetchFilteredSites from "./fetchFilteredSites";

const getSites = async ({ date, ordering }) => {
  const params = getSiteParams({
    date, ordering
  });

  const newSites = await fetchFilteredSites({ params });

  if (newSites) {
    return newSites;
  }

  return [];
};

export default getSites;
