import { baseAxios } from "../../../utils/axios";

// eslint-disable-next-line consistent-return
const getDevice = async (deviceId, setDevice) => {
  try {
    const response = await baseAxios.get(`api/devices/${deviceId}`);
    setDevice(response.data);
    return response;
  } catch (err) {
    console.error(err);
    setDevice(null);
  }
};

export default getDevice;
