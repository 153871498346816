import React from "react";
import { Formik } from "formik";
import { Box, Button, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import createUpdateDailyCover from "./axios/createUpdateDailyCover";
import getSite from "./axios/getSite";
import { selectFilters } from "../../redux/filters";

function FormUpdateCovers({
  dailyCovers,
  setDailyCovers,
  siteInfo,
  setSiteList,
  day,
  siteId,
  handleClose,
  mixpanelSubmit,
}) {
  const { filters } = useSelector(selectFilters);

  const { enqueueSnackbar } = useSnackbar();

  const udpateSiteValue = async (id) => {
    const currentSite = await getSite({
      id,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
    setSiteList((prevState) =>
      prevState.map((el) => (el.id === id ? currentSite.data : el))
    );
  };

  const getValue = () => {
    if (dailyCovers.length) {
      const value = dailyCovers.find(
        (el) => dayjs(el.day).get("date") === day.date()
      );
      if (value) {
        return value;
      }
    }
    return "";
  };

  const getDefaultCovers = () => {
    if (Object.keys(siteInfo).length) {
      return siteInfo.covers_per_day;
    }
    return 350;
  };

  const currentValue = getValue();
  const siteInfoCovers = getDefaultCovers();

  return (
    <Formik
      initialValues={{
        date: dayjs(day),
        coversPerDay: currentValue
          ? currentValue.covers_per_day
          : siteInfoCovers,
      }}
      onSubmit={async (values) => {
        try {
          const response = await createUpdateDailyCover({
            id: currentValue ? currentValue.id : null,
            day: values.date,
            cover: values.coversPerDay,
            siteId,
          });
          if (response.status === 200) {
            const instance = response.data;
            await udpateSiteValue(instance.site);
            setDailyCovers((prevState) =>
              prevState.map((el) =>
                el.id === instance.id ? { ...instance } : el
              )
            );
            enqueueSnackbar("Daily cover was successfully updated.", {
              variant: "success",
            });
          } else if (response.status === 201) {
            const instance = response.data;
            await udpateSiteValue(instance.site);
            setDailyCovers([...dailyCovers, { ...instance }]);
            enqueueSnackbar("Daily cover was successfully created.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Oops, something went wrong.", {
              variant: "error",
            });
          }
        } catch (e) {
          throw new Error(e);
        } finally {
          handleClose();
        }
      }}
    >
      {({
        isSubmitting,
        values,
        handleSubmit,
        setFieldValue,
        handleBlur,
        handleChange,
        touched,
        errors,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Waste Date"
                  onChange={(value) => setFieldValue("date", value)}
                  value={values.date}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      name: "date",
                      variant: "standard",
                      label: "Date",
                      error: Boolean(touched.date && errors.date),
                      helperText: touched.date && errors.date,
                      onBlur: handleBlur,
                    },
                  }}
                  format="DD/MM/YYYY"
                  components={{ OpenPickerIcon: CalendarTodayIcon }}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                name="coversPerDay"
                label="Covers Served"
                value={values.coversPerDay}
                error={Boolean(touched.coversPerDay && errors.coversPerDay)}
                helperText={touched.coversPerDay && errors.coversPerDay}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <Button
                sx={{ marginTop: "10px", height: "48px" }}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={mixpanelSubmit}
              >
                Update Covers
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}

FormUpdateCovers.propTypes = {
  dailyCovers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      day: PropTypes.string,
      covers_per_day: PropTypes.number,
    })
  ),
  setDailyCovers: PropTypes.func.isRequired,
  siteInfo: PropTypes.shape({
    covers_per_day: PropTypes.number,
  }).isRequired,
  setSiteList: PropTypes.func.isRequired,
  day: PropTypes.object.isRequired,
  siteId: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func,
};

FormUpdateCovers.defaultProps = {
  dailyCovers: [],
  siteId: null,
  mixpanelSubmit: () => {},
};

export default FormUpdateCovers;
