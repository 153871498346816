import { GridActionsCellItem } from "@mui/x-data-grid";

const useColumnsGroupManagementTable = (handleEdit, handleDelete) => {
  return [
    {
      field: "name",
      headerName: "Name",
      sorting: true,
      flex: 1,
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      sorting: true,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.contact_name}
          <br />
          {row.contact_number}
        </>
      ),
    },
    {
      field: "latitude",
      headerName: "Coordinate",
      sorting: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.latitude}
          <br />
          {row.longitude}
        </>
      ),
    },
    {
      field: "owner_name",
      headerName: "Owner",
      sorting: true,
      flex: 1,
    },
    {
      field: "signup_id_value",
      headerName: "Signup ID",
      sorting: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      hideSortIcons: true,
      sorting: false,
      width: 50,
      align: "right",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            showInMenu
            key={id}
            label="Edit"
            // onClick={() => handleEdit(row)}
            onClick={() => handleEdit(row)}
          />,
          <GridActionsCellItem
            showInMenu
            key={id}
            label="Delete"
            onClick={() => handleDelete(row)}
          />,
        ];
      },
    },
  ];
};

export default useColumnsGroupManagementTable;
