export const startDateValue = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const startDateParam = urlParams.get('startDate');

  if (startDateParam) {
    const startDate = new Date(startDateParam);
    return startDate;
  } else {
    const currentDate = new Date();
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    return date;
  }
};
