import styled from "@emotion/styled";
import {Card, Divider, Stack, Typography} from "@mui/material";
import React from "react";

const Container = styled(Card)`
    padding: 26px;
`

const DataCard = ({ title, children }) => {
  return (
    <Container>
      <Stack spacing={4}>
        <Typography variant="title" component="h1">{title}</Typography>
        <Divider />
        {children}
      </Stack>
    </Container>
  )
}

export default DataCard;
