import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Paper } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSnackbar } from "notistack";
import { faPen, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { CustomCheckbox } from "../styled/GlobalStyled";
import {
  gridStyles,
  headerHeight,
  minHeight,
  rowBuffer,
  rowHeight,
} from "../../constants/dataGrid";
import FormDialog from "../components/Dialogs/FormDialog";
import useResizeDataGrid from "../../hooks/useResizeDataGrid";
import handleRenderTooltipDoubleCell from "../../utils/table/handleRenderTooltipDoubleCell";
import AssignToGroupForm from "./AssignToGroupForm";
import AssignDeviceToSite from "./AssignDeviceToSite";
import getSites from "./axios/getSites";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import { localDateTime } from "../../utils/date/localDateTime";
import { COLUMNS_WIDTH } from "../../constants/dataGrid";
import handleRenderDoubleCell from "../../utils/table/handleRenderDoubleCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function SiteManagmentTable({
  loading,
  site,
  parentGroup,
  items,
  setItems,
  sites,
  width,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const refTable = useRef(null);
  const mixpanelTrack = useMixpanelTrack();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isAssignDevice, setIsAssignDevice] = useState(false);
  const [isAssignToGroup, setIsAssignToGroup] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [height, setHeight] = useState(`${minHeight}px`);
  const [openForm, setOpenForm] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }]);

  const assignDeviceToSiteLabel = "Assign Device to Site";
  const assignToGroupLabel = "Assign Site to Groups";
  const assignSelectedToGroupLabel = "Assign Selected Sites to Groups";

  const handleAssignToGroupClose = (value) => {
    setOpenForm(value || false);
    const name = rowSelectionModel.length > 1 ? assignSelectedToGroupLabel : assignToGroupLabel;
    mixpanelTrack(`Site Management - ${name} - Close Dialog`);
  }

  const mixpanelAssignToGroupSubmit = () => {
    const name = rowSelectionModel.length > 1 ? assignSelectedToGroupLabel : assignToGroupLabel;
    mixpanelTrack(`Site Management - ${name} - Submit`);
  }

  const assignToGroupForm = (
    <AssignToGroupForm
      selectedSite={selectedSite}
      setOpenForm={handleAssignToGroupClose}
      setItems={setItems}
      isAssignToGroup={isAssignToGroup}
      rowSelectionModel={rowSelectionModel}
      mixpanelSubmit={mixpanelAssignToGroupSubmit}
    />
  );

  const handleDeviceToSiteClose = (value) => {
    setOpenForm(value || false);
    mixpanelTrack(`Site Management - ${assignDeviceToSiteLabel} - Close Dialog`);
  }

  const mixpanelDeviceToSiteSubmit = () => {
    mixpanelTrack(`Site Management - ${assignDeviceToSiteLabel} - Submit`);
  }

  const assignDeviceToSiteForm = (
    <AssignDeviceToSite
      selectedSite={selectedSite}
      setOpenForm={handleDeviceToSiteClose}
      setItems={setItems}
      mixpanelSubmit={mixpanelDeviceToSiteSubmit}
    />
  );

  const handleAssignDeviceToSiteForm = () => {
    setIsAssignDevice(true);
    setOpenForm(true);
    mixpanelTrack(`Site Management - ${assignDeviceToSiteLabel} - Open Dialog`);
  };

  const handleAssignToGroupForm = () => {
    setIsAssignDevice(false);
    setIsAssignToGroup(false);
    setOpenForm(true);
    mixpanelTrack(`Site Management - ${assignToGroupLabel} - Open Dialog`);
  };

  const handleAssignMultipleToGroupForm = () => {
    setIsAssignDevice(false);
    setIsAssignToGroup(true);
    setOpenForm(true);
    mixpanelTrack(`Site Management - ${assignSelectedToGroupLabel} - Open Dialog`);
  };

  const handlerOnCellClick = (params) => {
    setSelectedSite(params.row);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      ...COLUMNS_WIDTH.default,
      renderCell: ({ row, formattedValue }) => {
        let groupName = "";
        let groupTooltip = "";

        if (row.parent_group && row.parent_group.length) {
          const groups = row.parent_group;
          const { name } = groups[0];
          const count = groups.length > 1 ? `+${groups.length - 1}` : "";

          groupName = name + count;
          groupTooltip = groups.reduce(
            (acc, cur) => (acc !== "" ? `${acc}, ${cur.name}` : cur.name),
            ""
          );
        }

        return handleRenderTooltipDoubleCell(
          formattedValue,
          groupName,
          formattedValue,
          groupTooltip
        );
      },
    },
    {
      field: "contact_name",
      headerName: "Contact",
      ...COLUMNS_WIDTH.default,
      renderCell: ({ row }) => {
        return handleRenderTooltipDoubleCell(
          row.contact_name,
          row.contact_number
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Data",
      ...COLUMNS_WIDTH.dateTime,
      valueFormatter: ({ value }) => localDateTime(value),
      renderCell: ({ formattedValue }) => {
        if (!formattedValue) return "-";

        const split = formattedValue.split(", ");
        const formattedDate = `${split[0]}, ${split[1]}`;
        const time = `${split[2]}`;
        return handleRenderDoubleCell(formattedDate, time);
      },
    },
    {
      field: "longitude",
      headerName: "Coordinate",
      ...COLUMNS_WIDTH.default,
      renderCell: ({ row }) => {
        return handleRenderTooltipDoubleCell(
          `Long: ${row.longitude}`,
          `Lat: ${row.latitude}`,
          `Longitude: ${row.longitude}`,
          `Latitude: ${row.latitude}`
        );
      },
    },
    {
      field: "capacity",
      headerName: "Capacity",
      ...COLUMNS_WIDTH.min,
    },

    {
      field: "go_live_date",
      headerName: "Go Live",
      type: "datetime",
      ...COLUMNS_WIDTH.dateTime,
      valueFormatter: ({ value }) => localDateTime(value),
      renderCell: ({ formattedValue }) => {
        if (!formattedValue) return "-";

        const split = formattedValue.split(", ");
        const formattedDate = `${split[0]}, ${split[1]}`;
        // const time = `${split[2]}`;
        return handleRenderTooltipDoubleCell(formattedDate, null, formattedValue );
      },
    },
    {
      field: "devices",
      headerName: "Devices",
      ...COLUMNS_WIDTH.default,
      // minWidth: 150,
      renderCell: ({ formattedValue }) => {
        if (!formattedValue || formattedValue.length === 0) return null;

        const allDevices = (formattedValue || []).reduce(
          (acc, cur) => (acc !== "" ? `${acc}, ${cur.serial}` : cur.serial),
          ""
        );
        const count =
          formattedValue.length > 1 ? `+${formattedValue.length - 1}` : "";
        const value = count
          ? `${formattedValue[0].serial} ${count}`
          : formattedValue[0].serial;
        return handleRenderTooltipDoubleCell(value, null, allDevices);
      },
    },
    {
      field: 'client_site_id',
      headerName: 'Client Site Id',
      ...COLUMNS_WIDTH.default,
      renderCell: ({ formattedValue }) => handleRenderTooltipDoubleCell(formattedValue, null, formattedValue)
    },
    {
      field: "actions",
      type: "actions",
      ...COLUMNS_WIDTH.actionMenu,
      sortable: false,
      getActions: (params) => {
        const multiple = (
          <GridActionsCellItem
            showInMenu
            key={`action-to-group-label-${params.row.id}`}
            icon={<EditIcon sx={{ fontSize: "24px" }} />}
            label={assignSelectedToGroupLabel}
            onClick={handleAssignMultipleToGroupForm}
          />
        );
        const actionList = [
          <GridActionsCellItem
            showInMenu
            key={`action-to-group-${params.row.id}`}
            icon={<FontAwesomeIcon icon={faPen} size="lg" />}
            label={assignToGroupLabel}
            onClick={handleAssignToGroupForm}
          />,
          <GridActionsCellItem
            showInMenu
            key={`action-to-site-${params.row.id}`}
            icon={<FontAwesomeIcon icon={faCircleInfo} size="lg" />}
            label={assignDeviceToSiteLabel}
            onClick={handleAssignDeviceToSiteForm}
          />,
        ];

        if (rowSelectionModel.length > 1) {
          return [multiple, ...actionList];
        }
        return actionList;
      },
    },
  ];

  useEffect(() => {
    useResizeDataGrid({ setHeight, ref: refTable, data: sites || [] });
  }, [sites]);

  useEffect(async () => {
    setLoadingData(true);
    const data = {};

    if (parentGroup.length > 0) {
      data.parentGroup = parentGroup.map((g) => g.id);
    }

    const responce = await getSites({ data, enqueueSnackbar });
    setItems(responce);

    setLoadingData(false);
  }, [site, parentGroup]);

  return (
    <Paper
      elevation={3}
      style={{
        width: width || "100%",
        height,
      }}
      sx={{ mt: "2rem" }}
    >
      <DataGrid
        hideFooterPagination
        hideFooterSelectedRowCount
        hideFooter
        disableColumnMenu
        checkboxSelection
        disableRowSelectionOnClick
        sortingOrder={["desc", "asc"]}
        ref={refTable}
        rowBuffer={rowBuffer}
        rows={items}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        loading={loading || loadingData}
        columns={columns}
        sortModel={sortModel}
        onCellClick={handlerOnCellClick}
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel);
        }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        components={{ BaseCheckbox: CustomCheckbox }}
        sx={gridStyles}
      />
      <FormDialog
        dialogTitle={
          isAssignDevice ? assignDeviceToSiteLabel : assignToGroupLabel
        }
        confirmAction={null}
        openForm={openForm}
        setOpenForm={setOpenForm}
        handleCloseModel={isAssignDevice ? handleDeviceToSiteClose : handleAssignToGroupClose}
        form={isAssignDevice ? assignDeviceToSiteForm : assignToGroupForm}
      />
    </Paper>
  );
}

SiteManagmentTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  site: PropTypes.arrayOf(PropTypes.object),
  parentGroup: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object),
  setItems: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number,
};

SiteManagmentTable.defaultProps = {
  site: [],
  items: [],
  parentGroup: [],
  width: undefined,
};

export default SiteManagmentTable;
