import axiosInstance from "../../../utils/axios";

const getSiteInfo = async (siteId) => {
  if (!siteId) {
    return { data: {}}
  }
  const res = await axiosInstance.get(`api/site-info/${siteId}`);
  return res;
};

export default getSiteInfo;
