import * as React from "react";
import { Box, InputLabel } from "@mui/material";
import { withResizeDetector } from "react-resize-detector";
import PropTypes from "prop-types";
import { NO_IMAGE } from "../../constants/urls";

function ImageUploadButton({ url, setFieldValue }) {
  return (
    <Box sx={{ textAlign: "center", height: 240 }}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        name="photo"
        onChange={(value) => {
          const imageFile = value.currentTarget.files[0];
          setFieldValue("file", imageFile);
          setFieldValue("photo", URL.createObjectURL(imageFile));
        }}
      />
      <InputLabel htmlFor="raised-button-file">
        <Box
          component="img"
          src={url || NO_IMAGE}
          alt="Selected"
          sx={{
            height: 240,
            cursor: "pointer",
            display: "inline-Block",
          }}
        />
      </InputLabel>
    </Box>
  );
}

ImageUploadButton.propTypes = {
  url: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

ImageUploadButton.defaultProps = {
  url: null,
};

export default withResizeDetector(ImageUploadButton);
