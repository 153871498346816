import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "../styled/GlobalStyled";
import { fetchUsers, selectUsers } from "../../redux/users";
import {
  fetchAvailableSignUpId,
  selectSignUpId,
} from "../../redux/reducers/signUpId";

function FormEditGroup({ data, handlerClose, handlerConfirm }) {
  const dispatch = useDispatch();

  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedSignUpId, setSelectedSignUpId] = useState("");

  const { users, isLoading: loadingUsers } = useSelector(selectUsers);
  const { signUpIds, status } = useSelector(selectSignUpId);

  useEffect(() => {
    if (!loadingUsers) {
      const owner = users.find((user) => user.id === data.owner_id);
      if (owner) setSelectedOwner(owner);
    }

    if (status === "succeeded") {
      const signUpId = signUpIds.find((el) => el.id === data.signup_id_id);
      if (signUpId) setSelectedSignUpId(signUpId.id);
    }
  }, [loadingUsers, status]);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchAvailableSignUpId());
  }, []);

  const initialValues = {
    id: data.id,
    name: data.name,
    contactName: data.contact_name,
    contactNumber: data.contact_number,
    longitude: data.longitude,
    latitude: data.latitude,
    ownerId: data.owner_id,
    signUpId: data.signup_id_id,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(1).max(255).required("Name is required"),
    contactName: Yup.string()
      .min(1)
      .max(255)
      .required("Contact Name is required"),
    contactNumber: Yup.number().required("Contact Number is required"),
    longitude: Yup.number().required("Longitude is required"),
    latitude: Yup.number().required("Latitude is required"),
    ownerId: Yup.number().nullable(),
    signUpId: Yup.number().nullable(),
  });

  return (
    <Box sx={{ p: 4 }}>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const data = {
            ...values,
            contact_name: values.contactName,
            contact_number: values.contactNumber,
            owner_id: selectedOwner ? selectedOwner.id : null,
            signup_id_id: selectedSignUpId ? selectedSignUpId : null,
          };
          await handlerConfirm(data);
          handlerClose();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              {touched.submit && errors.submit && (
                <Alert mt={2} mb={3} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                fullWidth
                variant="standard"
                type="text"
                name="name"
                label="Name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                my={4}
              />
              <TextField
                fullWidth
                variant="standard"
                type="text"
                name="contactName"
                label="Contact Name"
                value={values.contactName}
                error={Boolean(touched.contactName && errors.contactName)}
                helperText={touched.contactName && errors.contactName}
                onBlur={handleBlur}
                onChange={handleChange}
                my={4}
              />
              <TextField
                fullWidth
                variant="standard"
                type="number"
                name="contactNumber"
                label="Contact Number"
                value={values.contactNumber}
                error={Boolean(touched.contactNumber && errors.contactNumber)}
                helperText={touched.contactNumber && errors.contactNumber}
                onBlur={handleBlur}
                onChange={handleChange}
                my={4}
              />
              <TextField
                fullWidth
                variant="standard"
                type="number"
                name="longitude"
                label="Longitude"
                value={values.longitude}
                error={Boolean(touched.longitude && errors.longitude)}
                helperText={touched.longitude && errors.longitude}
                onBlur={handleBlur}
                onChange={handleChange}
                my={4}
              />
              <TextField
                fullWidth
                variant="standard"
                type="number"
                name="latitude"
                label="Latitude"
                value={values.latitude}
                error={Boolean(touched.latitude && errors.latitude)}
                helperText={touched.latitude && errors.latitude}
                onBlur={handleBlur}
                onChange={handleChange}
                my={4}
              />

              <Autocomplete
                value={selectedOwner}
                onChange={(event, newValue) => {
                  setSelectedOwner(newValue);
                }}
                options={users}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.surname}`
                }
                renderInput={(params) => (
                  <TextField {...params} label="Owner" variant="standard" />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <div>
                        <span> {`${option.first_name} ${option.surname}`}</span>
                        <br />
                        {option.email}
                      </div>
                    </li>
                  );
                }}
              />

              <FormControl fullWidth variant="standard" error={errors.signUpId}>
                <InputLabel id="sightUpId-label">SignUp ID</InputLabel>
                <Select
                  labelId="sightUpId-label"
                  variant="standard"
                  value={selectedSignUpId}
                  onChange={(e) => {
                    setSelectedSignUpId(e.target.value);
                  }}
                  label="SignUp ID"
                >
                  <MenuItem value="">None</MenuItem>
                  {signUpIds.map((el) => (
                    <MenuItem value={el.id} key={el.id}>
                      <em>{el.signup_id}</em>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.signUpId}</FormHelperText>
              </FormControl>

              <Button
                fullWidth
                sx={{ height: "50px", my: 4 }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {data.id ? "Update Group" : "Create Group" }
              </Button>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}

FormEditGroup.propTypes = {
  data: PropTypes.objectOf({
    id: PropTypes.number,
    name: PropTypes.string,
    contact_name: PropTypes.string,
    contact_number: PropTypes.number,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    owner_id: PropTypes.number,
    signup_id_id: PropTypes.number,
  }),
};

FormEditGroup.defaultProps = {
  data: {
    id: null,
    name: "",
    contact_name: "",
    contact_number: "",
    longitude: "",
    latitude: "",
    owner_id: null,
    signup_id_id: null,
  },
};

export default FormEditGroup;
