import React from 'react';
import {FormControlLabel, FormLabel, Radio, RadioGroup, Stack} from "@mui/material";

const MultiLayout = ({ question, name, value, onChange}) => {
  return (
    <Stack>
      <FormLabel id={name}>{question.question}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        onChange={onChange}
        value={value}
      >
        {question.answers.map((answer, index) => (
          <FormControlLabel
            key={`${question.type}-${index}`}
            value={typeof answer === "string" ? answer : answer.value}
            control={<Radio />}
            label={typeof answer === "string" ? answer : answer.label}
            name={name}
            sx={{ padding: '10px 0'}}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
};

export default MultiLayout;
