import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
} from "@mui/material";
import PropTypes from "prop-types";
import BlueButton from "../components/Buttons/BlueButton";

function InviteUserForm({ onSubmit }) {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            variant="standard"
            type="email"
            name="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ mb: 4, mr: 4, mt: -4 }}>
        <BlueButton name="Invite" onClick={handleSubmit} />
      </DialogActions>
    </form>
  );
}

InviteUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default InviteUserForm;
