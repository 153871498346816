import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { withResizeDetector } from "react-resize-detector";

import YesNoDialog from "../../components/components/Dialogs/YesNoDialog";
import FormDialog from "../../components/components/Dialogs/FormDialog";
import FormUpdate from "../../components/review/FormUpdate";
import PageFilters from "../../components/components/pageFilter/PageFilters";
import usePageStates from "../../components/review/hooks/usePageStates";
import DataBlock from "../../components/review/DataBlock";
import getReviewEvents from "../../components/review/axios/getReviewEvents";
import useGetHandles from "../../components/review/hooks/useGetHandles";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import Page from "../../layouts/Page";
import {Box, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import {localDateTime} from "../../utils/date/localDateTime";

function CategorisedWaste({ width }) {
  const isSecondary = null;
  // const isReview = true;

  const states = usePageStates({ isSecondary });

  const handles = useGetHandles({ states });

  const mixpanelTrack = useMixpanelTrack();

  const updateEventForm = (
    <FormUpdate
      foodList={states.eventFoods}
      food={states.formFood}
      setFood={states.setFormFood}
      statusList={states.statusItems}
      status={states.formStatus}
      setStatus={states.setFormStatus}
      isTrimmings={states.isTrimmings}
      setIsTrimmings={states.setIsTrimmings}
      updateHandler={handles.updateHandler}
    />
  );

  useEffect(() => {
    mixpanelTrack("Review - Page View");
  }, []);

  return (
    <Page title="Review">
      <Stack spacing={8}>
        <Typography variant="title" component="h1">Review</Typography>
        <PageFilters
          isLoading={states.loading}
          setIsLoading={states.setLoading}
        />
        <Box>
          <DataBlock
            isReview
            handles={handles}
            states={states}
            width={width}
            getEvents={getReviewEvents}
          />
          <YesNoDialog
            dialogTitle="Are you sure want to Discard this event"
            confirmAction={handles.discardHandler}
            open={states.open}
            setOpen={states.setOpen}
          />
          <FormDialog
            dialogTitle="Update Event"
            openForm={states.openForm}
            setOpenForm={states.setOpenForm}
            form={updateEventForm}
          />
        </Box>
      </Stack>
    </Page>
  );
}

CategorisedWaste.propTypes = {
  width: PropTypes.number,
};

CategorisedWaste.defaultProps = {
  width: 100,
};

export default withResizeDetector(CategorisedWaste);
