import React, { useRef } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/user";

import { Avatar } from "@mui/material";
import {useResizeDetector} from "react-resize-detector";

function FeedUserInfoCard() {
  const user = useSelector(selectUser);
  const containerRef = useRef(null)
  const { width } = useResizeDetector({ targetRef: containerRef })

  return (
    <Card sx={{ textAlign: "center" }}>
      <CardContent ref={containerRef}>
        <Avatar
          sx={{ width, height: width, margin: "auto" }}
          alt={`${user.first_name} ${user.surname}`}
          src={user.profile_photo}
        />
      </CardContent>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {user.first_name} {user.surname}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {user.employer}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default FeedUserInfoCard;
