import useAppSelector from "../../hooks/useAppSelector";
import {selectExplore} from "../../redux/explore";
import { useSearchParams} from "react-router-dom";
import {pipe} from "../../utils/pipe";
import {useDispatch} from "react-redux";
import { updateFilters as updateStateFilters } from "../../redux/explore";
import { updateFilters as updateSideFilter } from "../../redux/filters";
import {filterStateFood, propsToParams, excludeParams, getCorrectGranFilters} from "./storeMappers";
import {fetchFoods, selectFoods} from "../../redux/foods";

const updateFilters = ({filters, setParams, updateFood}) => async (dispatch, getState) => {
  const payload = excludeParams(filters)

  if(!updateFood || !payload.food.length) {
    const { foods } = selectFoods(getState())
    const filtersToUpdate = pipe(payload, filterStateFood(foods), getCorrectGranFilters)
    dispatch(updateStateFilters(filtersToUpdate))
    dispatch(updateSideFilter({
      site: filtersToUpdate.sites,
      food: filtersToUpdate.food,
      startDate: new Date(filtersToUpdate.dateRange[0]),
      endDate: new Date(filtersToUpdate.dateRange[1]),
      zone: filtersToUpdate.zones,
    }))
    setParams(pipe(filtersToUpdate, propsToParams))
    return
  }
  await dispatch(fetchFoods({ date: payload.dateRange }))
  const { foods } = selectFoods(getState())
  const filtersToSave = pipe(payload, filterStateFood(foods), getCorrectGranFilters)
  dispatch(updateStateFilters(filtersToSave))
  dispatch(updateSideFilter({
    site: filtersToSave.sites,
    food: filtersToSave.food,
    startDate: new Date(filtersToSave.dateRange[0]),
    endDate: new Date(filtersToSave.dateRange[1]),
  }))
  setParams(pipe(filtersToSave, propsToParams))
}


export const useExploreFilters = () => {
  const { filters } = useAppSelector(selectExplore)
  const [_, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  const handleUpdateFilters = (value, updateFood) => {
    dispatch(updateFilters({
      filters: {...filters, ...value},
      setParams: setSearchParams,
      updateFood,
    }))
  }

  return {
    filters,
    updateFilters: handleUpdateFilters,
  }

}
