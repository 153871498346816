import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { COLORS } from "../../../constants/base";
import formatPrevValue from "../utils/formatPrevValue";

dayjs.extend(dayOfYear);
dayjs.extend(advancedFormat);

function PreviousValue({ prevValue, date }) {
  const setColor = useCallback(() => {
    if (prevValue === 0 || prevValue < 100) {
      return { color: COLORS.green };
    }
    return { color: COLORS.red };
  }, [prevValue]);

  return (
    <Box mb={2}>
      <Typography sx={setColor} variant="body1">
        {formatPrevValue(prevValue, date)}
      </Typography>
    </Box>
  );
}

PreviousValue.propTypes = {
  prevValue: PropTypes.number,
  date: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)).isRequired,
};

PreviousValue.defaultProps = {
  prevValue: undefined,
};

export default PreviousValue;
