import React from 'react';
import DataCard from "./Container";
import CommonForm from "./CommonForm";
import {useConnector} from "./DataConnectorProvider";
import {baseAxios, errorMessage} from "../../utils/axios";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {selectProfile} from "../../redux/profile";
import {KEY_TYPE} from "../../constants/dataConnector";
import {localDateForUsers} from "../../utils/date/localDate";
import {Typography} from "@mui/material";
import {KNOWLEDGE_BASE_URL} from "../../constants/urls";

const DOCUMENTATION_LINK = `${KNOWLEDGE_BASE_URL}docs/reporting/api`
const renderDescription = () => (
  <Typography>
    Activate your personal API token to create a connection between your applications and the Positive Carbon system.
    For guidance on how to use your token,
    please refer to {<a target="_blank" referrerPolicy="no-referrer" href={DOCUMENTATION_LINK}>the documentation</a>}
  </Typography>
)
const ApiToken = () => {
  const { tokens, updateCache } = useConnector()
  const { enqueueSnackbar } = useSnackbar()
  const { data } = useSelector(selectProfile)
  const generateToken = () => baseAxios.post('/api/tokens/create_api_key')
    .then((res) => {
      enqueueSnackbar('Done', {variant: 'success'})
      updateCache({...tokens, api_key: res.data})
    })
    .catch((error) => enqueueSnackbar(errorMessage(error), { variant: 'error' }))

  const toggleActive = () => baseAxios.patch(`/api/tokens/${data.id}/`, {
    key_type: KEY_TYPE.ApiKey,
    is_active: !tokens?.api_key?.is_active,
  }).then((res) => updateCache({...tokens, api_key: res.data}))
    .catch((error) => enqueueSnackbar(errorMessage(error), { variant: 'error' }))

  const expiredDate = tokens?.api_key?.expired_at && `Expires at ${localDateForUsers(tokens?.api_key?.expired_at)}`
  return (
    <DataCard title="Personal API Token">
      <CommonForm
        onSubmit={generateToken}
        onSwitch={toggleActive}
        isActive={tokens?.api_key?.is_active}
        submitText="Generate New Token"
        copyText="Copy Token"
        inputProps={{ value: tokens?.api_key?.key}}
        helperText={expiredDate}
        description={renderDescription}
      />
    </DataCard>
  );
};

export default ApiToken;
