import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { baseAxios } from "../utils/axios";
import GroupService from "../services/GroupService";

export const fetchParentGroups = createAsyncThunk(
  "parentGroups/fetchParentGroups",
  async () => {
    try {
      const response = await baseAxios("api/parent-groups");
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

export const removeParentGroup = createAsyncThunk(
  'parentGroups/removeParentGroup',
  async (id) => {
    try {
      await GroupService.delete({ id });
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
)

const initialState = {
  // TODO - change
  isLoading: false,
  hasError: false,
  parentGroups: [],
};

export const parentGroupsSlice = createSlice({
  name: "parentGroups",
  initialState,
  reducers: {
    addParentGroup: (state, { payload }) => {
      state.parentGroups.push(payload);
    },
    updateParentGroup: (state, { payload }) => {
      state.parentGroups = state.parentGroups.map((group) => {
        if (group.id === payload.id) {
          return { ...group, ...payload };
        }
        return { ...group };
      });
      console.log(state.parentGroups);
    },
  },
  extraReducers: {
    [fetchParentGroups.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchParentGroups.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.hasError = false;
      state.parentGroups = [...payload];
    },
    [fetchParentGroups.rejected]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      state.parentGroups = [];
    },
    [removeParentGroup.fulfilled]: (state, { payload }) => {
      state.parentGroups = state.parentGroups.filter((group) => group.id !== payload);
      return state;
    }
  },
});

export const { addParentGroup, updateParentGroup } = parentGroupsSlice.actions;
export const selectParentGroups = (state) => state.parentGroups;
export default parentGroupsSlice.reducer;
