import React from 'react';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {Box} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

const BackButton = ({ path, onClick }) => {
  const navigate = useNavigate()
  const go = () => {
    if (path) {
      navigate(path)
    }else {
      navigate(-1)
    }
  }
  return (
    <Box sx={{ cursor: 'pointer' }} onClick={onClick || go}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </Box>
  );
};

export default BackButton;
