export const VIEW_AS_PATHS = [
  "/overview",
  "/feed",
  "/monitors",
  "/sites",
  "/foods",
  "/device-management",
  "/site-management",
  "/group-management",
];

export default { VIEW_AS_PATHS };
