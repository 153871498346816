import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function FormDialog({
  dialogTitle,
  disableTitle,
  showCross,
  backdropClick,
  disableEscapeKey,
  openForm,
  setOpenForm,
  form,
  disableStaticWidth,
  handleCloseModel,
}) {
  const handleClose = async (_event, reason) => {
    if (!backdropClick) {
      if (reason !== "backdropClick") {
        handleCloseModel ? handleCloseModel() : setOpenForm(false);
      }
    } else {
      handleCloseModel ? handleCloseModel() : setOpenForm(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      disableEscapeKeyDown={disableEscapeKey}
      open={openForm}
      fullWidth={!disableStaticWidth}
      maxWidth={disableStaticWidth ? false : "xs"}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {!disableTitle ? (
        <DialogTitle id="dialog-title" sx={{ padding: "1rem 2rem" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h2">{dialogTitle}</Typography>
            {showCross ? (
              <IconButton data-testid="form-close-btn" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : (
              <> </>
            )}
          </Stack>
        </DialogTitle>
      ) : null}
      <DialogContent
        dividers
        id="dialog-description"
        sx={{ padding: "1rem 2rem" }}
      >
        {form}
      </DialogContent>
    </Dialog>
  );
}

FormDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  disableTitle: PropTypes.bool,
  showCross: PropTypes.bool,
  backdropClick: PropTypes.bool,
  disableEscapeKey: PropTypes.bool,
  openForm: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func,
  handleCloseModel: PropTypes.func,
  form: PropTypes.node.isRequired,
  disableStaticWidth: PropTypes.bool,
};

FormDialog.defaultProps = {
  disableTitle: false,
  showCross: true,
  backdropClick: true,
  setOpenForm: undefined,
  handleCloseModel: undefined,
  disableEscapeKey: false,
  disableStaticWidth: false,
};

export default FormDialog;
