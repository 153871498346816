import PropTypes from "prop-types";

function formatInitialListState(props) {
  const { ids, names, otherName, otherValue } = props;
  const dataList = [];

  if (Array.isArray(ids)) {
    ids.map((id, index) => {
      const data = { id: parseInt(id, 10) };
      if (names[index]) data.name = decodeURIComponent(names[index]);
      if (otherName) data[otherName] = otherValue[index];
      dataList.push(data);
    });
  } else if (ids) {
    const data = { id: parseInt(ids, 10) };
    if (names) data.name = names;
    if (otherName) data[otherName] = names;
    dataList.push(data);
  }

  return dataList;
}

formatInitialListState.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  names: PropTypes.arrayOf(PropTypes.string),
  otherName: PropTypes.string,
};

formatInitialListState.defaultProps = {
  names: [],
  otherName: null,
};

export default formatInitialListState;
