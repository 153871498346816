import dayjs from "dayjs";

import dayOfYear from "dayjs/plugin/dayOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(dayOfYear);
dayjs.extend(advancedFormat);

const PREV_DATE_FORMAT = "Do MMM";
const PREV_DATE_YEAR_FORMAT = "Do MMM YYYY";

export function getDiffDays(date1, date2) {
  return dayjs(date1).diff(date2, "day");
}

export function getPreviousChartDates({ startDate, endDate }) {
  const diffDays = getDiffDays(endDate, startDate);

  switch (diffDays) {
    case diffDays > 63:
      return [
        new dayjs(startDate).add(-1, "month"),
        new dayjs(endDate).add(-1, "month"),
      ];
    case diffDays > 14:
      return [
        new dayjs(startDate).add(-1, "week"),
        new dayjs(endDate).add(-1, "week"),
      ];
    default:
      return [
        new dayjs(startDate).add(-1, "day"),
        new dayjs(endDate).add(-1, "day"),
      ];
  }
}

export function getPreviousDiffDates({ startDate, endDate }) {
  const diffDays = getDiffDays(endDate, startDate);

  const prevEndDate = dayjs(startDate).add(-1, "day");
  const prevStartDate = dayjs(prevEndDate).add(-diffDays, "day");

  return [prevStartDate, prevEndDate];
}

export function formatPrevDate({ startDate, endDate }) {
  const diffDays = getDiffDays(endDate, startDate);

  if (diffDays === 0) {
    return dayjs(startDate).format(PREV_DATE_FORMAT);
  } else {
    const format = diffDays > 265 ? PREV_DATE_YEAR_FORMAT : PREV_DATE_FORMAT;
    const startDayStr = dayjs(startDate).format(format);
    const endDayStr = dayjs(endDate).format(format);
    return `${startDayStr} - ${endDayStr}`;
  }
}
