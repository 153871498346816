import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import Base from "./Base";
import {useWidget} from "./useWidget";
import {useModal} from "mui-modal-provider";
import SimpleDialog from "../dialogs/SimpleDialog";
import ConfirmModal from "../../layouts/ConfirmModal";
import useIsMobile from "../../hooks/useIsMobile";
import {useDispatch, useSelector} from "react-redux";
import {selectProfile, updateProfile} from "../../redux/profile";
import {useLocation, useNavigate} from "react-router-dom";
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import {COLORS} from "../../constants/base";
import SuggestModal from "./components/SuggestModal";
import Button from "@mui/material/Button";
import {WIDGET_IDENTITY} from "./config";

const getPinTitle = name => `Do you wish to add the ${name} Widget?`
const ADDED_TITLE = 'This widget has already been added'
const SUGGEST_TITLE = 'Suggest Widget'
const SUGGEST_DESCRIPTION = 'What additional information would you like to see?'

const PreviewWidget = ({ name, imageUrl, description, identifier, suggest }) => {
  const { title, previewValue, isPinned } = useWidget(identifier, { isPreview: true })
  const { showModal } = useModal()
  const isMobile = useIsMobile()
  const { data } = useSelector(selectProfile)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()

  const goToHome = () => navigate({ pathname: '/overview', search })
  const pinWidget = () => dispatch(updateProfile({
    id: data.id,
    payload: {
      focus_areas: [...data.focus_areas, identifier],
    }
  })).then(goToHome)

  const showPinModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal
        onClose={() => targetModal.hide()}
        onConfirm={() => isPinned ? targetModal.hide() : pinWidget()}
        confirmText={isPinned ? 'Ok' : 'Yes'}
        title={isPinned ? ADDED_TITLE : getPinTitle(name)}
        closeModal={() => targetModal.hide()}
        info={isPinned}
      />,
    })
  }

  const showSuggestModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <SuggestModal
        closeModal={() => targetModal.hide()}
      />,
    })
  }

  return (
    <Stack spacing={4} height='100%'>
      <Box sx={{ cursor: 'pointer', flex: 1 }} onClick={suggest ? showSuggestModal : showPinModal}>
        <Base title={title} value={previewValue} id={identifier} withoutHeader={suggest}>
          <Base.Content style={{ minHeight: suggest ? '280px' : "unset"}}>
            {suggest && (
              <Stack height="100%" justifyContent="center" alignItems="center">
                <PlusIcon width={100} height={100} stroke={COLORS.darkBlue} />
              </Stack>
            )}
            {!suggest && (
              <img src={imageUrl} style={{width: '100%'}}/>
            )}
          </Base.Content>
          {!suggest && (
            <Base.Footer>
              <Stack direction="row" justifyContent="flex-end">
                <Button variant="link" disabled>Explore {identifier === WIDGET_IDENTITY.MOST_WASTED_GROUPS ? 'Food Groups' : name}</Button>
              </Stack>
            </Base.Footer>
          )}
        </Base>
      </Box>
      <Stack alignItems="center">
        <Typography variant="medium" textAlign="center">{suggest ? SUGGEST_TITLE : name}</Typography>
        <Typography variant="subtitle1" textAlign="center">{suggest ? SUGGEST_DESCRIPTION : description}</Typography>
      </Stack>
    </Stack>
  );
};

export default PreviewWidget;
