import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withResizeDetector } from "react-resize-detector";
import ReductionCardSkeleton from "./skeletons/ReductionCardSkeleton";
import ReductionCard from "./ReductionCard";

const CardDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.dark_gray,
}));

function ReductionMethods({ food, reduction, isLoading, width }) {
  const ref = useRef(null);
  const [gridColumn, setGridColumn] = useState("span 12");
  const [gridTemplateColumns, setGridTemplateColumns] =
    useState("repeat(12, 1fr)");

  const cardList = useMemo(() => {
    if (reduction.length) {
      return reduction.map((el) => {
        return {
          title: el.reduction_type,
          subtitle: el.reduction_string,
          index: `eduction-${el.id}`,
          key: el.id,
        };
      });
    }

    return [];
  }, [reduction]);

  useEffect(() => {
    if (width >= 1200) {
      setGridColumn("span 4");
      setGridTemplateColumns("repeat(20, 1fr)");
    } else if (width >= 900) {
      setGridColumn("span 5");
      setGridTemplateColumns("repeat(20, 1fr)");
    } else if (width >= 600) {
      setGridColumn("span 6");
      setGridTemplateColumns("repeat(12, 1fr)");
    } else {
      setGridColumn("span 12");
      setGridTemplateColumns("repeat(12, 1fr)");
    }
  }, [width]);

  return (
    <Box sx={{ marginBottom: "50px" }}>
      <Box mb={3}>
        <CardDescription variant="small1">Reduction Methods</CardDescription>
      </Box>
      <Box
        ref={ref}
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        gap={8}
      >
        {!isLoading ? (
          <ReductionCard
            cardList={cardList}
            gridColumn={gridColumn}
            food={food}
          />
        ) : (
          [1, 2, 3, 4, 5].map((id) => (
            <ReductionCardSkeleton key={`card-${id}`} gridColumn={gridColumn} />
          ))
        )}
      </Box>
    </Box>
  );
}

ReductionMethods.propTypes = {
  food: PropTypes.shape({
    id: PropTypes.number,
    category_name: PropTypes.string,
    image_url: PropTypes.string,
    name: PropTypes.string,
    subcategory_name: PropTypes.string,
  }),
  reduction: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ).isRequired,
  isLoading: PropTypes.bool,
  width: PropTypes.number,
};

ReductionMethods.defaultProps = {
  food: {},
  isLoading: false,
  width: undefined,
};

export default withResizeDetector(ReductionMethods);
