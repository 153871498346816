import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import styled from "@emotion/styled";

const CustomizedChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.color.white,
  fontWeight: 600,
  backgroundColor: theme.palette.color.yellow,
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

function TagChip({ label }) {
  return <CustomizedChip key={label} label={`#${label}`} />;
}

TagChip.propTypes = {
  label: PropTypes.string.isRequired,
};

export default TagChip;
