import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import { faPen, faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from "notistack";
import {
  gridStyles,
  headerHeight,
  minHeight,
  rowBuffer,
  rowHeight,
} from "../../constants/dataGrid";
import useResizeDataGrid from "../../hooks/useResizeDataGrid";
import getDevices from "./axios/getDevices";
import getTypeChoices from "./constants/typeChoices";
import handleRenderTooltipDoubleCell from "../../utils/table/handleRenderTooltipDoubleCell";
import handleRenderCellCheck from "../../utils/table/handleRenderCellCheck";
import { COLORS } from "../../constants/base";
import AssociateSiteForm from "./AssociateSiteForm";
import FormDialog from "../components/Dialogs/FormDialog";
import siteProps from "../../props/siteProps";
import shortZoneProps from "../../props/zoneProps";
import EditDeviceForm from "./EditDeviceForm";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import { COLUMNS_WIDTH } from "../../constants/dataGrid";
import { useSelector } from "react-redux";
import { selectFilters } from "../../redux/filters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function DeviceManagmentTable({
  sites,
  devices,
  setDevices,
  loading,
  zones,
  width,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const refTable = useRef(null);

  const mixpanelTrack = useMixpanelTrack();

  const { filters } = useSelector(selectFilters);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [height, setHeight] = useState(`${minHeight}px`);
  const [openForm, setOpenForm] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }]);

  const associateSiteLabel = "Associate Sites with Device";
  const editDeviceLabel = `Edit Device`;

  const handleCloseDialog = () => {
    setOpenForm(false);
    mixpanelTrack(`Device Management - ${formTitle} - Close Dialog`);
  };

  const handleAssociateSiteCloseDialog = () => {
    setOpenForm(false);
    mixpanelTrack(`Device Management - ${associateSiteLabel} - Close Dialog`);
  };

  const handleEditDeviceCloseDialog = () => {
    setOpenForm(false);
    mixpanelTrack(`Device Management - ${editDeviceLabel} - Close Dialog`);
  };

  const mixpanelAssociateSubmit = () => {
    mixpanelTrack(`Device Management - ${associateSiteLabel} - Submit`);
  };

  const mixpanelEditSubmit = () => {
    mixpanelTrack(`Device Management - ${editDeviceLabel} - Submit`);
  };

  const associateSiteForm = (
    <AssociateSiteForm
      selectedDevice={selectedDevice}
      mixpanelSubmit={mixpanelAssociateSubmit}
      setDevices={setDevices}
      sites={sites}
      handleCloseDialog={handleAssociateSiteCloseDialog}
    />
  );

  const editDeviceForm = (
    <EditDeviceForm
      zones={zones}
      sites={sites}
      selectedDevice={selectedDevice}
      handleEditDeviceCloseDialog={handleEditDeviceCloseDialog}
      mixpanelSubmit={mixpanelEditSubmit}
      setDevices={setDevices}
    />
  );
  const [form, setForm] = useState(editDeviceForm);
  const [formTitle, setFormTitle] = useState(editDeviceLabel);

  const handleAssociateSiteForm = () => {
    setForm(associateSiteForm);
    setFormTitle(associateSiteLabel);
    setOpenForm(true);
    mixpanelTrack(`Device Management - ${associateSiteLabel} - Open Dialog`);
  };

  const handleEditDeviceForm = () => {
    const title = `${editDeviceLabel}: ${selectedDevice?.serial || ""}`;
    setForm(editDeviceForm);
    setFormTitle(title);
    setOpenForm(true);
    mixpanelTrack(`Device Management - ${editDeviceLabel} - Open Dialog`);
  };

  const handlerOnCellClick = ({ row }) => {
    setSelectedDevice(row);
  };

  const columns = [
    {
      field: "serial",
      headerName: "Serial",
      ...COLUMNS_WIDTH.dateTime,
      minWidth: 140,
      renderCell: ({ row }) => {
        return handleRenderTooltipDoubleCell(row.serial, row.mac_address);
      },
    },
    {
      field: "bounding_area",
      headerName: "Bounding Area",
      ...COLUMNS_WIDTH.dateTime,
      renderCell: ({ row }) => {
        if (row.bounding_area) {
          // eslint-disable-next-line no-shadow
          const [x, y, width, height] = row.bounding_area;
          return (
            <Box>
              <Typography variant="body1" sx={{ color: COLORS.table_gray }}>
                {"X: "}
                <Typography
                  component="span"
                  variant="subtitle1"
                  sx={{ color: COLORS.tableBlack }}
                >
                  {x}
                </Typography>
                {" Y: "}
                <Typography
                  component="span"
                  variant="subtitle1"
                  sx={{ color: COLORS.tableBlack }}
                >
                  {y}
                </Typography>
              </Typography>
              <Typography variant="body1" sx={{ color: COLORS.table_gray }}>
                {"W: "}
                <Typography
                  component="span"
                  variant="subtitle1"
                  sx={{ color: COLORS.tableBlack }}
                >
                  {width}
                </Typography>
                {" H:"}
                <Typography
                  component="span"
                  variant="subtitle1"
                  sx={{ color: COLORS.tableBlack }}
                >
                  {height}
                </Typography>
              </Typography>
            </Box>
          );
        }
        return null;
      },
    },
    {
      field: "name",
      headerName: "Zone",
      ...COLUMNS_WIDTH.default,
      renderCell: ({ row }) => {
        return handleRenderTooltipDoubleCell(row.name);
      },
    },
    {
      field: "kitchen_photo",
      headerName: "Photo",
      ...COLUMNS_WIDTH.dateTime,
      renderCell: ({ row }) => {
        if (row.kitchen_photo) {
          const imgComponent = <Box component="img" src={row.kitchen_photo} />;
          return <Tooltip title={imgComponent}>{imgComponent}</Tooltip>;
        }
        return null;
      },
    },
    {
      field: "site_name",
      headerName: "Site",
      ...COLUMNS_WIDTH.default,
      renderCell: ({ formattedValue }) => (
        <Tooltip title={formattedValue}>
          <Typography>{formattedValue}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      ...COLUMNS_WIDTH.default,
      valueFormatter: ({ value }) => getTypeChoices(value),
      renderCell: ({ formattedValue }) => (
        <Tooltip title={formattedValue}>
          <Typography>{formattedValue}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "auto_label",
      headerName: "Auto Label",
      ...COLUMNS_WIDTH.default,
      renderCell: ({ row, formattedValue }) => (
        <Box>
          {handleRenderCellCheck({ value: formattedValue })}
          <Tooltip title={row.auto_label_food.name}>
            <Typography variant="body1" sx={{ color: COLORS.table_gray }}>
              {row.auto_label_food.name || "-"}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "actions",
      type: "actions",
      ...COLUMNS_WIDTH.actionMenu,
      sortable: false,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            showInMenu
            key={`action-to-site-${id}`}
            icon={<FontAwesomeIcon icon={faChartColumn} size="lg" />}
            label={associateSiteLabel}
            onClick={handleAssociateSiteForm}
          />,
          <GridActionsCellItem
            showInMenu
            key={`action-edit-${id}`}
            icon={<FontAwesomeIcon icon={faPen} size="lg" />}
            label={editDeviceLabel}
            onClick={handleEditDeviceForm}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    useResizeDataGrid({ setHeight, ref: refTable, data: devices || [] });
  }, [devices]);

  useEffect(async () => {
    if (loading) return;

    setLoadingData(true);
    const data = {};

    if (filters.zone.length) data.zone = filters.zone.map((z) => z.name);
    if (filters.site.length) data.site = filters.site.map((s) => s.id);
    if (filters.group.length) {
      const pgIds = filters.group.map((g) => g.id);
      const siteIds = sites
        .filter((s) => {
          const parent_group = s.parent_group || [];
          const pgId = parent_group?.length ? s.parent_group[0] : null;
          return pgId && pgIds.includes(pgId.id);
        })
        .map((s) => s.id);

      if (data.site) {
        data.site = [...new Set([...data.site, ...siteIds])];
      } else {
        data.site = siteIds;
      }
    }

    const responseDevices = await getDevices({
      data,
      enqueueSnackbar,
    });
    setDevices(responseDevices);
    setLoadingData(false);
  }, [filters, loading]);

  return (
    <Paper
      elevation={3}
      style={{ width: width || "100%", height }}
    >
      <DataGrid
        hideFooterPagination
        hideFooterSelectedRowCount
        hideFooter
        disableColumnMenu
        sortingOrder={["desc", "asc"]}
        ref={refTable}
        rowBuffer={rowBuffer}
        rows={devices}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        loading={loading || loadingData}
        columns={columns}
        sortModel={sortModel}
        onCellClick={handlerOnCellClick}
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel);
        }}
        sx={gridStyles}
      />
      <FormDialog
        dialogTitle={formTitle}
        confirmAction={null}
        openForm={openForm}
        setOpenForm={setOpenForm}
        form={form}
        handleCloseModel={handleCloseDialog}
      />
    </Paper>
  );
}

DeviceManagmentTable.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDevices: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape(siteProps)).isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape(shortZoneProps)).isRequired,
  width: PropTypes.number,
};

DeviceManagmentTable.defaultProps = {
  width: undefined,
};

export default DeviceManagmentTable;
