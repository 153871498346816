import { baseAxios } from "../axios";

const fetchUsers = async ({ params }) => {
  try {
    const response = await baseAxios("api/users", { params });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export default fetchUsers;
