import React, {useMemo} from "react";
import TuneIcon from "@mui/icons-material/Tune";
import {Button, DialogContent, useTheme} from "@mui/material";
import { useModal } from "mui-modal-provider";
import SimpleDialog from "../dialogs/SimpleDialog";
import FilterForm from "./FilterForm";
import {useLocation} from "react-router-dom";
import Filter from "../explore/Filter";
import WidgetFilters from '../widgets/Filters'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function NavbarFilter() {
  const theme = useTheme();

  const { showModal } = useModal();
  const { pathname } = useLocation();
  const isExplorePage = useMemo(() => pathname.includes('explore'), [pathname])
  const isHomePage = useMemo(() => pathname.includes('overview'), [pathname])
  const showSeparateness = isExplorePage || isHomePage

  const filterHandler = () => {
    const filterModal = showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: true,
      handleClose: () => filterModal.hide(),
      content: showSeparateness ? (
        <DialogContent>
          {isExplorePage && <Filter closeModal={() => filterModal.hide()} />}
          {isHomePage && <WidgetFilters closeModal={() => filterModal.hide()} />}
        </DialogContent>
      ) : <FilterForm onCancel={() => filterModal.hide()} />,
    });
  };

  return (
    <Button
      onClick={filterHandler}
      sx={{
        paddingLeft: 0,
        minWidth: "unset",
      }}
    >
      <FontAwesomeIcon icon={faFilter} color={theme.palette.navbar.mobile.color} />
    </Button>
  );
}

export default NavbarFilter;
