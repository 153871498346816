import React from "react";
import PropTypes from "prop-types";
import { Pagination as PaginationMui, Stack } from "@mui/material";

import getPageNumber from "./utils/getPageNumber";
import getPageCount from "./utils/getPageCount";
import { PAGE_LIMIT } from "./configs/constants";

function Pagination({ count, offset, limit, setOffset, ...rest }) {
  function handleChangePagination(_event, page) {
    setOffset((page - 1) * limit);
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ my: 4 }}
    >
      <PaginationMui
        {...rest}
        count={getPageCount({ count, limit })}
        page={getPageNumber({ limit, offset })}
        onChange={handleChangePagination}
      />
    </Stack>
  );
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number,
  setOffset: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  limit: PAGE_LIMIT,
  setOffset: () => {},
};

export default Pagination;
