import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";

const createUpdateSiteInfo = async ({
  siteId,
  address,
  breakfastTimeStart,
  breakfastTimeEnd,
  lunchTimeStart,
  lunchTimeEnd,
  dinnerTimeStart,
  dinnerTimeEnd,
  coversPerDay,
  wastePerCover,
  siteInfo,
}) => {
  let data = {
    address,
    breakfast_time_start: dayjs(breakfastTimeStart).format("HH:mm:ss"),
    breakfast_time_end: dayjs(breakfastTimeEnd).format("HH:mm:ss"),
    lunch_time_start: dayjs(lunchTimeStart).format("HH:mm:ss"),
    lunch_time_end: dayjs(lunchTimeEnd).format("HH:mm:ss"),
    dinner_time_start: dayjs(dinnerTimeStart).format("HH:mm:ss"),
    dinner_time_end: dayjs(dinnerTimeEnd).format("HH:mm:ss"),
    covers_per_day: coversPerDay,
    waste_per_cover: wastePerCover,
  };

  let res;
  if (siteInfo && siteInfo.id) {
    res = await axiosInstance.patch(`api/site-info/${siteInfo.id}`, data);
  } else {
    data = { ...data, site: siteId };
    res = await axiosInstance.post("api/site-info/", data);
  }
  return res;
};

export default createUpdateSiteInfo;
