import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { Paper } from "@mui/material";

import {
  gridStyles,
  rowBuffer,
  rowHeight,
  rowThreshold,
  sortingOrder,
} from "../../constants/dataGrid";
import reviewColumns from "./columns/reviewColumns";
import unidentifiedAndIdentifiedColumns from "./columns/unidentifiedAndIdentifiedColumns";
import useResizeDataGrid from "../../hooks/useResizeDataGrid";
import handleRenderDoubleCell from "../../utils/table/handleRenderDoubleCell";
import handleRenderCell from "../../utils/table/handleRenderCell";
import { selectFilters } from "../../redux/filters";
import { useSelector } from "react-redux";
import {selectDevices} from "../../redux/devices";

function WasteTable({
  states,
  isReview,
  width,
  isMobile,
  limit,
  getEvents,
  handleDiscardOpenDialog,
  handleUpdateOpenDialog,
}) {
  const {
    styleProp = {}, // styleProp is used to override the default style of the table
    selectedSite: site,
    selectedZone: zone,
    selectedFood: food,
    selectedDevice: device,
    selectedStatus: statusType,
    statusItems,
    autoLabel: autoLabelType,
    categorisedValue,
    date,
    setEvent,
    data,
    setData,
    selectionModel,
    setSelectionModel,
    isLoadData,
    setIsLoadData,
    location, // todo
    paginationModel,
    setPaginationModel,
    rowCountState,
    setRowCountState,
    loading,
    setLoading,
    sortModel,
    setSortModel,
  } = states;

  const { filters } = useSelector(selectFilters);
  const { devices } = useSelector(selectDevices)
  const refTable = useRef(null);
  const [height, setHeight] = React.useState("100%");
  const zones = useMemo(() => {
    if(!filters.zone) return [];
    return filters.zone.map(({ id }) => devices?.find(device => device.id === id));
  }, [devices, filters.zone])
  const columns = useMemo(() => {
    if (isReview) {
      return reviewColumns(
        handleRenderCell,
        handleUpdateOpenDialog,
        handleDiscardOpenDialog
      );
    }
    return unidentifiedAndIdentifiedColumns(
      handleRenderDoubleCell,
      handleUpdateOpenDialog,
      handleDiscardOpenDialog
    );
  }, [isReview]);

  const handlerCellClick = (params) => {
    if (params.field === "__check__") return null;

    if (selectionModel === params.id) {
      if (params.field !== "actions") {
        setSelectionModel();
      }
    } else {
      setSelectionModel(params.id);
    }

    const eventData = params.row;
    setEvent(eventData);
  };

  const handleisRowSelectable = ({ id }) => id === selectionModel;

  const handleCellKeyDown = (params, event) => {
    const elem = data.find((el) => el.id === params.id);
    const elemIdx = data.indexOf(elem);

    if (event.key === "ArrowDown") {
      const eventData = data[elemIdx + 1];
      if (eventData !== -1 && data.length) {
        setSelectionModel(data[elemIdx + 1].id);
        setEvent(eventData);
      }
    } else if (event.key === "ArrowUp") {
      const eventData = data[elemIdx - 1];
      if (eventData !== -1 && data.length) {
        setSelectionModel(eventData.id);
        setEvent(eventData);
      }
    }
  };

  useEffect(async () => {
    if (loading) return;
    setLoading(true);
    setPaginationModel({ ...paginationModel, page: 0 });

    const newData = await getEvents({
      ...filters,
      offset: limit * paginationModel.page,
      limit,
      statusType: filters.status,
      autoLabelType: filters.autoLabel,
      date: [filters.startDate, filters.endDate],
      isSecondary: isReview,
      statusItems,
      location,
      sortModel,
      setRowCountState,
      zone: zones,
    });

    setData(newData || []);
    setLoading(false);
    if (isLoadData) {
      setIsLoadData(false);
    }
  }, [filters]);

  useEffect(async () => {
    if (!isLoadData) return;
    if (loading) return;
    setLoading(true);
    setData(
      await getEvents({
        ...filters,
        offset: limit * paginationModel.page,
        limit,
        statusType: filters.status,
        autoLabelType: filters.autoLabel,
        categorised: categorisedValue,
        date: [filters.startDate, filters.endDate],
        isSecondary: isReview,
        location,
        sortModel,
        setRowCountState,
      })
    );
    setLoading(false);
    setIsLoadData(false);
  }, [isLoadData]);

  useEffect(() => {
    useResizeDataGrid({ setHeight, ref: refTable, data: data || [] });
  }, [isMobile, refTable.current, data]);

  return (
    <Paper
      style={{
        width: isMobile ? width : "100%",
        height,
      }}
    >
      <DataGrid
        disableColumnMenu
        pagination
        ref={refTable}
        sortingOrder={sortingOrder}
        hideFooterPagination={isLoadData}
        rowBuffer={rowBuffer}
        rowThreshold={rowThreshold}
        rows={data}
        rowHeight={rowHeight}
        rowCount={rowCountState}
        loading={loading}
        style={loading ? { pointerEvents: "none" } : {}}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => {
          setPaginationModel(newModel);
          setIsLoadData(true);
        }}
        paginationMode="server"
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel);
          setIsLoadData(true);
        }}
        isRowSelectable={handleisRowSelectable}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(newSelectionModel) => {
          if (isLoadData) {
            setSelectionModel([selectionModel]);
          } else {
            setSelectionModel(newSelectionModel);
          }
        }}
        onCellKeyDown={handleCellKeyDown}
        onCellClick={handlerCellClick}
        sx={{ ...gridStyles, ...styleProp }}
      />
    </Paper>
  );
}

WasteTable.propTypes = {
  isReview: PropTypes.bool,
  width: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  states: PropTypes.shape({
    identified: PropTypes.bool,
    unidentified: PropTypes.bool,
    styleProp: PropTypes.shape({}),
    selectedSite: PropTypes.arrayOf(PropTypes.object),
    selectedZone: PropTypes.arrayOf(PropTypes.object),
    selectedFood: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    selectedDevice: PropTypes.arrayOf(PropTypes.object),
    selectedStatus: PropTypes.arrayOf(PropTypes.object),
    statusItems: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    autoLabel: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    setEvent: PropTypes.func,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
    setData: PropTypes.func,
    setIdentFoods: PropTypes.func,
    setOpen: PropTypes.func,
    setOpenForm: PropTypes.func,
    selectionModel: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.number,
    ]),
    setSelectionModel: PropTypes.func,
    isLoadData: PropTypes.bool,
    setIsLoadData: PropTypes.func,
    limit: PropTypes.number,
    location: PropTypes.shape({}),
    paginationModel: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
    }),
    setPaginationModel: PropTypes.func,
    rowCountState: PropTypes.number,
    setRowCountState: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    sortModel: PropTypes.arrayOf(PropTypes.shape({})),
    setSortModel: PropTypes.func,
  }).isRequired,
  handleDiscardOpenDialog: PropTypes.func.isRequired,
  handleUpdateOpenDialog: PropTypes.func.isRequired,
};

WasteTable.defaultProps = {
  isReview: false,
  width: undefined,
};

export default WasteTable;
