import React, { useEffect, useMemo, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchParentGroups,
  selectParentGroups,
} from "../../redux/parentGroups";
import { fetchSites, selectSites } from "../../redux/sites";
import { getUsersParams } from "./getUsersParams";
import { paginationLimit } from "../../constants/base";

function withUsers(Component) {
  // eslint-disable-next-line react/display-name
  return function () {
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [dataUsers, setDataUsers] = useState([]);
    const [dataParentGroups, setDataParentGroups] = useState([]);
    const [dataSites, setDataSites] = useState([]);
    const [selectedParentGroup, setSelectedParentGroup] = useState([]);
    const [selectedSite, setSelectedSite] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: paginationLimit,
    });
    const [sortModel, setSortModel] = useState([
      { field: "name", sort: "asc" },
    ]);

    const { parentGroups } = useSelector(selectParentGroups);
    const { sites } = useSelector(selectSites);
    const role = useMemo(() => {
      return ["Admin", "Labeller", "User"];
    }, []);

    const dispatch = useDispatch();

    const usersPath = (path) => {
      const params = getUsersParams(
        paginationModel.page,
        selectedParentGroup,
        selectedSite,
        selectedRole,
        sortModel
      );

      const usersParams = new URLSearchParams(params);
      return `${path}?${usersParams}`;
    };

    useEffect(() => {
      if (parentGroups.length === 0) {
        dispatch(fetchParentGroups());
      }
      if (sites.length === 0) {
        dispatch(fetchSites());
      }
    }, [dispatch]);

    useEffect(() => {
      setDataParentGroups(parentGroups);
    }, [parentGroups]);

    useEffect(() => {
      setDataSites(sites);
    }, [sites]);

    return (
      <Component
        matches={matches}
        usersPath={usersPath}
        role={role}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
        sites={sites}
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        parentGroups={parentGroups}
        selectedParentGroup={selectedParentGroup}
        setSelectedParentGroup={setSelectedParentGroup}
        dataUsers={dataUsers}
        setDataUsers={setDataUsers}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
        dataParentGroups={dataParentGroups}
        dataSites={dataSites}
        limit={paginationLimit}
      />
    );
  };
}

export default withUsers;
