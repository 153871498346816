import React from "react";
import { Global, css } from "@emotion/react";

const globalCss = css`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  body > iframe {
    pointer-events: none;
  }
`;

function GlobalStyle(props) {
  return <Global {...props} styles={globalCss} />;
}

export default GlobalStyle;
