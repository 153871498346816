import React from "react";
import PropTypes from "prop-types";
import { Button, Stack, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { faUpRightAndDownLeftFromCenter, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function FeedCardActions({
  handleDiscard,
  handleEdit,
  handleComment,
  handleExpand,
  hasComment,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
      sx={{ mt: 4 }}
    >
      {!isMobile && (
        <Button startIcon={<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />} onClick={handleExpand}>
          Expand
        </Button>
      )}
      <Button startIcon={<FontAwesomeIcon icon={faPen} />} onClick={handleEdit}>
        Edit
      </Button>
      <Button startIcon={< FontAwesomeIcon icon={faTrash} />} onClick={handleDiscard}>
        Discard
      </Button>
    </Stack>
  );
}

FeedCardActions.propTypes = {
  handleDiscard: PropTypes.func.isRequired,
  handleComment: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
  hasComment: PropTypes.bool,
};

FeedCardActions.defaultProps = {
  hasComment: false,
};

export default FeedCardActions;
