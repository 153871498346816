import React from 'react';
import styled from "@emotion/styled";
import {COLORS} from "../../../../constants/base";
import {Box, Tooltip, Typography} from "@mui/material";
import {css} from "@emotion/react";
import numberFormater from "../../../../utils/formaters/numberFormater";
import {formatCoastValue} from "../../../../utils/formaters/formatCoastValue";

const PROGRESS_TYPE = {
  positive: 'positive',
  negative :'negative',
}

const COLOR_MAP = {
  [PROGRESS_TYPE.positive]: COLORS.positive,
  [PROGRESS_TYPE.negative]: COLORS.negative,
}

const Container = styled.div`
    display: flex;
    background-color: ${COLORS.gray2};
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    width: 100%;
    height: 32px;
    position: relative;
`
const Divider = styled.div`
    width: 2px;
    height: 65px;
    background-color: ${COLORS.inputBorder};
    align-self: center;
`

const Progress = styled.div`
    width: ${({ progress }) => progress > 100 ? 100 : progress}%;
    background-color: ${({ type }) => COLOR_MAP[type]};
    height: 100%;
    border-top-right-radius: ${({ progress, type, round }) => (progress >= 100 && type === PROGRESS_TYPE.positive ? (round ? '40px' : 0) : '40px')};
    border-bottom-right-radius: ${({ progress, type, round }) => (progress >= 100 && type === PROGRESS_TYPE.positive ? (round ? '40px' : 0) : '40px')};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    position: relative;
`

const posInner = css`
    right: 10px
`
const posOuter = css`
    left: calc(100% + 10px)
`
const LabelBox = styled.div`
    position: absolute;
    ${({ progress }) => progress > 30 ? posInner : posOuter}
`

const ProgressLine = ({ type, progress, label, target, unit, round }) => {
  const color = progress > 30 ? 'white' : COLORS.darkBlue2
  const text = `Threshold (${formatCoastValue(numberFormater({ value: target, fixed: 1, after: unit}))})`
  return (
    <Box flex={1} position="relative">
      {target && (
        <Box width='100%' position="absolute" top="-18px" paddingLeft="10px">
          <Tooltip title={text}>
            <Typography
              whiteSpace="nowrap"
              width="100%"
              textOverflow="ellipsis"
              variant="body2"
              overflow="hidden"
            >
              {text}
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Progress type={type} progress={progress} round={round}>
        {label && (
          <LabelBox progress={progress}>
            <Typography variant="body2" color={color}>{label}</Typography>
          </LabelBox>
        )}
      </Progress>
    </Box>
  )
}

const ProgressBar = ({ value, target, unit, max }) => {
  const waste = value ? Math.ceil(value * 100 / target) || 100 : 0
  const positiveLabel = waste < 100 && waste > 0 && `${waste}%`
  const negativeWaste = waste > 100 ? waste - 100 : 0
  const negativeLabel = waste > 100 && `${waste > 200 ? '200+' : waste}%`
  const maxWidth = max ? value * 100 / max : 100

  return (
    <Container style={{ maxWidth: `${maxWidth}%` }}>
      <ProgressLine type={PROGRESS_TYPE.positive} progress={waste} round={!target} label={positiveLabel} />
      {!!target &&  <Divider />}
      {!!target && (
        <ProgressLine
          type={PROGRESS_TYPE.negative}
          progress={negativeWaste}
          label={negativeLabel}
          target={target}
          unit={unit}
        />
      )}
    </Container>
  );
};

export default ProgressBar;
