import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../utils/axios";

export const fetchFoodSubcategories = createAsyncThunk(
  "foodSubcategories/fetchFoodSubcategories",
  async () => {
    const response = await axiosInstance("api/food-subcategories/", {
      method: "GET",
      // params: { labelling: true },
    });
    return response.data;
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  foodSubcategories: [],
};

export const foodSubcategoriesSlice = createSlice({
  name: "foodSubcategories",
  initialState,
  reducers: {
    // updateFoods: (state, { payload }) => state.items.push(payload),
    // clearFoods: (state) => (state.items.length = 0),
  },
  extraReducers: {
    [fetchFoodSubcategories.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchFoodSubcategories.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.foodSubcategories = [...action.payload];
    },
    [fetchFoodSubcategories.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

// export const { updateFoods, clearFoods } = foodsSlice.actions;

export const selectFoodSubcategories = (state) => state.foodSubcategories;

export default foodSubcategoriesSlice.reducer;
