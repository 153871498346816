import React from "react";
import { Paper, Stack } from "@mui/material";
import PropTypes from "prop-types";
import BlockTitle from "../components/BlockTitle";

function WhiteBlock({ title, leftSite, children }) {
  return (
    <Paper elevation={0} sx={{ p: 4 }}>
      {title || leftSite ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <BlockTitle title={title} />
          {leftSite}
        </Stack>
      ) : null}
      {children}
    </Paper>
  );
}

WhiteBlock.defaultProps = {
  title: null,
  leftSite: null,
  children: null,
};

WhiteBlock.propTypes = {
  title: PropTypes.string,
  leftSite: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
};

export default WhiteBlock;
