import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import {baseAxios, errorMessage} from "../../../utils/axios";
import {checkIsVerified} from "./constants";

const incorrectEvent = async ({ eventId, user, descriptionId, setLeftTime, reset, trimmings }) => {
  const url = `api/verification/${eventId}`;

  const data = {
    verify: false,
    verified_at: dayjs(),
    user_verify: user.id,
    food_description: descriptionId,
    trimmings,
  };

  return baseAxios.patch(url, data)
    .then(() => {
      reset();
      enqueueSnackbar("Event was successfully updated.", {
        variant: "success",
        autoHideDuration: 1500,
      });
    })
    .catch(error => {
      if (checkIsVerified(error)) {
        reset()
        enqueueSnackbar(errorMessage(error, ['message']), {
          variant: "error",
          autoHideDuration: 1500,
        });
      } else {
        enqueueSnackbar("Oops, something went wrong.", {
          variant: "error",
          autoHideDuration: 1500,
        });
      }
    })
};

export default incorrectEvent;
