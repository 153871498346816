import React, { useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";

import { Box, Paper, Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withResizeDetector } from "react-resize-detector";

import { numberFormater } from "../../utils/formaters/numberFormater";

const CardDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.dark_gray,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  height: 80,
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  textAlign: "start",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.gray,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.black,
}));

function StatisticsCards({ statistics, isLoading, width }) {
  const ref = useRef(null);
  const [gridColumn, setGridColumn] = useState("span 12");

  const cardList = useMemo(
    () => [
      {
        index: "interactions",
        title: "Interactions",
        value: numberFormater({ value: statistics.interactions }),
      },
      {
        index: "item-weight",
        title: "Total Weight",
        value: numberFormater({ value: statistics.item_weight, after: "kg" }),
      },
      {
        index: "value",
        title: "Total Value",
        value: numberFormater({ value: statistics.value, before: "€" }),
      },
      {
        index: "co2-captured",
        title: "Total CO2",
        value: numberFormater({ value: statistics.co2_captured, after: "kg" }),
      },
      {
        index: "ranking",
        title: "Ranking",
        value: numberFormater({ value: statistics.ranking }),
      },
    ],
    [statistics]
  );

  useEffect(() => {
    if (width >= 1200) {
      setGridColumn("span 4");
    } else if (width >= 900) {
      setGridColumn("span 5");
    } else if (width >= 600) {
      setGridColumn("span 10");
    } else {
      setGridColumn("span 12");
    }
  }, [width]);

  return (
    <Box sx={{ marginBottom: "50px" }}>
      <Box mb={3}>
        <CardDescription variant="small1">Key Statistics</CardDescription>
      </Box>
      <Box
        ref={ref}
        display="grid"
        gridTemplateColumns={
          width <= 600 ? "repeat(12, 1fr)" : "repeat(20, 1fr)"
        }
        gap={8}
      >
        {cardList.map((el) => (
          <Box gridColumn={gridColumn} key={el.index}>
            {isLoading && (
              <StyledPaper elevation={3}>
                <Skeleton animation="wave" width={50} />
                <Skeleton animation="wave" width={100} />
              </StyledPaper>
            )}
            {!isLoading && (
              <StyledPaper elevation={3}>
                <Title variant="subtitle1">{el.title}</Title>
                <Subtitle variant="h1" component="h6">
                  {el.value}
                </Subtitle>
              </StyledPaper>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

StatisticsCards.propTypes = {
  statistics: PropTypes.shape({
    co2_captured: PropTypes.number,
    interactions: PropTypes.number,
    item_weight: PropTypes.number,
    ranking: PropTypes.number,
    value: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  width: PropTypes.number,
};

StatisticsCards.defaultProps = {
  isLoading: false,
  statistics: {},
  width: undefined,
};

export default withResizeDetector(StatisticsCards);
