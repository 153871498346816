import { useMixpanel } from "react-mixpanel-browser";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/user";
import {useEffect} from "react";

function useMixpanelTrack() {
  const mixpanel = useMixpanel();
  const user = useSelector(selectUser)

  useEffect(() => {
    if (user.id) {
      const currentDistinctId = mixpanel.get_distinct_id()
      if (user.id !== currentDistinctId) {
        mixpanel.alias(user.id.toString(), currentDistinctId.toString())
      }
      mixpanel.identify(user.id)
    }
  }, [user.id]);

  mixpanel.saveTrack = (eventName) => {
    try {
      mixpanel.track(eventName);
    } catch (error) {
      console.debug(error);
    }
  }

  return mixpanel.saveTrack;
}

export default useMixpanelTrack;
