import useAppSelector from "../hooks/useAppSelector";
import {fetchSites, resetSites, selectSites} from "../redux/sites";
import CircularLoading from "../components/progress/CircularLoading";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

const initParams = {
  cache: true,
}

export const withSites = (Component, params = initParams) => props => {
  const { isLoading, sites } = useAppSelector(selectSites)
  const dispatch = useDispatch()
  const [init, setInit] = useState(false)

  useEffect(() => {
    if(!sites.length || !params.cache) {
      dispatch(fetchSites({ fields: ["id", "name", 'focus_food'], filters: params }))
        .then(() =>  setInit(true))
    } else {
      setInit(true)
    }
    return () => {
      if (!params.cache) {
        dispatch(fetchSites({ fields: ["id", "name", 'focus_food']}))
      }
    }
  }, []);

  return (
    <CircularLoading loading={isLoading || !init}>
      <Component {...props} />
    </CircularLoading>
  )
}
