import axiosInstance from "../axios";

export const axiosCreateUser = async ({
  role,
  firstName,
  surname,
  email,
  phoneNumber,
  employer,
  jobTitle,
  password,
  parentGroup,
  site,
  isActive,
  headOffice,
}) => {
  const url = "api/auth/register/";
  const data = {
    role,
    first_name: firstName,
    surname,
    email,
    phone_number: phoneNumber,
    employer,
    job_title: jobTitle,
    password,
    parent_group_id: parentGroup,
    site,
    is_active: isActive,
    head_office: headOffice,
  };

  const res = await axiosInstance.post(url, data);
  return res;
};
