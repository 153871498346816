import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import InformationBlock from "./InformationBlock";
import UserSErvice from "../../services/UserService";
import { initialData } from "./configs/initialData";

function SummaryInformation() {
  const [data, setData] = useState(initialData);

  useEffect(async () => {
    try {
      const response = await UserSErvice.summaryInformation();
      setData(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{
        borderBottom: "1px solid #e0e0e0",
        width: "100%",
        mt: "1rem !important",
      }}
    >
      {data.map(({ name, value }) => (
        <InformationBlock key={name} name={name} value={value} />
      ))}
    </Stack>
  );
}

export default SummaryInformation;
