import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { COLORS } from "../../constants/base";

function handleRenderDoubleCell(top, bottom) {
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ color: COLORS.tableBlack }}>
        {top || "-"}
      </Typography>
      {bottom && (
        <Typography variant="body1" sx={{ color: COLORS.table_gray }}>
          {bottom || "-"}
        </Typography>
      )}
    </Box>
  );
}

handleRenderDoubleCell.propTypes = {
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

handleRenderDoubleCell.defaultProps = {
  bottom: null,
};

export default handleRenderDoubleCell;
