import React from "react";
import { SnackbarContent, closeSnackbar } from "notistack";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { styled } from "@mui/material/styles";
import { snackBarDefaultStyles } from "./styles/snackbarStyles";

const Wrapper = styled(Box)(({ theme }) => ({
  ...theme.snackbar.success,
  ...snackBarDefaultStyles,
}));

const StyledCheckCircleOutlinedIcon = styled(CheckCircleOutlinedIcon)(
  ({ theme }) => ({
    color: theme.palette.color.green,
    marginRight: theme.snackbar.icon.marginRight,
  })
);

const StyledCloseOutlinedIcon = styled(CloseOutlinedIcon)(({ theme }) => ({
  color: theme.snackbar.success.color,
  fontSize: 15,
}));

const SuccessSnackbar = React.forwardRef((props, ref) => {
  const { id, message } = props;

  return (
    <SnackbarContent ref={ref} role="alert">
      <Wrapper>
        <StyledCheckCircleOutlinedIcon />
        <Box>
          <Typography variant="subtitle1">{message}</Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => closeSnackbar(id)}
            sx={(theme) => ({ marginLeft: theme.snackbar.icon.marginLeft })}
          >
            <StyledCloseOutlinedIcon />
          </IconButton>
        </Box>
      </Wrapper>
    </SnackbarContent>
  );
});

SuccessSnackbar.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
};

SuccessSnackbar.displayName = "SuccessSnackbar";

export default SuccessSnackbar;
