import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { Alert, Button, TextField } from "@mui/material";

import { useDispatch } from "react-redux";
import createParentGroup from "./axios/createParentGroup";
import { addParentGroup } from "../../redux/parentGroups";

function CreateParentGroupForm({ handleClose, mixpanelSubmit }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        name: "",
        contactName: "",
        contactNumber: undefined,
        longitude: undefined,
        latitude: undefined,
      }}
      // isInitialValid
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={Yup.object().shape({
        name: Yup.string().min(1).max(255).required("Name is required"),
        contactName: Yup.string()
          .min(1)
          .max(255)
          .required("Contact Name is required"),
        contactNumber: Yup.number().required("Contact Number is required"),
        longitude: Yup.number().required("Longitude is required"),
        latitude: Yup.number().required("Latitude is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = {
          name: values.name,
          contact_name: values.contactName,
          contact_number: values.contactNumber,
          longitude: values.longitude,
          latitude: values.latitude,
        };

        const { data: responseData, error } = await createParentGroup({
          data,
          enqueueSnackbar,
        });

        if (error) {
          // eslint-disable-next-line no-prototype-builtins
          if (error.hasOwnProperty("response")) {
            setStatus("Wrong data");
            const errors = error.response.data;
            errors.contactName = errors.contact_name;
            errors.contactNumber = errors.contact_number;
            setErrors(errors);
          } else {
            setStatus("Something went wrong");
            setErrors(error);
          }
        } else {
          setStatus("New group created successfully");
          dispatch(addParentGroup(responseData));
          handleClose();
        }

        setSubmitting(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            {touched.submit && errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              fullWidth
              variant="standard"
              type="text"
              name="name"
              label="Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="text"
              name="contactName"
              label="Contact Name"
              value={values.contactName}
              error={Boolean(touched.contactName && errors.contactName)}
              helperText={touched.contactName && errors.contactName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="number"
              name="contactNumber"
              label="Contact Number"
              value={values.contactNumber}
              error={Boolean(touched.contactNumber && errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="number"
              name="longitude"
              label="Longitude"
              value={values.longitude}
              error={Boolean(touched.longitude && errors.longitude)}
              helperText={touched.longitude && errors.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />
            <TextField
              fullWidth
              variant="standard"
              type="number"
              name="latitude"
              label="Latitude"
              value={values.latitude}
              error={Boolean(touched.latitude && errors.latitude)}
              helperText={touched.latitude && errors.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
            />

            <Button
              fullWidth
              sx={{ height: "50px", my: 4 }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={mixpanelSubmit}
            >
              Create Zone
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

CreateParentGroupForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
};

export default CreateParentGroupForm;
