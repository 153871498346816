import React from 'react';
import {Button, DialogContent, Stack, Typography} from "@mui/material";
import {handleWith} from "../utils/handleWith";

const ConfirmModal = ({ onConfirm, onClose, title, confirmText, canselText, bothActive, closeModal = () => null, info }) => {
  return (
    <DialogContent>
      <Stack spacing={6}>
        <Typography variant="medium">{title}</Typography>
        <Stack direction="row" justifyContent={info ? 'center' : "space-between" } gap={4}>
          {!info && (
            <Button size="small" fullWidth onClick={handleWith(onClose, closeModal)} variant="contained" color={bothActive ? 'secondary' : 'negative'}>{canselText || "No"}</Button>
          )}
          <Button size="small" fullWidth onClick={handleWith(onConfirm, closeModal)} variant="contained" color="secondary">{confirmText || "Yes"}</Button>
        </Stack>
      </Stack>
    </DialogContent>
  );
};

export default ConfirmModal;
