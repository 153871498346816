import React, { useState } from 'react';
import Modal from "../../../layouts/Modal";
import {Button, TextField} from "@mui/material";
import {baseAxios} from "../../../utils/axios";
import {useSnackbar} from "notistack";

const TITLE = 'What additional information would you like to see?'
const SuggestModal = ({ closeModal }) => {
  const [value, setValue] = useState('')
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async () => {
    try {
      await baseAxios.post('/api/focus-areas/suggest/', { text: value })
      closeModal()
      enqueueSnackbar('Request sent', {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      })
    }
  }
  return (
    <Modal title={TITLE}>
      <TextField value={value} onChange={event => setValue(event.target.value)} name="text" multiline minRows={5} placeholder="Details here" />
      <Modal.Footer>
        <Button disabled={!value} onClick={handleSubmit} variant="contained" color="secondary">Suggest Widget</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuggestModal;
