import React, { useMemo } from "react";
import { Box as MuiBox } from "@mui/material";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import HeaderFilter from "../../filter/HeaderFilter";

const WrapperBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;

const ChildBox = styled(MuiBox)(({ order, left }) => ({
  order,
  flex: "0 1 auto",
  alignSelf: "auto",

  ...(left && {
    marginLeft: "auto",
  }),
}));

const LeftBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
`;

const RightBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  gap: 1rem;
`;

function DesktopFilters({
  LeftSide,
  loading,
  isFullWidth,
  isReverceOrering,
}) {
  const leftOrder = useMemo(() => {
    if (isReverceOrering) {
      return isFullWidth ? 1 : 2;
    }
    return 0;
  }, [isFullWidth]);

  const filterOrder = useMemo(() => {
    if (isReverceOrering) {
      return isFullWidth ? 2 : 1;
    }
    return 0;
  }, [isFullWidth]);

  return (
    <WrapperBox
      style={loading ? { pointerEvents: "none", opacity: 0.7 } : {}}
    >
      <ChildBox order={leftOrder}>
        <LeftBox>{LeftSide}</LeftBox>
      </ChildBox>
      <ChildBox order={filterOrder} left>
        <RightBox>
          <HeaderFilter />
        </RightBox>
      </ChildBox>
    </WrapperBox>
  );
}

DesktopFilters.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  FilterComponets: PropTypes.node.isRequired,
  LeftSide: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  isReverceOrering: PropTypes.bool.isRequired,
};

DesktopFilters.defaultProps = {
  LeftSide: null,
};

export default DesktopFilters;
