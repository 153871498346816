import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormControlLabel, InputAdornment, TextField } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { COLORS } from "../../constants/base";
import { CustomCheckbox, Alert } from "../styled/GlobalStyled";

function SignUp() {
  const navigate = useNavigate();
  const { signUp, signIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const groupId = searchParams.get("group");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const inputProps = {
    InputProps: {
      endAdornment,
    },
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        surname: "",
        employer: "",
        jobTitle: "",
        email: "",
        password: "",
        confirmPassword: "",
        headOffice: false,
        isActive: true,
        submit: false,
        acceptTerms: false,
        signupId: "",
        groupId,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        surname: Yup.string().max(255).required("Surname is required"),
        employer: Yup.string().max(255).required("Employer is required"),
        jobTitle: Yup.string().max(255).required("Job title is required"),
        headOffice: Yup.bool(),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Must be at least 12 characters")
          .max(255)
          .required("Password is required"),
        confirmPassword: Yup.string().when("password", {
          is: (val) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
        signupId: Yup.string()
          .max(16, "Max length is 16 chars")
          .matches("^[a-zA-Z0-9_]*$", "Available only Alphanumeric characters"),
        groupId: Yup.number().nullable(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signUp(
            values.email,
            values.password,
            values.firstName,
            values.surname,
            values.employer,
            values.headOffice,
            values.signupId,
            values.isActive,
            values.groupId,
          );
          await signIn(values.email, values.password);
          navigate("/");
        } catch (error) {
          const { data } = error.response;

          setErrors({ ...data, signupId: data.signup_id });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            variant="standard"
            type="email"
            name="email"
            label="Email"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            variant="standard"
            type="text"
            name="firstName"
            label="First Name"
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            variant="standard"
            type="text"
            name="surname"
            label="Surname"
            value={values.surname}
            error={Boolean(touched.surname && errors.surname)}
            fullWidth
            helperText={touched.surname && errors.surname}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            variant="standard"
            type="text"
            name="employer"
            label="Employer"
            value={values.employer}
            error={Boolean(touched.employer && errors.employer)}
            fullWidth
            helperText={touched.employer && errors.employer}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            variant="standard"
            type="text"
            name="jobTitle"
            label="Job Title"
            value={values.jobTitle}
            error={Boolean(touched.jobTitle && errors.jobTitle)}
            fullWidth
            helperText={touched.jobTitle && errors.jobTitle}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            variant="standard"
            type="text"
            name="signupId"
            label="Signup ID"
            value={values.signupId}
            error={Boolean(touched.signupId && errors.signupId)}
            fullWidth
            helperText={touched.signupId && errors.signupId}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            inputProps={{ maxLength: 42 }}
            variant="standard"
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
            {...inputProps}
          />
          <TextField
            inputProps={{ maxLength: 42 }}
            variant="standard"
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
            {...inputProps}
          />
          <FormControlLabel
            sx={{ mt: 6 }}
            control={
              <CustomCheckbox
                sx={{ padding: "0 11px" }}
                checked={values.acceptTerms}
                onChange={handleChange}
                name="acceptTerms"
              />
            }
            label={
              <>
                {"I accept our "}
                <span style={{ color: COLORS.accent_blue }}>
                  Terms of Service
                </span>
                {" and "}
                <span style={{ color: COLORS.accent_blue }}>
                  Privacy Policy
                </span>
              </>
            }
          />
          <Button
            sx={{ marginTop: "30px", height: "50px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign up
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignUp;
