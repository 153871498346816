import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Box, Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectDevices} from "../../redux/devices";
import {selectSites} from "../../redux/sites";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import {baseAxios} from "../../utils/axios";
import {selectFilters, updateFilters} from "../../redux/filters";
import debounce from "lodash.debounce";
import objectsEqual from "../../utils/data/objectsEqual";

const WAIT = 2000

const SiteDeviceFilter = ({ aiLabel, loading, onFilterChange }) => {
  const [devices, setDevices] = useState(useSelector(selectDevices).devices);
  const { sites } = useSelector(selectSites)
  const { filters } = useSelector(selectFilters)
  const initSites = filters.site.filter((site) => sites.some(({ id }) => site.id === id))
  const dispatch = useDispatch()
  const paramsCache = useRef(null)
  const [localFilters, setLocalFilters] = useState({ site: initSites, zone: filters.zone})
  const init = useRef(false)
  const updateDevices = useCallback((params, sites, devices) => {
    if(objectsEqual(params, paramsCache.current)) {
      dispatch(updateFilters({ zone: devices }));
      return;
    }
    if (onFilterChange && init.current) onFilterChange();
    init.current = true;
    dispatch(updateFilters({ site: sites, zone: []}))
    paramsCache.current = params;
    baseAxios("api/devices/device_zones/", { params })
      .then(res => {
        setDevices(res.data)
      })
  }, [])

  const boundedUpdate = useCallback(debounce(updateDevices, WAIT), [])

  useEffect(() => {
    const params = {
      site: localFilters.site.map(({ id }) => id ),
      ...(aiLabel ? { ml_label: true } : {}),
    }

    boundedUpdate(params, localFilters.site, localFilters.zone)
  }, [localFilters, aiLabel]);

  const setSite = site => setLocalFilters(prev => ({
    zone: [],
    site: site,
  }))
  const setZone = zone => setLocalFilters(prev => ({ ...prev, zone }))

  return (
    <Stack spacing={4} direction="row" sx={{ flex: 1 }} justifyContent="flex-end">
      {sites.length > 1 && (
        <Box maxWidth="280px" sx={{ width: '100%' }}>
          <ReviewSelectStandard
            fullWidth
            multiple
            value={localFilters.site}
            setValue={setSite}
            items={sites}
            variant="outlined"
            placeholder="All Sites"
            disabled={loading}
          />
        </Box>
      )}
      <Box maxWidth="280px" sx={{ width: '100%' }}>
        <ReviewSelectStandard
          fullWidth
          multiple
          value={localFilters.zone}
          setValue={setZone}
          items={devices}
          variant="outlined"
          placeholder="All Devices"
          disabled={loading}
        />
      </Box>
    </Stack>
  );
};

export default SiteDeviceFilter;
