import React from "react";
import { Button, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
// import PropTypes from "prop-types";

import NewRecipeForm from "../../foods/NewRecipeForm";
import SimpleDialog from "../../dialogs/SimpleDialog";

export function AddRecipeButton() {
  const { showModal } = useModal();

  const handleForm = () => {
    const modalForm = showModal(SimpleDialog, {
      title: "",
      maxWidth: "xs",
      fullWidth: true,
    });

    modalForm.update({
      content: (
        <NewRecipeForm
          setFoodList={() => {}}
          handleAddRecipeClose={modalForm.hide}
          handleMixpanelSubmit={() => {}}
        />
      ),
    });
  };

  const sx = {
    height: "42px",
  };

  return (
    <Button
      fullWidth
      sx={sx}
      color="secondary"
      variant="contained"
      onClick={handleForm}
    >
      <Typography variant="button" mr={3} sx={{ ml: "1rem" }}>
        Add Recipe
      </Typography>
    </Button>
  );
}

AddRecipeButton.propTypes = {};

AddRecipeButton.defaultProps = {};

export default AddRecipeButton;
