import axiosInstance from "../../../utils/axios";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";

const updateSite = async ({
  siteId,
  siteName,
  contactName,
  contactNumber,
  date,
}) => {
  const params = new URLSearchParams({
    startDate: formatDataQuery(date[0]),
    endDate: formatDataQuery(date[1]),
  });

  const url = `api/sites/${siteId}/?${params}`;
  const data = {
    name: siteName,
    contact_name: contactName,
    contact_number: contactNumber,
  };

  const res = await axiosInstance.patch(url, data);
  return res;
};

export default updateSite;
