import React, { useEffect } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import usePlacesAutocomplete from "use-places-autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { COLORS } from "../../constants/base";
// import { appConfig } from "../../config";
// import { useJsApiLoader } from "@react-google-maps/api";

function AutocompleteAddress({
  values,
  touched,
  errors,
  handleBlur,
  setFieldValue,
  isLoaded,
}) {
  const {
    ready,
    value,
    setValue,
    suggestions: { loading, status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 500 });


  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: appConfig.mapsApiKey,
  // })

  useEffect(() => {
    setValue(values.address);
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue && newValue.description) {
      setFieldValue("address", newValue.description);
    } else {
      setFieldValue("address", newValue);
    }
    clearSuggestions();
  };

  const handleInput = (event, newValue) => {
    setValue(newValue);
    setFieldValue("address", newValue);
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label="Address"
      name="address"
      type="text"
      variant="standard"
      error={Boolean(touched.address && errors.address)}
      helperText={touched.address && errors.address}
      onBlur={handleBlur}
    />
  );

  const renderOption = (props, option) => {
    const { structured_formatting: addressValue } = option;
    if (status === "OK") {
      return (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid item sx={{ display: "flex", width: 44 }}>
              <LocationOnIcon sx={{ color: COLORS.red }} />
            </Grid>
            <Grid
              item
              sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
            >
              <Typography variant="subtitle1">
                {addressValue.main_text}
              </Typography>

              <Typography variant="body1">
                {addressValue.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        </li>
      );
    }
    return null;
  };

  const optionLabel = (option) => {
    if (option.description) {
      return option.description;
    }
    return option;
  };

  const isEqual = (option) => {
    if (value) {
      return option.description === value;
    }
    return undefined;
  };

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      blurOnSelect
      autoComplete
      loading={isLoaded}
      disabled={!ready}
      onChange={handleChange}
      onInputChange={handleInput}
      value={values.address}
      inputValue={value}
      placeholder="Search an address"
      options={!loading ? data : []}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={optionLabel}
      isOptionEqualToValue={isEqual}
    />
  );
}

AutocompleteAddress.propTypes = {
  values: PropTypes.shape({
    address: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
};

AutocompleteAddress.defaultProps = {
  touched: undefined,
  errors: undefined,
};

export default React.memo(AutocompleteAddress);
