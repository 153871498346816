import { paginationLimit } from "../../../constants/base";
import { getOrdering } from "../../../utils/data/getOrdering";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";

function getDeviceParams({ page, date, sortModel, ordering }) {
  const params = {};

  if (page) {
    params.limit = paginationLimit;
    params.offset = page * paginationLimit;
  }

  if (date && date.length) {
    if (date[0] > date[1]) {
      params.startDate = formatDataQuery(date[1]);
      params.endDate = formatDataQuery(date[0]);
    } else {
      params.startDate = formatDataQuery(date[0]);
      params.endDate = formatDataQuery(date[1]);
    }
  }

  const order = ordering || getOrdering(sortModel);
  if (order) params.ordering = order

  return params;
}

export default getDeviceParams;
