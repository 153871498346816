import React, { useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useDispatch, useSelector } from "react-redux";
import { baseAxios } from "../../utils/axios";
import SimpleDialog from "../dialogs/SimpleDialog";
import InviteUserForm from "./InviteUserForm";
import { useSnackbar } from "notistack";
import { selectUser, setUser } from "../../redux/user";
import getTeamsColumns from "./getTeamsColumns";
import DataGridTable from "../dataGrid/DataGridTable";
import WhiteBlock from "../blocks/WhiteBlock";
import BlueButton from "../ui/BlueButton";

function Teams() {
  const { id: userId, parent_group: groupName } = useSelector(selectUser);

  const { showModal } = useModal();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isOwner = useMemo(() => {
    return Boolean(data.find((el) => el.id === userId)?.is_owner);
  }, [data]);

  const handleInviteUser = () => {
    const deleteModal = showModal(SimpleDialog, {
      fullWidth: true,
      maxWidth: "xs",
      title: `Invite user`,
      content: (
        <InviteUserForm
          onSubmit={async (email) => {
            await handleSubmit(email);
            deleteModal.hide();
          }}
        />
      ),
    });
  };

  const handleSubmit = async (email) => {
    try {
      const response = await baseAxios.post("/api/teams", { email });
      if (response.status === 200) {
        setData((prev) => [...prev, response.data]);
      }
      enqueueSnackbar(`Was send letter to ${email}`, { variant: "success" });
    } catch (error) {
      const message = error?.response.data[0] || error;
      enqueueSnackbar(message || error, { variant: "error" });
    }
  };

  async function dispatchUser(userId) {
    const userResponse1 = await baseAxios.get(`/api/users/${userId}`);
    dispatch(setUser(userResponse1.data));
  }

  const dismissUser = async (id) => {
    try {
      const response = await baseAxios.delete(`/api/teams/${id}`);
      if (response.status === 204) {
        setData(data.filter((el) => el.id !== id));
        dispatchUser(userId);
      }
    } catch (error) {
      console.log(error); // TODO - add error handling
    } finally {
    }
  };

  const columns = getTeamsColumns({ isMobile, dismissUser });

  React.useEffect(async () => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await baseAxios("/api/teams");
        setData(response.data);
      } catch (error) {
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    dispatchUser(userId);
  }, []);

  const title = groupName ? `Teams: ${groupName}` : "No Team";

  return (
    <WhiteBlock
      title={title}
      leftSite={
        isOwner && (
          <BlueButton onClick={handleInviteUser}>Invite User</BlueButton>
        )
      }
    >
      <DataGridTable
        data={data}
        isMobile={isMobile}
        columns={columns}
        loading={loading}
        isOwner={isOwner}
      />
    </WhiteBlock>
  );
}

export default Teams;
