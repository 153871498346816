import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectFilters } from "../../redux/filters";
import InsightsChartList from "../sites/InsightsChartList";
import getDevices from "./axios/getDevices";
import ZoneChartCard from "../card/ZoneChartCard";

function orderZonesData(data, ordering) {
if (ordering === "environmental") {
    return data.sort((a, b) => b.environmental - a.environmental);
  } else {
    return data.sort((a, b) => b.value - a.value);
  }
}

function ZonesChartList({ data, setData }) {
  const { filters, view } = useSelector(selectFilters);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    const newData = await getDevices({
      site: filters.site,
      zone: filters.zone,
      date: [filters.startDate, filters.endDate],
      ordering: filters.ordering.id,
    });

    setData(newData);
    setIsLoading(false);
  }, [filters]);

  return (
    <InsightsChartList data={orderZonesData(data, view.ordering.id)} isLoading={isLoading} Card={ZoneChartCard} />
  );
}

ZonesChartList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setData: PropTypes.func,
};

ZonesChartList.defaultProps = {
  data: [],
  setData: () => {},
};

export default ZonesChartList;
