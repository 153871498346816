import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";
import { Box, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useSnackbar } from "notistack";
import DrilldownChart from "./DrilldownChart";
import { fetchSites, selectSites } from "../../redux/sites";
import { fetchFoods } from "../../redux/foods";
import { getFood } from "./axios/getFood";
import { getReductions } from "./axios/getReduction";
import { getStatistic } from "./axios/getStatistic";
import { formatDataQuery } from "../../utils/date/formatDataQuery";
import StatisticsCards from "./StatisticsCards";
import ReductionMethods from "./ReductionMethods";
import ActionButtons from "./ActionButtons";
import Filters from "./Filters";
import Actions from "./Actions";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";

function CategoryDrilldown() {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { foodId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const mixpanelTrack = useMixpanelTrack();

  const { sites } = useSelector(selectSites);

  const history = createBrowserHistory();

  const [searchParams] = useSearchParams();
  const querySite = searchParams.get("siteId");
  const queryStartDate = searchParams.get("startDate");
  const queryEndDate = searchParams.get("endDate");

  const [date, setDate] = useState([
    new Date(queryStartDate),
    new Date(queryEndDate),
  ]);

  const [foodLoading, setFoodLoading] = useState(false);
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [reductionsLoading, setReductionsLoading] = useState(false);

  const [food, setFood] = useState({});
  const [site, setSite] = useState(querySite || "");
  const [statistics, setStatistics] = useState({});
  const [reduction, setReduction] = useState([]);

  useEffect(() => {
    if (!sites.length) {
      dispatch(fetchSites());
    }
    dispatch(fetchFoods({ labelling: true }));
  }, [dispatch, sites]);

  useEffect(async () => {
    try {
      setFoodLoading(true);
      const { data } = await getFood(foodId, date);
      setFood(data);
    } catch (e) {
      enqueueSnackbar("Oops, something went wrong.", {
        variant: "error",
      });
    } finally {
      setFoodLoading(false);
    }
  }, []);

  useEffect(async () => {
    try {
      setReductionsLoading(true);
      const { data } = await getReductions(foodId);
      setReduction(data);
    } catch (e) {
      enqueueSnackbar("Oops, something went wrong.", {
        variant: "error",
      });
    } finally {
      setReductionsLoading(false);
    }

    mixpanelTrack("Foods Detail - Page View");
  }, []);

  useEffect(async () => {
    try {
      setStatisticsLoading(true);
      const { data } = await getStatistic(foodId, site, date);
      setStatistics(data);
    } catch (e) {
      enqueueSnackbar("Oops, something went wrong.", {
        variant: "error",
      });
    } finally {
      setStatisticsLoading(false);
    }
    const p = new URLSearchParams({
      siteId: site || "",
      startDate: formatDataQuery(date[0]),
      endDate: formatDataQuery(date[1]),
    });
    history.push(`/insights/foods/${foodId}/?${p}`); // changing browser url without reloading
  }, [date, site]);

  const filterProps = {
    isLoading: statisticsLoading && foodLoading,
    name: food.name,
    category: food.category_name,
    subcategory: food.subcategory_name,
    site,
    setSite,
    sites,
    date,
    setDate,
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        my="1rem"
        sx={{ paddingTop: 4, marginBottom: 0 }}
      >
        <ActionButtons
          name={food && food.name}
          site={site}
          sites={sites}
          date={date}
          isLoading={statisticsLoading && foodLoading}
        />
      </Stack>
      {matches ? <Actions {...filterProps} /> : <Filters {...filterProps} />}
      <ReductionMethods
        food={food}
        reduction={reduction}
        isLoading={reductionsLoading}
      />
      <StatisticsCards statistics={statistics} isLoading={statisticsLoading} />
      <DrilldownChart foodId={foodId} site={site} date={date} />
    </Box>
  );
}

export default CategoryDrilldown;
