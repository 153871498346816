import React from "react";
import { Alert } from "@mui/material";

function EventAlert() {
  return (
    <Alert severity="warning" data-testid="events-alert">
      <strong>All events has been processed!</strong>
    </Alert>
  );
}

export default EventAlert;
