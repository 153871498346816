import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import utc from "dayjs/plugin/utc";
import quaterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(quaterOfYear);

export const weekNumber = (value) => {
  if (dayjs(value).week() < 10) {
    return `0${dayjs(value).week()}`;
  }
  return dayjs(value).week();
};

export const formatXAisDateTick = (value, days) => {
  if (!dayjs(value).isValid()) {
    return value;
  }

  if (days === 0) {
    return dayjs(value).utc().format("H:mm");
  } else if (14 <= days && days < 63) {
    return `${dayjs(value).utc().format("YYYY")}-${weekNumber(value)}`;
  } else if (days >= 28) {
    return dayjs(value).utc().format("YYYY MMM");
  } else {
    return dayjs(value).utc().format("ddd DD/MM");
  }
};

export const formaterDateLable = (value, view) => {
  switch (view) {
    case "Hourly":
      return dayjs(value).utc().format("H:mm");
    case "Weekly":
      return `${dayjs(value).utc().format("YYYY")}-${weekNumber(value)}`;
    case "Monthly":
      return dayjs(value).utc().format("YYYY MMM");
    default:
      return dayjs(value).utc().format("ddd DD/MM");
  }
};

export const formaterDate = (value, view) => {
  switch (view) {
    case "Hourly":
      return dayjs(value).utc().format("MMM D, HH:mm");
    case "Weekly":
      return `${weekNumber(value)} week of ${dayjs(value)
        .utc()
        .format("YYYY")}`;
    case "Monthly":
      return dayjs(value).utc().format("YYYY MMM");
    default:
      return dayjs(value).utc().format("YYYY ddd DD/MM");
  }
};

export function getView(days) {
  if (days > 63) {
    return "monthly";
  } else if (days > 14) {
    return "weekly";
  } else if (days === 0) {
    return "hourly";
  } else {
    return "daily";
  }
}

export const formaterValue = (value, metric) => {
  if (metric === "€") {
    return `€${parseFloat(value.toFixed(2))}`;
  }
  if (
    [
      "Equivalent Meals",
      "Interactions",
      "Active Sites",
      "Active Devices",
    ].includes(metric)
  ) {
    return `${parseFloat(value.toFixed(2))}`;
  }
  if (metric === "Categorised") {
    return `${parseFloat(value.toFixed(2))} %`;
  }
  return `${parseFloat(value.toFixed(2))} kg`;
};

export function valueFormatter({ value, unit }) {
  const before = unit === "€" ? unit : "";
  const after = unit !== "€" ? unit : "";

  return before + value + after;
}

export function tooltipNameFormatter({ name, days }) {
  if (dayjs(name).isValid() && days !== null) {
    return formatXAisDateTick(name, days);
  } else {
    return name;
  }
}

export const quaterOfTheYear = date => dayjs(date).quarter()
