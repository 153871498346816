import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: theme.palette.color.bluePressed,
  letterSpacing: "0.25px",
}));

export default HeaderTypography;
