import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { Avatar, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import useAuth from "../../hooks/useAuth";
import { selectUser } from "../../redux/user";
import useAppSelector from "../../hooks/useAppSelector";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import Stack from "@mui/material/Stack";
import { stringAvatar } from "../../utils/avatar";

function NavbarUserDropdown() {
  const DOCUMENTATION_LINK = "https://www.notion.so/positivecarbon/Positive-Carbon-Knowledge-Hub-2a58970844c34c60b54bb5f7a2e43fa0";

  const navigate = useNavigate();

  const { signOut } = useAuth();

  const mixpanelTrack = useMixpanelTrack();

  const user = useAppSelector(selectUser);

  const [anchorMenu, setAnchorMenu] = useState(null);

  const userName = [user.first_name, user.surname].join(" ");

  const { pathname } = useLocation()

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleDocumentation = async () => {
    mixpanelTrack("Navbar -  Knowledge Base - Click");
    closeMenu();
    window.open(DOCUMENTATION_LINK, "_blank")
  };

  const handleProfile = async () => {
    mixpanelTrack("Navbar -  Profile - Click");
    navigate("/settings/profile");
  }

  const handleSignOut = async () => {
    mixpanelTrack("Navbar - Sign out - Click");
    await signOut();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    closeMenu()
  }, [pathname]);

  if (!user.id) return null;

  return (
    <Stack direction="row" alignItems="center" >
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          sx={{ p: 0 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              id="avatar-image"
              src={user.profile_photo}
              alt={userName}
              sx={{ textAlign: "center" }}
            >
              {stringAvatar(user.first_name, user.surname).children}
            </Avatar>
            <FontAwesomeIcon size="xs" icon={faAngleDown} color="white"/>
          </Stack>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText> Account</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDocumentation}>
          <ListItemIcon>
            <MenuBookIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText> Knowledge Base</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
        <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
}

export default NavbarUserDropdown;
