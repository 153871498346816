import React from "react";
import { Box, Box as MuiBox } from "@mui/material";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { ExportCSV } from "../components/Buttons/ExportCSV";
import UserCreate from "./userCreate";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";

const WrapperBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const ChildBox = styled(MuiBox)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
`;

const LeftBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
`;

const RightBox = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  gap: 1rem;
`;

function Filters({
  dataParentGroups,
  dataSites,
  dataUsers,
  setDataUsers,
  usersPath,
  selectedRole,
  setSelectedRole,
  role,
  selectedSite,
  setSelectedSite,
  sites,
  selectedParentGroup,
  setSelectedParentGroup,
  parentGroups,
  mixpanelExportCSV,
}) {
  return (
    <WrapperBox>
      <ChildBox>
        <LeftBox>
          <Box sx={{ display: "flex" }}>
            <UserCreate
              dataParentGroups={dataParentGroups}
              dataSites={dataSites}
              dataUsers={dataUsers}
              setDataUsers={setDataUsers}
            />
            <ExportCSV
              href={usersPath("/api/users/export_csv/")}
              filename="users.csv"
              mixpanelExportCSV={mixpanelExportCSV}
            />
          </Box>
        </LeftBox>
      </ChildBox>
      <ChildBox>
        <RightBox>
          <ReviewSelectStandard
            value={selectedRole}
            setValue={setSelectedRole}
            items={role}
            label="Role"
            multiple
            isTitle
          />
          <ReviewSelectStandard
            value={selectedSite}
            setValue={setSelectedSite}
            items={sites}
            label="Site"
            multiple
          />
          <ReviewSelectStandard
            value={selectedParentGroup}
            setValue={setSelectedParentGroup}
            items={parentGroups}
            label="Parent Group"
            multiple
          />
        </RightBox>
      </ChildBox>
    </WrapperBox>
  );
}

Filters.propTypes = {
  dataParentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      contact_name: PropTypes.string.isRequired,
      contact_number: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  dataSites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  dataUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      employer: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      head_office: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      is_superuser: PropTypes.bool.isRequired,
      job_title: PropTypes.string,
      last_activity: PropTypes.string,
      parent_group: PropTypes.string,
      phone_number: PropTypes.number,
      profile_photo: PropTypes.string,
      role: PropTypes.string,
      site: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
      surname: PropTypes.string.isRequired,
    })
  ).isRequired,
  setDataUsers: PropTypes.func.isRequired,
  usersPath: PropTypes.func.isRequired,
  selectedRole: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  role: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSite: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedSite: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedParentGroup: PropTypes.arrayOf(
    PropTypes.shape({
      contact_name: PropTypes.string.isRequired,
      contact_number: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedParentGroup: PropTypes.func.isRequired,
  parentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      contact_name: PropTypes.string.isRequired,
      contact_number: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Filters;
