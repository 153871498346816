import React from 'react';
import { Typography } from "@mui/material";
import DataGridTable from "../../dataGrid/DataGridTable";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {localDateFormat} from "../../../utils/date/localDate";
import numberFormater from "../../../utils/formaters/numberFormater";
import {renderDiff} from "./renders";
import styled from "@emotion/styled";
import {formatCoastValue} from "../../../utils/formaters/formatCoastValue";

const Padding = styled.div`
padding: 5px 0px;
`
const getColumns = (unit, labels) =>  [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: ({ value}) => <Padding><Typography>{localDateFormat(value)}</Typography></Padding>
  },
  {
    field: `name`,
    headerName: labels[0]?.legend,
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <Padding>
          <Typography>{value}</Typography>
        </Padding>
      )
    }
  },
  {
    field: 'value',
    headerName: 'Waste',
    flex: 1,
    renderCell: ({ value }) => (
      <Padding>
        <Typography>{formatCoastValue(numberFormater({ value, fixed: 2, after: unit }))}</Typography>
      </Padding>
    )
  },
  {
    field: 'diff',
    headerName: '% changes',
    flex: 1,
    renderCell: ({ value }) =><Padding>{renderDiff(value)}</Padding>
  },
]

const TableView = ({unit, activeLabels, data}) => {
  const allLabels = Object.values(activeLabels);
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const rule = { name: !!data[0]?.name }
  return (
    <DataGridTable
      data={data}
      columns={getColumns(unit, allLabels)}
      isMobile={isMobile}
      getRowHeight={() => 'auto'}
      visibility={rule}
      showFooter
      pageSizes={[25]}
      initState={{
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
      }}
    />
  );
};

export default TableView;
