import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CHART_TYPE, DATE_GRAN, LABELS, METRICS} from "../constants/explore";
import dayjs from "dayjs";
import {excludeParams, processDateRange, processFilters} from "../components/explore/storeMappers";
import {fetchFoods, selectFoods} from "./foods";
import {selectSites} from "./sites";
import {useSelector} from "react-redux";
import {selectZones} from "./zones";

export const last4Month = [new Date( dayjs().subtract(4, "month")), new Date(dayjs())]

export const init = createAsyncThunk(
  'explore/init',
  async ({ params, shouldFetchFood = true }, { dispatch, getState})  => {
    const store = getState()
    const { sites } = selectSites(store)
    const { filters } = selectExplore(store)
    const { zones } = selectZones(store);
    const defaultMetric = (sites.length > 1 && !params.metric)
      ? METRICS.AVGWaste
      : false
    const dateRange = processDateRange({ filters, params })
    if(shouldFetchFood) {
      await dispatch(fetchFoods({ date: dateRange }))
    }
    const { foods } = selectFoods(getState())
    return processFilters({
      filters,
      sites,
      defaultMetric,
      food: foods,
      params,
      zones
    })
  })

const initialState = {
  filters: {
    metric: METRICS.totalWaste,
    labels: LABELS.none,
    dateRange: last4Month,
    sites: [],
    food: [],
    dateGranularity: DATE_GRAN.monthly,
    chartType: CHART_TYPE.bar,
    zones: [],
  },
  data: null,
  isLoading: false
}

const merge = (state, { payload }) => ({...state, ...payload})

const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = excludeParams(merge(state.filters, action))
    },
    resetFilters: state => {
      state.filters = initialState.filters
    },
    updateData: (state, action) => {
      state.data = action.payload
    }
  },
  extraReducers: {
    [init.pending]: state => {
      state.isLoading = true
    },
      [init.fulfilled]: (state, action) => {
        state.filters = action.payload
        state.filters.sites = action.payload.sites.slice(0, 1)
        state.isLoading = false
    }
  }
})

export const selectExplore = state => state.explore;

export const { updateFilters, resetFilters, updateData } = exploreSlice.actions
export default exploreSlice.reducer
