import React from 'react';
import styled from "@emotion/styled";
import CircularProgress from "../progress/CircularProgress";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.7);
    z-index: 100;
`

const Loader = () => {
  return (
    <Container>
      <CircularProgress initSize={40} />
    </Container>
  );
};

export default Loader;
