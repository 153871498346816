export const initialData = [
  {
    name: "Sites",
    value: 0,
  },
  {
    name: "Monitors",
    value: 0,
  },
  {
    name: "Tonnes Tracked",
    value: 0,
  },
];
