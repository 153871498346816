import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";

const ButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.color.gray,
  padding: 0,
}));


const CardButton = ({ name, icon, onClick }) => {
  return (
    <Button startIcon={icon} onClick={onClick} sx={{px: 0}}>
          {name}
    </Button>
  );
};

CardButton.defaultProps = {};

CardButton.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CardButton;
