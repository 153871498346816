import React, {useEffect, useState} from 'react';
import Modal from "../../../../layouts/Modal";
import {Button, Stack, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from 'yup'
import {setDefaultCovers} from "../../../sites/axios/setDefaultCovers";
import {defaultsToRequest, occupancyValidation, REQUIRED_ERROR} from "../helpers";
import {baseAxios} from "../../../../utils/axios";
import {snakeToCamel} from "../../../../utils/formaters/camelCase";
import Loader from "../../Loader";

const TITLE = 'Set Default Daily Occupancy'

const initialValues = {
  default: null,
  mondayDefault: null,
  tuesdayDefault: null,
  wednesdayDefault: null,
  thursdayDefault: null,
  fridayDefault: null,
  saturdayDefault: null,
  sundayDefault: null,
}
const validationSchemaForDefault = Yup.object().shape({
  default: occupancyValidation.required(REQUIRED_ERROR),
})

const validationSchemaForDaily = Yup.object().shape({
  mondayDefault: occupancyValidation,
  tuesdayDefault: occupancyValidation,
  wednesdayDefault: occupancyValidation,
  thursdayDefault: occupancyValidation,
  fridayDefault: occupancyValidation,
  saturdayDefault: occupancyValidation,
  sundayDefault: occupancyValidation,
})

const SetDefaultOccupancy = ({ withDays, selectedSite, closeModal }) => {
  const { values, handleChange, handleSubmit, errors, dirty, isValid, resetForm } = useFormik({
    initialValues,
    validationSchema: withDays ? validationSchemaForDaily : validationSchemaForDefault,
    onSubmit: submit,
  })
  const [isReady, setIsReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    baseAxios(`api/daily-covers/get_defaults/${selectedSite}`)
      .then(res => {
        const { date, id, site, ...defaults} = snakeToCamel(res.data)
        resetForm({ values: defaults })
      })
      .finally(() => setIsReady(true))
  }, [selectedSite]);



  function submit(values) {
    setIsLoading(true)
    const payload = withDays
      ? defaultsToRequest(values)
      : {...initialValues, default: values.default }
    setDefaultCovers({ data: payload, siteId: selectedSite })
      .then(() => {
        closeModal()
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Modal title={TITLE}>
      {!isReady && <Loader />}
      <form onSubmit={handleSubmit}>
        <Stack>
          {!withDays && (
            <TextField
              name="default"
              onChange={handleChange}
              value={values.default}
              error={errors.default}
              helperText={errors.default}
              label="Default"
              InputLabelProps={{ shrink: true }}
              type="number"
            />
          )}
          {withDays && (
            <>
              <TextField
                name="mondayDefault"
                onChange={handleChange}
                value={values.mondayDefault}
                error={errors.mondayDefault}
                helperText={errors.mondayDefault}
                label="Monday"
                InputLabelProps={{ shrink: true }}
                type="number"
              />
              <TextField
                name="tuesdayDefault"
                onChange={handleChange}
                value={values.tuesdayDefault}
                error={errors.tuesdayDefault}
                helperText={errors.tuesdayDefault}
                label="Tuesday"
                InputLabelProps={{ shrink: true }}
                type="number"
              />
              <TextField
                name="wednesdayDefault"
                onChange={handleChange}
                value={values.wednesdayDefault}
                error={errors.wednesdayDefault}
                helperText={errors.wednesdayDefault}
                label="Wednesday"
                InputLabelProps={{ shrink: true }}
                type="number"
              />
              <TextField
                name="thursdayDefault"
                onChange={handleChange}
                value={values.thursdayDefault}
                error={errors.thursdayDefault}
                helperText={errors.thursdayDefault}
                label="Thursday"
                InputLabelProps={{ shrink: true }}
                type="number"
              />
              <TextField
                name="fridayDefault"
                onChange={handleChange}
                value={values.fridayDefault}
                error={errors.fridayDefault}
                helperText={errors.fridayDefault}
                label="Friday"
                InputLabelProps={{ shrink: true }}
                type="number"
              />
              <TextField
                name="saturdayDefault"
                onChange={handleChange}
                value={values.saturdayDefault}
                error={errors.saturdayDefault}
                helperText={errors.saturdayDefault}
                label="Saturday"
                InputLabelProps={{ shrink: true }}
                type="number"
              />
              <TextField
                name="sundayDefault"
                onChange={handleChange}
                value={values.sundayDefault}
                error={errors.sundayDefault}
                helperText={errors.sundayDefault}
                label="Sunday"
                InputLabelProps={{ shrink: true }}
                type="number"
              />
            </>
          )}
        </Stack>
        <Modal.Footer>
          <Button disabled={!dirty || !isValid || isLoading} type="submit" variant="contained" color="secondary">Set Default Daily Occupancy</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SetDefaultOccupancy;
