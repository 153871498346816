import PropTypes from "prop-types";
import { baseAxios } from "../../../utils/axios";

const createParentGroup = async ({ data, enqueueSnackbar }) => {
  try {
    const response = await baseAxios.post("api/parent-groups", data);
    enqueueSnackbar("Group was successfully create.", {
      variant: "success",
    });
    return { data: response.data, error: null };
  } catch (error) {
    console.warn(error);
    enqueueSnackbar("Oops, something went wrong.", {
      variant: "error",
    });
    return { data: null, error };
  }
};

createParentGroup.propTypes = {
  data: PropTypes.shape({}),
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default createParentGroup;
