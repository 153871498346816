import React from "react";
import PropTypes from "prop-types";

import { Stack, Typography } from "@mui/material";

function NoEvents({ hide }) {
  if (hide) return null;

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Typography variant="h4">No Events</Typography>
    </Stack>
  );
}

NoEvents.defaultProps = {
  hide: true,
};

NoEvents.propTypes = {
  hide: PropTypes.bool,
};

export default NoEvents;
