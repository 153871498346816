import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { fetchSites, selectSites } from "../../../redux/sites";
import { fetchZones, selectZones } from "../../../redux/zones";
import { selectUser } from "../../../redux/user";
import {
  adminEventStatusList,
  eventStatusList,
} from "../../../constants/eventStatusList";
import { fetchDevices, selectDevices } from "../../../redux/devices";
import { getFoods } from "../axios/getFoods";
import { selectFoods } from "../../../redux/foods";
import { paginationLimit } from "../../../constants/base";
import getLabellingFoods from "../../labelling/axios/getLabellingFoods";
import { selectFilters, updateFilters } from "../../../redux/filters";
import useToSearchParams from "../../../hooks/useToSearchParams";

function usePageStates({ isSecondary }) {
  const toSearchParams = useToSearchParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const user = useSelector(selectUser);
  const { foods: allFoods } = useSelector(selectFoods);
  const { sites: siteItems } = useSelector(selectSites);
  const { zones: zoneItems } = useSelector(selectZones);
  const { devices: deviceItems } = useSelector(selectDevices);
  const { filters } = useSelector(selectFilters);

  const categorisedItems = ["All", "Categorised", "Uncategorised"];

  const statusItems = useMemo(() => {
    if (!user) return [];
    if (user.role === "Admin") {
      return adminEventStatusList;
    }
    return eventStatusList;
  }, [user]);

  const autoLabelItems = useMemo(() => {
    return ["Yes", "No"].sort();
  }, []);

  const [foodItems, setFoodItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(filters.status);
  const [eventFoods, setEventFoods] = useState([]);
  const [selectedFood, setSelectedFood] = useState(filters.food);
  const [selectedSite, setSelectedSite] = useState(filters.site);
  const [selectedZone, setSelectedZone] = useState(filters.zone);
  const [selectedDevice, setSelectedDevice] = useState(filters.device);

  const [categorisedValue, setCategorisedValue] = useState(
    searchParams.get("categorised") || categorisedItems[1]
  );

  const [autoLabel, setAutoLabel] = useState(filters.autoLabel);
  const [date, setDate] = useState([filters.startDate, filters.endDate]);
  const [data, setData] = useState([]);
  const [event, setEvent] = useState();
  const [selectionModel, setSelectionModel] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadData, setIsLoadData] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [formFood, setFormFood] = useState(null);
  const [isTrimmings, setIsTrimmings] = useState(false);
  const [sortModel, setSortModel] = useState([
    { field: searchParams.get("weight") || "logged_at", sort: "desc" },
  ]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: paginationLimit,
  });
  const [rowCountState, setRowCountState] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSites({ fields: [], only: ["id", "name"] }));
    dispatch(fetchZones({ fields: [], only: ["id", "name"] }));
  }, [dispatch]);

  useEffect(() => {
    if (!loading && isSecondary === null) {
      dispatch(fetchDevices({ fields: ["id", "name", "serial"] }));
    }
  }, [dispatch, loading]);

  useEffect(async () => {
    if (event) {
      setFormStatus(event.status);
      setIsTrimmings(event.trimmings);

      if (event.food && event.food_name) {
        setFormFood({ id: event.food, name: event.food_name });
      } else {
        setFormFood(null);
      }

      setEventFoods(await getLabellingFoods({ siteId: parseInt(event.site) }));
    } else {
      setFormStatus(null);
      setFormFood(null);
    }
  }, [event]);

  useEffect(() => {
    dispatch(
      updateFilters({
        ...filters,
        site: selectedSite,
        device: selectedDevice,
        zone: selectedZone,
        startDate: date[0],
        endDate: date[1],
        autoLabel,
        status: selectedStatus,
      })
    );
  }, [selectedSite, selectedDevice, selectedZone, date]);

  useEffect(async () => {
    const searchParams = toSearchParams(filters);
    setSearchParams(searchParams);

    const eventsFood = await getFoods({
      site: selectedSite,
      device: selectedDevice,
      zone: selectedZone,
      date,
      isSecondary,
      foods: [],
      only: ["id", "name"],
      fields: [],
    });
    setFoodItems(eventsFood || []);
  }, [filters]);

  return {
    user,
    selectedStatus,
    setSelectedStatus,
    statusItems,
    selectedFood,
    setSelectedFood,
    foodItems,
    eventFoods,
    setEventFoods,
    allFoods,
    selectedSite,
    setSelectedSite,
    siteItems,
    selectedZone,
    setSelectedZone,
    zoneItems,
    selectedDevice,
    setSelectedDevice,
    deviceItems,
    autoLabel,
    setAutoLabel,
    autoLabelItems,
    categorisedValue,
    setCategorisedValue,
    categorisedItems,
    date,
    setDate,
    loading,
    setLoading,
    isLoadData,
    setIsLoadData,
    open,
    setOpen,
    openForm,
    setOpenForm,
    sortModel,
    setSortModel,
    event,
    setEvent,
    data,
    setData,
    selectionModel,
    setSelectionModel,
    formFood,
    setFormFood,
    formStatus,
    setFormStatus,
    isTrimmings,
    setIsTrimmings,
    paginationModel,
    setPaginationModel,
    rowCountState,
    setRowCountState,
  };
}

usePageStates.propTypes = {
  isSecondary: PropTypes.bool,
};

usePageStates.defaultProps = {
  isSecondary: null,
};

export default usePageStates;
