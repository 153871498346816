const secondsToTime = (timestamp) => {
  if (!timestamp) return "0secs";

  const time = Number(timestamp);
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let result = "";

  if (Math.floor(time / (60 * 60 * 24)))
    days = parseInt(time / (60 * 60 * 24), 10);
  if (Math.floor((time - 60 * 60 * 24 * days) / (60 * 60))) {
    hours = parseInt((time - 60 * 60 * 24 * days) / (60 * 60), 10);
  }

  if (days) result += `${days}days`;
  if (hours) result += ` ${hours}hrs`;
  if (days && hours) return result;

  if (Math.floor((time - 60 * 60 * hours) / 60)) {
    minutes = parseInt((time - 60 * 60 * hours) / 60, 10);
  }
  if (minutes) result += ` ${minutes}mins`;
  if (hours && minutes) return result;

  if (time - 60 * minutes < 60) seconds = parseInt(time - 60 * minutes, 10);
  if (seconds) result += ` ${seconds}secs`;
  return result;
};

export default secondsToTime;
