import React from 'react';
import {getGroundPath, getPath} from "../../../explore/chart/helpers";
import {COLORS} from "../../../../constants/base";

const inActiveColor = '#D9D9D9'
const widthIndex = 0.34
const posIndex = 0.5
const strokeIndex = 1/12
const radiusIndex = 1/2

const OccupancyBar = (props) => {
  const {
    x,
    y,
    width,
    height,
    payload,
    isVertical,
    direction,
  } = props
  const color = payload.isSet ? COLORS.brandYellow : inActiveColor
  const buttonWidth = isVertical ? height * widthIndex : width * widthIndex
  const posX = isVertical ? Math.max(x + buttonWidth, x + width - (buttonWidth * widthIndex + height/2)) : x + width/2
  const lineSize = buttonWidth * posIndex
  const stroke = buttonWidth * strokeIndex
  const posY = isVertical ? y + buttonWidth + buttonWidth/2 : buttonWidth < height - 6 ? y + buttonWidth + buttonWidth * widthIndex : y - buttonWidth
  const radius = isVertical ? height * radiusIndex : width * radiusIndex
  const ground = isVertical ? x : y + height
  return (
    <>
      <path d={getPath(x, y, width, height, isVertical, radius)} stroke="none" fill={color}/>
      {!payload?.isSet && (
        <g onClick={() => props.onClick(payload?.x, payload['Occupancy'])} style={{ cursor: 'pointer' }}>
          <circle cx={posX} cy={posY} r={buttonWidth} fill="#2196f3"/>
          <line x1={posX - lineSize} y1={posY} x2={posX + lineSize} y2={posY} stroke="white" strokeWidth={stroke}/>
          <line x1={posX} y1={posY - lineSize} x2={posX} y2={posY + lineSize} stroke="white" strokeWidth={stroke}/>
        </g>
      )}
      <>
        <path d={getGroundPath(isVertical ? ground : x, isVertical ? y + 2 : ground, radius, isVertical)} fill="white"/>
        <path d={getGroundPath(isVertical ? ground : x + width, isVertical ? y + height - 2 : ground, radius, isVertical)} fill="white"/>
      </>
    </>
  );
};

export default OccupancyBar;
