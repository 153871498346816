import React, { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { CssBaseline, Stack, Typography} from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import AuthHeader from "../components/auth/AuthHeader";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SideGrid = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.color.accentBlue,
  margin: "auto",
  display: "flex",
  flex: '0 0 100%',
  height: '100px',
  justifyContent: 'center',
  alignItems: "center",
  minHeight: '100%',
  flexDirection: "column",
}));

const Brad = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.accentBlue,
  alignSelf: 'flex-start',
  paddingTop: "4em",
  paddingBottom: "4em"
}));

const Footer = styled.div`
  align-self: flex-start;
  padding-top: 4em;
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.isMobile ? props.theme.palette.color.white : props.theme.palette.color.grayPaper};
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: ${({ isMobile }) => isMobile ? 'flex-start' : 'center'};
  width: 100%;
  justify-content: center;
`;

const Title = styled(Typography)`
  color: ${(props) => props.theme.palette.color.white};
  margin: 1em 0;
`;

const Subtitle = styled(Typography)`
  color: ${(props) => props.theme.palette.color.white};
`;

const StyledStack = styled(Stack)(() => ({
  minHeight: '100vh'
}))
const ContentWrapper = styled.div`
    max-width: 428px;
    background-color: white;
    padding: 20px 32px;
    border-radius: 6px;
    width: 100%;
    margin-top: ${({ isMobile }) => isMobile && '20px'}
`

function Auth({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <GlobalStyle />
      <StyledStack>
        <AuthHeader isMobile={isMobile} />
        <Wrapper isMobile={isMobile}>
          <ContentWrapper isMobile={isMobile}>
            <CssBaseline />
            {children}
            <Outlet />
          </ContentWrapper>
        </Wrapper>
      </StyledStack>
    </>
  );
}


Auth.propTypes = {
  children: PropTypes.node,
};

Auth.defaultProps = {
  children: undefined,
};

export default Auth;
