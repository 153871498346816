import React, { useRef } from "react";
import { Button, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { withResizeDetector } from "react-resize-detector";
import { formatDataQuery } from "../../utils/date/formatDataQuery";

const StyledButton = styled(Button)(({ theme }) => ({
  width: 164,
  height: 42,
  marginRight: theme.spacing(5),
}));

function ActionButtons({ name, site, sites, width, date, isLoading }) {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { foodId } = useParams();

  const handleInteractions = () => {
    let path = "/feed/?";
    let params = new URLSearchParams({
      foodId,
      foodName: name,
      startDate: formatDataQuery(date[0]),
      endDate: formatDataQuery(date[1]),
    });
    if (site) {
      params += `&${new URLSearchParams({
        siteId: site,
        siteName: sites.find((el) => el.id === parseInt(site, 10))?.name,
      })}`;
    }
    path += params;

    return navigate(path);
  };

  const styles = isLoading
    ? { pointerEvents: "none", opacity: "0.7", marginBottom: "2rem" }
    : { marginBottom: "2rem" };

  return (
    <Stack
      ref={ref}
      direction="row"
      justifyContent={width > 600 ? "flex-start" : "space-between"}
      alignItems="start"
      sx={styles}
    >
      <StyledButton
        onClick={handleInteractions}
        variant="contained"
        color="primary"
      >
        View Interactions
      </StyledButton>
    </Stack>
  );
}

ActionButtons.propTypes = {
  name: PropTypes.string,
  site: PropTypes.string,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  width: PropTypes.number,
  date: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ActionButtons.defaultProps = {
  name: undefined,
  width: undefined,
  site: "",
};

export default withResizeDetector(ActionButtons);
