import React, {useEffect, useState} from 'react';
import Modal from "../../../../layouts/Modal";
import {Button, FormControl, Grid, MenuItem, Select, Stack, TextField} from "@mui/material";
import DateInput from "../../../inputs/DateInput";
import {useSelector} from "react-redux";
import {selectSites} from "../../../../redux/sites";
import {ALL_SITES} from "../../../../constants/widgets";
import {useFormik} from "formik";
import createUpdateDailyCover, { resetCoversToDefault } from "../../../sites/axios/createUpdateDailyCover";
import {getCovers} from "../../../sites/axios/getCovers";
import {useModal} from "mui-modal-provider";
import SimpleDialog from "../../../dialogs/SimpleDialog";
import ConfirmModal from "../../../../layouts/ConfirmModal";
import useIsMobile from "../../../../hooks/useIsMobile";
import dayjs from "dayjs";
import * as Yup from 'yup'
import {handleWith} from "../../../../utils/handleWith";
import {occupancyValidation, REQUIRED_ERROR} from "../helpers";

const CONFIRM_TITLE = "You’ve already set an occupancy figure for this date. Continue?"
const SUCCESS_TITLE = "Success. Do you wish to add another?"
const ONE_YEAR = dayjs(new Date()).add(1, 'year')

const UpdateOccupancy = ({ dateToSet, selectedSite, closeModal, refetchData, value }) => {
  const { sites } = useSelector(selectSites)
  const [canSelectDate, setCanSelectDate] = useState(!dateToSet)
  const { showModal } = useModal()
  const isMobile = useIsMobile()
  const [alreadySet, setAlreadySet] = useState(null)
  const { values, handleChange, handleSubmit, setFieldValue, errors, dirty, isValid } = useFormik({
    initialValues: {
      site: selectedSite || ALL_SITES,
      occupancy: value || null,
      date: dateToSet || new Date(),
    },
    validationSchema: Yup.object().shape({
      occupancy: occupancyValidation.required(REQUIRED_ERROR),
    }),
    onSubmit: values => {
      if(alreadySet && !alreadySet.is_default) {
        confirmChange(values)
        return;
      }
      updateOccupancy(values)
        .then(handleWith(showSuccessModal))
    }
  })

  function updateOccupancy(values) {
    const payload = {
      day: values.date,
      cover: values.occupancy,
      ...(values.site === ALL_SITES ? {} : {siteId: values.site}),
      ...(alreadySet ? { id: alreadySet.id } : {}),
    }
    return createUpdateDailyCover(payload)
      .then((res) => setAlreadySet(res.data))
      .then(() => refetchData())
  }

  function confirmChange(data) {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal
        onClose={() => {
        targetModal.hide()
      }}
        onConfirm={() => updateOccupancy(data).then(() => {
          targetModal.hide()
          showSuccessModal()
        })}
        title={CONFIRM_TITLE}
      />,
    })
  }

  function showSuccessModal() {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal
        onClose={() => {
          targetModal.hide()
          closeModal()
        }}
        onConfirm={() => {
          targetModal.hide()
          setCanSelectDate(true)
        }}
        title={SUCCESS_TITLE}
      />,
    })
  }

  useEffect(() => {
    setAlreadySet(null)
    if(values.site) {
      getCovers({ siteId: values.site, startDate: values.date})
        .then(data => {
          setAlreadySet(data[0])
          if (data[0])
          setFieldValue('occupancy', data[0]?.covers_per_day)
        })
    }
  }, [values.date, values.site]);
  const isMultiSites = sites.length > 1
  const handleDateChange = date => setFieldValue('date', date)
  const resetCovers = () => {
    if (alreadySet?.id) {
      resetCoversToDefault({ id: alreadySet.id, cover: alreadySet.default_value })
        .then(() => closeModal())
    }
  }
  return (
    <Modal title="Update your Occupancy">
      <form onSubmit={handleSubmit}>
        <Stack>
          {isMultiSites && (
            <FormControl variant="outlined" fullWidth>
              <Select
                id="site"
                placeholder="All Sites"
                name="site"
                value={values.site}
                onChange={handleChange}
              >
                {sites.map(site => (
                  <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DateInput maxDate={ONE_YEAR} disabledFuture={false} fullWidth date={values.date} disabled={!canSelectDate}  newDesign setDate={handleDateChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                placeholder="Occupancy"
                type="number"
                value={values.occupancy}
                name="occupancy"
                error={errors.occupancy}
                helperText={errors.occupancy}
              />
            </Grid>
          </Grid>
          <Modal.Footer>
            {alreadySet?.is_default === false && (
              <Button onClick={resetCovers} variant="contained" type="button" color="negative">Reset to default</Button>
            )}
            <Button disabled={!dirty || !isValid} variant="contained" type="submit" color="secondary">Update Occupancy</Button>
          </Modal.Footer>
        </Stack>
      </form>
    </Modal>
  );
};

export default UpdateOccupancy;
