import {createContext, useContext, useMemo} from "react";
import {useSelector} from "react-redux";
import {selectProfile} from "../../redux/profile";
import useSWR from "swr";
import {fetcher} from "../../utils/axios";

const ConnectorContext = createContext({})

const DataConnectorProvider = ({ children }) => {
  const { data } = useSelector(selectProfile)

  const fetchKey = useMemo(() => data.id ? [`api/tokens/${data.id}`] : null, [data.id])
  const { data: tokens, mutate: localCacheUpdate } = useSWR(fetchKey, fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  })
  const updateCache = data => localCacheUpdate(data, { revalidate: false })

  const value = useMemo(() => ({
    tokens,
    updateCache,
  }),  [tokens])

  return (
    <ConnectorContext.Provider value={value}>
      {children}
    </ConnectorContext.Provider>
  )
}

export default DataConnectorProvider

export const useConnector = () => useContext(ConnectorContext)
