import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { axiosUpdate } from "../../../utils/events/axiosUpdate";
import useMixpanelTrack from "../../../hooks/useMixpanelTrack";

function useGetHandles({ states }) {
  const { enqueueSnackbar } = useSnackbar();
  const mixpanelTrack = useMixpanelTrack();

  const eventHandler = async ({ status }) => {
    const { data } = await axiosUpdate({
      eventId: states.selectionModel,
      userId: states.user.id,
      status,
      foodId: states.formFood && states.formFood.id,
      trimmings: states.isTrimmings,
    });

    if (states.statusItems.includes(status)) {
      states.setData((state) =>
        state.map((obj) => {
          return data.id === obj.id
            ? {
                ...obj,
                ...data,
              }
            : obj;
        })
      );
      states.setEvent(data);
    } else {
      states.setIsLoadData(true);
      states.setEvent();
    }

    states.setOpenForm(false);

    return data;
  };

  const discardHandler = async () => {
    try {
      await eventHandler({ status: "DISCARDED" });
      enqueueSnackbar("Event was successfully discarded.", {
        variant: "success",
      });
    } catch (error) {
      const message = error.response ? error.response.data : error.message;
      enqueueSnackbar(message || "Oops, something went wrong.", {
        variant: "error",
      });
      mixpanelTrack("Waste Log - Discard - Submit");
    }
  };

  const updateHandler = async () => {
    try {
      await eventHandler({ status: states.formStatus });
      enqueueSnackbar("Event was successfully discarded.", {
        variant: "success",
      });
    } catch (error) {
      const message = error.response.data;
      enqueueSnackbar(message || "Oops, something went wrong.", {
        variant: "error",
      });
    } finally {
      mixpanelTrack("Waste Log - Update Event - Submit");
    }
  };

  return { discardHandler, eventHandler, updateHandler };
}

useGetHandles.propTypes = {
  states: PropTypes.objectOf(PropTypes.shape({}).isRequired).isRequired,
};

export default useGetHandles;
