import {Checkbox, FormControlLabel, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {localDateFormat} from "../../../utils/date/localDate";
import styled from "@emotion/styled";
import {COLORS} from "../../../constants/base";
import {getDiff, getDiffColor, getDiffText, getGroundPath, getPath} from "./helpers";
import numberFormater from "../../../utils/formaters/numberFormater";
import useIsMobile from "../../../hooks/useIsMobile";
import {CHART_MODE, ROUND_BY_DIRECTION} from "./constants";
import {formatTicker} from "../helpers";
import {formatCoastValue} from "../../../utils/formaters/formatCoastValue";

const Status = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
`
const BAR_ROUND = 6
export const renderDiff = (diff, format) => {
  return(
    <Stack spacing={2} direction="row" alignItems="center">
      <Status color={getDiffColor(diff)} />
      <Typography>{`${getDiffText(diff, format)}`}</Typography>
    </Stack>
  )
}
export const renderTooltip = (labels, unit, bars, getColor, duration, formatValues, mode) => (props) => {
  const { label, payload } = props
  const total = useMemo(() => {
    if (!payload?.length || payload.length < 2 || mode === CHART_MODE.snapshot) {
      return 0
    }
    return payload.reduce((acc, item) => (acc += item.value), 0)
  }, [payload])
  const formattedTotal = formatValues
    ? formatValues(total, unit)
    : numberFormater({ value: total, fixed: 0, after: unit })
  return (
    <Paper sx={{ padding: '20px', maxWidth: '300px' }} key={label}>
      <Stack spacing={2} sx={{ padding: 0 }} direction="row" alignItems="flex-start" justifyContent="space-between">
        <Typography mb={2}>{formatTicker(duration)(label)}</Typography>
        {!!payload?.length && payload[0].payload.meta && renderDiff(getDiff(payload[0].payload.meta, bars), formatValues)}
        {!!payload?.length && payload[0].payload?.diff !== undefined && renderDiff(payload[0].payload.diff, formatValues)}
      </Stack>
      {!!total && (
        <Typography>{`Total: ${formatCoastValue(formattedTotal)}`}</Typography>
      )}
      <Stack sx={{ maxHeight: '800px', overflow: 'auto' }}>
      {payload?.map((item, index) => {
        const waste = formatValues
        ? formatValues(item.value, unit)
        : numberFormater({
          value: item.value,
          fixed: item.value === item.payload?.target ? 3 : 2,
          after: unit
        })
        const defaultColor = labels[item.dataKey]?.color
        const color = getColor
          ? getColor(item) || defaultColor
          : defaultColor
        return (
          item?.value >= 0 && item?.value !== null
            ? (
                <Stack key={`${item.dataKey}-${index}`}>
                  <Typography sx={{ color }}>{item.dataKey}: {formatCoastValue(waste)}</Typography>
                  {item.payload.meta && renderDiff(item.payload.meta[item.dataKey].diff, formatValues)}
                </Stack>
              )
              : null
        )
      })}
      </Stack>
    </Paper>
  )
}

export const renderLegend = (activeLabels, toggleLabel, scrollPosition) => () => {
  const labelsList = Object.keys(activeLabels)
  const legend = activeLabels[labelsList[0]]?.legend
  const isMobile = useIsMobile()
  const ref = useRef(null)
  const handleChange = event => {
    toggleLabel(ref.current.scrollTop)(event.target.value)
  }

  useEffect(() => {
    if(ref.current) {
      ref.current.scrollTo({
        top: scrollPosition,
      })
    }
  }, [scrollPosition])

  return (
    <Stack sx={{ marginTop: '40px' }} spacing={4} direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent="center">
      {legend && (
        <Typography>{legend}:</Typography>
      )}
      <Stack ref={ref} direction="row" sx={{ maxHeight: '200px', overflow: 'auto', }} flexWrap="wrap">
        {
          labelsList.map((entry) => (
            <FormControlLabel
              key={entry}
              control={<Checkbox checked={activeLabels[entry].isActive} onChange={handleChange} sx={{
                color: activeLabels[entry]?.color,
                '&.Mui-checked': {
                  color: activeLabels[entry]?.color,
                },
              }} />}
              label={entry}
              value={entry}
              labelPlacement="end"
            />
          ))
        }
      </Stack>
    </Stack>
  );
}

export const ExploreBar = (props) => {
  const { fill, x, y, width, height, direction = 'horizontal', isFirst, changeGround, ground } = props;
  const isVertical = direction === 'vertical'
  const shouldTrim = isVertical
    ? x - BAR_ROUND < ground
    : y + height + BAR_ROUND < ground
  const maxWidthForRounding = isVertical ? 3 : 10
  const radius = width > maxWidthForRounding ? BAR_ROUND : isVertical ? BAR_ROUND : 0
  useEffect(() => {
    if (isFirst) {
      const ground = isVertical ? x : y + height
      changeGround(ground)
    }
  }, [isFirst, x, y, height, width]);

  if(height) {
    return (
      <>
        <path d={getPath(x, y, width, height, isVertical, radius, !isFirst)} stroke="none" fill={fill} />
        {radius && (
          <>
            <path d={getGroundPath(isVertical ? ground : x, isVertical ? y : ground, BAR_ROUND, isVertical)} fill="white"/>
            <path d={getGroundPath(isVertical ? ground : x + width, isVertical ? y + height : ground, BAR_ROUND, isVertical)} fill="white"/>
          </>
        )}
      </>
    );
  }
  return null
};

export const TargetBar = (props) => {
  const {x, y, width, height, fill, total, target, value, direction, isFirst, ground, changeGround } = props
  const isVertical = direction === 'vertical'
  const targetHeight = (total - target) * height / target;
  const targetWidth = (total - target) * width / target;
  const color = height > 1 ? fill : 'transparent'
  const xTarget = isVertical ? x - targetWidth : x
  const yTarget = isVertical ? y : y + height
  const radius = 6
  const isRichTarget = total > target
  const targetColor =  height > 1 ? (isRichTarget ? COLORS.red : COLORS.gray_light) : 'transparent'
  const shouldRound = isVertical
    ? x - BAR_ROUND > ground
    : y + height + BAR_ROUND < ground

  useEffect(() => {
    if (isFirst) {
      const ground = isVertical ? x : y + height
      changeGround(ground)
    }
  }, [isFirst, x, y, height, width]);

  if (value[0] === 0 && width) {
    return (
      <>
        <path d={getPath(x, y, width, height, isVertical, radius)} fill={color}/>
        {!shouldRound && radius && (
          <>
            <path d={getGroundPath(isVertical ? ground : x, isVertical ? y : ground, BAR_ROUND, isVertical)}
                  fill="white"/>
            <path
              d={getGroundPath(isVertical ? ground : x + width, isVertical ? y + height : ground, BAR_ROUND, isVertical)}
              fill="white"/>
          </>
        )}
      </>
    )
  }

  return (
    targetHeight
      ? (
        <>
          <path d={
             getPath(
               xTarget || 0,
               yTarget,
               isVertical ? targetWidth || 0 : width,
               isVertical ? height : targetHeight,
               isVertical,
               radius,
               !isFirst
             )} fill={targetColor}/>
           { radius && (
             <>
               <path d={getGroundPath(isVertical ? ground : x, isVertical ? y : ground, BAR_ROUND, isVertical)} fill="white"/>
               <path d={getGroundPath(isVertical ? ground : x + width, isVertical ? y + height : ground, BAR_ROUND, isVertical)} fill="white"/>
             </>
           )}
         </>
       )
       : null
  );
};
