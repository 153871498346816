import React from 'react';
import styled from "@emotion/styled";
import {Typography} from "@mui/material";
import {COLORS} from "../../../constants/base";

const StyledText = styled(Typography)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Placeholder = ({ text, children}) => {
  return (
    text
      ? (
        <StyledText textAlign="center" variant="medium" color={COLORS.inputBorder}>
          {text}
        </StyledText>
      )
      : children
  );
};

export default Placeholder;
