import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { withResizeDetector } from "react-resize-detector";
import DateRangeInput from "../inputs/DateRangeInput";

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.black,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  color: theme.palette.color.gray,
}));

function Filters({
  isLoading,
  name,
  category,
  subcategory,
  site,
  setSite,
  sites,
  date,
  setDate,
  width,
}) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "350px",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };

  const styles = isLoading
    ? { pointerEvents: "none", opacity: "0.7", marginBottom: "2rem" }
    : { marginBottom: "2rem" };

  return (
    <Stack
      sx={styles}
      direction="row"
      justifyContent="space-between"
      alignItems="start"
      spacing={6}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="start"
        spacing={2}
      >
        <Box>
          {isLoading && <Skeleton animation="wave" width={150} />}
          {!isLoading && (
            <Title variant="h1" component="h6">
              {name}
            </Title>
          )}
        </Box>
        <Box>
          {isLoading && <Skeleton animation="wave" width={250} />}
          {!isLoading && (
            <Subtitle variant="h3">{`${category} -${subcategory}`}</Subtitle>
          )}
        </Box>
      </Stack>
      <Stack
        direction={width > 600 ? "row" : "column"}
        justifyContent="flex-end"
        alignItems="center"
        spacing={6}
      >
        <FormControl variant="standard" sx={{ width: 164 }}>
          <InputLabel id="site-label">Site</InputLabel>
          <Select
            labelId="site-label"
            id="site-filter"
            value={site}
            label="Site"
            onChange={(e) => setSite(e.target.value)}
            MenuProps={MenuProps}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {sites.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DateRangeInput value={date} setValue={setDate} />
      </Stack>
    </Stack>
  );
}

Filters.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  category: PropTypes.string,
  subcategory: PropTypes.string,
  site: PropTypes.string,
  setSite: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  date: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  setDate: PropTypes.func.isRequired,
  width: PropTypes.number,
};

Filters.defaultProps = {
  name: undefined,
  category: undefined,
  subcategory: undefined,
  site: "",
  isLoading: false,
  width: undefined,
};

export default withResizeDetector(Filters);
