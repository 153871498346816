import { baseAxios } from "../utils/axios";
import { urls } from "./configs/urls";

const PATH = urls.group;

class GroupService {
  async create({ data }) {
    const response = await baseAxios.post(PATH, data);
    return response.data;
  }

  async update({ id, data }) {
    const commentPath = `${PATH}/${id}`;
    const response = await baseAxios.patch(commentPath, data);
    return response.data;
  }

  async delete({ id }) {
    const commentPath = `${PATH}/${id}`;
    await baseAxios.delete(commentPath);
  }

  async get({ id }) {
    const commentPath = `${PATH}/${id}`;
    const response = await baseAxios.get(commentPath);
    return response.data;
  }
}

export default new GroupService();
