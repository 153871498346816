import React, { useState } from "react";

import PropTypes from "prop-types";

import { Button, Typography } from "@mui/material";

import UserCreateModal from "./UserCreateModal";
import FormDialog from "../components/Dialogs/FormDialog";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";

function UserCreate({ dataSites, dataUsers, setDataUsers, dataParentGroups }) {
  const [openModal, setOpenModal] = useState(false);
  const mixpanelTrack = useMixpanelTrack();

  const handleOpenModal = () => {
    setOpenModal(true);
    mixpanelTrack("Users - Add User - Open Dialog");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    mixpanelTrack("Users - Add User - Close Dialog");
  };

  const mixpanelSubmit = () => {
    mixpanelTrack("Users - Add User - Submit");
  };

  return (
    <div>
      <Button
        sx={{ marginRight: "8px", width: "160px", height: "42px" }}
        variant="contained"
        onClick={handleOpenModal}
      >
        <Typography variant="button">Add User</Typography>
      </Button>
      <FormDialog
        dialogTitle=""
        disableTitle
        openForm={openModal}
        handleCloseModel={handleCloseModal}
        form={
          <UserCreateModal
            dataParentGroups={dataParentGroups}
            dataSites={dataSites}
            setDataUsers={setDataUsers}
            dataUsers={dataUsers}
            handleCloseModal={handleCloseModal}
            mixpanelSubmit={mixpanelSubmit}
          />
        }
      />
    </div>
  );
}

UserCreate.propTypes = {
  dataSites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataParentGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDataUsers: PropTypes.func.isRequired,
};

export default UserCreate;
