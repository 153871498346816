export const metricList = (user = {}) => {
  let lst = [
    "Kg",
    "CO2",
    "€",
    "Equivalent Meals",
    "Categorised",
    "Interactions",
    "Active Sites",
    "Active Devices",
  ];

  if (
    Object.keys(user).length &&
    user.role &&
    user.role.toLowerCase() !== "admin"
  ) {
    lst = lst.filter((el) => el !== "Categorised");
  }

  return lst;
};
