import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Paper, Stack, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import withLabelling from "../../components/labelling/withLabelling";
import EventStatistic from "../../components/labelling/EventStatistic";
import ImageSection from "../../components/labelling/ImageSection";
import WasteSection from "../../components/labelling/WasteSection";
import EventAlert from "../../components/labelling/EventAlert";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import Page from "../../layouts/Page";
import {discardedStatus} from "../../components/feed/configs/constants";
import RelabelDialog from "../../components/components/Dialogs/RelableDialog";

function Labelling({
  device,
  completedTasks,
  titleToEnd,
  progress,
  labelledEvent,
  unlabelledEvent,
  loadingLabelled,
  setLoadingLabelled,
  loadingUnlabelled,
  setLoadingUnlabelled,
  setSelectedIds,
  setIsDiscard,
  open,
  setOpen,
  food,
  setFood,
  foodOpen,
  setFoodOpen,
  select,
  selectOpen,
  setSelectOpen,
  handleChange,
  isDisabled,
  handlerSubmit,
  isDiscard,
  confirmAction,
  alert,
  setIsFetch,
  foods,
  setSelectedImage,
  isTrimmings,
}) {
  const middle = useMediaQuery("(max-width: 800px)");
  const mixpanelTrack = useMixpanelTrack();

  const wasteSectionProps = {
    food,
    setFood,
    foodOpen,
    setFoodOpen,
    select,
    selectOpen,
    setSelectOpen,
    handleChange,
    isDisabled,
    handlerSubmit,
    foods,
    isTrimmings,
  };

  useEffect(() => {
    mixpanelTrack("Labelling - Page View");
  }, []);

  const handleConfirmAction = async (foodId) => {
    confirmAction(foodId);
    if (isDiscard) {
      mixpanelTrack("Labelling - Discard - Submit");
    } else {
      mixpanelTrack("Labelling - Escalate - Submit");
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    const name = isDiscard ? "Discard" : "Escalate";
    mixpanelTrack(`Labelling - ${name} - Close Dialog`);
  };

  const setOpenHandle = () => {
    setIsDiscard(true);
    setOpen(true);
    mixpanelTrack("Labelling - Discard - Open Dialog");
  };

  return (
    <Page title="Labelling">
      <Stack spacing={8}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="title" component="h1">Labelling</Typography>
        </Stack>
        <Box>
          <Paper style={{ padding: "1rem", position: "relative" }}>
            {alert ? (
              <EventAlert />
            ) : (
              <EventStatistic
                timeData={device}
                completedTasks={completedTasks}
                titleToEnd={titleToEnd}
                progress={progress}
              />
            )}
            <ImageSection
              md={middle}
              leftEvent={labelledEvent}
              rightEvent={unlabelledEvent}
              device={device}
              loadingLeftEvent={loadingLabelled}
              setLoadingLeftEvent={setLoadingLabelled}
              loadingRightEvent={loadingUnlabelled}
              setLoadingRigthEvent={setLoadingUnlabelled}
              setSelectedIds={setSelectedIds}
              setOpen={setOpen}
              setIsFetch={setIsFetch}
              setOpenHandle={setOpenHandle}
              setSelectedImage={setSelectedImage}
              isDelete
            />
            <WasteSection {...wasteSectionProps} />
            <RelabelDialog
              status={discardedStatus}
              onSubmit={handleConfirmAction}
              onClose={handleCloseDialog}
              isOpen={open && isDiscard}
            />
          </Paper>
        </Box>
      </Stack>
    </Page>
  );
}

Labelling.propTypes = {
  device: PropTypes.object,
  completedTasks: PropTypes.number,
  titleToEnd: PropTypes.string,
  progress: PropTypes.number.isRequired,
  labelledEvent: PropTypes.object,
  unlabelledEvent: PropTypes.object,
  loadingLabelled: PropTypes.bool.isRequired,
  loadingUnlabelled: PropTypes.bool.isRequired,
  setLoadingLabelled: PropTypes.func.isRequired,
  setLoadingUnlabelled: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setIsDiscard: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  food: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFood: PropTypes.func.isRequired,
  foodOpen: PropTypes.bool.isRequired,
  setFoodOpen: PropTypes.func.isRequired,
  select: PropTypes.string.isRequired,
  selectOpen: PropTypes.bool.isRequired,
  setSelectOpen: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handlerSubmit: PropTypes.func.isRequired,
  isDiscard: PropTypes.bool.isRequired,
  confirmAction: PropTypes.func.isRequired,
  alert: PropTypes.bool.isRequired,
  setIsFetch: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
};

Labelling.defaultProps = {
  device: null,
  completedTasks: null,
  titleToEnd: null,
  labelledEvent: null,
  unlabelledEvent: null,
};

export default withLabelling(Labelling);
