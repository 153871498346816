import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { baseAxios } from "../utils/axios";

export const fetchDevices = createAsyncThunk(
  "devices/fetchDevices",
  async (props) => {
    const { fields } = props || {};
    const params = fields ? { fields } : {};
    const response = await baseAxios.get("api/devices/", { params });
    return response.data.sort((a, b) =>
      a.irl ? b.irl - a.irl : a.name - b.name
    );
  }
);

const initialState = {
  isLoading: false,
  hasError: false,
  devices: [],
};

export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    updateDevices: (state, { payload }) => {
      state.devices = state.devices.map((device) => {
        if (device.id === payload.id) {
          return [...device, ...payload];
        }
        return device;
      });
    },
    createDevice: (state, { payload }) => {
      state.devices = [...state.devices, payload];
    },
    clearDevices: (state) => {
      state.devices.length = 0;
    },
  },
  extraReducers: {
    [fetchDevices.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchDevices.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.hasError = false;
      state.devices = [...payload];
    },
    [fetchDevices.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
      state.devices = [];
    },
  },
});

export const { updateDevices, createDevice, clearDevices } =
  devicesSlice.actions;

export const selectDevices = (state) => state.devices;

export default devicesSlice.reducer;
