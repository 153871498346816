import React from "react";
import { Backdrop as BackdropMui, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CircularProgressStyled } from "../progress/CircularProgress";

const useStyles = makeStyles({
  parent: {
    position: "relative",
    zIndex: 0,
  },
  backdrop: {
    position: "absolute",
  },
});

export default function Backdrop({ open, children }) {
  const classes = useStyles();

  return (
    <Box className={classes.parent}>
      {children}
      <BackdropMui className={classes.backdrop} open={open}>
        <CircularProgressStyled />
      </BackdropMui>
    </Box>
  );
}
