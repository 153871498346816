import {ensureArray} from "../../utils/formaters/ensureArray";
import {getAvailableDateRange} from "./helpers";
import {last4Month} from "../../redux/explore";
import {DATE_GRAN, LABELS, MAX_AVAILABLE_RANGE_BY_GRAN, METRICS} from "../../constants/explore";
import dayjs from "dayjs";
import {pipe} from "../../utils/pipe";

export const parseDate = ({ startDate, endDate, ...restFilters}) => {
  if(startDate) {
    return {...restFilters, dateRange: [new Date(startDate), new Date(endDate)]}
  }
  return restFilters
}

const mapOptions = key => (list=[]) => filters => {
  const filteredList = filters[key].filter(itemId => list.find(item => item.id.toString() === itemId.toString()))
  const mappedList = filteredList.map(itemId => list.find(listItem => listItem?.id.toString() === itemId.toString()))
  return {...filters, [key]: mappedList }
}

export const sitesToParams = filters => {
  if (filters?.sites?.length) {
    return {
      ...filters,
      siteId: filters.sites.map(site => site.id),
      siteName: filters.sites.map(site => site.name)
    }
  }
  return filters
}

export const ensureArrayValues = ({ foodId, siteId, siteName, foodName, zoneId, zoneName, zoneSerial, ...restFilters}) => {
  return {
    ...restFilters,
    sites: ensureArray(siteId || []),
    food: ensureArray(foodId || []),
    zones: ensureArray(zoneId || []),
  }
}
export const normalizeFilters = ({labels, ...filters}) => {
  if(labels === LABELS.none) {
    return filters
  }
  return {...filters, labels}
}
export const propsToParams = ({ sites, food, dateRange, zones, ...restProps }) => {
  const params = {
    ...normalizeFilters(restProps),
    startDate: dateRange[0],
    endDate: dateRange[1],
  }
  if (sites?.length) {
    params.siteId = sites.map(site => site.id).join(',')
    params.siteName = sites.map(site => site.name).join(',')
  }
  if (food?.length) {
    params.foodId = food.map(food => food.id)
    params.foodName = food.map(food => food.name)
  }
  if (zones?.length) {
    params.zoneId = zones.map(zone => zone.id)
    params.zoneName = zones.map(zone => zone.name)
    params.zoneSerial = zones.map(zone => zone.serial)
  }
  return params
}

export const applyDateRestrictions = range => value => {
  if (value.dateRange && !value.dateRange.length) {
    const maxRange = getAvailableDateRange(value.dateGranularity) || last4Month
    return {...value, dateRange: maxRange}
  }

  const getEndDate = MAX_AVAILABLE_RANGE_BY_GRAN[value.dateGranularity]
  if (value.dateGranularity && getEndDate) {
    const currentRange = range && !value.dateRange ? range : value.dateRange
    const start = dayjs(currentRange[0])
    const end  = dayjs(currentRange[1])
    const maxEndDate = getEndDate(currentRange[0])
    if (end.diff(start, 'day') > maxEndDate) {
      return { ...value, dateRange: [new Date(start), new Date(start.add(maxEndDate - 1, 'day'))]}
    }
    return { ...value, dateRange: [new Date(start), new Date(end)] }
  }
  return value
}

export const applyDefaultMetric = initMetric => filters => {
  return {
    ...filters,
    metric: initMetric || filters.metric,
  }
}

export const filterStateFood = (food) => filters => {
  const filteredFood = filters.food.filter(item => food.find(f => f.id.toString() === item.id.toString()))
  return {...filters, food: filteredFood }
}

const mapSites = mapOptions('sites')
const mapFood = mapOptions('food')
const mapZones = mapOptions('zones')

const metricRestriction = {
  [METRICS.occupancy]: {
    blockList: [DATE_GRAN.hourly],
    default: DATE_GRAN.daily
  }
}

export const getCorrectGranFilters = (filters) => {
  const { dateGranularity, metric } = filters
  const rule = metricRestriction[metric]
    if (rule) {
      return {
        ...filters,
        dateGranularity: rule.blockList.includes(dateGranularity) ? rule.default : dateGranularity
      }
    }
    return filters
}

export const processFilters = ({ filters, sites, food, params, defaultMetric, zones }) => {
  try {
    return {
      ...pipe(
        {...filters, ...params},
        ensureArrayValues,
        parseDate,
        applyDefaultMetric(defaultMetric),
        mapSites(sites),
        mapFood(food),
        mapZones(zones),
        getCorrectGranFilters,
      )
    }
  } catch (error) {
    return filters
  }
}

export const processDateRange = ({ filters, params }) => {
  const processedData = pipe({...filters, ...params}, parseDate)
  return processedData.dateRange
}

// filters are using only like flags mostly from widget redirect and are not part of the filters on sidebar
export const excludeParams = ({ rollingAverage, platesWasteOnly, ...filters}) => filters
