import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { fetchSites, selectSites } from "../../redux/sites";
import { COLORS } from "../../constants/base";
import LocalStorageService from "../../services/LocalStorageService";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";

const compareFn = (a, b) => {
  if (a.parentName === b.parentName) {
    if (a.siteName > b.siteName) {
      return 1;
    }
    if (a.siteName < b.siteName) {
      return -1;
    }

    return 0;
  }

  if (a.parentName > b.parentName) {
    return 1;
  }

  if (a.parentName < b.parentName) {
    return -1;
  }

  return 0;
};

const pushParentGroup = (acc, cur) => {
  const isExists = acc.some(
    (site) => (cur.parentName || "Without group") === site.parentName
  );

  if (!isExists) {
    acc.push({
      siteId: null,
      siteName: null,
      parentId: cur.parentId || null,
      parentName: cur.parentName || "Without group",
    });
  }
};

const pushSite = (acc, cur) => {
  pushParentGroup(acc, cur);
  acc.push({
    siteId: cur.id,
    siteName: cur.name,
    parentId: cur.parentId || null,
    parentName: cur.parentName || "Without group",
  });
};

const reduceFn = (acc, cur) => {
  const isParentCur = cur.parent_group ? cur.parent_group.length > 0 : false;

  if (isParentCur) {
    cur.parent_group.forEach((group) => {
      const newSite = {
        id: cur.id,
        name: cur.name,
        parentId: group.id,
        parentName: group.name,
      };
      pushSite(acc, newSite);
    });
  } else {
    pushSite(acc, cur);
  }
  return acc;
};

function ViewAsForm({ handleClose }) {
  const mixpanelTrack = useMixpanelTrack();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { sites, isLoading } = useSelector(selectSites);

  useEffect(() => {
    if (sites.length) {
      const initialValue = [
        {
          siteId: null,
          siteName: null,
          parentId: null,
          parentName: "Without group",
        },
      ];
      const formattedData = sites.reduce(reduceFn, initialValue);
      if (Array.isArray(formattedData)) {
        const sortedData = formattedData.sort(compareFn);
        setData(sortedData);
      } else {
        setData([formattedData]);
      }
    }
  }, [sites]);

  useEffect(() => {
    dispatch(fetchSites({ viewAs: true }));
  }, []);

  const viewAsValue = useMemo(() => {
    const localValue = JSON.parse(LocalStorageService.getLocalItem("viewAs"));
    if (!localValue) return "all";
    const parentValue = localValue.isParent ? "p" : "s";
    return `${parentValue}-${localValue.id}`;
  }, []);

  return (
    <Box>
      <Formik
        initialValues={{
          viewAs: viewAsValue,
        }}
        validationSchema={Yup.object().shape({
          viewAs: Yup.string(),
        })}
        onSubmit={({ viewAs }, { setSubmitting }) => {
          if (viewAs !== "all") {
            const [patent, idString] = viewAs.split("-");
            const isParent = patent === "p";
            const id = parseInt(idString, 10);
            let name = "";
            if (isParent) {
              name = data.find((p) => p.parentId === id).parentName;
            } else {
              name = data.find((s) => s.siteId === id).siteName;
            }
            const selectedSites = data.filter((el) => {
              if (isParent) {
                return el.siteId && el.parentId === id;
              }
              return el.siteId && el.siteId === id;
            });
            const ids = Array.from(new Set(selectedSites.map((s) => s.siteId)));

            LocalStorageService.setLocalItem("viewAs", {
              id,
              name,
              isParent,
              siteIds: ids,
            });
            mixpanelTrack(`View As - Set ${name}`)
          } else {
            LocalStorageService.removeLocalItem("viewAs");
            mixpanelTrack("View As - Set All")
          }

          setSubmitting(false);
          window.location.reload(true);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="view-as-label">View As</InputLabel>
              <Select
                displayEmpty
                labelId="view-as-label"
                id="view-as-id"
                name="viewAs"
                value={values.viewAs}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="all">All</MenuItem>
                {data.map((el, index) => {
                  const isSite = Boolean(el.siteId);
                  const key = `${index}-${el.siteId}-${el.parentId}`;
                  return (
                    <MenuItem
                      key={key}
                      value={isSite ? `s-${el.siteId}` : `p-${el.parentId}`}
                      sx={{
                        color: isSite ? COLORS.dark_gray : "black",
                        ml: isSite ? 4 : 0,
                      }}
                    >
                      {el.siteName || el.parentName}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors.password && touched.password && (
                <FormHelperText>
                  {errors.password || touched.password}
                </FormHelperText>
              )}
            </FormControl>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={isSubmitting || isLoading}
              sx={{ mt: 4, p: "0.75rem" }}
            >
              View As
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
}

ViewAsForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ViewAsForm;
