import axiosInstance from "../../../utils/axios";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";

export const getChartData = async (foodId, site, date, metric, view) => {
  const urlParams = new URLSearchParams({
    food_id: foodId,
    site,
    startDate: formatDataQuery(date[0]),
    endDate: formatDataQuery(date[1]),
    metric,
    view,
  });
  const chartData = await axiosInstance(
    `/api/overview/drilldown/?${urlParams}`
  );

  return chartData.data;
};
