import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import TimeAgo from "timeago-react";

import axiosInstance from "../../utils/axios";
import DataGridTable from "../dataGrid/DataGridTable";
import WhiteBlock from "../blocks/WhiteBlock";

const columns = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    flex: 3,
  },
  {
    field: "device_count",
    headerName: "Monitors",
    sortable: true,
    flex: 2,
    valueGetter: ({ value }) => value || 0,
  },
  {
    field: "last_active",
    headerName: "Last Active",
    sortable: true,
    flex: 2,
    renderCell: ({ value }) => {
      if (value)
        return <TimeAgo datetime={value} locale={navigator.language} />;
      return null;
    },
  },
];

function Sites() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`api/sites/profile/`);
      setData(response.data);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <WhiteBlock title="Sites">
      <DataGridTable
        data={data}
        isMobile={isMobile}
        columns={columns}
        loading={loading}
      />
    </WhiteBlock>
  );
}

Sites.propTypes = {
  user: PropTypes.shape({
    address: PropTypes.string,
    phone_number: PropTypes.string,
    head_office: PropTypes.bool,
    is_superuser: PropTypes.bool,
  }).isRequired,
};

export default Sites;
