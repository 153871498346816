export const endDateValue = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const endDateParam = urlParams.get("endDate");

  if (endDateParam) {
    return new Date(endDateParam);
  }

  const currentDate = new Date();

  return new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    23,
    59,
    59
  );
};
