import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)


const formatFullDate = (date) => {
  if (date) {
    const noTimeZone = date.replace(/(([+-]\d{2}:\d{2})|Z)$/, '')
    return dayjs(noTimeZone).format("DD/MM/YYYY HH:mm:ss");
  }
  return null
}

export default formatFullDate;
