import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../utils/axios";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await axiosInstance("api/users/");
  return response.data;
});

const initialState = {
  isLoading: false,
  hasError: false,
  users: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // addCreatedUser: (state, action) => state.users.push(action.payload)
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      if (action.payload?.results) {
        state.users = [...action.payload.results];
      } else {
        state.users = [...action.payload];
      }
    },
    [fetchUsers.rejected]: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

export const { addCreatedUser } = usersSlice.actions;
export const selectUsers = (state) => state.users;
export default usersSlice.reducer;
