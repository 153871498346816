import React from "react";

import { useTranslation } from "react-i18next";
import SidebarNavListItem from "./SidebarNavListItem";

const reduceChildRoutes = (props) => {
  const { items, page, depth, toggle } = props;
  const { t } = useTranslation();
  const hasChildren = !!page.children?.length

  items.push(
    <SidebarNavListItem
      depth={depth}
      href={page.href}
      icon={page.icon}
      key={page.title}
      badge={page.badge}
      title={t(page.title)}
      toggle={toggle}
      hasChildren={hasChildren}
      subRoutes={page.children}
    />
  );

  return items;
};

export default reduceChildRoutes;
