import { COLORS } from "../../../constants/base";

export const chartColors = [
  COLORS.yellow,
  COLORS.blue,
  COLORS.green,
  COLORS.red,
  COLORS.purple,
  COLORS.gray,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
  `#${Math.floor(Math.random()*16777215).toString(16)}`,
];
