import { paginationLimit } from "../../constants/base";
import { getOrdering } from "../../utils/data/getOrdering";

export function getUsersParams({
  page,
  selectedParentGroup,
  selectedSite,
  selectedRole,
  sortModel,
}) {
  const params = { limit: paginationLimit, offset: page * paginationLimit };

  if (selectedParentGroup) params.type = selectedParentGroup;
  if (selectedSite) params.site = selectedSite;
  if (selectedRole) params.site = selectedRole;

  const ordering = getOrdering(sortModel);
  if (ordering) params.ordering = ordering;
  return params;
}
